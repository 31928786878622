import styled from "styled-components/macro"

const PageItem = styled.div<{ active?: boolean; disabled?: boolean; readonly?: boolean }>`
  min-width: 32px;
  height: 32px;
  padding: 0 2px;
  border-radius: 8px;
  border: 1px solid #cbd5e1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $size14;
  font-weight: 500;
  margin: 0 4px;
  cursor: pointer;

  svg {
    fill: #475569;
  }

  &.active,
  &:hover {
    border-color: #2db2c8;
    background: #ebfcff;
  }

  ${({ active }) =>
    active &&
    `
      border-color: #2db2c8;
      background: #ebfcff;
    `}

  ${({ disabled }) =>
    disabled &&
    `
      cursor: initial;
      border: none;
      background: #f1f5f9;

      &:hover {
        border-color: #cbd5e1;
        background: #f1f5f9;
      }

      svg { 
        fill: #cbd5e1;
      }
    `}

  ${({ readonly }) =>
    readonly &&
    `
      cursor: initial;

      &:hover {
        border-color: #cbd5e1;
        background: #ffffff;
      }
    `}
`

interface PaginationItemProps {
  page: number | "..."
  active?: boolean
  disabled?: boolean
  readonly?: boolean
  children: React.ReactNode
  onPageChange: (page: number) => void
}

const PaginationItem = ({ page, disabled, active, readonly, children, onPageChange }: PaginationItemProps) => {
  const handleCLick = () => {
    if (!disabled && !readonly && typeof page === "number") {
      onPageChange(page)
    }
  }

  return (
    <PageItem onClick={handleCLick} disabled={disabled} readonly={readonly} active={active}>
      {children}
    </PageItem>
  )
}

export default PaginationItem
