import { PropsWithChildren } from "react"
import classNames from "classnames"

import styles from "./index.module.scss"

interface Props extends PropsWithChildren {
  title: string
  subtitle?: string
  borderless?: boolean
  withShadow?: boolean
}

export const ModalHeader = ({ title, subtitle, borderless, withShadow, children }: Props) => {
  return (
    <header
      className={classNames(styles.modalHeader, {
        [styles.borderless]: borderless,
        [styles.modalHeaderShadow]: withShadow,
      })}
    >
      <figure className={styles.modalHeaderIconWrapper}>{children}</figure>
      <div>
        <h3 className={styles.modalHeaderTitle}>{title}</h3>
        <div className={styles.modalHeaderSubtitle}>{subtitle}</div>
      </div>
    </header>
  )
}
