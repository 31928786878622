export function parseQueryString(queryString: string): Record<string, string | string[]> {
  const params = new URLSearchParams(queryString)
  const result: Record<string, string | string[]> = {}

  const keys = Array.from(params.keys())

  keys.forEach((key: string) => {
    const values = params.getAll(key)
    result[key] = values.length === 1 ? values[0] : values
  })

  return result
}
