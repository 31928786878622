import { useEffect, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import dayjs from "dayjs"

import "assets/libs/react-datepicker.css"

import { ReactComponent as IconCalendar } from "assets/svg/icon-calendar.svg"
import { ReactComponent as IconTableCross } from "assets/svg/icon-table-cross.svg"

import { useOutsideClick } from "utils/useOutsideClick"
import useUrlQueryParams from "hooks/useUrlQueryParams"

import styles from "./index.module.scss"

interface Props {
  name?: string
}

export const TableDateRangePicker = ({ name }: Props) => {
  const startDateName = `${name}_start_date`
  const endDateName = `${name}_end_date`
  const [isActive, setActive] = useState(false)
  const [value, setValue] = useState("")
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()
  const datePickerRef = useRef(null)

  useOutsideClick(datePickerRef, () => setActive(false))

  const onClear = () => {
    setValue("")
    if (name) {
      setUrlQueryParams({
        [startDateName]: null,
        [endDateName]: null,
      })
    }
    setActive(false)
  }

  const onDateSelect = (date: Date | null) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD")

    if (name) {
      setUrlQueryParams({
        [startDateName]: formattedDate,
        [endDateName]: formattedDate,
      })
    }

    setValue(formattedDate)
    setActive(false)
  }

  useEffect(() => {
    if (name && urlQueryParams[startDateName] && !value) {
      setValue(urlQueryParams[startDateName])
      setActive(true)
    } else if (urlQueryParams && startDateName && !Object.hasOwn(urlQueryParams, startDateName)) {
      setValue("")
      setActive(false)
    }
  }, [urlQueryParams])

  return (
    <div className={styles.tableDateWrapper} onClick={() => {}}>
      {isActive ? (
        <div className={styles.inputWrapper} ref={datePickerRef}>
          <DatePicker
            value={value}
            dateFormat="y-m-d"
            open={true}
            name={startDateName}
            inline
            onSelect={onDateSelect}
          />
        </div>
      ) : value ? (
        <div className={styles.valueWrapper}>
          <span className={styles.value}>{value}</span>
          <span className={styles.icon} onClick={onClear}>
            <IconTableCross />
          </span>
        </div>
      ) : (
        <span className={styles.icon} onClick={() => setActive(true)}>
          <IconCalendar />
        </span>
      )}
    </div>
  )
}
