import { useRef, useState } from "react"
import { ConfigProvider, DatePicker, DatePickerProps } from "antd"
import dayjs, { Dayjs } from "dayjs"
import styled from "styled-components/macro"

import { UITypes } from "types"

import { useOutsideClick } from "utils/useOutsideClick"

import SelectTimeContainer, { TimeIndicator } from "./SelectTimeContainer"
import { timeSelectOptions } from "./timeSelectOptions"

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-end;
  border-radius: 8px;
  width: 290px;
  height: 440px;
  bottom: 30px;
  left: 200px;
  background-color: #fff;
  z-index: 10;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-picker-outlined {
    opacity: 0;
    display: none;
  }

  .ant-picker {
    position: relative;
    height: 32px;
    padding-bottom: 3px;
    z-index: 10000;
  }

  .ant-picker-footer {
    display: none;
  }

  .ant-picker-dropdown {
    position: relative !important;
    inset: 0 auto auto 0 !important;
  }

  .ant-picker-panel-container {
    box-shadow: none !important;
  }

  .ant-picker-body {
    padding: 6px !important;
  }

  @media only screen and (max-width: 520px) {
    left: auto;
  }
`

interface PerformanceTestDateTimePickerModalProps {
  onCancel: () => void
  selectedDateTime: Dayjs | null
  onDateTimeChange: (dateTime: Dayjs) => void
}

const PerformanceTestDateTimePickerModal = ({
  onCancel,
  selectedDateTime,
  onDateTimeChange,
}: PerformanceTestDateTimePickerModalProps) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null)
  const [selectedTime, setSelectedTime] = useState<UITypes.Option[]>([timeSelectOptions[0]])
  const [timeIndicator, setTimeIndicator] = useState<TimeIndicator>("AM")
  const container = useRef<HTMLDivElement>(null)

  useOutsideClick(container, () => onCancel())

  const handleTimeIndicatorClick = (indicator: TimeIndicator) => {
    setTimeIndicator(indicator)
    calculateDate(selectedDate, selectedTime, indicator)
  }

  const handleChangeOption = (option: UITypes.Option[]) => {
    setSelectedTime(option)
    calculateDate(selectedDate, option, timeIndicator)
  }

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date)
    calculateDate(date, selectedTime, timeIndicator)
  }

  const calculateDate = (date: Dayjs | null, selectedTime: UITypes.Option[], indicator: TimeIndicator) => {
    if (!date) {
      return
    }

    const time = (selectedTime[0].value as string).split(":")
    const hour = parseInt(time[0] === "12" ? "0" : time[0])
    const minute = parseInt(time[1])

    const newDate = date.hour(indicator === "AM" ? hour : hour + 12).minute(minute)
    onDateTimeChange(newDate)
  }

  const disabledRange: DatePickerProps["disabledDate"] = current => {
    return current < dayjs().startOf("day")
  }

  return (
    <Container ref={container}>
      <ConfigProvider
        theme={{
          token: {
            motion: false,
            colorPrimary: "#43e0f8",
            colorPrimaryBg: "#ebfcff",
          },
        }}
      >
        <DatePicker
          open
          placement="topLeft"
          getPopupContainer={() => container.current!}
          onChange={handleDateChange}
          disabledDate={disabledRange}
        />
      </ConfigProvider>
      <SelectTimeContainer
        selectedTime={selectedTime}
        selectedDateTime={selectedDateTime}
        timeIndicator={timeIndicator}
        onChangeOption={handleChangeOption}
        onTimeIndicatorClick={handleTimeIndicatorClick}
      />
    </Container>
  )
}

export default PerformanceTestDateTimePickerModal
