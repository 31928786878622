import { useEffect } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"

import { parseQueryString } from "utils/parseQueryString"
import { storageKeys } from "utils/storageKeys"

export const PrivateRoute = () => {
  const location = useLocation()

  useEffect(() => {
    // For the technician "add device flow"
    if (location?.pathname === "/add-device/customer" && location?.search !== "") {
      const result = parseQueryString(location?.search)

      if (result?.sn) {
        sessionStorage.setItem(storageKeys.technicianSN, JSON.stringify(result?.sn))
      }
    }
  }, [location])

  // const onLogOut = () => {
  //   sessionStorage.clear();
  //   window.location.reload();
  // };

  const checkCredentials = () => {
    const tokenStorage = localStorage.getItem(storageKeys.token)
    if (!tokenStorage) return false

    // TO-DO:
    // 1) check session time
    // 2) logout if session time is expired
    // toast.error("You have been signed out, time expired");

    return true
  }

  return checkCredentials() ? <Outlet /> : <Navigate to="/auth/sign-in" />
}
