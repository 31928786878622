import { useDispatch } from "react-redux"
import { CellContext } from "@tanstack/react-table"
import classNames from "classnames"

import { useLazyGetPerformanceTestReportQuery } from "store/api/performanceTestApi"
import { showPerformanceTestReportModal } from "store/modalSlise"
import { setPerformanceTestReport } from "store/tempDataSlise"
import { UITypes } from "types"

import styles from "./index.module.scss"

interface Props {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}

export const StatusOfPerformanceTest = ({ cell: { getValue, row } }: Props) => {
  const type = getValue().value ? String(getValue().value) : ""
  const currentItem = row.original.rowSettings.item
  const dispatch = useDispatch()
  const [triggerGetPerformanceTestReport] = useLazyGetPerformanceTestReportQuery()

  if (!type) return null

  const handleTableRowClick = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (!["passed", "issue"].includes(String(type)) || !currentItem) {
      return
    }

    e.stopPropagation()

    const res = await triggerGetPerformanceTestReport(currentItem?.perftest.perftest_result_id)
    if (res.data) {
      dispatch(
        setPerformanceTestReport({
          ...res?.data,
          customer: currentItem?.homeowner_name,
          zip_code: currentItem?.zip?.code,
          where_installed_name: currentItem?.residence_type?.name,
        }),
      )
      dispatch(showPerformanceTestReportModal(true))
    }
  }

  return (
    <span
      className={classNames(styles.performanceTestStatus, styles[type], {
        [styles.clickable]: ["passed", "issue"].includes(type),
      })}
      onClick={handleTableRowClick}
    >
      {type}
    </span>
  )
}
