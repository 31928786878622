import { Chart, TooltipModel } from "chart.js"

export const getOrCreateTooltip = (chart: Chart, customWidth: number) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector("div")

  if (!tooltipEl) {
    tooltipEl = document.createElement("div")
    Object.assign(tooltipEl.style, {
      background: "#ffffff",
      width: `${customWidth}px`,
      borderRadius: "8px",
      color: "#0f172a",
      opacity: "1",
      pointerEvents: "none",
      position: "absolute",
      transition: "all .1s ease",
      padding: "4px",
      border: "1px solid #f1f5f9",
      fontSize: "14px",
      zIndex: "100",
    })

    const table = document.createElement("table")
    table.style.margin = "0px"

    tooltipEl.appendChild(table)
    chart.canvas.parentNode?.appendChild(tooltipEl)
  }

  return tooltipEl
}

const calculateTooltipPosition = (
  chart: Chart,
  tooltip: TooltipModel<"bar">,
  tooltipEl: HTMLDivElement,
  customWidth: number,
) => {
  const { offsetLeft: positionX, offsetTop: positionY, offsetWidth, offsetHeight } = chart.canvas

  // Display, position, and set styles for font
  tooltipEl.style.opacity = "1"
  tooltipEl.style.left = positionX + tooltip.caretX + "px"

  if (offsetHeight - tooltip.caretY < tooltipEl.offsetHeight + 10) {
    tooltipEl.style.bottom = "20px"
    tooltipEl.style.top = "auto"
  } else {
    tooltipEl.style.top = positionY + tooltip.caretY + "px"
    tooltipEl.style.bottom = "auto"
  }

  if (tooltip.caretX > offsetWidth - customWidth / 2) {
    const translateX = Math.floor(customWidth - offsetWidth + tooltip.caretX + 5)
    tooltipEl.style.transform = `translate(-${translateX}px, 0)`
  } else if (tooltip.caretX < customWidth / 2 + 20) {
    const translateX = Math.floor(-tooltip.caretX + 35)
    tooltipEl.style.transform = `translate(${translateX}px, 0)`
  } else {
    tooltipEl.style.transform = `translate(-${Math.floor(customWidth / 2)}px, 0)`
  }
  tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px"
}

export const externalTooltipHandler = (context: { chart: Chart; tooltip: TooltipModel<"bar"> }, customWidth = 150) => {
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart, customWidth)

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0"
    return
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map(b => b.lines)

    const tableBody = document.createElement("tbody")
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i]

      if (tooltip.dataPoints[i].formattedValue === "0") {
        return
      }

      const container = document.createElement("div")
      Object.assign(container.style, {
        display: "flex",
        alignItems: "center",
        marginBottom: "5px",
      })

      const span = document.createElement("span")
      Object.assign(span.style, {
        background: colors.backgroundColor,
        borderColor: colors.borderColor,
        borderWidth: "2px",
        marginRight: "5px",
        height: "20px",
        width: "4px",
        display: "inline-block",
      })

      const tr = document.createElement("tr")
      tr.style.backgroundColor = "inherit"
      tr.style.borderWidth = "0"

      const td = document.createElement("td")
      td.style.borderWidth = "0"

      const text = document.createTextNode(body.join(" "))

      container.appendChild(span)
      container.appendChild(text)
      td.appendChild(container)
      tr.appendChild(td)
      tableBody.appendChild(tr)
    })

    const tableRoot = tooltipEl.querySelector("table")!

    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    tableRoot.appendChild(tableBody)
  }

  calculateTooltipPosition(chart, tooltip, tooltipEl, customWidth)
}
