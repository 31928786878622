import { useMemo, useState } from "react"
import { ColumnDef, ExpandedState, getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table"
import classNames from "classnames"
import styled from "styled-components/macro"

import { UITypes } from "types"

import { Spinner } from "components/common/Spinner"

import { getTableHeaderCellsConfig } from "./getTableHeaderCellsConfig"
import TableBody from "./TableBody"
import TableHeader from "./TableHeader"

import styles from "./index.module.scss"

export interface TableProps {
  tableName?: string
  headerCells: UITypes.TableHeaderCell[]
  data?: UITypes.TableBodyRow[]
  isLoading?: boolean
  currentItemID?: number | string | null
  currentItemIDs?: number[] | string[] | undefined
  statusOptions?: UITypes.Option[]
  className?: string
  isHeaderChecked?: boolean
  editable?: boolean
  columnsLoaded?: boolean
  onHeaderCheckboxChange?: () => void
  onCheckboxChange?: (id: number) => void
  onDeleteClick?: (id: number) => void
  onEditClick?: (id: number) => void
  dynamicNavHandler1?: (id: number) => void
  dynamicNavHandler2?: (id: number) => void
  dynamicNavHandler3?: (id: number) => void
  onSort?: (data: UITypes.Sort) => void
  onRowClick?: (id: number) => void
}

const TableContainer = styled.div<{ width: number }>`
  width: ${props => props.width}px;
  font-size: 14px;
`

function Table({
  tableName,
  headerCells,
  data,
  isLoading,
  currentItemID,
  currentItemIDs,
  className,
  isHeaderChecked,
  columnsLoaded = true,
  onHeaderCheckboxChange,
  onCheckboxChange,
  onDeleteClick,
  onEditClick,
  dynamicNavHandler1,
  dynamicNavHandler2,
  dynamicNavHandler3,
  onRowClick,
}: TableProps) {
  const [expanded, setExpanded] = useState<ExpandedState>(true)
  const hiddenColumns = useMemo(
    () => headerCells.filter(cell => cell.visibility === false).map(cell => cell.name),
    [headerCells],
  )

  const columns = useMemo<ColumnDef<UITypes.TableBodyRow, UITypes.TableBodyCell>[]>(
    () => getTableHeaderCellsConfig(headerCells),
    [headerCells],
  )

  const table = useReactTable<UITypes.TableBodyRow>({
    columns,
    data: data || [],
    getCoreRowModel: getCoreRowModel(),
    getSubRows: row => row.subRows,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    enableSorting: false,
    columnResizeMode: "onChange",
    defaultColumn: {
      maxSize: 500,
    },
    state: {
      expanded,
      columnVisibility: {
        ...hiddenColumns.reduce((acc, column) => ({ ...acc, [column]: false }), {}),
      },
    },
    meta: {
      currentItemID: currentItemID,
      currentItemIDs: currentItemIDs,
      isHeaderChecked: isHeaderChecked,
      onCheckboxChange: onCheckboxChange,
      onHeaderCheckboxChange: onHeaderCheckboxChange,
    },
  })

  return (
    <div className={classNames(styles.tableWrapper, className)}>
      {columnsLoaded && (
        <TableContainer width={table.getTotalSize()}>
          <TableHeader table={table} />
          <TableBody
            tableName={tableName}
            table={table}
            onRowClick={onRowClick}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            currentItemID={currentItemID}
            currentItemIDs={currentItemIDs}
            dynamicNavHandler1={dynamicNavHandler1}
            dynamicNavHandler2={dynamicNavHandler2}
            dynamicNavHandler3={dynamicNavHandler3}
          />
        </TableContainer>
      )}
      {(isLoading || !columnsLoaded) && (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default Table
