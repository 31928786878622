import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import classNames from "classnames"

import { useAppDispatch, useAppSelector } from "store/hooks"
import { showPerformanceTestActionsModal, showPerformanceTestCancelModal } from "store/modalSlise"
import { setSelectedDevicesForPerformanceTest } from "store/tempDataSlise"
import { ApiTypes } from "types"

import { convertDataToPerformanceTestTableRows } from "utils/convertData"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import { ButtonNew } from "components/form/ButtonNew"
import TableWrapper from "components/table/TableWrapper"
import { WidgetPerformanceTest } from "components/widgets/WidgetPerformanceTest"

import performanceTestTableHeaderCells from "./performanceTestTableHeaderCells"

import styles from "./index.module.scss"

interface PerformanceTestContentProps {
  devices: ApiTypes.Model.Device[]
  meta: ApiTypes.Model.Pagination | undefined
  isFetching: boolean
  widgetData: ApiTypes.Res.PerformanceTestWidgetData
  isFetchingWidgetData: boolean
  onExport: () => void
}

const PerformanceTestContent = ({
  devices,
  meta,
  isFetching,
  widgetData,
  isFetchingWidgetData,
  onExport,
}: PerformanceTestContentProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isHeaderChecked, setHeaderChecked] = useState(false)
  const { devices: selectedDevices } = useAppSelector(state => state.tempData.selectedDevicesForPerformanceTest)

  const allDevicesSelected = () => {
    if (devices.length === 0) return false
    return devices.every(item => selectedDevices.findIndex(device => device.id === item.id) > -1)
  }

  useEffect(() => {
    dispatch(setSelectedDevicesForPerformanceTest({ devices: [] }))
  }, [])

  useEffect(() => {
    setHeaderChecked(allDevicesSelected())
  }, [devices, selectedDevices])

  const handlePerformanceTestActions = () => {
    dispatch(showPerformanceTestActionsModal(true))
  }

  const handleCancelPerformanceTest = () => {
    dispatch(showPerformanceTestCancelModal(true))
  }

  const onSelectDeviceForPerformanceTest = (id?: number) => {
    if (selectedDevices?.some(item => item.id === id)) {
      dispatch(setSelectedDevicesForPerformanceTest({ devices: selectedDevices?.filter(item => item.id !== id) }))
    } else {
      const selectedItem = devices.find(item => item.id === id)
      dispatch(
        setSelectedDevicesForPerformanceTest({
          isImmediate: false,
          devices: [
            ...selectedDevices,
            ...[{ id, email: selectedItem?.email, sn: selectedItem?.sn, system_type: selectedItem?.system_type }],
          ] as ApiTypes.Model.PerformanceTestDevice[],
        }),
      )
    }
  }

  const toggleAllDevices = () => {
    if (isHeaderChecked) {
      dispatch(
        setSelectedDevicesForPerformanceTest({
          devices: selectedDevices?.filter(item => devices.findIndex(device => device.id === item.id) === -1),
        }),
      )
      setHeaderChecked(false)
    } else {
      dispatch(
        setSelectedDevicesForPerformanceTest({
          devices: [
            ...selectedDevices,
            ...devices
              .filter(item => selectedDevices.findIndex(device => device.id === item.id) === -1)
              .map(item => ({
                id: item.id,
                email: item.email,
                sn: item.sn,
                system_type: item.system_type,
              })),
          ] as ApiTypes.Model.PerformanceTestDevice[],
        }),
      )
      setHeaderChecked(true)
    }
  }

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetPerformanceTest widgetData={widgetData} isFetching={isFetchingWidgetData} />
      </div>
      <ContentWrapper title="Performance Test">
        <nav className={styles.tableNavWrapper}>
          <div className={styles.tableNavButtonsWrapper}>
            <ButtonNew
              className={classNames(styles.tableNavItem, styles.startPerformanceButton)}
              type="submit"
              disabled={selectedDevices.length === 0}
              onClick={handlePerformanceTestActions}
            >
              Start Performance Test
            </ButtonNew>
            <ButtonNew
              className={styles.cancelButton}
              type="submit"
              disabled={selectedDevices.length === 0}
              onClick={handleCancelPerformanceTest}
            >
              CANCEL
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <ButtonNew className={styles.tableNavItem} onClick={() => navigate("/perf-test-history")}>
              History
            </ButtonNew>
            <ButtonNew className={styles.tableNavItem} onClick={onExport} disabled={meta?.total === 0}>
              Export
            </ButtonNew>
          </div>
        </nav>
        <TableWrapper
          headerCells={performanceTestTableHeaderCells}
          data={convertDataToPerformanceTestTableRows(devices)}
          isLoading={isFetching}
          onCheckboxChange={onSelectDeviceForPerformanceTest}
          onHeaderCheckboxChange={toggleAllDevices}
          currentItemIDs={selectedDevices.map(item => item.id)}
          isHeaderChecked={isHeaderChecked}
        />
        <Pagination meta={meta} />
      </ContentWrapper>
    </>
  )
}

export default PerformanceTestContent
