import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  showAccountSettingsModal,
  showAddContractorModal,
  showAddDeviceModal,
  showAddTechnicianModal,
  showAddUserModal,
  showAddWarehouseModal,
  showAlertsAcknowledgeModal,
  showAlertsFreezeModal,
  showAlertsUnfreezeModal,
  showAlertWarrantyModal,
  showAutoRestockModal,
  showDamageDeviceModalForVendor,
  showDamageDeviceModalForWarehouse,
  showDeleteMessageTemplateModal,
  showDeleteOrderModal,
  showDeleteOrderModalForVendor,
  showDeviceHistoryLogModal,
  showEditColumnsModal,
  showEditContractorModal,
  showEditCustomerModal,
  showEditOrderModal,
  showEditTechnicianModal,
  showEditUserModal,
  showEditVendorOrderModal,
  showEditWarehouseModal,
  showMissingModalForContractor,
  showMissingModalForVendor,
  showMissingModalForWarehouse,
  showNewOrderModal,
  showNewVendorOrderModal,
  showOrderFulfillmentModal,
  showPackingOrderModal,
  showPerformanceTestActionsModal,
  showPerformanceTestCancelModal,
  showPerformanceTestConfirmModal,
  showPerformanceTestFailModal,
  showPerformanceTestReportModal,
  showReturnToWarehouseModal,
  showShippingOrderModal,
  showStatsReportModal,
  showTermsOfUseModal,
  showTransferDeviceModal,
  showTransferModal,
  showUndamageDeviceModalForVendor,
  showWarrantyModal,
} from "store/modalSlise"

import { AccountSettingsModal } from "modals/AccountSettingsModal"
import { AddContractorModal } from "modals/AddContractorModal"
import { AddDeviceModal } from "modals/AddDeviceModal"
import { AddTechnicianModal } from "modals/AddTechnicianModal"
import { AddUserModal } from "modals/AddUserModal"
import { AddWarehouseModal } from "modals/AddWarehouseModal"
import { AlertsAcknowledgeModal } from "modals/AlertsAcknowledgeModal"
import { AlertsFreezeModal } from "modals/AlertsFreezeModal"
import { AlertsUnfreezeModal } from "modals/AlertsUnfreezeModal"
import { AlertsWarrantyModal } from "modals/AlertsWarrantyModal"
import { AutoRestockModal } from "modals/AutoRestockModal"
import { DamageDeviceModalForVendor } from "modals/DamageDeviceModalForVendor"
import { DamageDeviceModalForWarehouse } from "modals/DamageDeviceModalForWarehouse"
import { DeleteMessageTemplateModal } from "modals/DeleteMessageTemplateModal"
import { DeleteOrderModal } from "modals/DeleteOrderModal"
import { DeleteOrderModalForVendor } from "modals/DeleteOrderModalForVendor"
import { DeviceHistoryLogModal } from "modals/DeviceHistoryLogModal"
import { EditColumnsModal } from "modals/EditColumnsModal"
import { EditContractorModal } from "modals/EditContractorModal"
import { EditCustomerModal } from "modals/EditCustomerModal"
import { EditOrderModal } from "modals/EditOrderModal"
import { EditTechnicianModal } from "modals/EditTechnicianModal"
import { EditUserModal } from "modals/EditUserModal"
import { EditVendorOrderModal } from "modals/EditVendorOrderModal"
import { EditWarehouseModal } from "modals/EditWarehouseModal"
import { MissingModalContractor } from "modals/MissingModalContractor"
import { MissingModalVendor } from "modals/MissingModalVendor"
import { MissingModalWarehouse } from "modals/MissingModalWarehouse"
import { NewOrderModal } from "modals/NewOrderModal"
import { NewVendorOrderModal } from "modals/NewVendorOrderModal"
import { OrderFulfillmentModal } from "modals/OrderFulfillmentModal"
import { PackingDeviceModal } from "modals/PackingDeviceModal"
import { PerformanceTestActionsModal } from "modals/PerformanceTestActionsModal"
import { PerformanceTestCancelModal } from "modals/PerformanceTestCancelModal"
import { PerformanceTestConfirmModal } from "modals/PerformanceTestConfirmModal"
import { PerformanceTestFailModal } from "modals/PerformanceTestFailModal"
import { PerformanceTestReportModal } from "modals/PerformanceTestReportModal"
import { ReturnToWarehouseModal } from "modals/ReturnToWarehouseModal"
import { ShippingModal } from "modals/ShippingModal"
import { ShippingWarningModal } from "modals/ShippingWarningModal"
import { StatsReportModal } from "modals/StatsReportModal"
import { TermOfUseModal } from "modals/TermOfUseModal"
import { TransferDeviceModal } from "modals/TransferDeviceModal"
import { TransferModal } from "modals/TransferModal"
import { UndamageDeviceModalForVendor } from "modals/UndamageDeviceModalForVendor"
import { WarrantyModal } from "modals/WarrantyModal"

export const Modals = () => {
  const dispatch = useAppDispatch()
  const isDeleteOrderModalVisible = useAppSelector(state => state.modals.isDeleteOrderModalVisible)
  const isDeleteOrderModalForVendorVisible = useAppSelector(state => state.modals.isDeleteOrderModalForVendorVisible)
  const isNewVendorOrderModalVisible = useAppSelector(state => state.modals.isNewVendorOrderModalVisible)
  const isNewOrderModalVisible = useAppSelector(state => state.modals.isNewOrderModalVisible)
  const isOrderFulfillmentModalVisible = useAppSelector(state => state.modals.isOrderFulfillmentModalVisible)
  const isAddTechnicianModalVisible = useAppSelector(state => state.modals.isAddTechnicianModalVisible)
  const isEditTechnicianModalVisible = useAppSelector(state => state.modals.isEditTechnicianModalVisible)
  const isEditOrderModalVisible = useAppSelector(state => state.modals.isEditOrderModalVisible)
  const isEditVendorOrderModalVisible = useAppSelector(state => state.modals.isEditVendorOrderModalVisible)
  const isAutoRestockModalVisible = useAppSelector(state => state.modals.isAutoRestockModalVisible)
  const isTransferModalVisible = useAppSelector(state => state.modals.isTransferModalVisible)
  const isMissingModalForWarehouseVisible = useAppSelector(state => state.modals.isMissingModalForWarehouseVisible)
  const isMissingModalForVendorVisible = useAppSelector(state => state.modals.isMissingModalForVendorVisible)
  const isMissingModalForContractorVisible = useAppSelector(state => state.modals.isMissingModalForContractorVisible)
  const isTermsOfUseModalVisible = useAppSelector(state => state.modals.isTermsOfUseModalVisible)
  const isAddContractorModalVisible = useAppSelector(state => state.modals.isAddContractorModalVisible)
  const isEditContractorModalVisible = useAppSelector(state => state.modals.isEditContractorModalVisible)
  const isAlertsFreezeModalVisible = useAppSelector(state => state.modals.isAlertsFreezeModalVisible)
  const isAlertsUnfreezeModalVisible = useAppSelector(state => state.modals.isAlertsUnfreezeModalVisible)
  const isAlertsAcknowledgeModalVisible = useAppSelector(state => state.modals.isAlertsAcknowledgeModalVisible)
  const isShippingOrderModalVisible = useAppSelector(state => state.modals.isShippingOrderModalVisible)
  const isShippingWarningModalVisible = useAppSelector(state => state.modals.isShippingWarningModalVisible)
  const isDamageDeviceModalForWarehouseVisible = useAppSelector(
    state => state.modals.isDamageDeviceModalForWarehouseVisible,
  )
  const isDamageDeviceModalForVendorVisible = useAppSelector(state => state.modals.isDamageDeviceModalForVendorVisible)
  const isUndamageDeviceModalForVendorVisible = useAppSelector(
    state => state.modals.isUndamageDeviceModalForVendorVisible,
  )
  const isAddWarehouseModalVisible = useAppSelector(state => state.modals.isAddWarehouseModalVisible)
  const isEditWarehouseModalVisible = useAppSelector(state => state.modals.isEditWarehouseModalVisible)
  const isAddUserModalVisible = useAppSelector(state => state.modals.isAddUserModalVisible)
  const isEditUserModalVisible = useAppSelector(state => state.modals.isEditUserModalVisible)
  const isAddDeviceModalVisible = useAppSelector(state => state.modals.isAddDeviceModalVisible)
  const isTransferDeviceModalVisible = useAppSelector(state => state.modals.isTransferDeviceModalVisible)
  const isReturnToWarehouseModalVisible = useAppSelector(state => state.modals.isReturnToWarehouseModalVisible)
  const isWarrantyModalVisible = useAppSelector(state => state.modals.isWarrantyModalVisible)
  const isDeviceHistoryLogModalVisible = useAppSelector(state => state.modals.isDeviceHistoryLogModalVisible)
  const isPackingOrderModalVisible = useAppSelector(state => state.modals.isPackingOrderModalVisible)
  const isAccountSettingsModalVisible = useAppSelector(state => state.modals.isAccountSettingsModalVisible)
  const isEditCustomerModalVisible = useAppSelector(state => state.modals.isEditCustomerModalVisible)
  const isAlertWarrantyModalVisible = useAppSelector(state => state.modals.isAlertWarrantyModalVisible)
  const isEditColumnsModalVisible = useAppSelector(state => state.modals.isEditColumnsModalVisible)
  const isStatsReportModalVisible = useAppSelector(state => state.modals.isStatsReportModalVisible)

  const isDeleteMessageTemplateModalVisible = useAppSelector(state => state.modals.isDeleteMessageTemplateModalVisible)
  const isPerformanceTestActionsModalVisible = useAppSelector(
    state => state.modals.isPerformanceTestActionsModalVisible,
  )
  const isPerformanceTestConfirmModalVisible = useAppSelector(
    state => state.modals.isPerformanceTestConfirmModalVisible,
  )
  const isPerformanceTestCancelModalVisible = useAppSelector(state => state.modals.isPerformanceTestCancelModalVisible)
  const isPerformanceTestFailModalVisible = useAppSelector(state => state.modals.isPerformanceTestFailModalVisible)
  const isPerformanceTestReportModalVisible = useAppSelector(state => state.modals.isPerformanceTestReportModalVisible)

  return (
    <>
      {isNewOrderModalVisible && <NewOrderModal onCancel={() => dispatch(showNewOrderModal(false))} />}
      {isNewVendorOrderModalVisible && (
        <NewVendorOrderModal onCancel={() => dispatch(showNewVendorOrderModal(false))} />
      )}
      {isOrderFulfillmentModalVisible && (
        <OrderFulfillmentModal onCancel={() => dispatch(showOrderFulfillmentModal(false))} />
      )}
      {isAddTechnicianModalVisible && <AddTechnicianModal onCancel={() => dispatch(showAddTechnicianModal(false))} />}
      {isEditTechnicianModalVisible && (
        <EditTechnicianModal onCancel={() => dispatch(showEditTechnicianModal(false))} />
      )}
      {isEditOrderModalVisible && <EditOrderModal onCancel={() => dispatch(showEditOrderModal(false))} />}
      {isEditVendorOrderModalVisible && (
        <EditVendorOrderModal onCancel={() => dispatch(showEditVendorOrderModal(false))} />
      )}
      {isDeleteOrderModalVisible && <DeleteOrderModal onCancel={() => dispatch(showDeleteOrderModal(false))} />}
      {isAutoRestockModalVisible && <AutoRestockModal onCancel={() => dispatch(showAutoRestockModal(false))} />}
      {isTransferModalVisible && <TransferModal onCancel={() => dispatch(showTransferModal(false))} />}
      {isMissingModalForWarehouseVisible && (
        <MissingModalWarehouse onCancel={() => dispatch(showMissingModalForWarehouse(false))} />
      )}
      {isMissingModalForVendorVisible && (
        <MissingModalVendor onCancel={() => dispatch(showMissingModalForVendor(false))} />
      )}
      {isMissingModalForContractorVisible && (
        <MissingModalContractor onCancel={() => dispatch(showMissingModalForContractor(false))} />
      )}
      {isTermsOfUseModalVisible && <TermOfUseModal onCancel={() => dispatch(showTermsOfUseModal(false))} />}
      {isAddContractorModalVisible && <AddContractorModal onCancel={() => dispatch(showAddContractorModal(false))} />}
      {isEditContractorModalVisible && (
        <EditContractorModal onCancel={() => dispatch(showEditContractorModal(false))} />
      )}
      {isAlertsFreezeModalVisible && <AlertsFreezeModal onCancel={() => dispatch(showAlertsFreezeModal(false))} />}
      {isAlertsUnfreezeModalVisible && (
        <AlertsUnfreezeModal onCancel={() => dispatch(showAlertsUnfreezeModal(false))} />
      )}
      {isAlertsAcknowledgeModalVisible && (
        <AlertsAcknowledgeModal onCancel={() => dispatch(showAlertsAcknowledgeModal(false))} />
      )}
      {isDeleteMessageTemplateModalVisible && (
        <DeleteMessageTemplateModal onCancel={() => dispatch(showDeleteMessageTemplateModal(false))} />
      )}
      {isShippingOrderModalVisible && <ShippingModal onCancel={() => dispatch(showShippingOrderModal(false))} />}
      {isDamageDeviceModalForWarehouseVisible && (
        <DamageDeviceModalForWarehouse onCancel={() => dispatch(showDamageDeviceModalForWarehouse(false))} />
      )}
      {isDamageDeviceModalForVendorVisible && (
        <DamageDeviceModalForVendor onCancel={() => dispatch(showDamageDeviceModalForVendor(false))} />
      )}
      {isUndamageDeviceModalForVendorVisible && (
        <UndamageDeviceModalForVendor onCancel={() => dispatch(showUndamageDeviceModalForVendor(false))} />
      )}
      {isShippingWarningModalVisible && <ShippingWarningModal />}
      {isAddWarehouseModalVisible && <AddWarehouseModal onCancel={() => dispatch(showAddWarehouseModal(false))} />}
      {isEditWarehouseModalVisible && <EditWarehouseModal onCancel={() => dispatch(showEditWarehouseModal(false))} />}
      {isAddUserModalVisible && <AddUserModal onCancel={() => dispatch(showAddUserModal(false))} />}
      {isEditUserModalVisible && <EditUserModal onCancel={() => dispatch(showEditUserModal(false))} />}
      {isTransferDeviceModalVisible && (
        <TransferDeviceModal onCancel={() => dispatch(showTransferDeviceModal(false))} />
      )}
      {isReturnToWarehouseModalVisible && (
        <ReturnToWarehouseModal onCancel={() => dispatch(showReturnToWarehouseModal(false))} />
      )}
      {isWarrantyModalVisible && <WarrantyModal onCancel={() => dispatch(showWarrantyModal(false))} />}
      {isDeviceHistoryLogModalVisible && (
        <DeviceHistoryLogModal onCancel={() => dispatch(showDeviceHistoryLogModal(false))} />
      )}
      {isAccountSettingsModalVisible && (
        <AccountSettingsModal onCancel={() => dispatch(showAccountSettingsModal(false))} />
      )}
      {isEditCustomerModalVisible && <EditCustomerModal onCancel={() => dispatch(showEditCustomerModal(false))} />}
      {isAlertWarrantyModalVisible && <AlertsWarrantyModal onCancel={() => dispatch(showAlertWarrantyModal(false))} />}
      {isDeleteOrderModalForVendorVisible && (
        <DeleteOrderModalForVendor onCancel={() => dispatch(showDeleteOrderModalForVendor(false))} />
      )}
      {isPerformanceTestActionsModalVisible && (
        <PerformanceTestActionsModal onCancel={() => dispatch(showPerformanceTestActionsModal(false))} />
      )}
      {isPerformanceTestConfirmModalVisible && (
        <PerformanceTestConfirmModal onCancel={() => dispatch(showPerformanceTestConfirmModal(false))} />
      )}
      {isPerformanceTestCancelModalVisible && (
        <PerformanceTestCancelModal onCancel={() => dispatch(showPerformanceTestCancelModal(false))} />
      )}
      {isPerformanceTestFailModalVisible && (
        <PerformanceTestFailModal onCancel={() => dispatch(showPerformanceTestFailModal(false))} />
      )}
      {isPerformanceTestReportModalVisible && (
        <PerformanceTestReportModal onCancel={() => dispatch(showPerformanceTestReportModal(false))} />
      )}

      {/* DEVICES */}
      {isAddDeviceModalVisible && <AddDeviceModal onCancel={() => dispatch(showAddDeviceModal(false))} />}
      {isPackingOrderModalVisible && <PackingDeviceModal onCancel={() => dispatch(showPackingOrderModal(false))} />}
      {isEditColumnsModalVisible && <EditColumnsModal onCancel={() => dispatch(showEditColumnsModal(false))} />}
      {isStatsReportModalVisible && <StatsReportModal onCancel={() => dispatch(showStatsReportModal(false))} />}
    </>
  )
}
