import { useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"

import { useLazyEditNewOrderQuery, useLazyGetOrdersQuery } from "store/api/ordersApi"
import { useAppSelector } from "store/hooks"
import { ReactComponent as IconNewOrder } from "assets/svg/modal-icon-new-order.svg"

import getErrorMessage from "utils/getErrorMessage"
import OrdersModalContent from "modals/NewOrderModal/OrdersModalContent"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"

interface Props {
  onCancel: () => void
}

export const EditOrderModal = ({ onCancel }: Props) => {
  const [triggerEditNewOrder, { isFetching }] = useLazyEditNewOrderQuery()
  const [triggerGetOrders] = useLazyGetOrdersQuery()
  const currentOrder = useAppSelector(state => state.tempData.currentOrder)
  const [quantity, setQuantity] = useState(currentOrder?.quantity || 0)

  const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()

    try {
      const res = await triggerEditNewOrder({
        data: { quantity: quantity },
        id: currentOrder?.id || 0,
      })

      const errorMessage = getErrorMessage(res.error)

      if (errorMessage) {
        toast.error(errorMessage)
      } else {
        toast.success("Order has been successfully updated!")
        triggerGetOrders({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="570px" onConfirm={onSubmit} onCancel={onCancel} isFetching={isFetching}>
          <ModalHeader title="Edit order" subtitle="You can update the number of Nuve Thermostats.">
            <IconNewOrder />
          </ModalHeader>
          <OrdersModalContent quantity={quantity} setQuantity={setQuantity} />
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
