import { Bar } from "react-chartjs-2"
import { Chart, registerables, TooltipItem, TooltipModel } from "chart.js"
import dayjs, { Dayjs } from "dayjs"
import styled from "styled-components/macro"

import { ApiTypes, UITypes } from "types"

import getLegendMarginPlugin from "utils/chartjs/getLegendMarginPlugin"
import { defaultChartTooltipOptions } from "utils/chartUtils"
import DateRangeSelect from "components/DateRangeSelect"

Chart.register(...registerables)

const Container = styled.div`
  padding: 20px 35px;
`

const BarChartContainer = styled.div`
  padding-top: 50px;
  height: 400px;
`

const settings = {
  yesterday: {
    labelX: "Hours",
    barWidth: 0.7,
  },
  last3Days: {
    labelX: "Days",
    barWidth: 0.5,
  },
  last7Days: {
    labelX: "Days",
    barWidth: 0.7,
  },
  last30Days: {
    labelX: "Days",
    barWidth: 0.7,
  },
  custom: {
    labelX: "Days",
    barWidth: 0.7,
  },
}

interface InstalledDevicesHistoryModalContentProps {
  selectedTime: UITypes.Option[]
  setSelectedTime: (time: UITypes.Option[]) => void
  selectedDateRange: [Dayjs, Dayjs] | null
  setSelectedDateRange: (dateRange: [Dayjs, Dayjs] | null) => void
  daysCount: number
  historicalData: ApiTypes.Model.InstalledDevicesCount[] | null
}

export const InstalledDevicesHistoryModalContent = ({
  selectedTime,
  selectedDateRange,
  daysCount,
  historicalData,
  setSelectedTime,
  setSelectedDateRange,
}: InstalledDevicesHistoryModalContentProps) => {
  const selectedSettings = settings[selectedTime[0].value as keyof typeof settings]
  const ticks = daysCount

  const data = {
    labels: historicalData?.map(item => item.date) || [],
    datasets: [
      {
        label: "Installed Devices",
        backgroundColor: "#22c55e",
        data: historicalData?.map(item => item.count) || [],
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
    ],
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
        align: "start" as const,
        labels: {
          color: "#94A3B8",
          usePointStyle: true,
          pointStyle: "rect",
        },
      },
      tooltip: {
        ...defaultChartTooltipOptions,
        displayColors: false,
        titleAlign: "center" as const,
        callbacks: {
          title: function (this: TooltipModel<"bar">, tooltipItems: TooltipItem<"bar">[]) {
            return `Devices: ${tooltipItems[0].formattedValue}`
          },
          label: function (this: TooltipModel<"bar">, tooltipItem: TooltipItem<"bar">) {
            return dayjs(tooltipItem.label).format("DD MMMM, YYYY")
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Days",
          color: "#94A3B8",
        },
        ticks: {
          color: "#94A3B8",
          callback: (value: number | string) => Number(value) + 1,
          maxTicksLimit: ticks + 1,
        },
      },
      y: {
        suggestedMax: 5,
        title: {
          display: true,
          text: "Devices",
          color: "#94A3B8",
        },
        ticks: {
          maxTicksLimit: 6,
          color: "#94A3B8",
        },
      },
    },
  }

  return (
    <Container>
      <DateRangeSelect
        skipTimeOptions
        selectedTime={selectedTime}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        setSelectedTime={setSelectedTime}
      />
      <BarChartContainer>
        <Bar data={data} options={options} plugins={[getLegendMarginPlugin(10)]} />
      </BarChartContainer>
    </Container>
  )
}
