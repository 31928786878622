import { FormEvent, useEffect, useState } from "react"
import { toast } from "react-toastify"

import { useAppSelector } from "store/hooks"
import { UITypes } from "types"

import { convertDataToOptions } from "utils/convertData"
import {
  contractorSchema,
  contractorSTIntegrationSchema,
  onFormValidate,
  validationInitialState,
} from "utils/onValidate"

import DetailsSection from "./DetailsSection"
import InfoSection from "./InfoSection"
import IntegrationSection from "./IntegrationSection"
import SubscriptionSection from "./SubscriptionSection"
import prepareFormData from "./utils/prepareFormData"

import styles from "./index.module.scss"

interface Props {
  isCreate?: boolean
  contractor: UITypes.Contractor
  onSubmit: (data: FormData, contractor: UITypes.Contractor) => void
}

export const ContractorModalContent = ({ contractor, isCreate, onSubmit }: Props) => {
  const [tenantIDStatus, setTenantIDStatus] = useState<UITypes.TenantIDStautus>("check")
  const [memberships, setMemberships] = useState<UITypes.Option[]>([])
  const [inputValues, setInputValues] = useState(contractor!)
  const [error, setError] = useState(validationInitialState)
  const currentZip = useAppSelector(state => state.tempData.currentZip)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues(prevState => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const stFieldsNotEmpty = () => {
    return (
      inputValues.stClientID?.length ||
      inputValues.stClientSecret?.length ||
      inputValues.stAppKey?.length ||
      inputValues.stTenant?.length
    )
  }

  const handleSubmit = (event: FormEvent<HTMLDivElement>) => {
    event.preventDefault()

    if (!onFormValidate(inputValues, contractorSchema, setError, true)) return

    if (
      (contractor?.isSTChecked === "1" || stFieldsNotEmpty()) &&
      !onFormValidate(inputValues, contractorSTIntegrationSchema, setError, true)
    ) {
      return
    }

    if (isCreate && !inputValues.image) {
      setError({ field: "logo", message: "Logo is required" })
      return
    }

    if (stFieldsNotEmpty() && tenantIDStatus !== "pass") {
      toast.error("Please check Tenant ID")
      return
    }

    onSubmit(prepareFormData(inputValues, tenantIDStatus, memberships), inputValues)
  }

  useEffect(() => {
    if (currentZip?.city) {
      const city = convertDataToOptions({ data: [currentZip.city] }, "id", "name")
      const state = convertDataToOptions({ data: [currentZip.state] }, "id", "name")
      const country = convertDataToOptions({ data: [currentZip.country] }, "id", "name")
      const suburb = convertDataToOptions(
        { data: currentZip.suburbs?.length > 0 ? [currentZip.suburbs[0]] : null },
        "id",
        "name",
      )

      if (city && state) {
        setInputValues({
          ...inputValues,
          ...{
            city,
            state,
            country,
            suburb,
          },
        })
      }
    }
  }, [currentZip])

  return (
    <>
      <div className={styles.saveButton} onClick={handleSubmit}>
        Save
      </div>
      <div className={styles.contractorContainer}>
        <DetailsSection
          inputValues={inputValues}
          error={error}
          onInputChange={onInputChange}
          setInputValues={setInputValues}
        />
        <SubscriptionSection
          inputValues={inputValues}
          onInputChange={onInputChange}
          setInputValues={setInputValues}
          isCreate={isCreate}
        />
        <InfoSection
          inputValues={inputValues}
          error={error}
          setError={setError}
          onInputChange={onInputChange}
          setInputValues={setInputValues}
        />
        <IntegrationSection
          inputValues={inputValues}
          error={error}
          setError={setError}
          onInputChange={onInputChange}
          tenantIDStatus={tenantIDStatus}
          setTenantIDStatus={setTenantIDStatus}
          memberships={memberships}
          setMemberships={setMemberships}
        />
      </div>
    </>
  )
}
