import { createApi } from "@reduxjs/toolkit/query/react"

import { ApiTypes, UITypes } from "types"

import { baseQueryWithAuth } from "utils/api"
import { prepareQueryParams } from "utils/prepareQueryParams"

export const dashboardApi = createApi({
  reducerPath: "dashboard",
  tagTypes: ["dashboard"],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    getTableColumns: builder.query<{ data: ApiTypes.Model.TableColumns }, UITypes.Params>({
      query: arg => ({
        url: `/dashboard/table-column-orders`,
        params: prepareQueryParams(arg),
        headers: { Accept: "application/json" },
      }),
    }),
    updateTableColumns: builder.query<void, { body: ApiTypes.Model.TableColumns }>({
      query: data => ({
        url: `/dashboard/table-column-orders`,
        body: data?.body,
        method: "POST",
        headers: { Accept: "application/json" },
      }),
    }),
  }),
})

export const { useLazyGetTableColumnsQuery, useLazyUpdateTableColumnsQuery } = dashboardApi
