import { FormEvent, useState } from "react"
import { toast } from "react-toastify"
import classNames from "classnames"

import { useLazyChangeContractorPasswordQuery } from "store/api/contractorsApi"

import getErrorMessage from "utils/getErrorMessage"
import { changeContractorPassword, onFormValidate, validationInitialState } from "utils/onValidate"
import { Spinner } from "components/common/Spinner"
import { ButtonNew } from "components/form/ButtonNew"
import { InputNew } from "components/form/InputNew"

import styles from "./index.module.scss"

const inputValuesInitialState = {
  currentPassword: "",
  password: "",
  passwordConfirmation: "",
}

interface Props {
  onCancel: () => void
}

export const AccountSettingsPassword = ({ onCancel }: Props) => {
  const [triggerProfileChangePassword, { isFetching }] = useLazyChangeContractorPasswordQuery()
  const [inputValues, setInputValues] = useState({ ...inputValuesInitialState })
  const [error, setError] = useState(validationInitialState)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues(prevState => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const sendRequest = async (data: typeof inputValues) => {
    try {
      const res = await triggerProfileChangePassword(data)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success("Account has been successfully updated!")
        setInputValues(inputValuesInitialState)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (onFormValidate(inputValues, changeContractorPassword, setError)) {
      sendRequest(inputValues)
    }
  }

  return (
    <>
      <div className={classNames(styles.modalContent, styles.settings)}>
        <h4 className={styles.fullWidthTitle}>Change password</h4>
        <InputNew
          label="Current password*"
          placeholder="Current password"
          name="currentPassword"
          inputType="password"
          value={inputValues?.currentPassword}
          onChange={onInputChange}
          errorMessage={error.field === "currentPassword" ? error.message : ""}
        />
        <InputNew
          label="New password*"
          placeholder="New password"
          name="password"
          inputType="password"
          value={inputValues?.password}
          onChange={onInputChange}
          errorMessage={error.field === "password" ? error.message : ""}
        />
        <InputNew
          label="Confirm new password*"
          placeholder="Confirm new password"
          name="passwordConfirmation"
          inputType="password"
          value={inputValues?.passwordConfirmation}
          onChange={onInputChange}
          errorMessage={error.field === "passwordConfirmation" ? error.message : ""}
        />
      </div>
      <footer className={styles.modalFooter}>
        <ButtonNew onClick={onCancel} size="halfWidth" color="greyBordered">
          Cancel
        </ButtonNew>
        <ButtonNew onClick={onSubmit} size="halfWidth" type="submit" color="blue" disabled={isFetching}>
          {isFetching ? <Spinner /> : "Save"}
        </ButtonNew>
      </footer>
    </>
  )
}
