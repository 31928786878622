import { createPortal } from "react-dom"
import { toast } from "react-toastify"

import { useLazyCreateContractorQuery, useLazyGetContractorsQuery } from "store/api/contractorsApi"
import { useAppDispatch } from "store/hooks"
import { setCurrentZip } from "store/tempDataSlise"
import { UITypes } from "types"
import SubscriptionPlan from "types/enums/SubscriptionPlan"
import { ReactComponent as IconContractor } from "assets/svg/modal-icon-contractor.svg"

import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"

import { ContractorModalContent } from "../components/contractorModalContent"
import styles from "../EditContractorModal/index.module.scss"

const inputValuesInitialState: UITypes.Contractor = {
  name: "",
  brand: "",
  parentGroup: [],
  address1: "",
  address2: "",
  zip: [],
  state: [],
  city: [],
  suburb: [],
  url: "",
  schedule: "",
  fullName: "",
  phone: "",
  email: "",
  alertEmail: "",
  emailAlerts: true,
  isDisable: false,
  appStoreLink: "",
  googlePlayLink: "",
  phoneForSupport: true,
  phoneForSupportCall: "",
  phoneForSupportText: "",
  stClientID: "",
  stClientSecret: "",
  stAppKey: "",
  stTenant: "",
  subscriptionPlan: SubscriptionPlan.BASIC,
}

interface Props {
  onCancel: () => void
}

export const AddContractorModal = ({ onCancel }: Props) => {
  const dispatch = useAppDispatch()
  const [triggerGetContractors] = useLazyGetContractorsQuery()
  const [triggerCreateContractor, { isFetching }] = useLazyCreateContractorQuery()
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (data: FormData) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await triggerCreateContractor(data)

      if (res?.error) {
        const errors = Object.keys(res?.error?.data?.errors || {})
          .map(key => res?.error?.data?.errors[key][0])
          .join(", ")

        toast.error(errors.length > 0 ? errors : res?.error?.data?.message)
      } else {
        toast.success("Contractor has been successfully added!")
        triggerGetContractors(urlQueryParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onCancelClick = () => {
    dispatch(setCurrentZip(undefined))
    onCancel()
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="770px"
          onClose={onCancelClick}
          closeIconClassName={styles.closeIconClassName}
          isDrawer
          isWithoutFooter
          isFetching={isFetching}
        >
          <ModalHeader title="Add Contractor" borderless withShadow>
            <IconContractor />
          </ModalHeader>
          <ContractorModalContent contractor={inputValuesInitialState} onSubmit={onSubmit} isCreate />
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
