import { FormEvent, SetStateAction } from "react"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import { UITypes } from "types"

import { urlPatternWithoutHttpPrefix } from "utils/regularExpressions"
import ModalSectionTitle from "components/common/ModalSectionTitle/ModalSectionTitle"
import { CheckboxNew } from "components/form/CheckboxNew"
import { InputLogo } from "components/form/InputLogo"
import { InputNew } from "components/form/InputNew"
import { InputPhone } from "components/form/InputPhone"

import { ContractorCustomApp } from "../../components/contractorCustomApp"
import { ContractorPhoneSupport } from "../../components/contractorPhoneSupport"

import styles from "./index.module.scss"

interface Props {
  inputValues: UITypes.Contractor
  error: {
    field: string
    message: string
  }
  setError: (value: SetStateAction<{ field: string; message: string }>) => void
  onInputChange: (event: FormEvent<HTMLInputElement>) => void
  setInputValues: (value: SetStateAction<UITypes.Contractor>) => void
}

const InfoSection = ({ inputValues, error, setError, onInputChange, setInputValues }: Props) => {
  const onInputBlur = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget

    if (urlPatternWithoutHttpPrefix.test(value)) {
      const urlPattern = /^(http:\/\/|https:\/\/)/

      setInputValues(prevState => {
        return { ...prevState, [name]: `${urlPattern.test(value) ? "" : "https://"}${value}` }
      })
    }
  }

  return (
    <div>
      <ModalSectionTitle title="Info" />
      <div className={styles.row}>
        <InputNew
          label="Company web URL"
          placeholder="Enter URL"
          name="url"
          value={inputValues.url}
          className={styles.inputText}
          onChange={onInputChange}
          onBlur={onInputBlur}
          errorMessage={error.field === "url" ? error.message : ""}
        />
        <InputNew
          label="Schedule URL"
          placeholder="Enter schedule URL"
          name="schedule"
          value={inputValues.schedule}
          className={styles.inputText}
          onChange={onInputChange}
          onBlur={onInputBlur}
          errorMessage={error.field === "schedule" ? error.message : ""}
        />
      </div>
      <div className={styles.row}>
        <InputNew
          label="Full Name"
          placeholder="Enter Full Name"
          name="fullName"
          value={inputValues.fullName}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "fullName" ? error.message : ""}
        />
      </div>
      <div className={styles.row}>
        <InputPhone
          label="Phone number"
          placeholder="Enter phone"
          name="phone"
          value={inputValues.phone}
          className={styles.inputText}
          onChange={value => setInputValues({ ...inputValues, ...{ phone: value } })}
          errorMessage={error.field === "phone" ? error.message : ""}
        />
      </div>
      <ContractorPhoneSupport
        phoneForSupport={inputValues.phoneForSupport}
        phoneForSupportCall={inputValues.phoneForSupportCall}
        phoneForSupportText={inputValues.phoneForSupportText}
        inputValues={inputValues}
        error={error}
        setError={setError}
        setInputValues={setInputValues}
      />
      <div className={classNames(styles.row, styles.directionColumn)}>
        <InputNew
          label="Email"
          placeholder="Enter email address"
          name="email"
          value={inputValues.email}
          className={styles.inputText}
          onChange={onInputChange}
          errorMessage={error.field === "email" ? error.message : ""}
        />
        <CheckboxNew
          id={uuidv4()}
          className={styles.emailAlertsCheckbox}
          name="emailAlerts"
          onChange={onInputChange}
          isChecked={inputValues.emailAlerts}
        >
          Use this email address to receive alert notifications
        </CheckboxNew>
      </div>
      <div className={styles.row}>
        <InputNew
          label="Email for alert notifications (optional)"
          placeholder="Email"
          name="alertEmail"
          onChange={onInputChange}
          value={inputValues.alertEmail}
          errorMessage={error.field === "alertEmail" ? error.message : ""}
        />
      </div>
      <div className={styles.row}>
        <InputLogo
          name="logo"
          id="logo"
          value={inputValues.image && [inputValues.image]}
          imageUrl={inputValues.logo}
          errorMessage={error.field === "logo" ? error.message : ""}
          label="Company logo"
          formatNote="(Format: Png Ratio: 2:1 Max size: 750x375px)"
          onSetFile={files =>
            setInputValues(prevState => {
              return { ...prevState, image: files?.[0] }
            })
          }
        />
      </div>
      <div className={styles.row}>
        <ContractorCustomApp
          customAppEnabled={inputValues.isCustom || false}
          appStoreLink={inputValues.appStoreLink}
          googlePlayLink={inputValues.googlePlayLink}
          error={error}
          setError={setError}
          setInputValues={setInputValues}
          onInputChange={onInputChange}
          onInputBlur={onInputBlur}
        />
      </div>
    </div>
  )
}

export default InfoSection
