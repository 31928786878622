import { createApi } from "@reduxjs/toolkit/query/react"

import { ApiTypes, UITypes } from "types"

import { baseQueryWithAuth } from "utils/api"
import { prepareQueryParams } from "utils/prepareQueryParams"

export const contractorsApi = createApi({
  reducerPath: "contractors",
  tagTypes: ["contractors"],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    getContractors: builder.query<ApiTypes.Res.Contractors, UITypes.Params>({
      query: arg => {
        return {
          url: `/contractors`,
          method: "GET",
          params: prepareQueryParams(arg),
        }
      },
    }),
    getContractor: builder.query<{ data: ApiTypes.Model.Contractor }, string>({
      query: id => {
        return {
          url: `/contractors/contractor/${id}`,
          method: "GET",
        }
      },
    }),
    getSettings: builder.query<ApiTypes.Res.Settings, void>({
      query: () => ({
        url: `/contractors/settings`,
      }),
    }),
    getStatuses: builder.query<ApiTypes.Res.Status[], void>({
      query: () => ({
        url: `/contractors/statuses`,
      }),
    }),
    setAutoRestock: builder.query<ApiTypes.Res.Settings, ApiTypes.Req.AutoRestock>({
      query: body => ({
        url: `/contractors/settings`,
        method: "POST",
        body,
      }),
    }),
    createContractor: builder.query<void, FormData>({
      query: body => ({
        url: `/contractors`,
        method: "POST",
        body,
        headers: { Accept: "application/json" },
      }),
    }),
    editContractor: builder.query<void, { data: FormData; id: number }>({
      query: body => {
        return {
          url: `/contractors/${body.id}`,
          method: "POST",
          body: body.data,
          headers: {
            Accept: "application/json",
          },
        }
      },
    }),
    createMessageTemplate: builder.query<void, ApiTypes.Req.CreateMessageTemplate>({
      query: body => ({
        url: `/contractors/message-template`,
        method: "POST",
        body,
      }),
    }),
    getMessageTemplates: builder.query<ApiTypes.Res.MessageTemplates, void>({
      query: () => ({
        url: `/contractors/message-template`,
      }),
    }),
    getMessageTemplate: builder.query<{ data: ApiTypes.Model.MessageTemplate }, number>({
      query: id => ({
        url: `/contractors/message-template/${id}`,
      }),
    }),
    deleteMessageTemplate: builder.query<void, string>({
      query: id => ({
        url: `/contractors/message-template/${id}`,
        method: "DELETE",
      }),
    }),
    editMessageTemplate: builder.query<void, { body: ApiTypes.Req.CreateMessageTemplate; id: string }>({
      query: data => ({
        url: `/contractors/message-template/${data.id}`,
        method: "PUT",
        body: data.body,
      }),
    }),
    getMessageHistory: builder.query<ApiTypes.Res.MessageHitories, UITypes.Params>({
      query: arg => ({
        url: `/contractors/messages-history`,
        params: prepareQueryParams(arg),
      }),
    }),
    generateMessages: builder.query<void, ApiTypes.Req.GenerateMessages>({
      query: data => ({
        url: `/contractors/generate-messages`,
        method: "POST",
        body: JSON.stringify(data.body),
        params: prepareQueryParams(data.params),
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),
    changeContractorPassword: builder.query<void, ApiTypes.Req.ChangeContractorPassword>({
      query: data => ({
        url: `/contractors/change-password`,
        method: "POST",
        params: prepareQueryParams(data),
      }),
    }),
    changeContractorProfile: builder.query<void, FormData>({
      query: data => ({
        url: `/contractors/profile`,
        method: "POST",
        body: data,
        headers: { Accept: "application/json" },
      }),
    }),
    getContractorProfile: builder.query<{ data: ApiTypes.Res.ContractorProfile }, void>({
      query: () => ({
        url: `/contractors/profile`,
      }),
    }),
    putContractorsDevicesAsMissing: builder.query<void, UITypes.Params>({
      query: arg => {
        return {
          url: `/contractors/actions/mark-as-missing`,
          method: "PUT",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    putContractorsDevicesAsTrasfered: builder.query<void, UITypes.Params>({
      query: arg => {
        return {
          url: `/contractors/actions/transfer`,
          method: "PUT",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    changeContractorsDevicesStatus: builder.query<void, UITypes.Params>({
      query: params => {
        return {
          url: `/devices/update-phisical-status`,
          method: "POST",
          body: params,
          headers: { Accept: "application/json" },
        }
      },
    }),
    markDeviceAsWarranty: builder.query<void, string>({
      query: sn => {
        return {
          url: `/devices/mark-warranty`,
          method: "POST",
          params: prepareQueryParams({ sn }),
          headers: { Accept: "application/json" },
        }
      },
    }),
    checkServiceTitan: builder.query<void, UITypes.Params>({
      query: arg => {
        return {
          url: `/contractors/service-titan/checking`,
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    getSTMemberships: builder.query<ApiTypes.Res.VendorSTMemberships, UITypes.Params>({
      query: arg => {
        return {
          url: `/contractors/service-titan/memberships`,
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    getTemplateLibraryGroups: builder.query<{ data: ApiTypes.Model.TemplatesLibrary[] }, void>({
      query: () => ({
        url: `/contractors/template-library-groups`,
      }),
    }),
    addTemplateLibrary: builder.query<{ data: ApiTypes.Model.TemplatesLibrary[] }, number>({
      query: id => ({
        url: `/contractors/add-template-from-library/${id}`,
        method: "POST",
        headers: { Accept: "application/json" },
      }),
    }),
    getMessageReport: builder.query<ApiTypes.Res.MessageReports, { id: string; params: UITypes.Params }>({
      query: data => ({
        url: `/contractors/messages-history/${data.id}/messages`,
        params: prepareQueryParams(data.params),
        headers: { Accept: "application/json" },
        // headers: {
        //   "content-type": "application/json",
        //   "access-control-allow-origin": "*",
        //   "cache-control": "no-cache,private",
        //   server: "nginx",
        //   "x-ratelimit-limit": 60,
        //   "x-ratelimit-remaining": 59,
        // } as any,
      }),
    }),
  }),
})

export const {
  useLazyGetContractorsQuery,
  useLazyGetContractorQuery,
  useLazyGetSettingsQuery,
  useLazyGetStatusesQuery,
  useLazySetAutoRestockQuery,
  useLazyCreateContractorQuery,
  useLazyEditContractorQuery,
  useLazyCreateMessageTemplateQuery,
  useLazyGetMessageTemplatesQuery,
  useLazyGetMessageTemplateQuery,
  useLazyDeleteMessageTemplateQuery,
  useLazyEditMessageTemplateQuery,
  useLazyGetMessageHistoryQuery,
  useLazyGenerateMessagesQuery,
  useLazyGetMessageReportQuery,
  useLazyChangeContractorPasswordQuery,
  useLazyChangeContractorProfileQuery,
  useLazyGetContractorProfileQuery,
  useLazyPutContractorsDevicesAsMissingQuery,
  useLazyPutContractorsDevicesAsTrasferedQuery,
  useLazyChangeContractorsDevicesStatusQuery,
  useLazyMarkDeviceAsWarrantyQuery,
  useLazyCheckServiceTitanQuery,
  useLazyGetSTMembershipsQuery,
  useLazyGetTemplateLibraryGroupsQuery,
  useLazyAddTemplateLibraryQuery,
} = contractorsApi

export const getContractors = contractorsApi.endpoints.getContractors
export const getSettings = contractorsApi.endpoints.getSettings
export const getStatuses = contractorsApi.endpoints.getStatuses
export const getMessageTemplates = contractorsApi.endpoints.getMessageTemplates
export const getTemplateLibrary = contractorsApi.endpoints.getTemplateLibraryGroups
export const getContractorProfile = contractorsApi.endpoints.getContractorProfile
