import { useEffect, useMemo } from "react"
import { toast } from "react-toastify"

import { useLazyGetContractorQuery, useLazyGetContractorsQuery } from "store/api/contractorsApi"
import { useLazyGetTableColumnsQuery } from "store/api/dashboardApi"
import { useAppDispatch } from "store/hooks"
import { showEditContractorModal } from "store/modalSlise"
import { setContractor } from "store/tempDataSlise"
import { UITypes } from "types"

import { convertContractorsToTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import { reorderTableHeaderCells } from "utils/reorderTabbleColumns"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import TableWrapper from "components/table/TableWrapper"

import { contractorTableHeaderCells } from "./contractorTableHeaderCells"
import Header from "./Header"
import { parseApiDataToContractor } from "./parseApiDataToContractor"

export const Contractors = () => {
  const dispatch = useAppDispatch()
  const [triggerGetContractors, { isFetching, data }] = useLazyGetContractorsQuery()
  const [triggerGetContractor, { isFetching: isContractorFetching }] = useLazyGetContractorQuery()
  const [triggerGetTableColumns, { isFetching: isColumnsFetching, data: columnsData }] = useLazyGetTableColumnsQuery()

  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()

  const tableHeaderCells = useMemo(
    (): UITypes.TableHeaderCell[] =>
      columnsData?.data?.config
        ? reorderTableHeaderCells(contractorTableHeaderCells, columnsData?.data?.config)
        : contractorTableHeaderCells,
    [columnsData],
  )

  const sortByTopType = useMemo(() => {
    if (Object.keys(urlQueryParams).length > 1) {
      return ""
    }

    if (urlQueryParams["top15"]) {
      return "Top 15"
    }

    if (urlQueryParams["bottom15"]) {
      return "Bottom 15"
    }

    return ""
  }, [urlQueryParams])

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res = await triggerGetContractors(params)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onEditClick = async (id: number) => {
    try {
      const res = await triggerGetContractor(`${id}`)
      if (res.data?.data) {
        dispatch(setContractor(parseApiDataToContractor(res.data?.data)))
        dispatch(showEditContractorModal(true))
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    triggerGetTableColumns({ list_name: "admin_contractors_list" })
  }, [])

  const getRequestParams = () => {
    if (urlQueryParams["top15"] || urlQueryParams["bottom15"]) {
      return {
        perPage: 15,
        page: 1,
        order_column: "installed",
        order_type: urlQueryParams["top15"] ? "DESC" : "ASC",
      }
    }

    return urlQueryParams
  }

  const handleClearAdditionalTags = () => {
    setUrlQueryParams({ top15: null, bottom15: null })
  }

  useEffect(() => {
    if (Object.keys(urlQueryParams).length > 1 && (urlQueryParams["top15"] || urlQueryParams["bottom15"])) {
      handleClearAdditionalTags()
    } else {
      const params = getRequestParams()
      sendRequest({ isEnabled: 1, ...params })
    }
  }, [urlQueryParams])

  return (
    <ContentWrapper title="Contractors">
      <Header tableHeaderCells={tableHeaderCells} meta={data?.meta} />
      <TableWrapper
        tableName="contractors"
        columnsLoaded={!!columnsData}
        headerCells={tableHeaderCells}
        data={convertContractorsToTableRows(data?.data)}
        isLoading={isFetching || isContractorFetching || isColumnsFetching}
        onEditClick={onEditClick}
        skippedTags={sortByTopType ? ["orderColumn"] : []}
        clearAdditionalTags={handleClearAdditionalTags}
        additionalTags={
          sortByTopType
            ? [
                {
                  label: "Contractors",
                  values: [sortByTopType],
                },
              ]
            : []
        }
      />
      {!sortByTopType && <Pagination meta={data?.meta} />}
    </ContentWrapper>
  )
}
