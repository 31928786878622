import { ApiTypes } from "types"

export const dummyMeta: ApiTypes.Model.Pagination = {
  total: 2,
  per_page: 10,
  current_page: 1,
  last_page: 1,
  from: 1,
  to: 2,
}

export const dummyDevices = [
  {
    id: 1,
    sn: "01-111-000000",
    address1: "Mr John Smith. 132 My Street, Kingston",
    state: {
      id: 22,
      name: "Alabama",
      short: "AL",
    },
    city: {
      id: 1,
      name: "Holtsville",
    },
    zip: {
      id: 38161,
      code: "92612",
      city_id: 28845,
    },
    group: "standard",
    accessories: "none",
    name: "Kitchen",
    alerts: "Yes",
    membership: "no",
    activation_date: "2024-09-24",
    residence_type: {
      id: 6,
      name: "Residential",
    },
    device_uid: "000",
    last_update: "2024-03-21 13:18:54",
    contractor_id: 7,
    actual_contractor_id: 7,
    instock_date: "2024-03-21",
    phisical_status: "installed",
    hvac: "existing",
    installation_type: "existing",
    hv: "01",
    email: "example@examples.com",
    option: "opted_in",
    homeowner_name: "John Smith",
    system_type: "cooling",
    where_installed_id: 6,
    work_status_id: 0,
    work_status: "online",
    perftest: {
      perftest_id: 1,
      perftest_result_id: 0,
      action: "cooling",
      label: "",
      status: "passed",
      time: "2024-11-07 09:43:50",
    },
  },
  {
    id: 2,
    sn: "01-200-000000",
    address1: "Mr John Smith. 132 My Street, Kingston",
    state: {
      id: 22,
      name: "Alabama",
      short: "AL",
    },
    city: {
      id: 1,
      name: "Holtsville",
    },
    zip: {
      id: 38161,
      code: "92612",
      city_id: 28845,
    },
    group: "standard",
    accessories: "none",
    name: "Kitchen",
    alerts: "Yes",
    membership: "no",
    activation_date: "2024-09-24",
    residence_type: {
      id: 6,
      name: "Residential",
    },
    device_uid: "000",
    last_update: "2024-03-21 13:18:54",
    contractor_id: 7,
    actual_contractor_id: 7,
    instock_date: "2024-03-21",
    phisical_status: "installed",
    hvac: "existing",
    installation_type: "existing",
    hv: "01",
    email: "example_example@examples.com",
    option: "opted_in",
    homeowner_name: "John Doe",
    system_type: "cooling",
    where_installed_id: 6,
    work_status_id: 0,
    work_status: "online",
    perftest: {
      perftest_id: 1,
      perftest_result_id: 0,
      action: "cooling",
      label: "",
      status: "passed",
      time: "2024-11-07 09:43:50",
    },
  },
] as unknown as ApiTypes.Model.Device[]
