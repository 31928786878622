import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import classNames from "classnames"

import { useLazyGetUserQuery, useLazyGetUsersQuery } from "store/api/adminApi"
import { useAppDispatch } from "store/hooks"
import { showAddUserModal, showEditUserModal } from "store/modalSlise"
import { setEditableUser } from "store/tempDataSlise"
import { userRoleOptions } from "constants/SelectFieldOptions"
import { UITypes } from "types"
import styles from "assets/scss/index.module.scss"

import { convertUsersToTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import { ButtonNew } from "components/form/ButtonNew"
import { CheckboxNew } from "components/form/CheckboxNew"
import TableWrapper from "components/table/TableWrapper"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Name",
    type: "sort-search",
    size: "middle",
    name: "name",
  },
  {
    title: "Role",
    size: "middle",
    type: "select",
    name: "role",
    options: userRoleOptions,
  },
  {
    title: "Email",
    type: "sort-search",
    name: "email",
  },
  {
    title: "Phone",
    type: "sort-search",
    name: "phone",
  },
  {
    title: "Creation date",
    type: "sort-date",
    name: "creation_date",
  },
  {
    title: "Created By",
    type: "sort-search",
    name: "created_by",
  },
]

export const Users = () => {
  const dispatch = useAppDispatch()
  const [triggerGetUsers, { isFetching, data }] = useLazyGetUsersQuery()
  const [triggerGetUser] = useLazyGetUserQuery()
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()
  const [isFirstRender, setFirstRender] = useState(true)

  const onAddClick = () => {
    dispatch(showAddUserModal(true))
  }

  const onEditClick = async (id: number) => {
    try {
      const res = await triggerGetUser(id)
      if (res.data?.data) {
        dispatch(setEditableUser(res.data?.data))
        dispatch(showEditUserModal(true))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res = await triggerGetUsers(params)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onShowArchive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrlQueryParams({
      isEnabled: event?.target?.checked === true ? 0 : null,
    })
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender])

  return (
    <>
      <ContentWrapper title="Users list">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={onAddClick} color="blue">
              Add user
            </ButtonNew>
          </div>
          <div className={classNames(styles.tableNavButtonsWrapper)}>
            <CheckboxNew className={styles.tableNavItem} isReverse={true} id="archive" onChange={onShowArchive}>
              Show Archive
            </CheckboxNew>
            {/* <ButtonNew className={styles.tableNavItem}>Export</ButtonNew> */}
          </div>
        </nav>
        <TableWrapper
          headerCells={tableHeaderCells}
          onEditClick={onEditClick}
          isLoading={isFetching}
          data={convertUsersToTableRows(data?.data)}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
