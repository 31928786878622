import { FormEvent, useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"

import { useLazyCreateTechnicianQuery, useLazyGetTechniciansQuery } from "store/api/techniciansApi"
import styles from "assets/scss/index.module.scss"
import { ReactComponent as IconTechnician } from "assets/svg/modal-icon-technician.svg"

import getErrorMessage from "utils/getErrorMessage"
import { createTechnicianSchema, onFormValidate, validationInitialState } from "utils/onValidate"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"
import { InputNew } from "components/form/InputNew"

const inputValuesInitialState = {
  fullName: "",
  userName: "",
  password: "",
}

interface Props {
  onCancel: () => void
}

export const AddTechnicianModal = ({ onCancel }: Props) => {
  const [triggerCreateTechnician, { isFetching }] = useLazyCreateTechnicianQuery()
  const [triggerGetTechnicians] = useLazyGetTechniciansQuery()
  const [inputValues, setInputValues] = useState(inputValuesInitialState)

  const [error, setError] = useState(validationInitialState)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues(prevState => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onSubmit = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (onFormValidate(inputValues, createTechnicianSchema, setError)) {
      try {
        const res = await triggerCreateTechnician({
          full_name: inputValues.fullName,
          username: inputValues.userName,
          password: inputValues.password,
        })

        const errorMessage = getErrorMessage(res.error)
        if (errorMessage) {
          toast.error(errorMessage)
        } else {
          toast.success("Technician has been successfully added!")
          setInputValues(inputValuesInitialState)
          triggerGetTechnicians({})
          onCancel()
        }
      } catch (error: unknown) {
        const errorObject = error as Error
        toast.error(errorObject.message)
      }
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          isFetching={isFetching}
          onConfirm={onSubmit}
          confirmButtonType="submit"
          onCancel={onCancel}
        >
          <ModalHeader title="Add technician">
            <IconTechnician />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Full name"
              placeholder="Enter full name"
              name="fullName"
              value={inputValues.fullName}
              onChange={onInputChange}
              errorMessage={error.field === "fullName" ? error.message : ""}
            />
            <InputNew
              label="Username"
              placeholder="Enter username"
              name="userName"
              autoComplete="one-time-code"
              value={inputValues.userName}
              onChange={onInputChange}
              labelBottom="Please do not enter an email address in the Username field."
              errorMessage={error.field === "userName" ? error.message : ""}
            />
            <InputNew
              label="Password"
              placeholder="Enter password"
              inputType="password"
              name="password"
              autoComplete="one-time-code"
              value={inputValues.password}
              onChange={onInputChange}
              errorMessage={error.field === "password" ? error.message : ""}
            />
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
