import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import dayjs from "dayjs"

import { useLazyFulfillOrderQuery } from "store/api/ordersApi"
import {
  useLazyGetOrderStatusWidgetQuery,
  useLazyGetVendorOrdersQuery,
  useLazyGetWarehouseWidgetInfoQuery,
} from "store/api/vendorApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setCurrentOrder } from "store/tempDataSlise"
import { ReactComponent as IconOrderConfirmation } from "assets/svg/modal-icon-order-confirmation.svg"

import { checkRoleType } from "utils/checkRoleType"
import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"

import styles from "./index.module.scss"

interface Props {
  onCancel: () => void
}

export const OrderFulfillmentModal = ({ onCancel }: Props) => {
  const [triggerFulfillOrder, { isFetching }] = useLazyFulfillOrderQuery()
  const [triggerGetWarehouseWidgetInfo] = useLazyGetWarehouseWidgetInfoQuery()
  const [triggerGetOrderStatusWidget] = useLazyGetOrderStatusWidgetQuery()
  const [triggerGetVendorOrders] = useLazyGetVendorOrdersQuery()
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector(state => state.user.userProfile)
  const { urlQueryParams } = useUrlQueryParams()
  const currentOrder = useAppSelector(state => state.tempData.currentOrder)

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const res = await triggerFulfillOrder(currentOrder?.id || 0)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success("Order has been successfully fulfilled!")
        triggerGetVendorOrders(urlQueryParams)
        dispatch(setCurrentOrder(undefined))
        onCancel()

        if (checkRoleType(userProfile?.type) === "vendor-admin") {
          triggerGetWarehouseWidgetInfo(urlQueryParams)
          triggerGetOrderStatusWidget()
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <ModalHeader
            title="Order fulfillment confirmation"
            subtitle="Are you sure you want to confirm the fulfillment of the order?"
          >
            <IconOrderConfirmation />
          </ModalHeader>
          <div className={styles.modalContent}>
            <ul className={styles.orderItemsList}>
              <li className={styles.orderItem}>
                <span className={styles.orderItemName}>Order quantity:</span>
                <span className={styles.orderItemValue}>{currentOrder?.quantity}</span>
              </li>
              <li className={styles.orderItem}>
                <span className={styles.orderItemName}>Order creations date:</span>
                <span className={styles.orderItemValue}>{dayjs(currentOrder?.create_date).format("MM-DD-YYYY")}</span>
              </li>
            </ul>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
