import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { useLazyGenerateMessagesQuery, useLazyGetMessageTemplatesQuery } from "store/api/contractorsApi"
import { useLazyGetTableColumnsQuery } from "store/api/dashboardApi"
import { useGetDevicesQuery } from "store/api/devicesApi"
import { useAppDispatch } from "store/hooks"
import { showEditColumnsModal } from "store/modalSlise"
import { setCurrentTableColumns } from "store/tempDataSlise"
import {
  deviceAccessoriesOptions,
  deviceSystemTypeOptions,
  hvacOptions,
  optInOptions,
} from "constants/SelectFieldOptions"
import { UITypes } from "types"

import { convertDevicesToTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import { reorderTableHeaderCells } from "utils/reorderTabbleColumns"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import { Spinner } from "components/common/Spinner"
import { ButtonNew } from "components/form/ButtonNew"
import { SelectDynamic } from "components/form/SelectDynamic"
import { OptedInOut } from "components/table/columnComponents/OptedInOut"
import TableWrapper from "components/table/TableWrapper"

import styles from "./index.module.scss"

const initialTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
    name: "checkbox",
    type: "checkbox",
    size: "mini",
    cellComponent: "checkbox",
    id: 1,
  },
  {
    title: "Email",
    type: "sort-search",
    name: "email",
    size: "middle",
    id: 2,
  },
  {
    title: "Device S/N",
    type: "sort-search",
    name: "sn",
    size: "small",
    id: 3,
  },
  {
    title: "Country",
    type: "sort-search",
    name: "country",
    size: "small",
    id: 4,
  },
  {
    title: "City",
    type: "sort-search",
    name: "city",
    size: "small",
    id: 5,
  },
  {
    title: "Zip code",
    type: "sort-search",
    name: "zip_code",
    size: "small",
    id: 6,
  },
  {
    title: "State",
    type: "sort-search",
    name: "state",
    size: "small",
    id: 7,
  },
  {
    title: "Opt in/out",
    type: "select",
    name: "option",
    size: "small",
    options: optInOptions,
    cellComponent: OptedInOut,
    id: 8,
  },
  {
    title: "Activation date",
    type: "sort-date",
    name: "activation_date",
    size: "middle",
    id: 9,
  },
  {
    title: "Customer name",
    type: "sort-search",
    name: "homeowner_name",
    id: 10,
  },
  {
    title: "Membership",
    type: "sort-search",
    name: "membership",
    size: "small",
    id: 11,
  },
  {
    title: "Residence type",
    type: "sort-search",
    name: "residence_type",
    size: "middle",
    id: 12,
  },
  {
    title: "System type",
    type: "select",
    size: "small",
    name: "system_type",
    options: deviceSystemTypeOptions,
    id: 13,
  },
  {
    title: "Accessories",
    type: "select",
    name: "accessories",
    options: deviceAccessoriesOptions,
    size: "small",
    id: 14,
  },
  {
    title: "HVAC",
    type: "select",
    size: "small",
    name: "installation_type",
    options: hvacOptions,
    id: 15,
  },
]

export const MessageCenter = () => {
  const navigate = useNavigate()
  const { urlQueryParams } = useUrlQueryParams()
  const { data, isFetching } = useGetDevicesQuery(
    { ...urlQueryParams, phisical_status: "installed" },
    {
      pollingInterval: 30000,
      skipPollingIfUnfocused: true,
    },
  )

  const [triggerGenerateMessages, { isFetching: isGenerateMessagesFetching }] = useLazyGenerateMessagesQuery()
  const [triggerGetTableColumns, { data: columnsData }] = useLazyGetTableColumnsQuery()
  const dispatch = useAppDispatch()
  const [templateID, setTemplateID] = useState<undefined | UITypes.Option[]>(undefined)
  const [deviceIDs, setDeviceIDs] = useState<number[]>([])
  const [isHeaderChecked, setHeaderChecked] = useState(false)
  const [isFirstRender, setFirstRender] = useState(true)

  const tableHeaderCells = useMemo(
    (): UITypes.TableHeaderCell[] =>
      columnsData?.data?.config
        ? reorderTableHeaderCells(initialTableHeaderCells, columnsData?.data?.config)
        : initialTableHeaderCells,
    [columnsData],
  )

  const toggleAllDevices = () => {
    if (isHeaderChecked) {
      setDeviceIDs([])
      setHeaderChecked(false)
    } else {
      const result = data?.data?.map(item => item?.id)
      if (result) {
        setDeviceIDs(result)
      }
      setHeaderChecked(true)
    }
  }

  const onSelectDevice = (id?: number) => {
    if (deviceIDs?.some(item => item === id)) {
      setDeviceIDs(deviceIDs?.filter(item => item !== id))
    } else {
      setDeviceIDs([...deviceIDs, ...[id]] as number[])
    }
  }

  const onEditColumnsClick = () => {
    dispatch(showEditColumnsModal(true))
    dispatch(
      setCurrentTableColumns({
        table: "contractor_messages_list",
        columns: tableHeaderCells,
      }),
    )
  }

  const onSendMessage = async () => {
    if (!templateID) return

    const data = {
      params: {
        message_template_id: templateID[0]?.value as number,
      },
      body: {
        device_ids: deviceIDs,
      },
    }

    try {
      const res = await triggerGenerateMessages(data)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success(
          "The messages will be sent to the selected group during 1 hour. Navigate to History page to check the status of the sent messages.",
        )
        setDeviceIDs([])
        setTemplateID(undefined)
        setHeaderChecked(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    triggerGetTableColumns({ list_name: "contractor_messages_list" })
  }, [isFirstRender])

  return (
    <>
      <ContentWrapper title="Message Center">
        <nav className={styles.tableNavWrapper}>
          <div className={styles.leftNavWrapper}>
            <ButtonNew onClick={() => navigate("/messages/create-template")} color="blue">
              Create Template
            </ButtonNew>
            <SelectDynamic
              className={styles.selectWrapper}
              valueName="id"
              labelName="title"
              useLazyQuery={useLazyGetMessageTemplatesQuery}
              values={templateID}
              onChange={setTemplateID}
            />
            {templateID && deviceIDs?.length ? (
              <ButtonNew onClick={onSendMessage} color={"blue"}>
                {isGenerateMessagesFetching ? <Spinner /> : "Send"}
              </ButtonNew>
            ) : (
              <ButtonNew disabled color="grey">
                Send
              </ButtonNew>
            )}
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <span className={styles.editColumnsButton} onClick={onEditColumnsClick}>
              Edit columns
            </span>
            <ButtonNew className={styles.tableNavItem} onClick={() => navigate("/messages/history")}>
              History
            </ButtonNew>
            {/* <ButtonNew className={styles.tableNavItem} disabled>Export</ButtonNew> */}
          </div>
        </nav>
        <TableWrapper
          headerCells={tableHeaderCells}
          columnsLoaded={!!columnsData}
          data={convertDevicesToTableRows(data?.data)}
          isLoading={isFetching}
          onCheckboxChange={onSelectDevice}
          onHeaderCheckboxChange={toggleAllDevices}
          currentItemIDs={deviceIDs}
          isHeaderChecked={isHeaderChecked}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
