import { useState } from "react"

import { ApiTypes } from "types"
import { ReactComponent as RightIcon } from "assets/svg/icon-right.svg"

import styles from "./index.module.scss"

interface Props {
  devices: ApiTypes.Model.PerformanceTestDevice[]
}

export const PerformanceTestSelectedDevices = ({ devices }: Props) => {
  const [showSelectedDevices, setShowSelectedDevices] = useState(false)

  const handleShowSelectedDevices = () => {
    setShowSelectedDevices(!showSelectedDevices)
  }

  return (
    <div className={styles.selectedDeviceSection}>
      <div
        className={`${styles.selectedDevicesHeader} ${showSelectedDevices ? styles.showSelectedDevices : ""}`}
        onClick={handleShowSelectedDevices}
      >
        <p>Selected Device(S) ({devices.length})</p>
        <RightIcon />
      </div>
      {showSelectedDevices && (
        <div className={styles.selectedDevicesBody}>
          {devices.map(({ id, email, sn }) => (
            <div key={id} className={styles.selectedDeviceRow}>
              <div className={styles.selectedDeviceRowItem}>
                <p>Email</p>
                <span>{email || "-"}</span>
              </div>
              <div className={styles.selectedDeviceRowItem}>
                <p>Device S/N</p>
                <span>{sn}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
