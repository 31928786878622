import { Bar } from "react-chartjs-2"
import { Chart, registerables, TooltipItem, TooltipModel } from "chart.js"

import { ApiTypes } from "types"

import getLegendMarginPlugin from "utils/chartjs/getLegendMarginPlugin"

import { externalTooltipHandler } from "./CustomModesBarTooltip"
import { getBarChartSettings } from "./getBarChartsSettinsg"
import parseFormattedValueToTime from "./parseFormattedValueToTime"

import styles from "./index.module.scss"

Chart.register(...registerables)

interface TemperatureBarChartProps {
  ticksCount: number
  selectedTime: string | number | boolean
  historicalData: ApiTypes.Res.MonitoringHistoricalDataBucket[]
}

const TemperatureBarChart = ({ selectedTime, historicalData, ticksCount }: TemperatureBarChartProps) => {
  const settings = getBarChartSettings("Runtime")
  const selectedSettings = settings[selectedTime as keyof typeof settings]
  const ticks = ticksCount || selectedSettings.ticksCount

  const barChartsData = (
    historicalData.reduce(
      (acc, item, index) => {
        if (index % selectedSettings.intervalItemsCount === 0) {
          acc.push({
            coolingStage1: 0,
            coolingStage2: 0,
            heatingStage1: 0,
            heatingStage2: 0,
            heatingStage3: 0,
          })
        }
        const lastItem = acc[acc.length - 1]
        lastItem.coolingStage1 += item["filters#current_cooling_stage_count"].buckets.count1.doc_count
        lastItem.coolingStage2 += item["filters#current_cooling_stage_count"].buckets.count2.doc_count
        lastItem.heatingStage1 += item["filters#current_heating_stage_count"].buckets.count1.doc_count
        lastItem.heatingStage2 += item["filters#current_heating_stage_count"].buckets.count2.doc_count
        lastItem.heatingStage3 += item["filters#current_heating_stage_count"].buckets.count3.doc_count

        return acc
      },
      [] as {
        coolingStage1: number
        coolingStage2: number
        heatingStage1: number
        heatingStage2: number
        heatingStage3: number
      }[],
    ) || []
  ).map(item => ({
    coolingStage1: item.coolingStage1 / selectedSettings.hourlyDivider,
    coolingStage2: item.coolingStage2 / selectedSettings.hourlyDivider,
    heatingStage1: item.heatingStage1 / selectedSettings.hourlyDivider,
    heatingStage2: item.heatingStage2 / selectedSettings.hourlyDivider,
    heatingStage3: item.heatingStage3 / selectedSettings.hourlyDivider,
  }))

  const labels = [...Array(ticks)].map((_, index) => index + 1)
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Cooling - stage 1",
        backgroundColor: "#92c5fd",
        data: barChartsData.map(item => item.coolingStage1),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Cooling - stage 2",
        backgroundColor: "#2463eb",
        data: barChartsData.map(item => item.coolingStage2),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Heating - stage 1",
        backgroundColor: "#fca5a5",
        data: barChartsData.map(item => item.heatingStage1),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Heating - stage 2",
        backgroundColor: "#ef4444",
        data: barChartsData.map(item => item.heatingStage2),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Heating - stage 3",
        backgroundColor: "#b91c1c",
        data: barChartsData.map(item => item.heatingStage3),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
    ].filter(item => item.data.some(value => value !== 0)),
  }

  const options = {
    interaction: {
      mode: "index" as const,
    },
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
        align: "start" as const,
        labels: {
          color: "#94A3B8",
          usePointStyle: true,
          pointStyle: "rect",
        },
      },
      tooltip: {
        enabled: false,
        position: "nearest" as const,
        external: (context: { chart: Chart; tooltip: TooltipModel<"bar"> }) => externalTooltipHandler(context, 200),
        callbacks: {
          label: function (this: TooltipModel<"bar">, tooltipItem: TooltipItem<"bar">) {
            return `${tooltipItem.dataset.label}: ${parseFormattedValueToTime(tooltipItem.formattedValue, selectedSettings.dataType)}`
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: selectedSettings.labelX,
          color: "#94A3B8",
        },
        ticks: {
          color: "#94A3B8",
          // TODO: Fix ticks count for backend data
          maxTicksLimit: ticks + 1,
        },
      },
      y: {
        title: {
          display: true,
          text: selectedSettings.labelY,
          color: "#94A3B8",
        },
        stacked: true,
        max: selectedSettings.suggestedMax,
        ticks: {
          maxTicksLimit: 6,
          color: "#94A3B8",
        },
      },
    },
  }

  return (
    <div className={styles.barChartContainer}>
      <Bar data={data} options={options} plugins={[getLegendMarginPlugin(8)]} />
    </div>
  )
}

export default TemperatureBarChart
