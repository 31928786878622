import { useEffect, useState } from "react"
import ReactSelect, { ClearIndicatorProps, components, DropdownIndicatorProps, SingleValue } from "react-select"
import classNames from "classnames"

import "./select.scss"

import { UITypes } from "types"
import { ReactComponent as IconSelect } from "assets/svg/icon-select.svg"
import { ReactComponent as IconTableCross } from "assets/svg/icon-table-cross.svg"

import useUrlQueryParams from "hooks/useUrlQueryParams"

import styles from "./index.module.scss"

const DropdownIndicatorComponent = (props: DropdownIndicatorProps<UITypes.Option>) => {
  return (
    // @ts-expect-error: The code below works fine, but TypeScript throws an error
    <components.DropdownIndicator {...props}>
      <span
        className={classNames(styles.iconArrow, {
          [styles.open]: props?.selectProps?.menuIsOpen,
        })}
      >
        <IconSelect />
      </span>
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props: ClearIndicatorProps<UITypes.Option>) => {
  const { innerProps } = props
  return (
    <div {...innerProps} className={styles.clearIcon}>
      <IconTableCross />
    </div>
  )
}

interface Props {
  headerCell: UITypes.TableHeaderCell
}

export const TableSelect = ({ headerCell: { title, name, options } }: Props) => {
  const [value, setValue] = useState<UITypes.Option | undefined>(undefined)
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()

  useEffect(() => {
    if (options) {
      const result = options.filter(item => item.value === urlQueryParams[name || ""])
      setValue(result[0])
    }
  }, [urlQueryParams])

  const onChange = (data: SingleValue<UITypes.Option>) => {
    if (!name) return

    setUrlQueryParams({
      [name]: data?.value || null,
    })
  }

  return (
    <div className={classNames(styles.field)}>
      <ReactSelect
        // menuIsOpen
        // isMulti
        isMulti={false}
        options={options}
        classNamePrefix="filter"
        value={value || null}
        isClearable={true}
        hideSelectedOptions={true}
        className="custom"
        name={name}
        placeholder={title}
        onChange={onChange}
        components={{
          DropdownIndicator: DropdownIndicatorComponent,
          ClearIndicator,
        }}
      />
    </div>
  )
}
