import { contractorPerformanceTestHistoryStatusOptions } from "constants/SelectFieldOptions"
import { UITypes } from "types"

import { StatusOfPerformanceTest } from "components/table/columnComponents/StatusOfPerformanceTest"

const performanceTestHistoryTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Email",
    type: "sort-search",
    name: "email",
    size: "middle",
  },
  {
    title: "Status",
    type: "select",
    name: "status",
    size: "small",
    options: contractorPerformanceTestHistoryStatusOptions,
    cellComponent: StatusOfPerformanceTest,
  },
  {
    title: "Test Date",
    name: "time",
    size: "middle",
    type: "sort",
  },
  {
    title: "Label",
    type: "sort-search",
    name: "label",
    size: "small",
  },
  {
    title: "Country",
    type: "sort-search",
    name: "country",
    size: "small",
  },
  {
    title: "City",
    type: "sort-search",
    name: "city",
    size: "small",
  },
  {
    title: "ZIP code",
    type: "sort-search",
    name: "zip_code",
    size: "small",
  },
  {
    title: "State",
    type: "sort-search",
    name: "state",
    size: "small",
  },
  {
    title: "Customer",
    type: "sort-search",
    name: "homeowner_name",
    size: "middle",
  },
  {
    title: "Device S/N",
    type: "sort-search",
    name: "sn",
    size: "small",
  },
  {
    title: "Activation date",
    type: "sort",
    name: "activation_date",
    size: "middle",
  },
  {
    title: "Address",
    type: "sort-search",
    name: "address",
  },
]

export default performanceTestHistoryTableHeaderCells
