import { toast } from "react-toastify"

import { useLazyDeleteVendorOrderQuery } from "store/api/adminApi"
import { useLazyGetVendorOrdersQuery } from "store/api/vendorApi"
import { useAppSelector } from "store/hooks"

import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"

import { DeleteModal } from "../DeleteModal"

interface Props {
  onCancel: () => void
}

export const DeleteOrderModalForVendor = ({ onCancel }: Props) => {
  const [triggerDeleteVendorOrder, { isFetching }] = useLazyDeleteVendorOrderQuery()
  const [triggerGetVendorOrders] = useLazyGetVendorOrdersQuery()
  const currentOrder = useAppSelector(state => state.tempData.currentOrder)
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()

    try {
      const res = await triggerDeleteVendorOrder(currentOrder?.id as unknown as number)
      const error = getErrorMessage(res?.error)
      if (error) {
        toast.error(error)
      } else {
        toast.success("Order has been successfully deleted!")
        triggerGetVendorOrders(urlQueryParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DeleteModal
      title="Delete order"
      message="Are you sure you want to delete the selected order(s)?"
      isFetching={isFetching}
      onCancel={onCancel}
      onConfirm={onSubmit}
    />
  )
}
