import { PropsWithChildren } from "react"
import classNames from "classnames"

import { ApiTypes } from "types"
import styles from "assets/scss/index.module.scss"

import { MiniPagination } from "components/common/MiniPagination"
import { Spinner } from "components/common/Spinner"
import { ButtonNew } from "components/form/ButtonNew"

interface Props extends PropsWithChildren {
  isFetching?: boolean
  buttonText?: string
  title?: string
  address?: string
  className?: string
  tag?: number
  tagName?: string
  pagination?: ApiTypes.Model.Pagination
  onButtonClick?: () => void
}

export const WidgetLayout = ({
  children,
  title,
  isFetching,
  buttonText,
  className,
  address,
  tag,
  tagName = "Devices",
  onButtonClick,
  pagination,
}: Props) => {
  return (
    <div className={classNames(styles.widgetWrapper, className)}>
      <header className={styles.widgetHeader}>
        <h3 className={styles.widgetTitle}>{title}</h3>
        {address && <span className={styles.widgetAddress}>{address}</span>}
        {buttonText && <ButtonNew onClick={onButtonClick}>{buttonText}</ButtonNew>}
        {Boolean(tag) && (
          <div className={styles.widgetTag}>
            {tagName} <span className={styles.widgetTagValue}>{tag}</span>
          </div>
        )}
        {!!pagination && <MiniPagination meta={pagination} />}
      </header>
      <div className={styles.widgetDataWrapper}>
        {children}
        {isFetching && (
          <div className={styles.widgetSpinnerWrapper}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}
