import classNames from "classnames"

import { ReactComponent as IconSort } from "assets/svg/icon-sort.svg"
import { ReactComponent as IconSortActive } from "assets/svg/icon-sort-active.svg"

import useUrlQueryParams from "hooks/useUrlQueryParams"

import styles from "./index.module.scss"

interface Props {
  label: string
  name?: string
}

export const TableSort = ({ label, name }: Props) => {
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()
  const orderColumn = urlQueryParams["orderColumn"]
  const orderType = urlQueryParams["orderType"]

  const renderCurrentIcon = () => {
    if (orderColumn === name) {
      return <IconSortActive className={classNames({ [styles.desc]: orderType === "DESC" })} />
    }

    return <IconSort />
  }

  const checkCorrentType = () => {
    if (orderColumn !== name) {
      return "ASC"
    }
    if (!orderType && orderColumn === name) {
      return "ASC"
    }
    if (orderType === "ASC" && orderColumn === name) {
      return "DESC"
    }
    if (orderType === "DESC" && orderColumn === name) {
      return ""
    }
  }

  const checkIsActive = () => {
    if (!orderColumn) return false

    return orderColumn === name && orderType !== null
  }

  const onSort = () => {
    if (orderType === "DESC" && orderColumn === name) {
      setUrlQueryParams({
        orderType: null,
        orderColumn: null,
      })
    } else {
      setUrlQueryParams({
        orderType: checkCorrentType() || null,
        orderColumn: name || null,
      })
    }
  }

  return (
    <span
      className={classNames(styles.tableSort, {
        [styles.active]: checkIsActive(),
      })}
      onClick={onSort}
    >
      {label}
      {renderCurrentIcon()}
    </span>
  )
}
