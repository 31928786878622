import { FormEvent, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { useLazyCreateUserQuery, useLazyGetUsersQuery } from "store/api/adminApi"
import { useLazyGetWarehouseListQuery } from "store/api/warehousesApi"
import { useAppDispatch } from "store/hooks"
import { showAddUserModal, showAddWarehouseModal } from "store/modalSlise"
import { UITypes } from "types"
import styles from "assets/scss/index.module.scss"
import { ReactComponent as IconAddUser } from "assets/svg/modal-icon-add-user.svg"

import { convertDataToOptions } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import { createUserSchema, onFormValidate, validationInitialState } from "utils/onValidate"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"
import { InputNew } from "components/form/InputNew"
import { InputPhone } from "components/form/InputPhone"
import { SelectStatic } from "components/form/SelectStatic"

const inputValuesInitialState: UITypes.User = {
  fullName: "",
  email: "",
  phone: "",
  useRole: [],
  warehouse: [],
}

interface Props {
  onCancel: () => void
}

export const AddUserModal = ({ onCancel }: Props) => {
  const [triggerCreateUser, { isFetching }] = useLazyCreateUserQuery()
  const [triggerGetWarehouses, { data: whData }] = useLazyGetWarehouseListQuery()
  const [triggerGetUsers] = useLazyGetUsersQuery()
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues(prevState => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onSelectChange = (name: string, values: UITypes.Option[]) => {
    if (!values?.length) return
    setInputValues({ ...inputValues, ...{ [name]: values } })
  }

  const prepareData = () => {
    const data = {
      full_name: inputValues.fullName,
      phone: inputValues.phone,
      email: inputValues.email,
      type: inputValues.useRole![0]?.value,
      warehouse_id: inputValues.warehouse![0]?.value,
    }

    return data
  }

  const sendRequest = async () => {
    try {
      const res = await triggerCreateUser(prepareData())
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success("User has been successfully added!")
        setInputValues(inputValuesInitialState)
        triggerGetUsers({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (onFormValidate(inputValues, createUserSchema, setError)) {
      sendRequest()
    }
  }

  const onCancelClick = () => {
    onCancel()
  }

  const onAddNewWarehouse = () => {
    navigate("/warehouse-list")
    dispatch(showAddUserModal(false))
    dispatch(showAddWarehouseModal(true))
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetWarehouses({
          page: 1,
          perPage: 100,
        })
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          confirmButtonType="submit"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          isFetching={isFetching}
        >
          <ModalHeader title="Add user">
            <IconAddUser />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Full Name"
              placeholder="Enter full name"
              name="fullName"
              value={inputValues.fullName}
              onChange={onInputChange}
              errorMessage={error.field === "fullName" ? error.message : ""}
            />
            <InputNew
              label="Email"
              placeholder="Enter Email"
              name="email"
              value={inputValues.email}
              onChange={onInputChange}
              errorMessage={error.field === "email" ? error.message : ""}
            />
            <InputPhone
              label="Phone number"
              placeholder="Enter phone"
              name="phone"
              value={inputValues.phone}
              onChange={value => setInputValues({ ...inputValues, ...{ phone: value } })}
              errorMessage={error.field === "phone" ? error.message : ""}
            />
            <SelectStatic
              label="Role"
              options={[
                {
                  value: 1,
                  label: "Admin",
                },
                {
                  value: 2,
                  label: "Sales Manager",
                },
                {
                  value: 8,
                  label: "Warehouse Manager",
                },
              ]}
              onChange={data => onSelectChange("useRole", data)}
              errorMessage={error.field === "useRole" ? error.message : ""}
            />
            {Boolean(inputValues.useRole?.length) && inputValues.useRole![0].value === 8 && (
              <>
                <SelectStatic
                  placeholder="Select warehouse"
                  options={convertDataToOptions(whData, "id", "name")}
                  onChange={data => onSelectChange("warehouse", data)}
                  errorMessage={error.field === "warehouse" ? error.message : ""}
                />
                <div className={styles.infoMessage}>
                  Choose a warehouse to assign Warehouse manager. If you haven&#39;t created a warehouse yet, go to{" "}
                  <span className={styles.link} onClick={onAddNewWarehouse}>
                    Add New Warehouse
                  </span>
                </div>
              </>
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
