import { useDispatch } from "react-redux"

import { useAppSelector } from "store/hooks"
import { setModalContent } from "store/tempDataSlise"
import { ReactComponent as IconCheck } from "assets/svg/icon-check.svg"

import { ModalLayout } from "layouts/ModalLayout"

import styles from "./index.module.scss"

interface PerformanceTestConfirmModalProps {
  onCancel: () => void
}

export const PerformanceTestConfirmModal = ({ onCancel }: PerformanceTestConfirmModalProps) => {
  const dispatch = useDispatch()
  const modalContent = useAppSelector(state => state.tempData.modalContent)

  const handleCancel = () => {
    onCancel()
    dispatch(setModalContent())
  }

  return (
    <>
      <ModalLayout width="454px" buttonSize="fullWidth" onCancel={handleCancel} cancelButtonText="Close">
        <div className={styles.modalBody}>
          <div className={styles.iconWrapper}>
            <IconCheck />
          </div>
          <h3>{modalContent?.title}</h3>
          <p>{modalContent?.content}</p>
        </div>
      </ModalLayout>
    </>
  )
}
