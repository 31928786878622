import { useEffect, useState } from "react"
import classNames from "classnames"

import { useLazyGetMobileUsageWidgetQuery } from "store/api/adminApi"

import { WidgetLayout } from "layouts/WidgetLayout"
import { RainbowChart } from "components/common/RainbowChart"
import { Spinner } from "components/common/Spinner"

import styles from "./index.module.scss"

const initialState = {
  withMobileApp: 0,
  withoutMobileApp: 0,
  customers: 0,
}

export const WidgetMobileAppUsage = () => {
  const [triggerGetMobileUsageWidget, { isFetching, data }] = useLazyGetMobileUsageWidgetQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetMobileUsageWidget()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!data) return

    setWidgetData({
      withMobileApp: data.mobile_app_activated,
      withoutMobileApp: data.customers - data.mobile_app_activated,
      customers: data.customers,
    })
  }, [data])

  return (
    <WidgetLayout title="Mobile app Usage" className={styles.widgetWrapper}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <RainbowChart
            colors={["#22C55E", "#E2E8F0"]}
            values={[widgetData.withMobileApp, widgetData.withoutMobileApp]}
            className={styles.chart}
          />
        </div>
        <div className={styles.widgetMainValue}>
          <span className={styles.text}>CUSTOMERS</span>
          <div className={styles.widgetValueWrapper}>{widgetData.customers}</div>
        </div>
        <footer className={styles.widgetFooter}>
          <div className={classNames(styles.widgetFooterItem)}>
            <span className={classNames(styles.widgetNote, styles.withMobileApp)}>With Mobile app</span>
            <div className={styles.widgetValueWrapper}>{widgetData.withMobileApp}</div>
          </div>
          <div className={classNames(styles.widgetFooterItem)}>
            <span className={classNames(styles.widgetNote, styles.withoutMobileApp)}>Without Mobile app</span>
            <div className={styles.widgetValueWrapper}>{widgetData.withoutMobileApp}</div>
          </div>
        </footer>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
