import { FormEvent, SetStateAction } from "react"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import { UITypes } from "types"

import { CheckboxNew } from "components/form/CheckboxNew"
import { InputPhone } from "components/form/InputPhone"

import styles from "./index.module.scss"

interface ContractorPhoneSupportProps {
  phoneForSupport?: boolean
  phoneForSupportCall?: string
  phoneForSupportText?: string
  inputValues: UITypes.Contractor
  error: { field: string; message: string }
  isProfileSettings?: boolean
  setError: (value: SetStateAction<{ field: string; message: string }>) => void
  setInputValues: (value: SetStateAction<UITypes.Contractor>) => void
}

export const ContractorPhoneSupport = ({
  phoneForSupport,
  phoneForSupportCall,
  phoneForSupportText,
  inputValues,
  error,
  isProfileSettings,
  setError,
  setInputValues,
}: ContractorPhoneSupportProps) => {
  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, checked } = event.currentTarget

    setInputValues(prevState => {
      return {
        ...prevState,
        [name]: checked,
        ...(checked ? { phoneForSupportCall: "", phoneForSupportText: "" } : {}),
      }
    })

    if (checked && ["phoneForSupportCall", "phoneForSupportText"].includes(error.field)) {
      setError({ field: "", message: "" })
    }
  }

  return (
    <>
      <div className={classNames({ [styles.row]: !isProfileSettings, [styles.personalInfoRow]: isProfileSettings })}>
        <CheckboxNew id={uuidv4()} name="phoneForSupport" onChange={handleInputChange} isChecked={phoneForSupport}>
          Use this number also for Support purposes (get calls and texts)
        </CheckboxNew>
      </div>
      <div className={classNames({ [styles.row]: !isProfileSettings })}>
        <div className={styles.phoneNumberContainer}>
          <InputPhone
            label="Phone number for Support purposes (Call)"
            placeholder="Enter phone"
            name="phoneForSupportCall"
            value={phoneForSupportCall || ""}
            className={classNames({ [styles.personalInfoRow]: isProfileSettings })}
            disabled={phoneForSupport}
            onChange={value => setInputValues({ ...inputValues, ...{ phoneForSupportCall: value } })}
            errorMessage={error.field === "phoneForSupportCall" ? error.message : ""}
          />
        </div>
        <div className={styles.phoneNumberContainer}>
          <InputPhone
            label="Phone number for Support purposes (Text)"
            placeholder="Enter phone"
            name="phoneForSupportText"
            value={phoneForSupportText || ""}
            disabled={phoneForSupport}
            onChange={value => setInputValues({ ...inputValues, ...{ phoneForSupportText: value } })}
            errorMessage={error.field === "phoneForSupportText" ? error.message : ""}
          />
        </div>
      </div>
    </>
  )
}
