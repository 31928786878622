import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"

import { useLazyGetTableColumnsQuery } from "store/api/dashboardApi"
import { useLazyGetVendorFulfilledOrdersQuery, useLazyGetVendorOrdersQuery } from "store/api/vendorApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  showDeleteOrderModalForVendor,
  showEditColumnsModal,
  showEditVendorOrderModal,
  showNewVendorOrderModal,
  showOrderFulfillmentModal,
} from "store/modalSlise"
import { setCurrentOrder, setCurrentTableColumns, setSelectedVSMOrder } from "store/tempDataSlise"
import { orderStatusOptions } from "constants/SelectFieldOptions"
import { UITypes } from "types"

import { convertVSMOrdersToTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import { reorderTableHeaderCells } from "utils/reorderTabbleColumns"
import useExportCSV from "hooks/useExportCSV"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import { ButtonNew } from "components/form/ButtonNew"
import { CheckboxNew } from "components/form/CheckboxNew"
import { StatusOfOrder } from "components/table/columnComponents/StatusOfOrder"
import TableWrapper from "components/table/TableWrapper"
import { WidgetVendorOrderStatus } from "components/widgets/WidgetVendorOrderStatus"
import { WidgetWarehouseInformation } from "components/widgets/WidgetWarehouseInformation"

import styles from "./index.module.scss"

const initialTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
    name: "checkbox",
    cellComponent: "checkbox",
    size: "mini",
    id: 1,
  },
  {
    title: "Order number",
    type: "sort-search",
    name: "id",
    id: 2,
  },
  {
    title: "Status",
    type: "select",
    name: "status",
    options: orderStatusOptions,
    cellComponent: StatusOfOrder,
    id: 3,
  },
  {
    title: "Company name",
    type: "sort-search",
    name: "company_name",
    id: 4,
  },
  {
    title: "Creation Date",
    type: "sort-date",
    name: "create_date",
    id: 5,
  },
  {
    title: "Quantity",
    type: "sort-search",
    name: "quantity",
    id: 6,
  },
  {
    title: "Warehouse",
    type: "sort-search",
    name: "warehouse",
    id: 7,
  },
  {
    title: "Phone number",
    type: "sort-search",
    name: "phone_number",
    id: 8,
  },
  {
    title: "Email",
    type: "sort-search",
    name: "email",
    id: 9,
  },
  {
    title: "Status Updated",
    type: "sort-search",
    name: "status_updated",
    id: 10,
  },
]

export const Orders = () => {
  const dispatch = useAppDispatch()
  const [triggerGetOrders, { isFetching, data }] = useLazyGetVendorOrdersQuery()
  const [triggerGetFulfilledOrder, { isFetching: isFetchingFulfilled, data: dataFulfilled }] =
    useLazyGetVendorFulfilledOrdersQuery()
  const [triggerGetTableColumns, { data: columnsData }] = useLazyGetTableColumnsQuery()
  const [selectFulfilled, setSelectFulfilled] = useState<boolean>(false)
  const selectedVSMOrder = useAppSelector(state => state.tempData.selectedVSMOrder)
  const { urlQueryParams } = useUrlQueryParams()
  const [isFirstRender, setFirstRender] = useState(true)
  const meta = selectFulfilled ? dataFulfilled?.meta : data?.meta

  const tableHeaderCells = useMemo(
    (): UITypes.TableHeaderCell[] =>
      columnsData?.data?.config
        ? reorderTableHeaderCells(initialTableHeaderCells, columnsData?.data?.config)
        : initialTableHeaderCells,
    [columnsData],
  )

  const { handleExport } = useExportCSV({
    params: urlQueryParams,
    headerCells: tableHeaderCells,
    meta: meta,
    fileName: "orders",
    convertData: convertVSMOrdersToTableRows,
    useLazyQuery: selectFulfilled ? useLazyGetVendorFulfilledOrdersQuery : useLazyGetVendorOrdersQuery,
  })

  const onSetCurrentOrder = (id?: number) => {
    if (!selectFulfilled) {
      const ordersList = data?.data

      if (!id || !ordersList?.length) {
        dispatch(setSelectedVSMOrder(undefined))
        dispatch(setCurrentOrder(undefined))
      } else {
        const selectedVSMOrder = ordersList.filter(item => item.id === id)[0]
        dispatch(setSelectedVSMOrder(selectedVSMOrder))
        dispatch(setCurrentOrder(selectedVSMOrder))
      }
    }
  }

  const onEditClick = (id: number) => {
    if (!selectFulfilled) {
      onSetCurrentOrder(id)
      dispatch(showEditVendorOrderModal(true))
    }
  }

  const onDeleteClick = (id: number) => {
    if (!selectFulfilled) {
      onSetCurrentOrder(id)
      dispatch(showDeleteOrderModalForVendor(true))
    }
  }

  const onNewClick = () => {
    dispatch(showNewVendorOrderModal(true))
  }

  const onShowFulfill = (event: React.FormEvent<HTMLInputElement>) => {
    setSelectFulfilled(event.currentTarget.checked)
  }

  const onFulfillClick = () => {
    if (!checkFulfillAbility()) return false
    dispatch(showOrderFulfillmentModal(true))
  }

  const onCheckboxChange = (id: number) => {
    onSetCurrentOrder(id === selectedVSMOrder?.id ? undefined : id)
  }

  const checkFulfillAbility = () => {
    return selectedVSMOrder?.id && selectedVSMOrder?.status === "shipping"
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res = selectFulfilled ? await triggerGetFulfilledOrder(params) : await triggerGetOrders(params)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onEditColumnsClick = () => {
    dispatch(showEditColumnsModal(true))
    dispatch(
      setCurrentTableColumns({
        table: "admin_orders_list",
        columns: tableHeaderCells,
      }),
    )
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
    triggerGetTableColumns({ list_name: "admin_orders_list" })
  }, [urlQueryParams, selectFulfilled, isFirstRender])

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetWarehouseInformation />
        <WidgetVendorOrderStatus />
      </div>
      <ContentWrapper title="Orders history">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={onNewClick} color="blue">
              New order
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <span className={styles.editColumnsButton} onClick={onEditColumnsClick}>
              Edit columns
            </span>
            <ButtonNew
              disabled={!checkFulfillAbility()}
              onClick={onFulfillClick}
              color={!checkFulfillAbility() ? "grey" : "blue"}
            >
              Fulfill
            </ButtonNew>
            <CheckboxNew onChange={onShowFulfill} className={styles.tableNavItem} isReverse={true} id="fulfilled">
              Show fulfilled
            </CheckboxNew>
            <ButtonNew onClick={handleExport} className={styles.tableNavItem} disabled={meta?.total === 0}>
              Export
            </ButtonNew>
          </div>
        </nav>
        <TableWrapper
          tableName="vendor-orders"
          headerCells={tableHeaderCells}
          columnsLoaded={!!columnsData}
          data={convertVSMOrdersToTableRows(selectFulfilled ? dataFulfilled?.data : data?.data)}
          isLoading={isFetching || isFetchingFulfilled}
          currentItemID={selectedVSMOrder?.id}
          onCheckboxChange={onCheckboxChange}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          clearAdditionalTags={() => dispatch(setSelectedVSMOrder(undefined))}
          additionalTags={
            selectedVSMOrder
              ? [
                  {
                    label: "Order number",
                    values: [selectedVSMOrder.id],
                  },
                ]
              : []
          }
        />
        <Pagination meta={meta} />
      </ContentWrapper>
    </>
  )
}
