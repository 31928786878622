import { FormEvent, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import { ApiTypes, UITypes } from "types"
import { ReactComponent as IconPlus } from "assets/svg/icon-plus.svg"

import { createMessageTemplateSchema, onFormValidate, validationInitialState } from "utils/onValidate"
import { Spinner } from "components/common/Spinner"
import { Tooltip } from "components/common/Tooltip"

import { ButtonNew } from "../ButtonNew"
import { MarkdownEditor } from "../MarkdownEditor"

import styles from "./index.module.scss"

const frequencies: {
  label: string
  value: ApiTypes.Model.MessageTemplateFrequency
}[] = [
  {
    label: "Does not repeat",
    value: "never",
  },
  {
    label: "1 month",
    value: "1_month",
  },
  {
    label: "6 months",
    value: "6_months",
  },
  {
    label: "12 months",
    value: "12_months",
  },
]

const inputValuesInitialState: UITypes.MessageTemplate = {
  title: "",
  message: "",
  frequency: "never",
}

interface Props {
  onSave?: (data: UITypes.MessageTemplate) => void
  isLoading?: boolean
  editableTemplate?: UITypes.MessageTemplate
}

export const MessageForm = ({ onSave, isLoading, editableTemplate }: Props) => {
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)
  const location = useLocation()

  const onInputChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget

    setInputValues(prevState => {
      return { ...prevState, [name]: value }
    })
  }

  const onMessageChange = (value: string) => {
    setInputValues(prevState => {
      return { ...prevState, message: value }
    })
  }

  const setFrequency = (value: ApiTypes.Model.MessageTemplateFrequency) => {
    setInputValues({ ...inputValues, ...{ frequency: value } })
  }

  const onSubmit = () => {
    const data = {
      frequency: inputValues.frequency || "never",
      title: inputValues.title,
      message: inputValues.message,
    }

    if (onFormValidate(data, createMessageTemplateSchema, setError)) {
      onSave?.(data)
      setFrequency("never")
      setInputValues(inputValuesInitialState)
    }
  }

  useEffect(() => {
    if (editableTemplate) {
      setInputValues(editableTemplate)
    } else {
      setInputValues(inputValuesInitialState)
    }
  }, [editableTemplate])

  return (
    <form className={styles.messageFormWrapper} onSubmit={onSubmit}>
      <input
        type="text"
        className={classNames(styles.titleImput, { [styles.error]: error.field === "title" })}
        name="title"
        placeholder="Type a title..."
        value={inputValues.title}
        onChange={onInputChange}
      />
      {error.field === "title" && <div className={styles.errorMessage}>{error?.message}</div>}
      <div className={styles.symbolsCount}>{inputValues?.title?.length}/64</div>
      <div className={styles.textareaWrapper}>
        <span className={styles.textareaLabel}>Message</span>
        <MarkdownEditor
          value={inputValues.message}
          onChange={onMessageChange}
          errorMessage={error.field === "message" ? error.message : ""}
        />
        <div className={styles.symbolsCount}>{inputValues?.message?.length}/550</div>
      </div>
      {location.pathname === "/messages/add-template" ? (
        <div className={styles.submitButtonWrapper}>
          <span className={styles.noteText}>Save this message to your templates to be able edit and use.</span>
          <ButtonNew color="blue" disabled={isLoading} onClick={onSubmit}>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <IconPlus /> Add to my templates
              </>
            )}
          </ButtonNew>
        </div>
      ) : (
        <>
          <Tooltip id={uuidv4()} text="Repeat every" className={styles.tooltip}>
            Choose how often you want the event to repeat.
          </Tooltip>
          <div className={styles.repeatButtonsWrapper}>
            {frequencies.map(item => (
              <span
                key={uuidv4()}
                onClick={() => setFrequency(item.value)}
                className={classNames(styles.repeatButtons, {
                  [styles.active]: inputValues.frequency === item.value,
                })}
              >
                {item.label}
              </span>
            ))}
          </div>
          <div className={styles.submitButtonWrapper}>
            <ButtonNew disabled={isLoading} onClick={onSubmit} color="blue">
              {isLoading ? <Spinner /> : "Save"}
            </ButtonNew>
          </div>
        </>
      )}
    </form>
  )
}
