import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import { useLazyGetWHOrdersQuery, useLazyPackDevicesQuery } from "store/api/warehouseManagerApi"
import { useAppSelector } from "store/hooks"
import { UITypes } from "types"
import { ReactComponent as IconPacking } from "assets/svg/modal-icon-packing.svg"

import { convertValidatedDevicesToState } from "utils/convertData"
import { isValidDeviceSn } from "utils/isValidDeviceSn"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"
import { InputSN } from "components/form/InputSN"

import styles from "./index.module.scss"

interface Props {
  onCancel: () => void
}

export const PackingDeviceModal = ({ onCancel }: Props) => {
  const [triggerPackDevices, { isFetching }] = useLazyPackDevicesQuery()
  const [triggerGetWHOrders] = useLazyGetWHOrdersQuery()
  const selectedWHOrder = useAppSelector(state => state.tempData.selectedWHOrder)
  const [isNoDataError, setNoDataError] = useState(false)
  const [validatedDevices, setValidatedDevices] = useState<UITypes.InvalidDevice[]>([
    {
      uid: uuidv4(),
      sn: "",
      errorMessage: "",
    },
  ])

  const onItemDelete = (id: string) => {
    const result = validatedDevices?.filter(item => item.uid !== id)
    setValidatedDevices(result)
  }

  const checkDuplicates = (devices: UITypes.InvalidDevice[], currentItem: UITypes.InvalidDevice) => {
    const snCount: Record<string, number> = {}

    devices.forEach(item => {
      if (item.sn && item.uid !== currentItem.uid) {
        snCount[item.sn] = (snCount[item.sn] || 0) + 1
      }
    })

    const isDuplicate = currentItem.sn && snCount[currentItem.sn] > 0

    return devices.map(item =>
      item.uid === currentItem.uid ? { ...item, errorMessage: isDuplicate ? `Duplicate sn: ${item.sn}` : "" } : item,
    )
  }

  const handleChange = (data: UITypes.InvalidDevice) => {
    setValidatedDevices(prev => prev.map(item => (item.uid === data.uid ? { ...item, sn: data.sn } : item)))
  }

  const onItemBlur = (data: UITypes.InvalidDevice) => {
    const updatedDevices = validatedDevices?.map(item => (item.uid === data.uid ? data : item))

    const result = checkDuplicates(updatedDevices || [], data)

    const parseResult = result.map(item => (item.uid === data.uid ? { ...item, uid: uuidv4() } : item))
    setValidatedDevices([
      ...parseResult,
      ...(parseResult.length < Number(selectedWHOrder?.order_quantity)
        ? [
            {
              uid: data.uid,
              sn: "",
              errorMessage: "",
            },
          ]
        : []),
    ])
  }

  const sendRequest = async (devices: string[]) => {
    try {
      const res = await triggerPackDevices({ body: { serial_numbers: devices }, id: selectedWHOrder?.id || 0 })

      if (res?.data?.data?.failed?.length) {
        const resultDevices = convertValidatedDevicesToState(res?.data?.data?.failed)
        setValidatedDevices(prev =>
          prev.map(item => resultDevices.find(resultDevice => resultDevice.sn === item.sn) || item),
        )
      } else {
        triggerGetWHOrders({})
        toast.success("Successfully packed")
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const serialNumbers: string[] = []

    validatedDevices.forEach(item => {
      if (item.sn) {
        serialNumbers.push(item.sn as never)
      }
    })

    if (!serialNumbers?.length) {
      setNoDataError(true)
      return
    }

    setNoDataError(false)
    sendRequest(serialNumbers)
  }

  // ADD LAST INPUT
  useEffect(() => {
    if (validatedDevices?.length === selectedWHOrder?.order_quantity) return
    if (validatedDevices[validatedDevices?.length - 1]?.sn != "") {
      setValidatedDevices([
        ...validatedDevices,
        ...[
          {
            uid: uuidv4(),
            sn: "",
            errorMessage: "",
          },
        ],
      ])
    }
  }, [validatedDevices.length])

  const checkSubmitAvailability = (): boolean => {
    if (validatedDevices?.length !== selectedWHOrder?.order_quantity) {
      return true
    }

    if (validatedDevices?.some(item => item?.sn === "" || item.errorMessage !== "" || !isValidDeviceSn(item.sn))) {
      return true
    }
    return false
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          confirmButtonType="submit"
          buttonSize="auto"
          onCancel={onCancel}
          onConfirm={onSubmit}
          isDisabled={checkSubmitAvailability()}
          isFetching={isFetching}
        >
          <ModalHeader title="Packing">
            <IconPacking />
          </ModalHeader>
          <div className={styles.modalContent}>
            <div className={styles.dataWrapper}>
              <div className={styles.dataItem}>
                <h3 className={styles.dataItemTitle}>Contractor’s name</h3>
                <span className={styles.dataItemText}>{selectedWHOrder?.company_name}</span>
              </div>
              <div className={styles.dataItem}>
                <h3 className={styles.dataItemTitle}>Order Quantity</h3>
                <span className={styles.dataItemText}>{selectedWHOrder?.order_quantity}</span>
              </div>
            </div>
            <div className={styles.line}></div>
            <div className={classNames(styles.dataWrapper, styles.devicesInfo)}>
              <div className={styles.devicesInfoItem}>
                <h3 className={styles.dataItemTitle}>Added Devices</h3>
                <span className={styles.dataItemText}>{validatedDevices?.length}</span>
              </div>
            </div>
            {Boolean(validatedDevices?.length) &&
              validatedDevices.map((item, index) => (
                <InputSN
                  {...{ ...item, ...{ row: index + 1 } }}
                  key={item.uid}
                  onBlur={onItemBlur}
                  onDelete={onItemDelete}
                  onChange={handleChange}
                  autoFocus={validatedDevices?.length == index + 1 ? true : false}
                />
              ))}
            {isNoDataError && (
              <div className={classNames(styles.errorMessage, styles.errorMessageFullWith)}>
                Please upload the file or add the S/N manually
              </div>
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
