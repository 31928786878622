import { useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import classNames from "classnames"

import {
  useLazyGetAdminDevicesQuery,
  useLazyGetInstalledDevicesWidgetQuery,
  useLazyGetPhysicalStatusesWidgetQuery,
  useLazyPutReturnToWarehouseQuery,
} from "store/api/adminApi"
import { useLazyGetWarehouseListQuery } from "store/api/warehousesApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setSelectedDeviceIDs } from "store/tempDataSlise"
import { UITypes } from "types"
import { ReactComponent as IconTransfer } from "assets/svg/modal-icon-transfer.svg"

import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ModalLayout } from "layouts/ModalLayout"
import { SelectDynamic } from "components/form/SelectDynamic"

import styles from "./index.module.scss"
interface Props {
  onCancel: () => void
}

export const ReturnToWarehouseModal = ({ onCancel }: Props) => {
  const [triggerReturnToWarehouse, { isFetching }] = useLazyPutReturnToWarehouseQuery()
  const [triggerGetPhysicalStatusesWidget] = useLazyGetPhysicalStatusesWidgetQuery()
  const [triggerGetInstalledDEvicesWidget] = useLazyGetInstalledDevicesWidgetQuery()
  const [triggerGetAdminDevices] = useLazyGetAdminDevicesQuery()
  const dispatch = useAppDispatch()
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedWarehouse, setSelectedWarehouse] = useState<undefined | UITypes.Option[]>(undefined)
  const selectedDeviceIDs = useAppSelector(state => state.tempData.selectedDeviceIDs)
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    if (!selectedWarehouse?.length) {
      setErrorMessage("Please select a warehouse")
      return
    }

    try {
      const res = await triggerReturnToWarehouse({
        device_ids: selectedDeviceIDs,
        warehouse_id: selectedWarehouse[0]?.value as string,
      })
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetAdminDevices(urlQueryParams)
        triggerGetPhysicalStatusesWidget()
        triggerGetInstalledDEvicesWidget()
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconTransfer />
            <h3 className={styles.modalTitle}>Return to warehouse</h3>
            <div className={styles.modalMessage}>
              Are you sure you want to return the device(s) to the selected warehouse?
            </div>
            <div className={styles.selectWrapper}>
              <SelectDynamic
                label="Warehouses"
                valueName="id"
                labelName="name"
                placeholder="Select warehouse"
                name="name"
                errorMessage={errorMessage}
                useLazyQuery={useLazyGetWarehouseListQuery}
                onChange={setSelectedWarehouse}
              />
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
