import SubscriptionPlan from "../types/enums/SubscriptionPlan"
import SubscriptionPlanTitlesWithPrefix from "./SubscriptionPlanTitlesWithPrefix"

export const alertTypeOptions = [
  {
    label: "Air quality",
    value: "air_quality",
  },
  {
    label: "Offline",
    value: "offline",
  },
  {
    label: "Temperature",
    value: "temperature",
  },
  {
    label: "Humidity sensor malfunction",
    value: "humidity_sensor_malfunction",
  },
  {
    label: "Fan malfunction",
    value: "fan_malfunction",
  },
  {
    label: "Temperature sensor malfunction",
    value: "temperature_sensor_malfunction",
  },
  {
    label: "Ambient sensor malfunction",
    value: "ambient_sensor_malfunction",
  },
]

export const alertStatusOptions = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Acknowledged",
    value: "acknowledged",
  },
  {
    label: "Frozen",
    value: "frozen",
  },
]

export const contractorDevicePhysicalStatusOptions = [
  {
    label: "In Inventory",
    value: "inventory",
  },
  {
    label: "Installed",
    value: "installed",
  },
  {
    label: "Missing",
    value: "missing",
  },
  {
    label: "Warranty",
    value: "warranty",
  },
  {
    label: "Damaged",
    value: "damaged",
  },
  {
    label: "Transferring",
    value: "transferring",
  },
  {
    label: "Pre-Installed",
    value: "preinstalled",
  },
]

export const contractorPerformanceTestStatusOptions = [
  {
    label: "Scheduled",
    value: "scheduled",
  },
  {
    label: "Rescheduled",
    value: "rescheduled",
  },
  {
    label: "Running",
    value: "running",
  },
  {
    label: "Passed",
    value: "passed",
  },
  {
    label: "Issue",
    value: "issue",
  },
]

export const contractorPerformanceTestHistoryStatusOptions = [
  {
    label: "Passed",
    value: "passed",
  },
  {
    label: "Issue",
    value: "issue",
  },
]

export const deviceWorkStatusOptions = [
  {
    label: "Online",
    value: "online",
  },
  {
    label: "Offline",
    value: "offline",
  },
]

export const hvacOptions = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Existing",
    value: "existing",
  },
]

export const devicesAlertsOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
]

export const devicesMembershipOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
]

export const deviceSystemTypeOptions = [
  {
    label: "Traditional",
    value: "traditional",
  },
  {
    label: "Heat pump",
    value: "heat_pump",
  },
  {
    label: "Heat only",
    value: "heating",
  },
  {
    label: "Cool only",
    value: "cooling",
  },
  {
    label: "Dual fuel heating",
    value: "dual_fuel_heating",
  },
]

export const deviceAccessoriesOptions = [
  {
    label: "Humidifier",
    value: "humidifier",
  },
  {
    label: "Dehumidifier",
    value: "dehumidifier",
  },
  {
    label: "None",
    value: "none",
  },
]

export const optInOptions = [
  {
    label: "Opted In",
    value: "opted_in",
  },
  {
    label: "Opted Out",
    value: "opted_out",
  },
]

export const orderStatusOptions = [
  {
    label: "New",
    value: "new",
    // value: 1,
  },
  {
    label: "Pending",
    value: "pending",
    // value: 2,
  },
  {
    label: "Denied",
    value: "denied",
    // value: 3,
  },
  {
    label: "Confirmed",
    value: "confirmed",
    // value: 4,
  },
  {
    label: "Packed",
    value: "packed",
    // value: 5,
  },
  {
    label: "Shipping",
    value: "shipping",
    // value: 6,
  },
]

export const contractorSubscriptionPlanOptions = [
  {
    label: SubscriptionPlanTitlesWithPrefix[SubscriptionPlan.GO],
    value: SubscriptionPlan.GO,
  },
  {
    label: SubscriptionPlanTitlesWithPrefix[SubscriptionPlan.BASIC],
    value: SubscriptionPlan.BASIC,
  },
  {
    label: SubscriptionPlanTitlesWithPrefix[SubscriptionPlan.PLUS],
    value: SubscriptionPlan.PLUS,
  },
  {
    label: SubscriptionPlanTitlesWithPrefix[SubscriptionPlan.NETWORK],
    value: SubscriptionPlan.NETWORK,
  },
  {
    label: SubscriptionPlanTitlesWithPrefix[SubscriptionPlan.CONNECT],
    value: SubscriptionPlan.CONNECT,
  },
]

export const contractorStatusOptions = [
  {
    label: "Enabled",
    value: "1",
  },
  {
    label: "Disabled",
    value: "0",
  },
]

export const vendorDevicePhysicalStatusOptions = [
  {
    label: "In Stock",
    value: "stocked",
  },
  {
    label: "In Inventory",
    value: "inventory",
  },
  {
    label: "Installed",
    value: "installed",
  },
  {
    label: "Missing",
    value: "missing",
  },
  {
    label: "Production",
    value: "production",
  },
  {
    label: "Warranty",
    value: "warranty",
  },
  {
    label: "Damaged",
    value: "damaged",
  },
  {
    label: "Packed",
    value: "packed",
  },
  {
    label: "Pre-Installed",
    value: "preinstalled",
  },
  {
    label: "Transferring",
    value: "transferring",
  },
]

export const vendorDeviceRedFlagOptions = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "0",
  },
]

export const vendorDeviceRedFlagTypeOptions = [
  {
    label: "No Contractor & Installed",
    value: "No Contractor & Installed",
  },
  {
    label: "Warranty & online",
    value: "Warranty & online",
  },
  {
    label: "Missing & online",
    value: "Missing & online",
  },
  {
    label: "Damaged & online",
    value: "Damaged & online",
  },
  {
    label: "Alert over 2 days",
    value: "Alert over 2 days",
  },
  {
    label: "Offline over 7 days",
    value: "Offline over 7 days",
  },
  {
    label: "Installed without ST",
    value: "Installed without ST",
  },
  {
    label: "Installed over installed",
    value: "Installed over installed",
  },
  {
    label: "Zip Code not found",
    value: "Zip Code not found",
  },
  // {
  //   label: "Wrong location",
  //   value: "Wrong location",
  // },
]

export const userRoleOptions = [
  {
    label: "Vendor Admin",
    value: "VENDOR_ADMIN",
  },
  {
    label: "Vendor Sales Admin",
    value: "VENDOR_SALES_MANAGER",
  },
  {
    label: "Warehouse Manager",
    value: "WAREHOUSE_MANAGER",
  },
]

export const warehouseManagerOrderStatusOptions = [
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "Packed",
    value: "packed",
  },
  {
    label: "Shipping",
    value: "shipping",
  },
]

export const warehouseManagerDeviceStatusOptions = [
  {
    label: "In Stock",
    value: "stocked",
  },
  {
    label: "Missing",
    value: "missing",
  },
  {
    label: "Warranty",
    value: "warranty",
  },
  {
    label: "Damaged",
    value: "damaged",
  },
  {
    label: "Packed",
    value: "packed",
  },
]

export const parentGroupOptions = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "Wrench Group",
    value: "wrench",
  },
]
