import { RefObject, useEffect } from "react"

export function useOutsideClick(ref: RefObject<HTMLElement | null>, callback: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        callback()
        event.stopPropagation()
      }
    }

    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") {
        callback()
        event.stopPropagation()
      }
    }

    document.addEventListener("click", handleClickOutside, true)
    document.addEventListener("keydown", handleKeyDown, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
      document.removeEventListener("keydown", handleKeyDown, true)
    }
  }, [ref])
}
