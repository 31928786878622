import { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { debounce } from "lodash"

import { ReactComponent as IconMinus } from "assets/svg/icon-minus.svg"
import { ReactComponent as IconPlus } from "assets/svg/icon-plus.svg"

import styles from "./index.module.scss"

interface Props {
  value: number
  onInputChange: (value: number) => void
  minValue?: number
  maxValue?: number
}

export const InputNumber = ({ onInputChange, value, minValue, maxValue }: Props) => {
  const [permanentValue, setPermanentValue] = useState(value)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const disabledMinValue = minValue && value <= minValue
  const disabledMaxValue = maxValue && value >= maxValue

  useEffect(() => {
    if (value !== permanentValue) {
      setPermanentValue(value)
    }
  }, [value])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  })

  const onQuantityChange = (value: number) => {
    onInputChange(value)
    setPermanentValue(value)
  }

  const setDebounceQuantityChange = useRef<typeof onQuantityChange | null>(null)

  useEffect(() => {
    setDebounceQuantityChange.current = debounce(onQuantityChange, 200)
  }, [onInputChange])

  const calculateValue = (value: number) => {
    if (minValue && value < minValue) {
      return minValue
    }

    if (maxValue && value > maxValue) {
      return maxValue
    }

    return value
  }

  const handleInputChange = (value: number) => {
    const calculatedValue = calculateValue(value)
    setDebounceQuantityChange.current?.(calculatedValue)
    setPermanentValue(value)
  }

  return (
    <div className={styles.inputNumberWrapper}>
      <span
        className={classNames(styles.inputNumberButton, {
          [styles.disabled]: minValue && value <= minValue,
        })}
        onClick={() => !disabledMinValue && onInputChange(value - 1)}
      >
        <IconMinus />
      </span>
      <input
        value={permanentValue}
        ref={inputRef}
        type="number"
        className={styles.inputNumber}
        onChange={event => handleInputChange(Number(event?.currentTarget?.value))}
      />
      <span
        className={classNames(styles.inputNumberButton, {
          [styles.disabled]: maxValue && value >= maxValue,
        })}
        onClick={() => !disabledMaxValue && onInputChange(value + 1)}
      >
        <IconPlus />
      </span>
    </div>
  )
}
