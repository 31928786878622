export const shouldParseString = (value: string) => {
  const validFormat = /^(\d{2}-\d{3}-\d{6})(, ?(\d{2}-\d{3}-\d{6}))+$/
  const concatenatedFormat = /^(\d{2}-\d{3}-\d{6}){2,}$/
  return validFormat.test(value) || concatenatedFormat.test(value)
}

export const determineSeparator = (value: string) => {
  if (value.includes(", ")) {
    return ", "
  } else if (value.includes(",")) {
    return ","
  } else {
    return null
  }
}

export const parseStringToArray = (value: string): string[] | RegExpMatchArray => {
  const separator = determineSeparator(value)
  let result: string[] | RegExpMatchArray = []

  if (separator) {
    result = value.split(separator)
  } else {
    const regex = /(\d{2}-\d{3}-\d{6})/g
    result = value.match(regex) || [value]
  }

  return result
}

/*
EXAMPLE:

01-324-00065601-324-00066301-324-00067601-324-00066201-324-00067301-324-00065501-324-000658
01-324-000656,01-324-000663,01-324-000676,01-324-000662,01-324-000673,01-324-000655,01-324-000658
01-324-000656, 01-324-000663, 01-324-000676, 01-324-000662, 01-324-000673, 01-324-000655, 01-324-000658
*/
