import { contractorStatusOptions, contractorSubscriptionPlanOptions } from "constants/SelectFieldOptions"
import { UITypes } from "types"

import ContractorCompanyName from "components/table/columnComponents/ContractorCompanyName"
import ContractorInstalledDevicesCount from "components/table/columnComponents/ContractorInstalledDevicesCount"
import ContractorWarrantyDevicesCount from "components/table/columnComponents/ContractorWarrantyDevicesCount"

export const contractorTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Company name",
    type: "sort-search",
    name: "name",
    cellComponent: ContractorCompanyName,
    id: 1,
  },
  {
    title: "State",
    type: "sort-search",
    name: "state",
    id: 2,
  },
  {
    title: "Brand Name",
    type: "sort-search",
    name: "brand",
    id: 3,
  },
  {
    title: "Country",
    type: "sort-search",
    name: "country",
    id: 4,
  },
  {
    title: "Subscription Plan",
    type: "select",
    name: "subscription_plan",
    options: contractorSubscriptionPlanOptions,
    id: 5,
  },
  {
    title: "Purchased Devices",
    type: "sort-search",
    name: "purchased",
    id: 6,
  },
  {
    title: "Installed Devices",
    type: "sort-search",
    name: "installed",
    cellComponent: ContractorInstalledDevicesCount,
    id: 7,
  },
  {
    title: "Installed Last 7 days",
    type: "sort",
    name: "install_7_day",
    id: 8,
  },
  {
    title: "Activated mobile apps",
    type: "sort",
    name: "active_mobile",
    id: 9,
  },
  {
    title: "Inventory",
    type: "sort-search",
    name: "inventory",
    id: 10,
  },
  {
    title: "Warranty",
    type: "sort-search",
    name: "warranty",
    cellComponent: ContractorWarrantyDevicesCount,
    id: 11,
  },
  {
    title: "Status",
    type: "select",
    name: "isEnabled",
    options: contractorStatusOptions,
    cellComponent: "boolean",
    id: 12,
  },
]
