import { FormEvent } from "react"
import styled from "styled-components/macro"

import { useLazyGetContractorsQuery } from "store/api/contractorsApi"
import { useAppDispatch } from "store/hooks"
import { showAddContractorModal, showEditColumnsModal } from "store/modalSlise"
import { setCurrentTableColumns } from "store/tempDataSlise"
import { ApiTypes, UITypes } from "types"
import styles from "assets/scss/index.module.scss"
import { ReactComponent as TopArrow } from "assets/svg/contractor-top-arrow.svg"

import { convertContractorsToTableRows } from "utils/convertData"
import useExportCSV from "hooks/useExportCSV"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ButtonNew } from "components/form/ButtonNew"

const ReverseArrow = styled(TopArrow)`
  transform: rotate(180deg);
`

const Bottom16Button = styled(ButtonNew)`
  margin-right: 15px;
`

interface HeaderProps {
  tableHeaderCells: UITypes.TableHeaderCell[]
  meta?: ApiTypes.Model.Pagination
}

const Header = ({ tableHeaderCells, meta }: HeaderProps) => {
  const dispatch = useAppDispatch()
  const { setUrlQueryParams } = useUrlQueryParams()
  const { urlQueryParams } = useUrlQueryParams()

  const { handleExport } = useExportCSV({
    params: urlQueryParams,
    headerCells: tableHeaderCells,
    meta,
    fileName: "contractors",
    convertData: convertContractorsToTableRows,
    useLazyQuery: useLazyGetContractorsQuery,
  })

  const onAddClick = (event: FormEvent<HTMLElement>) => {
    event?.preventDefault()
    dispatch(showAddContractorModal(true))
  }

  const onEditColumnsClick = () => {
    dispatch(showEditColumnsModal(true))
    dispatch(
      setCurrentTableColumns({
        table: "admin_contractors_list",
        columns: tableHeaderCells,
      }),
    )
  }

  const setSearchParam = (param: "top15" | "bottom15") => {
    setUrlQueryParams({ [param]: true }, true)
  }

  const handleTop15Click = () => {
    setSearchParam("top15")
  }

  const handleBottom15Click = () => {
    setSearchParam("bottom15")
  }

  return (
    <nav className={styles.tableNavWrapper}>
      <div>
        <ButtonNew onClick={onAddClick} color="blue">
          Add contractor
        </ButtonNew>
      </div>
      <div className={styles.tableNavButtonsWrapper}>
        <ButtonNew
          color="tropicalCyan"
          onClick={handleTop15Click}
          className={styles.tableNavItem}
          disabled={meta?.total === 0}
        >
          <TopArrow />
          Top 15
        </ButtonNew>
        <Bottom16Button
          color="tropicalCyan"
          onClick={handleBottom15Click}
          className={styles.tableNavItem}
          disabled={meta?.total === 0}
        >
          <ReverseArrow />
          Bottom 15
        </Bottom16Button>
        <span className={styles.editColumnsButton} onClick={onEditColumnsClick}>
          Edit columns
        </span>
        <ButtonNew
          color="tropicalCyan"
          onClick={handleExport}
          className={styles.tableNavItem}
          disabled={meta?.total === 0}
        >
          Export
        </ButtonNew>
      </div>
    </nav>
  )
}

export default Header
