import { useEffect, useState } from "react"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import { useAppDispatch } from "store/hooks"
import { setAdditionalRequestParams } from "store/tempDataSlise"
import { ApiTypes } from "types"
import { ReactComponent as IconLeft } from "assets/svg/icon-left.svg"
import { ReactComponent as IconRight } from "assets/svg/icon-right.svg"

import styles from "./index.module.scss"

interface Props {
  meta?: ApiTypes.Model.Pagination
}

export const MiniPagination = ({ meta }: Props) => {
  const dispatch = useAppDispatch()

  const [data, setData] = useState({
    currentPage: 0,
    from: 0,
    lastPage: 0,
    perPage: 4,
  })

  const onPageChange = (value: number) => {
    dispatch(
      setAdditionalRequestParams({
        page: {
          value,
          label: "Page",
          type: "pagination",
        },
      }),
    )
  }

  useEffect(() => {
    if (!meta) return

    const { current_page, from, last_page, per_page } = meta

    setData({
      currentPage: current_page,
      lastPage: last_page,
      perPage: per_page,
      from,
    })

    if (current_page > last_page) {
      onPageChange(last_page)
    }
  }, [meta])

  return (
    <div className={styles.paginationWrapper}>
      <div className={styles.pageNumberList}>
        <span
          key={uuidv4()}
          className={classNames(styles.pageNumber, { [styles.disabled]: data.currentPage == 1 })}
          onClick={() => data.currentPage > 1 && onPageChange(data.currentPage - 1)}
        >
          <IconLeft />
        </span>
        <span key={uuidv4()} className={styles.paginationText}>
          {data.currentPage}/{data.lastPage}
        </span>
        <span
          key={uuidv4()}
          className={classNames(styles.pageNumber, { [styles.disabled]: data.currentPage == data.lastPage })}
          onClick={() => data.currentPage < data.lastPage && onPageChange(data.currentPage + 1)}
        >
          <IconRight />
        </span>
      </div>
    </div>
  )
}
