import { CellContext } from "@tanstack/react-table"
import classNames from "classnames"

import { UITypes } from "types"

import styles from "./index.module.scss"

interface Props {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}

export const StatusOfWork = ({ cell: { getValue } }: Props) => {
  const type = getValue().value ? String(getValue().value) : ""
  let label = ""

  switch (type) {
    case "offline":
      label = "Offline"
      break

    case "online":
      label = "Online"
      break

    default:
      label = type
      break
  }

  return (
    <span
      className={classNames(styles.deviceStatus, {
        [styles[type]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
