import { createPortal } from "react-dom"
import classNames from "classnames"

import styles from "assets/scss/index.module.scss"
import { ReactComponent as IconMissing } from "assets/svg/modal-icon-missing.svg"

import { ModalLayout } from "layouts/ModalLayout"

export const ShippingWarningModal = () => {
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          // isFetching={isFetching}
          buttonSize="fullWidth"
          onConfirm={onSubmit}
          confirmButtonText="Got it"
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconMissing />
            <div className={styles.modalMessage}>
              Sorry, we don&#39;t have enough devices in the warehouse for packing at the moment. Please contact the
              Administrator for further assistance.
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
