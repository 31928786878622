import { Line } from "react-chartjs-2"
import { Chart, ChartEvent, registerables, TooltipItem, TooltipModel } from "chart.js"
import classNames from "classnames"
import dayjs from "dayjs"

import { NoDataLayer } from "../../components/chart/NoDataLayer"
import { ApiTypes } from "../../types"
import getLegendMarginPlugin from "../../utils/chartjs/getLegendMarginPlugin"
import { borderColorWithSkipped, borderDashWithSkipped, defaultChartTooltipOptions } from "../../utils/chartUtils"
import { getRoundedTemperature } from "../../utils/getRoundedTemperature"
import parseCelsiusToFahrenheit from "../../utils/parseCelsiusToFahrenheit"

import styles from "./index.module.scss"

Chart.register(...registerables)

interface Props {
  isCelsius: boolean
  settings: {
    ticksCount: number
    dataCountsDivider: number
  }
  historicalData: ApiTypes.Res.MonitoringHistoricalDataBucket[]
  isSingleDataPoint: boolean
}

const TemperatureLineChart = ({ isCelsius, settings, historicalData, isSingleDataPoint }: Props) => {
  const getTemperature = (value: number | null) => {
    if (value === null) {
      return null
    }

    return isCelsius ? value : parseCelsiusToFahrenheit(value)
  }

  const parsedData =
    historicalData.map(item => ({
      temperature: getTemperature(item["avg#average_current_temperature_embedded"].value),
      setTemperature: getTemperature(item["avg#average_set_temperature"].value),
      timestamp: item.key_as_string,
    })) || []
  const noData = parsedData.length === 0

  const temperatureMark = isCelsius ? "°C" : "°F"

  const commonDatasetValues = {
    fill: "start",
    backgroundColor: "transparent",
    pointStyle: isSingleDataPoint as unknown as "circle",
    spanGaps: true,
    segment: {
      borderDash: borderDashWithSkipped,
      borderColor: borderColorWithSkipped,
    },
  }

  const data = {
    labels: [...parsedData.map(item => item.timestamp)],
    datasets: [
      {
        ...commonDatasetValues,
        label: "Current temperature",
        data: parsedData.map(item => item.temperature),
        borderColor: "#43E0F8",
        pointBackgroundColor: "#43E0F8",
      },
      {
        ...commonDatasetValues,
        label: "Set temperature",
        data: parsedData.map(item => item.setTemperature),
        borderColor: "#22c55e",
        pointBackgroundColor: "#22c55e",
      },
      ...(parsedData.length === 0
        ? [
            {
              label: "",
              data: [74, 75, 76, 77, 78],
            },
          ]
        : []),
    ],
  }

  const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: "index" as const,
    },
    plugins: {
      legend: {
        display: !noData,
        align: "end" as const,
        labels: {
          color: "#94A3B8",
          usePointStyle: true,
          pointStyle: "rect",
        },
      },
      tooltip: {
        ...defaultChartTooltipOptions,
        displayColors: true,
        callbacks: {
          title: function (this: TooltipModel<"line">, tooltipItem: TooltipItem<"line">[]) {
            const date = dayjs(tooltipItem[0].label)
            return date.format("YYYY-MM-DD HH:mm")
          },
          label: function (this: TooltipModel<"line">, tooltipItem: TooltipItem<"line">) {
            return `${getRoundedTemperature(tooltipItem.formattedValue, 2)}${temperatureMark}`
          },
        },
      },
    },
    scales: {
      x: {
        border: {
          color: "#e2e8f0",
        },
        grid: {
          color: "#e2e8f0",
        },
        ticks: {
          maxRotation: 0,
          maxTicksLimit: settings.ticksCount,
          callback: (value: number | string) => Number(value) / settings.dataCountsDivider,
          color: "#94A3B8",
        },
      },
      y: {
        position: "left" as const,
        border: {
          display: false,
          color: "rgba(0,0,0,0)",
        },
        ticks: {
          maxTicksLimit: 6,
          stepSize: parsedData.length === 0 ? 1 : 0.1,
          callback: function (value: number | string) {
            return `${getRoundedTemperature(value, 1)}${temperatureMark}`
          },
          color: "#94A3B8",
        },
      },
    },
    onHover: (ctx: ChartEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const chart: any = (ctx as any).chart
      chart.data.datasets[0].pointStyle = true
      chart.data.datasets[1].pointStyle = true
    },
  }

  return (
    <div
      className={classNames(styles.chartContainer, {
        [styles.chartContainerWithLabel]: !noData,
        [styles.emptyReportDataWrapper]: noData,
      })}
    >
      {noData && <NoDataLayer />}
      <Line data={data} options={options} plugins={[getLegendMarginPlugin(15)]} />
    </div>
  )
}

export default TemperatureLineChart
