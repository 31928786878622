import { useEffect, useState } from "react"
import classNames from "classnames"

import { useLazyGetContractorsWidgetQuery } from "store/api/vendorApi"

import { WidgetLayout } from "layouts/WidgetLayout"
import { BarChart } from "components/common/BarChart"
import { Spinner } from "components/common/Spinner"

import styles from "./index.module.scss"

const initialState = {
  active: 0,
  total: 0,
}

export const WidgetContractors = () => {
  const [widgetData, setWidgetData] = useState(initialState)
  const [triggerGetContractorsWidget, { isFetching, data }] = useLazyGetContractorsWidgetQuery()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetContractorsWidget()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      setWidgetData({
        active: data?.enabled,
        total: data?.total,
      })
    }
  }, [data])

  return (
    <WidgetLayout title="Contractors" className={styles.widgetWrapper} tag={widgetData?.total} tagName="Total">
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartValuesWrapper}>
          <span className={classNames(styles.chartValuesTitle, styles.green)}>Active</span>
          <span className={styles.chartValuesTitle}>
            {widgetData?.active} ({Math.round((widgetData?.active / widgetData.total) * 100)}%)
          </span>
        </div>
        <BarChart
          className={styles.chart}
          items={[
            {
              value: widgetData.active,
              color: "#22C55E",
            },
            {
              value: widgetData.total - widgetData.active,
              color: "#E2E8F0",
            },
          ]}
        />
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
