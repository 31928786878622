import { UITypes } from "types"

const prepareFormData = (
  contractor: UITypes.Contractor,
  tenantIDStatus: UITypes.TenantIDStautus,
  memberships: UITypes.Option[],
) => {
  const formData = new FormData()
  formData.append("name", contractor.name!)
  formData.append("brand", contractor.brand!)
  formData.append("address1", contractor.address1!)
  formData.append("address2", contractor.address2!)
  formData.append("url", contractor.url!)
  formData.append("schedule", contractor.schedule!)
  formData.append("full_name", contractor.fullName!)
  formData.append("tel1", contractor.phone!)
  formData.append("email", contractor.email!)
  formData.append("email_alerts", contractor.emailAlerts ? "1" : "0")
  formData.append("alert_email", contractor.alertEmail || "")
  if (contractor.state?.length) {
    formData.append("state", contractor.state[0].value as string)
  }
  if (contractor.city?.length) {
    formData.append("city", contractor.city[0].value as string)
  }
  if (contractor.zip?.length) {
    formData.append("zip", contractor.zip[0].value as string)
  }
  if (contractor.country?.length) {
    formData.append("country", contractor.country[0].value as string)
  }
  if (contractor.suburb?.length) {
    formData.append("suburb", contractor.suburb[0].value! as string)
  }
  if (contractor.parentGroup) {
    formData.append("parent_group", contractor.parentGroup[0].value as string)
  }
  if (contractor.image) {
    formData.append("image", contractor.image || "")
  }
  formData.append("is_enabled", contractor.isDisable ? "0" : "1")
  formData.append("is_custom", contractor.isCustom ? "1" : "0")
  formData.append("app_store_link", contractor.appStoreLink || "")
  formData.append("google_play_link", contractor.googlePlayLink || "")
  formData.append("phone_for_support", contractor.phoneForSupport ? "1" : "0")
  formData.append("phone_for_support_call", contractor.phoneForSupportCall || "")
  formData.append("phone_for_support_text", contractor.phoneForSupportText || "")
  formData.append("subscription_plan", contractor.subscriptionPlan || "")

  if (tenantIDStatus === "pass") {
    formData.append("has_st", "1")
    formData.append("is_st_checked", "1")
    formData.append("st_client_id", contractor.stClientID?.trim() || "")
    formData.append("st_client_secret", contractor.stClientSecret?.trim() || "")
    formData.append("st_app_key", contractor.stAppKey?.trim() || "")
    formData.append("st_tenant", contractor.stTenant?.trim() || "")
    formData.append(
      "memberships",
      JSON.stringify(memberships?.map(item => ({ id: Number(item.value), name: item.label }))),
    )
  }

  return formData
}

export default prepareFormData
