import { useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"

import { useLazyCreateOrderQuery, useLazyGetOrdersQuery } from "store/api/ordersApi"
import { ReactComponent as IconNewOrder } from "assets/svg/modal-icon-new-order.svg"

import getErrorMessage from "utils/getErrorMessage"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"

import OrdersModalContent from "./OrdersModalContent"

interface Props {
  onCancel: () => void
}

export const NewOrderModal = ({ onCancel }: Props) => {
  const [triggerCreateOrder, { isFetching }] = useLazyCreateOrderQuery()
  const [triggerGetOrders] = useLazyGetOrdersQuery()
  const [quantity, setQuantity] = useState(15)

  const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
    if (quantity < 15) {
      toast.error("Minimal quantity is 15 thermostats.")
      return
    }

    event.preventDefault()

    try {
      const res = await triggerCreateOrder({
        quantity: quantity,
      })

      const errorMessage = getErrorMessage(res.error)

      if (errorMessage) {
        toast.error(errorMessage)
      } else {
        toast.success("Order has been successfully added!")
        triggerGetOrders({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="570px" onConfirm={onSubmit} onCancel={onCancel} isFetching={isFetching}>
          <ModalHeader title="New order" subtitle="Please input the number of Nuve Thermostats you want to order.">
            <IconNewOrder />
          </ModalHeader>
          <OrdersModalContent isNewOrder quantity={quantity} setQuantity={setQuantity} />
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
