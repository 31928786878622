import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"

import { adminApi } from "./api/adminApi"
import { alertsApi } from "./api/alertsApi"
import { authApi } from "./api/authApi"
import { clientApi } from "./api/clientApi"
import { contractorsApi } from "./api/contractorsApi"
import { dashboardApi } from "./api/dashboardApi"
import { devicesApi } from "./api/devicesApi"
import { dictionaryApi } from "./api/dictionaryApi"
import { monitoringApi } from "./api/monitoringApi"
import { ordersApi } from "./api/ordersApi"
import { performanceTestApi } from "./api/performanceTestApi"
import { profileApi } from "./api/profileApi"
import { techniciansApi } from "./api/techniciansApi"
import { vendorApi } from "./api/vendorApi"
import { warehouseManagerApi } from "./api/warehouseManagerApi"
import { warehousesApi } from "./api/warehousesApi"
import { widgetsApi } from "./api/widgetsApi"
import { authMiddleware } from "./authMiddleware"
import modalsReducer from "./modalSlise"
import tempDataReducer from "./tempDataSlise"
import userReducer from "./userSlise"

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [techniciansApi.reducerPath]: techniciansApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [widgetsApi.reducerPath]: widgetsApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [contractorsApi.reducerPath]: contractorsApi.reducer,
    [dictionaryApi.reducerPath]: dictionaryApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [alertsApi.reducerPath]: alertsApi.reducer,
    [devicesApi.reducerPath]: devicesApi.reducer,
    [warehousesApi.reducerPath]: warehousesApi.reducer,
    [warehouseManagerApi.reducerPath]: warehouseManagerApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [vendorApi.reducerPath]: vendorApi.reducer,
    [performanceTestApi.reducerPath]: performanceTestApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [monitoringApi.reducerPath]: monitoringApi.reducer,
    modals: modalsReducer,
    user: userReducer,
    tempData: tempDataReducer,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authMiddleware)
      .concat(authApi.middleware)
      .concat(techniciansApi.middleware)
      .concat(ordersApi.middleware)
      .concat(widgetsApi.middleware)
      .concat(clientApi.middleware)
      .concat(contractorsApi.middleware)
      .concat(profileApi.middleware)
      .concat(alertsApi.middleware)
      .concat(dictionaryApi.middleware)
      .concat(devicesApi.middleware)
      .concat(warehousesApi.middleware)
      .concat(warehouseManagerApi.middleware)
      .concat(adminApi.middleware)
      .concat(vendorApi.middleware)
      .concat(performanceTestApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(monitoringApi.middleware)
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
