import { JSX } from "react"
import {
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  tablePlugin,
  thematicBreakPlugin,
} from "@mdxeditor/editor"
import { CellContext, ColumnDef, HeaderContext } from "@tanstack/react-table"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import { UITypes } from "types"
import { TableMeta } from "types/tableMeta"

import { CheckboxNew } from "components/form/CheckboxNew"

import GroupHeader from "./columnComponents/GroupHeader"
import { TableDatePicker } from "./TableDatePicker"
import { TableDateRangePicker } from "./TableDateRangePicker"
import { TableSearch } from "./TableSearch"
import { TableSelect } from "./TableSelect"
import { TableSort } from "./TableSort"

import styles from "./index.module.scss"

const getSize = (value: string | undefined) => {
  switch (value) {
    case "mini":
      return 32
    case "tiny":
      return 100
    case "small":
      return 160
    case "middle":
      return 250
    case "large":
      return 350
    case "big":
      return 450
    case "extra-big":
      return 600
    default:
      return 300
  }
}

const getHeaderComponent = (headerCell: UITypes.TableHeaderCell) => {
  return (header: HeaderContext<UITypes.TableBodyRow, UITypes.TableBodyCell>) => {
    const meta = header.table.options.meta as TableMeta

    switch (headerCell.type) {
      case "sort-search":
        return (
          <div className={classNames(styles.tableHeaderCell, styles.withoutSizeSettings)}>
            <TableSort label={headerCell.title} name={headerCell.name} />
            <TableSearch name={headerCell.name} />
          </div>
        )

      case "sort-date":
        return (
          <div className={classNames(styles.tableHeaderCell, styles.withoutSizeSettings)}>
            <TableSort label={headerCell.title} name={headerCell.name} />
            <TableDatePicker name={headerCell.name} />
          </div>
        )

      case "sort-date-range":
        return (
          <div className={classNames(styles.tableHeaderCell, styles.withoutSizeSettings)}>
            <TableSort label={headerCell.title} name={headerCell.name} />
            <TableDateRangePicker name={headerCell.name} />
          </div>
        )

      case "sort":
        return (
          <div className={classNames(styles.tableHeaderCell, styles.withoutSizeSettings)}>
            <TableSort label={headerCell.title} name={headerCell.name} />
          </div>
        )

      case "select":
        return (
          <div className={classNames(styles.tableHeaderCell, styles.withoutGaps, styles.withoutSizeSettings)}>
            <TableSelect headerCell={headerCell} />
          </div>
        )

      case "checkbox":
        return (
          <div className={classNames(styles.tableHeaderCell, styles.tableCellCheckbox, styles.withoutSizeSettings)}>
            <CheckboxNew isChecked={meta.isHeaderChecked} onChange={meta.onHeaderCheckboxChange} id={uuidv4()} />
          </div>
        )

      default:
        return (
          <div
            className={classNames(styles.tableHeaderCell, styles.withoutSizeSettings, {
              [styles.tableCellCheckbox]: !headerCell.title,
            })}
          >
            {headerCell.title}
          </div>
        )
    }
  }
}

const getRowCellComponent = (
  component:
    | (({ cell }: { cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell> }) => JSX.Element | undefined | null)
    | string
    | undefined,
) => {
  return (cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>) => {
    const meta = cell.table.options.meta as TableMeta
    const id = cell.row.original.rowSettings.id
    const cellProps = cell.getValue()
    const color = cellProps?.color || ""
    const classNamesList = classNames(styles[color], {
      [styles.action]: cellProps.callback ? true : false,
    })

    if (component instanceof Function) {
      const Component = component
      return (
        <div className={classNamesList}>
          <Component cell={cell} />
        </div>
      )
    }

    if (typeof component === "string") {
      switch (component) {
        case "boolean":
          return (
            <div key={uuidv4()} className={classNamesList}>
              {cellProps.value === "1" ? (
                <span className={classNames(styles.booleanValue, styles.enable)}>Enabled</span>
              ) : (
                <span className={classNames(styles.booleanValue, styles.disable)}>Disabled</span>
              )}
            </div>
          )

        case "checkbox": {
          let isChecked

          if (meta.currentItemIDs) {
            isChecked = meta.currentItemIDs?.some(item => item === id)
          } else {
            isChecked = meta.currentItemID === id ? true : false
          }

          return (
            <div className={classNamesList}>
              <CheckboxNew
                id={String(id)}
                onChange={() => meta.onCheckboxChange && meta.onCheckboxChange(id)}
                isChecked={isChecked}
                disabled={cellProps.disabled}
              />
            </div>
          )
        }
        case "markdown": {
          return (
            <div className={classNamesList}>
              <MDXEditor
                className={styles.markdownWrapper}
                markdown={cellProps.value as string}
                readOnly={true}
                plugins={[
                  headingsPlugin(),
                  listsPlugin(),
                  tablePlugin(),
                  thematicBreakPlugin(),
                  markdownShortcutPlugin(),
                ]}
              />
            </div>
          )
        }
      }
    }

    if (cellProps.groupHeader) {
      return (
        <div key={uuidv4()} className={classNamesList} onClick={() => cellProps?.callback && cellProps.callback(id)}>
          <GroupHeader cell={cell} />
        </div>
      )
    }

    return (
      <div key={uuidv4()} className={classNamesList} onClick={() => cellProps?.callback && cellProps.callback(id)}>
        {cellProps.value}
      </div>
    )
  }
}

type CustomColumnDef<TData, TCell> = ColumnDef<TData, TCell> & {
  meta?: number
}

export const getTableHeaderCellsConfig = (
  headerCells: UITypes.TableHeaderCell[],
): CustomColumnDef<UITypes.TableBodyRow, UITypes.TableBodyCell>[] => {
  return headerCells.map(headerCell => ({
    accessorKey: headerCell.name,
    header: getHeaderComponent(headerCell),
    size: getSize(headerCell.size),
    minSize: getSize(headerCell.size) / 2,
    type: headerCell.type,
    cell: getRowCellComponent(headerCell.cellComponent),
  }))
}
