import { flexRender, RowData, Table } from "@tanstack/react-table"
import styled from "styled-components/macro"

const HeaderRow = styled.div`
  display: flex;
  top: 0;
  z-index: 1000;
  margin-bottom: 2px;
  display: flex;
  min-height: 38px;
`

const HeaderCell = styled.div<{ width: number }>`
  display: flex;
  width: ${props => props.width}px;
  background: #f8fafc;
  color: #64748b;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }
`

const HeaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const Resizer = styled.div<{
  resizing: boolean
}>`
  position: absolute;
  right: 0;
  height: 100%;
  width: 10px;
  cursor: col-resize;

  &:after {
    position: relative;
    display: block;
    left: 4px;
    width: 2px;
    background-color: #ccc;
    height: 100%;

    ${props =>
      props.resizing &&
      `
        content: "";
        background-color: #42e8ff;
        opacity: 0.4;
     `}
  }

  &:hover {
    &:after {
      content: "";
    }
  }
`

interface TableHeaderProps<T extends RowData> {
  table: Table<T>
}

const TableHeader = <T extends RowData>({ table }: TableHeaderProps<T>) => {
  return (
    <>
      {table.getHeaderGroups().map(headerGroup => (
        <HeaderRow key={headerGroup.id}>
          {headerGroup.headers.map(header => {
            return (
              <HeaderCell key={header.id} width={header.getSize()}>
                <HeaderContainer>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.id !== "checkbox" && (
                    <Resizer
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      resizing={header.column.getIsResizing()}
                    />
                  )}
                </HeaderContainer>
              </HeaderCell>
            )
          })}
        </HeaderRow>
      ))}
    </>
  )
}

export default TableHeader
