import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import classNames from "classnames"

import {
  useLazyGetAdminDevicesQuery,
  useLazyGetInstalledDevicesWidgetQuery,
  useLazyGetPhysicalStatusesWidgetQuery,
  useLazyPutDevicesAsDamagedQuery,
} from "store/api/adminApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setSelectedDeviceIDs } from "store/tempDataSlise"
import styles from "assets/scss/index.module.scss"
import { ReactComponent as IconDamaged } from "assets/svg/modal-icon-damage.svg"

import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ModalLayout } from "layouts/ModalLayout"

interface Props {
  onCancel: () => void
}

export const DamageDeviceModalForVendor = ({ onCancel }: Props) => {
  const [triggerPutDevicesAsDamaged, { isFetching }] = useLazyPutDevicesAsDamagedQuery()
  const [triggerGetPhysicalStatusesWidget] = useLazyGetPhysicalStatusesWidgetQuery()
  const [triggerGetInstalledDEvicesWidget] = useLazyGetInstalledDevicesWidgetQuery()
  const [triggerGetAdminDevices] = useLazyGetAdminDevicesQuery()
  const dispatch = useAppDispatch()
  const selectedDeviceIDs = useAppSelector(state => state.tempData.selectedDeviceIDs)
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()

    try {
      const res = await triggerPutDevicesAsDamaged({ device_ids: selectedDeviceIDs })
      const error = getErrorMessage(res?.error)
      if (error) {
        toast.error(error)
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetAdminDevices(urlQueryParams)
        triggerGetPhysicalStatusesWidget()
        triggerGetInstalledDEvicesWidget()
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconDamaged />
            <h3 className={styles.modalTitle}>Mark as damaged</h3>
            <div className={styles.modalMessage}>Are you sure you want to mark the selected device(s) as Damaged?</div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
