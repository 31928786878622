import { toast } from "react-toastify"

import { useLazyChangeContractorsDevicesStatusQuery } from "store/api/contractorsApi"
import { useLazyGetDevicesQuery } from "store/api/devicesApi"
import { useAppDispatch } from "store/hooks"
import { setSelectedDeviceIDs } from "store/tempDataSlise"
import { ApiTypes } from "types"
import { ReactComponent as IconTransfer } from "assets/svg/modal-icon-trasfer.svg"

import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import ConfirmationModal from "modals/ConfirmationModal/ConfirmationModal"

interface InventoryConfirmationModalProps {
  selectedDevices: ApiTypes.Model.Device[]
  onCancel: () => void
}

const InventoryConfirmationModal = ({ onCancel, selectedDevices }: InventoryConfirmationModalProps) => {
  const [triggerChangeContractorsDevicesStatus, { isFetching }] = useLazyChangeContractorsDevicesStatusQuery()
  const [triggerGetDevices] = useLazyGetDevicesQuery()
  const dispatch = useAppDispatch()
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const res = await triggerChangeContractorsDevicesStatus({
        devices: selectedDevices.map(device => device.sn),
        new_status: "inventory",
      })
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetDevices(urlQueryParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ConfirmationModal
      title="In Inventory"
      message="Are you sure you want to change the status of the device to In Inventory?"
      isFetching={isFetching}
      icon={<IconTransfer />}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}

export default InventoryConfirmationModal
