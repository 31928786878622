import { useState } from "react"

import { useAppDispatch } from "store/hooks"
import { showPerformanceTestActionsModal, showStatsReportModal } from "store/modalSlise"
import { setSelectedDeviceForMonitoringReport, setSelectedDevicesForPerformanceTest } from "store/tempDataSlise"
import {
  contractorPerformanceTestStatusOptions,
  deviceAccessoriesOptions,
  devicesAlertsOptions,
  devicesMembershipOptions,
  deviceSystemTypeOptions,
  deviceWorkStatusOptions,
  hvacOptions,
} from "constants/SelectFieldOptions"
import { ApiTypes, UITypes } from "types"

import { convertDataToMonitoringTableRows } from "utils/convertData"
import DeviceRebootModal from "modals/DeviceRebootModal/DeviceRebootModal"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import { StatusOfPerformanceTest } from "components/table/columnComponents/StatusOfPerformanceTest"
import { StatusOfWork } from "components/table/columnComponents/StatusOfWork"
import TableWrapper from "components/table/TableWrapper"

import styles from "./index.module.scss"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Email",
    type: "sort-search",
    name: "email",
    size: "middle",
  },
  {
    title: "Status",
    type: "select",
    name: "perftest_status",
    size: "small",
    options: contractorPerformanceTestStatusOptions,
    cellComponent: StatusOfPerformanceTest,
  },
  {
    title: "Work status",
    type: "select",
    name: "work_status",
    size: "small",
    options: deviceWorkStatusOptions,
    cellComponent: StatusOfWork,
  },
  {
    title: "Country",
    type: "sort-search",
    name: "country",
    size: "small",
  },
  {
    title: "City",
    type: "sort-search",
    name: "city",
    size: "small",
  },
  {
    title: "ZIP code",
    type: "sort-search",
    name: "zip_code",
    size: "small",
  },
  {
    title: "State",
    type: "sort-search",
    name: "state",
    size: "small",
  },
  {
    title: "Customer",
    type: "sort-search",
    name: "homeowner_name",
    size: "middle",
  },
  {
    title: "Device S/N",
    type: "sort-search",
    name: "sn",
    size: "small",
  },
  {
    title: "Device name",
    type: "sort-search",
    size: "middle",
    name: "name",
  },
  {
    title: "Address",
    type: "sort-search",
    name: "address",
  },
  {
    title: "HVAC",
    type: "select",
    size: "small",
    name: "installation_type",
    options: hvacOptions,
  },
  {
    title: "Alerts",
    type: "select",
    size: "small",
    name: "alerts",
    options: devicesAlertsOptions,
  },
  {
    title: "Membership",
    type: "select",
    name: "membership",
    options: devicesMembershipOptions,
    size: "small",
  },
  {
    title: "System type",
    type: "select",
    size: "small",
    name: "system_type",
    options: deviceSystemTypeOptions,
  },
  {
    title: "Accessories",
    type: "select",
    size: "small",
    name: "accessories",
    options: deviceAccessoriesOptions,
  },
]

interface MonitoringPageContentProps {
  devices: ApiTypes.Model.Device[]
  meta: ApiTypes.Model.Pagination | undefined
  isFetching: boolean
}

const MonitoringPageContent = ({ devices, isFetching, meta }: MonitoringPageContentProps) => {
  const dispatch = useAppDispatch()
  const [showRebootModalDevice, setShowRebootModalDevice] = useState<ApiTypes.Model.Device | undefined>()

  const handleShowActionsModal = (id: number) => {
    const device = devices.find(item => item.id === id)

    dispatch(
      setSelectedDevicesForPerformanceTest({
        isImmediate: true,
        devices: [
          ...[{ id, email: device?.email, sn: device?.sn, system_type: device?.system_type }],
        ] as ApiTypes.Model.PerformanceTestDevice[],
      }),
    )
    dispatch(showPerformanceTestActionsModal(true))
  }

  const handleShowStatsReportModal = (id: number) => {
    const device = devices.find(item => item.id === id)

    dispatch(setSelectedDeviceForMonitoringReport(device))
    dispatch(showStatsReportModal(true))
  }

  const handleShowRebootModal = (id: number) => {
    const device = devices.find(item => item.id === id)

    setShowRebootModalDevice(device)
  }

  return (
    <ContentWrapper title="Monitoring Page">
      <nav className={styles.tableNavWrapper}>
        <div className={styles.tableNavButtonsWrapper}>
          {/* <ButtonNew className={styles.tableNavItem} onClick={() => {}} color="blueBordered">
            Export
          </ButtonNew> */}
        </div>
      </nav>
      <TableWrapper
        tableName="monitoring"
        headerCells={tableHeaderCells}
        data={convertDataToMonitoringTableRows(devices)}
        dynamicNavHandler1={handleShowActionsModal}
        dynamicNavHandler2={handleShowStatsReportModal}
        dynamicNavHandler3={handleShowRebootModal}
        isLoading={isFetching}
      />
      <Pagination meta={meta} />
      {showRebootModalDevice && (
        <DeviceRebootModal device={showRebootModalDevice} onClose={() => setShowRebootModalDevice(undefined)} />
      )}
    </ContentWrapper>
  )
}

export default MonitoringPageContent
