import { useState } from "react"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import { Dayjs } from "dayjs"

import {
  useLazyGetPerformanceTestLabelsQuery,
  useRunDeviceCommandMutation,
  useSchedulePerformanceTestMutation,
} from "store/api/performanceTestApi"
import { useAppSelector } from "store/hooks"
import {
  showPerformanceTestActionsModal,
  showPerformanceTestConfirmModal,
  showPerformanceTestFailModal,
} from "store/modalSlise"
import { setModalContent } from "store/tempDataSlise"
import { ApiTypes, UITypes } from "types"
import { ReactComponent as IconSummer } from "assets/svg/icon-summer.svg"
import { ReactComponent as IconWinter } from "assets/svg/icon-winter.svg"
import { ReactComponent as IconPerformance } from "assets/svg/menu-performance.svg"

import { ModalLayout } from "layouts/ModalLayout"
import DummyStaticSelect from "components/form/DummyStaticSelect"
import { SelectDynamic } from "components/form/SelectDynamic"

import { PerformanceTestSelectedDevices } from "../components/performanceTestSelectedDevices"
import PerformanceTestDateTimePickerModal from "./PerformanceTestDateTimePickerModal"

import styles from "./index.module.scss"

interface PerformanceTestActionsModalProps {
  onCancel: () => void
}

export const PerformanceTestActionsModal = ({ onCancel }: PerformanceTestActionsModalProps) => {
  const dispatch = useDispatch()
  const { devices, isImmediate } = useAppSelector(state => state.tempData.selectedDevicesForPerformanceTest)
  const [triggerSchedulePerformanceTest] = useSchedulePerformanceTestMutation()
  const [triggerRunPerformanceTest] = useRunDeviceCommandMutation()
  const [showDateTimePicker, setShowDateTimePicker] = useState(false)
  const [selectedDateTime, setSelectedDateTime] = useState<Dayjs | null>(null)
  const [selectedAction, setSelectedAction] = useState<ApiTypes.Model.PerformanceTestAction>("cooling")
  const [selectedLabel, setSelectedLabel] = useState<UITypes.Option | null>(null)

  const schedulePerformanceTest = async () => {
    const scheduleData: ApiTypes.Req.SchedulePerformanceTest = {
      action: selectedAction,
      label: selectedLabel?.value?.toString() || "",
      devices: devices.map(device => String(device.sn)),
      schedule: selectedDateTime?.format("YYYY-MM-DD HH:mm:00") || "",
    }
    try {
      const errorDevices = devices.filter(
        device =>
          (selectedAction === "cooling" && device.system_type === "heating") ||
          (selectedAction === "heating" && device.system_type === "cooling"),
      )
      dispatch(showPerformanceTestActionsModal(false))

      if (errorDevices.length) {
        dispatch(
          setModalContent({
            title: "Unable to run the performance test",
            content:
              "The selected devices contain System type Cool Only/Heat Only and user tries to turn on Cooling/Heating performance. Please recheck.",
            devices: errorDevices,
          }),
        )
        dispatch(showPerformanceTestFailModal(true))
        return
      }

      const res = await triggerSchedulePerformanceTest(scheduleData)

      if (isImmediate) {
        await triggerRunPerformanceTest({ sn: devices[0]?.sn || "", command: "perf_test" })
      }

      if (res?.error) {
        dispatch(
          setModalContent({
            title: "Unable to run the performance test",
            content: "There was an error while running the performance test. Please try again.",
          }),
        )
        dispatch(showPerformanceTestFailModal(true))
      } else {
        dispatch(
          setModalContent({
            title: "Successfully scheduled",
            content: `The Performance Tests for the selected devices will start ${selectedDateTime ? `at ${selectedDateTime.format("YYYY-MM-DD hh:mma")} device local time` : "between 10:00 AM and 12:00 PM."}`,
          }),
        )
        if (!isImmediate) {
          dispatch(showPerformanceTestConfirmModal(true))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeSelect = (item: UITypes.Option[]) => {
    setSelectedLabel(item?.[0] || null)
  }

  const addCustomOptionRenderer = (searchValue: string) => {
    return (
      <>
        <span className={styles.plusIcon}>+</span>Create New &#34;{searchValue}&#34; label
      </>
    )
  }

  const handleShowDateTimePicker = () => {
    setShowDateTimePicker(true)
  }

  return (
    <ModalLayout
      width="524px"
      isFetching={false}
      onConfirm={schedulePerformanceTest}
      onCancel={onCancel}
      confirmButtonText={`Start ${isImmediate ? "Test Now" : ""}`}
      cancelButtonText="Close"
    >
      <div className={styles.modalHeader}>
        <div className={styles.performanceIconWrapper}>
          <IconPerformance />
        </div>
        <div className={styles.performanceTestInfo}>
          <h3>Performance Test</h3>
          <p>
            Choose Summer or Winter to automatically start a 15-minute HVAC system check. The system will automatically
            activate Cooling for Summer or Heating for Winter for the selected customer(s).
          </p>
        </div>
      </div>
      <div className={styles.modalBody}>
        <div className={styles.performanceTestActionsWrapper}>
          <div
            className={classNames(styles.performanceTestAction, {
              [styles.activeAction]: selectedAction === "cooling",
            })}
            onClick={() => setSelectedAction("cooling")}
          >
            <IconSummer />
            <p>Summer</p>
            <span>Turn on Cooling for 15 minutes</span>
          </div>
          <div
            className={classNames(styles.performanceTestAction, {
              [styles.activeAction]: selectedAction === "heating",
            })}
            onClick={() => setSelectedAction("heating")}
          >
            <IconWinter />
            <p>Winter</p>
            <span>Turn on Heating for 15 minutes</span>
          </div>
        </div>
      </div>
      <PerformanceTestSelectedDevices devices={devices} />
      <div className={styles.modalBody}>
        <div className={styles.labelSection}>
          <p>Attach label</p>
          <SelectDynamic
            placeholder="Select or create a new one"
            values={[]}
            valueName=""
            labelName=""
            useLazyQuery={useLazyGetPerformanceTestLabelsQuery}
            onChange={handleChangeSelect}
            responseType="stringArray"
            name="label"
            dropdownHeight="200px"
            addCustomOptionRenderer={addCustomOptionRenderer}
          />
        </div>
        <div className={styles.scheduleSection}>
          <p>Schedule performance test</p>
          <DummyStaticSelect
            placeholder="Select schedule date and time"
            value={selectedDateTime ? selectedDateTime.format("MMMM D, YYYY [a]t hh:mm A") : ""}
            onClick={handleShowDateTimePicker}
          />
          {showDateTimePicker && (
            <PerformanceTestDateTimePickerModal
              onCancel={() => setShowDateTimePicker(false)}
              selectedDateTime={selectedDateTime}
              onDateTimeChange={setSelectedDateTime}
            />
          )}
        </div>
      </div>
    </ModalLayout>
  )
}
