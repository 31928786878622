import { CellContext } from "@tanstack/react-table"
import classNames from "classnames"

import { UITypes } from "types"

import styles from "./index.module.scss"

interface Props {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}

export const StatusOfMessageReport = ({ cell: { getValue } }: Props) => {
  let label = ""
  const type = getValue().value ? String(getValue().value) : ""

  switch (type) {
    case "pending":
      label = "Pending"
      break

    case "failed":
      label = "Failed"
      break

    case "delivered":
      label = "Delivered"
      break

    default:
      label = type
      break
  }

  return (
    <span
      className={classNames(styles.alertStatus, {
        [styles[type]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
