import { Bar } from "react-chartjs-2"
import { TooltipItem, TooltipModel } from "chart.js"
import styled from "styled-components/macro"

import { ApiTypes } from "types"

import { externalTooltipHandler } from "./CustomModesBarTooltip"
import { getBarChartSettings } from "./getBarChartsSettinsg"
import parseFormattedValueToTime from "./parseFormattedValueToTime"

const ChartContainer = styled.div`
  position: relative;
  margin-top: 30px;
  height: 180px;
`

interface SystemModesBarChartProps {
  ticksCount: number
  selectedTime: string | number | boolean
  historicalData: ApiTypes.Res.MonitoringHistoricalDataBucket[]
}

const SystemModesBarChart = ({ ticksCount, selectedTime, historicalData }: SystemModesBarChartProps) => {
  const settings = getBarChartSettings("System modes")
  const selectedSettings = settings[selectedTime as keyof typeof settings]
  const ticks = ticksCount || selectedSettings.ticksCount

  const barChartsData = (
    historicalData.reduce(
      (acc, item, index) => {
        if (index % selectedSettings.intervalItemsCount === 0) {
          acc.push({
            auto: 0,
            cooling: 0,
            emergency_heat: 0,
            heating: 0,
            off: 0,
            vacation: 0,
          })
        }
        const lastItem = acc[acc.length - 1]
        lastItem.auto += item["filters#running_mode_count"].buckets.auto.doc_count
        lastItem.cooling += item["filters#running_mode_count"].buckets.cooling.doc_count
        lastItem.emergency_heat += item["filters#running_mode_count"].buckets.emergency_heat.doc_count
        lastItem.heating += item["filters#running_mode_count"].buckets.heating.doc_count
        lastItem.off += item["filters#running_mode_count"].buckets.off.doc_count
        lastItem.vacation += item["filters#running_mode_count"].buckets.vacation.doc_count

        return acc
      },
      [] as {
        auto: number
        cooling: number
        emergency_heat: number
        heating: number
        off: number
        vacation: number
      }[],
    ) || []
  ).map(item => ({
    auto: item.auto / selectedSettings.hourlyDivider,
    cooling: item.cooling / selectedSettings.hourlyDivider,
    emergency_heat: item.emergency_heat / selectedSettings.hourlyDivider,
    heating: item.heating / selectedSettings.hourlyDivider,
    off: item.off / selectedSettings.hourlyDivider,
    vacation: item.vacation / selectedSettings.hourlyDivider,
  }))

  const labels = [...Array(ticks)].map((_, index) => index + 1)
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Cooling",
        backgroundColor: "#3c82f6",
        data: barChartsData.map(item => item.cooling),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Heating",
        backgroundColor: "#ef4444",
        data: barChartsData.map(item => item.heating),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Auto",
        backgroundColor: "#f59e0c",
        data: barChartsData.map(item => item.auto),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Vacation",
        backgroundColor: "#22c55d",
        data: barChartsData.map(item => item.vacation),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Em/Aux",
        backgroundColor: "#7f1c1d",
        data: barChartsData.map(item => item.emergency_heat),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
      {
        label: "Off",
        backgroundColor: "#cbd5e1",
        data: barChartsData.map(item => item.off),
        barPercentage: selectedSettings.barWidth,
        categoryPercentage: selectedSettings.barWidth,
      },
    ],
  }

  const options = {
    interaction: {
      mode: "index" as const,
    },
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest" as const,
        external: externalTooltipHandler,
        callbacks: {
          label: function (this: TooltipModel<"bar">, tooltipItem: TooltipItem<"bar">) {
            return `${tooltipItem.dataset.label}: ${parseFormattedValueToTime(tooltipItem.formattedValue, selectedSettings.dataType)}`
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: selectedSettings.labelX,
          color: "#94A3B8",
        },
        ticks: {
          color: "#94A3B8",
          // TODO: Fix ticks count for backend data
          maxTicksLimit: ticks + 1,
        },
      },
      y: {
        title: {
          display: true,
          text: selectedSettings.labelY,
          color: "#94A3B8",
        },
        stacked: true,
        max: selectedSettings.suggestedMax,
        ticks: {
          maxTicksLimit: 6,
          color: "#94A3B8",
        },
      },
    },
  }

  return (
    <ChartContainer>
      <Bar data={data} options={options} />
    </ChartContainer>
  )
}

export default SystemModesBarChart
