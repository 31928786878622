import React from "react"
import Joi from "joi"
export * from "./validationShemas"

type StateHandler = React.Dispatch<
  React.SetStateAction<{
    field: string
    message: string
  }>
>

export const onFormValidate = <T>(
  data: T,
  schema: Joi.ObjectSchema<T>,
  stateHandler: StateHandler,
  stripUnknown: boolean = false,
): boolean => {
  const result = schema?.validate(data, { stripUnknown })

  if (result.error) {
    const error = result?.error?.details[0]
    stateHandler({
      field: error?.path[0] as string,
      message: error?.message,
    })
    return false
  } else {
    stateHandler(validationInitialState)
    return true
  }
}

export const validationInitialState = {
  field: "",
  message: "",
}
