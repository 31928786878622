import { pickBy } from "lodash"

function toSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`)
}

const hasOwnProperty = <T>(obj: T, prop: string) => {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

const removeEmptyValues = <T>(obj: T) => {
  const transformedObject = {} as Record<string, string | number | boolean>

  for (const key in obj) {
    if (hasOwnProperty(obj, key)) {
      const snakeCaseKey = toSnakeCase(key)
      const param = obj[key]

      if (Array.isArray(param)) {
        param.forEach((item, index) => {
          if (item != null && typeof item === "object" && hasOwnProperty(item, "value")) {
            transformedObject[`${snakeCaseKey}[${index}]`] = item.value
          } else {
            transformedObject[`${snakeCaseKey}[${index}]`] = item
          }
        })
      } else if (param != null && typeof param === "object" && hasOwnProperty(param, "value")) {
        // @ts-expect-error hasOwnProperty checks for value
        transformedObject[snakeCaseKey] = param.value
      } else if (param != null) {
        transformedObject[snakeCaseKey] = param as string | number | boolean
      }
    }
  }

  return pickBy(transformedObject, value => value !== "")
}

export const prepareQueryParams = <T>(obj: T) => {
  return removeEmptyValues(obj)
}
