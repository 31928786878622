import { useState } from "react"
import { CellContext } from "@tanstack/react-table"
import styled from "styled-components/macro"

import { UITypes } from "types"

import { WarrantyDevicesHistoryModal } from "modals/WarrantyDevicesHistoryModal"

const Container = styled.div`
  color: #2db2c8;
  text-decoration: underline;
  cursor: pointer;

  span:hover {
    opacity: 0.8;
  }
`

interface ContractorWarrantyDevicesCountProps {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}
const ContractorWarrantyDevicesCount = ({ cell: { getValue, row } }: ContractorWarrantyDevicesCountProps) => {
  const contractor = row.original.rowSettings.item
  const [shownWarrantyDevicesContractorId, setShownWarrantyDevicesContractorId] = useState<number | null>(null)

  const onShowWarrantyDevicesHistory = async () => {
    setShownWarrantyDevicesContractorId(contractor.id)
  }

  return (
    <>
      <Container onClick={onShowWarrantyDevicesHistory}>{getValue().value}</Container>
      {shownWarrantyDevicesContractorId !== null && (
        <WarrantyDevicesHistoryModal
          contractorId={shownWarrantyDevicesContractorId}
          onCancel={() => setShownWarrantyDevicesContractorId(null)}
        />
      )}
    </>
  )
}

export default ContractorWarrantyDevicesCount
