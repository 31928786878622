import { v4 as uuidv4 } from "uuid"

import { UITypes } from "types"

import { transformText } from "utils/transformText"
import useUrlQueryParams from "hooks/useUrlQueryParams"

import styles from "./index.module.scss"

interface FilterTagProps {
  additionalTags?: UITypes.AdditionalTag[]
  clearAdditionalTags?: () => void
  skippedTags?: string[]
  headerCells: UITypes.TableHeaderCell[]
}

export const FilterTags = ({ additionalTags, clearAdditionalTags, skippedTags = [], headerCells }: FilterTagProps) => {
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()
  const orderHeaderCell = headerCells.find(item => item.name === urlQueryParams["orderColumn"])
  const hasSortTag = orderHeaderCell && urlQueryParams["orderType"] && !skippedTags.includes("orderColumn")
  const tags = headerCells
    .filter(item => !["checkbox"].includes(item.type || "") && !skippedTags.includes(item.name || ""))
    .filter(
      item =>
        (Object.hasOwn(urlQueryParams, item.name || "") && urlQueryParams[item.name || ""] !== "") ||
        (item.type === "sort-date-range" &&
          Object.hasOwn(urlQueryParams, `${item.name}_start_date`) &&
          urlQueryParams[`${item.name}_start_date`] !== ""),
    )

  const getFilterValue = (item: UITypes.TableHeaderCell) => {
    const value = item.name || ""

    if (item.type === "select") {
      return item.options?.find(option => option.value === urlQueryParams[value])?.label
    }

    if (item.type === "sort-date-range") {
      const startDate = urlQueryParams[`${value}_start_date`]

      return `${transformText(startDate)}`
    }

    return transformText(urlQueryParams[value] || "")
  }

  const handleFilterItemClear = (key: string, itemType?: string) => {
    if (key === "") {
      clearAdditionalTags?.()
      return
    }

    if (itemType === "sort-date-range") {
      setUrlQueryParams({ [`${key}_start_date`]: null, [`${key}_end_date`]: null })
      return
    }

    setUrlQueryParams({ [key]: null, ...(key === "orderColumn" ? { orderType: null } : {}) })
  }

  const renderFilterTags = (
    key: string | undefined,
    label: string,
    itemClearFunction?: (key: string, itemType: string | undefined) => void,
    value?: string,
    itemType?: string,
  ) => {
    return (
      <li className={styles.filterTag} key={key}>
        <span className={styles.filterTagName}>{label}</span>
        <span className={styles.filterTagValues}>{value}</span>
        {itemClearFunction && (
          <span onClick={() => itemClearFunction(key || "", itemType)} className={styles.filterTagClose}></span>
        )}
      </li>
    )
  }

  const renderAdditionalTags = () =>
    additionalTags?.map(item => {
      const values = item.values
      const shownValues = [...values.slice(0, 3), ...(values.length - 3 > 0 ? [`+${values.length - 3}`] : [])]

      return renderFilterTags(uuidv4(), item.label, clearAdditionalTags, shownValues.join(", "))
    })

  const renderSortTag = () => {
    if (
      skippedTags.includes("orderColumn") ||
      !urlQueryParams["orderType"] ||
      !urlQueryParams["orderColumn"] ||
      !orderHeaderCell
    ) {
      return null
    }

    return renderFilterTags(
      "orderColumn",
      orderHeaderCell.label || orderHeaderCell.title,
      handleFilterItemClear,
      urlQueryParams["orderType"] || "",
    )
  }

  if (tags.length || additionalTags?.length || hasSortTag) {
    return (
      <div className={styles.filterTagsWrapper}>
        <h3 className={styles.filterTitle}>Selected filter(s)</h3>
        <ul className={styles.filterTagsList}>
          {renderAdditionalTags()}
          {renderSortTag()}
          {tags.map(item =>
            renderFilterTags(
              item.name,
              item.label || item.title,
              handleFilterItemClear,
              getFilterValue(item),
              item.type,
            ),
          )}
        </ul>
      </div>
    )
  }

  return null
}
