import { useEffect } from "react"
import { toast } from "react-toastify"

import { useLazyGetDevicesQuery } from "store/api/devicesApi"
import { useAppSelector } from "store/hooks"
import { UITypes } from "types"
import SubscriptionPrivilege from "types/enums/SubscriptionPrivilege"

import getErrorMessage from "utils/getErrorMessage"
import hasSubscriptionAccess from "utils/hasSubscriptionAccess"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { SubscriptionUpgradeModal } from "modals/SubscriptionUpgradeModal/SubscriptionUpgradeModal"

import { dummyDevices, dummyMeta } from "./dummyMonitoringData"
import MonitoringPageContent from "./MonitoringPageContent"

const MonitoringPage = () => {
  const { urlQueryParams } = useUrlQueryParams()
  const userProfile = useAppSelector(state => state.user.userProfile)
  const [triggerGetMonitoringData, { isFetching, data }] = useLazyGetDevicesQuery({
    pollingInterval: 30000,
    skipPollingIfUnfocused: true,
  })
  const isFetchingData = isFetching && !data

  const hasMonitoringAccess = userProfile ? hasSubscriptionAccess(userProfile, SubscriptionPrivilege.Monitoring) : false

  const sendRequest = async (params: UITypes.Params) => {
    if (!hasMonitoringAccess) {
      return
    }

    try {
      const res = await triggerGetMonitoringData({ phisical_status: "installed", ...params })
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    sendRequest(urlQueryParams)
  }, [urlQueryParams])

  const getDevices = () => {
    if (!hasMonitoringAccess) {
      return dummyDevices
    }

    return data?.data || []
  }

  const devices = getDevices()
  const meta = hasMonitoringAccess ? data?.meta : dummyMeta

  return (
    <>
      <MonitoringPageContent isFetching={isFetchingData} devices={devices} meta={meta} />
      {!hasMonitoringAccess && <SubscriptionUpgradeModal page={SubscriptionPrivilege.Monitoring} />}
    </>
  )
}

export default MonitoringPage
