import classNames from "classnames"
import dayjs from "dayjs"
import styled from "styled-components/macro"

const CorrectedTemperatureText = styled.span`
  color: #64748b;
  font-size: 12px;
`

import { useGetMonitoringLiveDataQuery } from "store/api/monitoringApi"
import { ApiTypes } from "types"
import { ReactComponent as IconAir } from "assets/svg/modal-icon-air.svg"
import { ReactComponent as IconFan } from "assets/svg/modal-icon-fan.svg"
import { ReactComponent as IconHumidity } from "assets/svg/modal-icon-humidity.svg"
import { ReactComponent as IconSystem } from "assets/svg/modal-icon-system.svg"
import { ReactComponent as IconTemperature } from "assets/svg/modal-icon-temperature.svg"

import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter"
import { getRoundedTemperature } from "utils/getRoundedTemperature"
import parseCelsiusToFahrenheit from "utils/parseCelsiusToFahrenheit"
import { parseSystemType } from "utils/parseSystemType"
import ModalSectionTitle from "components/common/ModalSectionTitle/ModalSectionTitle"

import styles from "./index.module.scss"

interface Props {
  device: ApiTypes.Model.Device
  isCelsius: boolean
}

const airQualityStates = {
  1: { label: "Good", className: styles.good },
  2: { label: "Fair", className: styles.fair },
  3: { label: "Bad", className: styles.bad },
}

const LiveDateView = ({ device, isCelsius }: Props) => {
  const temperatureMark = isCelsius ? "°C" : "°F"

  const { data } = useGetMonitoringLiveDataQuery(device.sn, {
    pollingInterval: 5000,
    skipPollingIfUnfocused: true,
  })
  const liveData = dayjs().diff(dayjs(data?.updated_at), "minutes") < 6 ? data : null

  const getTemperature = (temperature: number | undefined, parseExactChange = false) => {
    if (temperature) {
      const parsedTemperature = isCelsius ? temperature : parseCelsiusToFahrenheit(temperature, parseExactChange)

      return `${getRoundedTemperature(parsedTemperature, 1)}${temperatureMark}`
    }

    return "-"
  }

  const getSystemModeTemperature = () => {
    if (liveData?.mode_alias === "auto") {
      return `${getTemperature(liveData?.auto_temp_low || 0)}-${getTemperature(liveData?.auto_temp_high || 0)}`
    }

    return getTemperature(liveData?.set_temperature)
  }

  const getHumidity = (humidity: number | undefined) => {
    if (humidity === undefined) return "-"

    return `${humidity}%`
  }

  const getAirQuality = (airQuality: 1 | 2 | 3 | undefined) => {
    if (airQuality) {
      return (
        <div className={classNames(styles.devicesDataText, airQualityStates[airQuality].className)}>
          {airQualityStates[airQuality].label}
        </div>
      )
    }

    return "-"
  }

  const getFunMode = () => {
    if (liveData?.set_fan === undefined) return "-"

    return liveData?.set_fan === 0 ? "AUTO" : "ON"
  }

  const getSystemStage = () => {
    if ((liveData?.current_cooling_stage || 0) > 0) {
      return (
        <div className={classNames(styles.devicesDataText, styles.cooling)}>
          Cooling (Stage {liveData?.current_cooling_stage})
        </div>
      )
    }

    if ((liveData?.current_heating_stage || 0) > 0) {
      return (
        <div className={classNames(styles.devicesDataText, styles.heating)}>
          Heating (Stage {liveData?.current_heating_stage})
        </div>
      )
    }

    return "-"
  }

  const getSystemMode = () => {
    if (liveData?.mode_alias === undefined) return "-"

    return (
      <div
        className={classNames(styles.devicesDataText, {
          [styles.heating]: liveData?.mode_alias === "heating",
          [styles.cooling]: liveData?.mode_alias === "cooling",
        })}
      >
        {capitalizeFirstLetter(liveData?.mode_alias)}
      </div>
    )
  }

  return (
    <>
      <section className={styles.devicesDataWrapper}>
        <ModalSectionTitle title="Temperature" Icon={IconTemperature} />
        <div className={classNames(styles.devicesDataContainer)}>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Current temperature</h4>
            <div className={styles.devicesDataText}>
              {getTemperature(liveData?.current_temperature_embedded)}
              {liveData?.temp_correction ? (
                <CorrectedTemperatureText>
                  {" "}
                  (Corrected by {getTemperature(liveData?.temp_correction, true)})
                </CorrectedTemperatureText>
              ) : null}
            </div>
          </div>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Set temperature</h4>
            <div className={styles.devicesDataText}>{getSystemModeTemperature()}</div>
          </div>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Outdoor temperature</h4>
            <div className={styles.devicesDataText}>{getTemperature(liveData?.current_temperature_outdoor)}</div>
          </div>
        </div>
      </section>
      <section className={styles.devicesDataWrapper}>
        <ModalSectionTitle title="Humidity" Icon={IconHumidity} />
        <div className={classNames(styles.devicesDataContainer)}>
          {device.accessories !== "none" && (
            <div className={classNames(styles.devicesDataItem, styles.column3)}>
              <h4 className={styles.modalSubtitle}>Set humidity</h4>
              <div className={styles.devicesDataText}>{getHumidity(liveData?.set_humidity)}</div>
            </div>
          )}
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Current humidity</h4>
            <div className={styles.devicesDataText}>{getHumidity(liveData?.current_humidity_embedded)}</div>
          </div>
        </div>
      </section>
      <section className={styles.devicesDataWrapper}>
        <ModalSectionTitle title="Air Quality" Icon={IconAir} />
        <div className={classNames(styles.devicesDataContainer)}>
          <div className={styles.devicesDataItem}>
            <h4 className={styles.modalSubtitle}>Air Quality state</h4>
            {getAirQuality(liveData?.current_air_quality as 1 | 2 | 3 | undefined)}
          </div>
        </div>
      </section>
      <section className={styles.devicesDataWrapper}>
        <ModalSectionTitle title="System" Icon={IconSystem} />
        <div className={classNames(styles.devicesDataContainer)}>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>Set system mode</h4>
            <div className={classNames(styles.devicesDataText, styles.blue)}>{getSystemMode()}</div>
          </div>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>System Current state</h4>
            <div className={classNames(styles.devicesDataText, styles.blue)}>{getSystemStage()}</div>
          </div>
          <div className={classNames(styles.devicesDataItem, styles.column3)}>
            <h4 className={styles.modalSubtitle}>System type</h4>
            <div className={styles.devicesDataText}>{parseSystemType(device.system_type) || "-"}</div>
          </div>
        </div>
      </section>
      <section className={styles.devicesDataWrapper}>
        <ModalSectionTitle title="Fan" Icon={IconFan} />
        <div className={classNames(styles.devicesDataContainer)}>
          <div className={styles.devicesDataItem}>
            <h4 className={styles.modalSubtitle}>Fan mode</h4>
            <div className={styles.devicesDataText}>{getFunMode()}</div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LiveDateView
