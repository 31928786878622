import { useEffect, useState } from "react"
import classNames from "classnames"

import { useLazyGetWHODevicesWidgetQuery } from "store/api/warehouseManagerApi"

import { WidgetLayout } from "layouts/WidgetLayout"
import { RainbowChart } from "components/common/RainbowChart"
import { Spinner } from "components/common/Spinner"

import styles from "./index.module.scss"

const initialState = {
  packedDevices: 0,
  availableForPacking: 0,
  allDevices: 0,
}

export const WidgetWarehouse = () => {
  const [widgetData, setWidgetData] = useState(initialState)
  const [triggerGetWHODevices, { isFetching, data }] = useLazyGetWHODevicesWidgetQuery()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetWHODevices()
        if (res?.data) {
          setWidgetData({
            packedDevices: res?.data?.data?.packed_devices,
            availableForPacking: res?.data?.data?.available_for_packing,
            allDevices: res?.data?.data?.all_devices,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <WidgetLayout title="Warehouse" address={data?.data?.address} className={styles.widgetWrapper}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <RainbowChart
            colors={["#FCD34D", "#FEF3C7"]}
            values={[widgetData.packedDevices, widgetData.availableForPacking]}
            className={styles.chart}
          />
        </div>
        <div className={styles.widgetMainValue}>
          <span className={styles.text}>DEVICES</span>
          <div className={styles.widgetValueWrapper}>{widgetData.allDevices}</div>
        </div>
        <footer className={styles.widgetFooter}>
          <div className={classNames(styles.widgetFooterItem)}>
            <span className={classNames(styles.widgetNote, styles.packed)}>Packed devices </span>
            <div className={styles.widgetValueWrapper}>{widgetData.packedDevices}</div>
          </div>
          <div className={classNames(styles.widgetFooterItem)}>
            <span className={classNames(styles.widgetNote, styles.available)}>Available for packing</span>
            <div className={styles.widgetValueWrapper}>{widgetData.availableForPacking}</div>
          </div>
        </footer>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
