import styled from "styled-components/macro"
import { v4 as uuidv4 } from "uuid"

import { ReactComponent as IconLeft } from "assets/svg/icon-left.svg"
import { ReactComponent as IconRight } from "assets/svg/icon-right.svg"

import PaginationItem from "./PaginationItem"

const Container = styled.div`
  display: flex;

  @media only screen and (max-width: 900px) {
    order: 3;
    width: 100%;
    justify-content: center;
    margin-top: 15px;
  }
`

interface PaginationItemsProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

const PaginationItems = ({ currentPage, totalPages, onPageChange }: PaginationItemsProps) => {
  const getCloseToStartPages = () => {
    if (currentPage - 5 < 1) {
      return [2].filter(item => currentPage - 2 > item)
    }

    return ["..." as const, ...[totalPages - 4, totalPages - 3].filter(n => currentPage - n > 2)]
  }

  const getCloseToEndPages = () => {
    if (totalPages - currentPage < 5) {
      return [totalPages - 1].filter(item => currentPage + 2 < item)
    }

    return [...[4, 5].filter(n => currentPage - n < -2), "..." as const]
  }

  const getPages = () => {
    if (totalPages <= 6) {
      return Array.from({ length: totalPages }, (_, i) => i + 1)
    }

    return [
      1,
      ...getCloseToStartPages(),
      ...[currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2].filter(
        item => item > 1 && item < totalPages,
      ),
      ...getCloseToEndPages(),
      totalPages,
    ]
  }

  if (!currentPage) return null

  return (
    <Container>
      <PaginationItem page={currentPage - 1} onPageChange={onPageChange} disabled={currentPage === 1}>
        <IconLeft />
      </PaginationItem>
      {getPages().map((page: number | "...") => (
        <PaginationItem
          key={uuidv4()}
          page={page}
          onPageChange={onPageChange}
          active={currentPage === page}
          readonly={page === "..."}
        >
          {page}
        </PaginationItem>
      ))}
      <PaginationItem page={currentPage + 1} onPageChange={onPageChange} disabled={currentPage === totalPages}>
        <IconRight />
      </PaginationItem>
    </Container>
  )
}

export default PaginationItems
