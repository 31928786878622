import { keyBy, merge, values } from "lodash"

import { ApiTypes, UITypes } from "types"

export const reorderTableHeaderCells = (
  columnsFE: UITypes.TableHeaderCell[],
  columnsBE: ApiTypes.Model.TableColumn[],
) => {
  const keyedArray1 = keyBy(columnsFE, "name")
  const keyedArray2 = keyBy(columnsBE, "name")

  const merged = merge({}, keyedArray1, keyedArray2)
  const result = values(merged)
    .filter(item => keyedArray1[item.name])
    .map(item => ({ ...item, visibility: item.visibility ?? true }))

  result.sort((a, b) => {
    const indexA = columnsBE.findIndex(item => item.name === a.name)
    const indexB = columnsBE.findIndex(item => item.name === b.name)

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB
    }

    const FEindexA = columnsFE.findIndex(item => item.name === a.name)
    const FEindexB = columnsFE.findIndex(item => item.name === b.name)

    return FEindexA - FEindexB
  })

  return result
}

export const reorderTabbleBodyCells = (
  sourceArray: UITypes.TableBodyCell[],
  referenceArray: UITypes.TableHeaderCell[] | undefined,
) => {
  const visibilityMap =
    referenceArray?.reduce(
      (acc, item) => {
        const name = item.name
        if (name) {
          acc[name] = item.visibility
        }
        return acc
      },
      {} as Record<string, boolean | undefined>,
    ) ?? ({} as Record<string, boolean | undefined>)

  const filteredSource = sourceArray.filter(item => visibilityMap[item.name || ""] !== undefined)

  const orderedArray = referenceArray
    ?.filter(refItem => refItem.visibility)
    .map(refItem => filteredSource.find(srcItem => srcItem.name === refItem.name))
    .filter(item => item !== undefined)

  return orderedArray || []
}
