import { useRef, useState } from "react"
import { Dayjs } from "dayjs"
import styled from "styled-components/macro"

import { UITypes } from "types"

import DateRangePicker from "modals/StatsReportModal/DateRangePicker"
import { dropDownOptions } from "modals/StatsReportModal/getMonitoringDateRangeParams"
import { SelectStatic } from "components/form/SelectStatic"

const Container = styled.div`
  z-index: 1000;
  position: absolute;

  > div {
    margin-bottom: 5px;
  }

  .react-dropdown-select {
    min-width: 160px;
    width: auto;
    display: flex;
    min-height: 32px !important;
  }
`

interface DateRangeSelectProps {
  options?: UITypes.Option[]
  selectedTime: UITypes.Option[]
  selectedDateRange?: [Dayjs, Dayjs] | null
  skipTimeOptions?: boolean
  setSelectedDateRange?: (dateRange: [Dayjs, Dayjs] | null) => void
  setSelectedTime: (time: UITypes.Option[]) => void
}

const DateRangeSelect = ({
  options,
  selectedTime,
  selectedDateRange,
  skipTimeOptions,
  setSelectedDateRange,
  setSelectedTime,
}: DateRangeSelectProps) => {
  const selectKey = useRef(1)
  const isCustom = selectedTime[0].value === "custom"
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false)
  const selectOptions = options || dropDownOptions

  const handleDateCancel = () => {
    selectKey.current += 1
    setShowCustomDatePicker(false)
  }

  const contentRenderer = () => {
    if (isCustom && selectedDateRange) {
      return () => (
        <>
          {selectedDateRange[0].format("MMM D, YYYY")}-{selectedDateRange[1].format("MMM D, YYYY")}
        </>
      )
    }

    return undefined
  }

  const handleDateRangeSubmit = (dateRange: [Dayjs, Dayjs]) => {
    setSelectedDateRange?.(dateRange)
    setShowCustomDatePicker(false)
    setSelectedTime([selectOptions[selectOptions.length - 1]])
  }

  const handleSetSelectedTime = (option: UITypes.Option[]) => {
    if (option[0].value === "custom") {
      setShowCustomDatePicker(true)
      return
    }

    setShowCustomDatePicker(false)
    setSelectedTime(option)
  }

  return (
    <Container>
      <SelectStatic
        key={selectKey.current}
        values={selectedTime}
        onChange={handleSetSelectedTime}
        options={selectOptions.filter(
          option => !skipTimeOptions || !["lastHour", "last6Hours", "last24Hours"].includes(String(option.value)),
        )}
        contentRenderer={contentRenderer()}
      />
      {showCustomDatePicker && (
        <DateRangePicker
          maxRange={30}
          onCancel={handleDateCancel}
          onSubmit={handleDateRangeSubmit}
          selectedRange={selectedDateRange || undefined}
        />
      )}
    </Container>
  )
}

export default DateRangeSelect
