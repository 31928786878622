import { PropsWithChildren } from "react"
import { Tooltip as ReactTooltip } from "react-tooltip"
import styled from "styled-components"

import "./tooltip.css"

import styles from "./index.module.scss"

const MiniText = styled.span`
  font-size: 10px;
`

interface Props extends PropsWithChildren {
  id: string
  text?: string
  miniText?: string
  className?: string
  orientation?: "top" | "bottom" | "left" | "right" | "top-start"
}
export const Tooltip = ({ children, id, text = "", miniText = "", orientation = "top-start", className }: Props) => {
  return (
    <div className={className}>
      <span data-tooltip-id={id} className={styles.tooltipText}>
        {text}
        {miniText && <MiniText> {miniText}</MiniText>}
      </span>
      <ReactTooltip classNameArrow={styles.tooltipArrow} className={styles.tooltip} place={orientation} id={id}>
        {children}
      </ReactTooltip>
    </div>
  )
}
