import { ReactNode } from "react"
import DropdownSelect from "react-dropdown-select"
import classNames from "classnames"

import "assets/libs/select.css"

import { UITypes } from "types"

import styles from "./index.module.scss"

interface Props {
  label?: string
  placeholder?: string
  values?: UITypes.Option[]
  name?: string
  disabled?: boolean
  errorMessage?: string
  options?: UITypes.Option[]
  portal?: HTMLElement
  multi?: boolean
  isLoading?: boolean
  className?: string
  onChange?: (value: UITypes.Option[]) => void
  minWidth?: string
  backspaceDelete?: boolean
  contentRenderer?: () => ReactNode
}

export const SelectStatic = ({
  label,
  options = [],
  placeholder,
  disabled,
  values = [],
  name,
  portal,
  multi,
  isLoading,
  errorMessage,
  className,
  onChange,
  minWidth,
  backspaceDelete = false,
  contentRenderer,
}: Props) => {
  const handleChange = (options: UITypes.Option[]) => {
    if (onChange) {
      onChange(options)
    }
  }

  return (
    <div className={classNames(styles.fieldWrapper, className)}>
      {label && <div className={styles.fieldLabelNew}>{label}</div>}
      <div className={classNames(styles.field)}>
        <DropdownSelect
          key={options.length}
          disabled={disabled}
          options={options}
          values={values?.length ? values : []}
          searchable
          loading={isLoading}
          className={classNames("custom", {
            [styles.error]: errorMessage,
            [styles.disabled]: disabled,
          })}
          name={name}
          style={{ minWidth: minWidth }}
          multi={multi}
          placeholder={placeholder}
          portal={portal}
          onChange={handleChange}
          contentRenderer={contentRenderer}
          backspaceDelete={backspaceDelete}
        />
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
