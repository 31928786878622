import { toast } from "react-toastify"

import { useLazyMarkDeviceAsWarrantyQuery } from "store/api/contractorsApi"
import { useLazyGetDevicesQuery } from "store/api/devicesApi"
import { useAppDispatch } from "store/hooks"
import { setSelectedDeviceIDs } from "store/tempDataSlise"
import { ApiTypes } from "types"
import { ReactComponent as IconTransfer } from "assets/svg/modal-icon-trasfer.svg"

import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import ConfirmationModal from "modals/ConfirmationModal/ConfirmationModal"

interface WarrantyConfirmationModalProps {
  selectedDevices: ApiTypes.Model.Device[]
  onCancel: () => void
}

const WarrantyConfirmationModal = ({ onCancel, selectedDevices }: WarrantyConfirmationModalProps) => {
  const [triggerMarkAsWarranty, { isFetching }] = useLazyMarkDeviceAsWarrantyQuery()
  const [triggerGetDevices] = useLazyGetDevicesQuery()
  const dispatch = useAppDispatch()
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const allRequests = Promise.all(selectedDevices.map(device => triggerMarkAsWarranty(device.sn)))

    allRequests.then(responses => {
      const errors = responses.map(res => getErrorMessage(res?.error)).filter(Boolean)

      if (errors.length) {
        toast.error(errors.join(", "))
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetDevices(urlQueryParams)
        onCancel()
      }
    })
  }

  return (
    <ConfirmationModal
      title="Warranty"
      message="The customer information will be lost. Are you sure you want to change the status of the device to Warranty?"
      isFetching={isFetching}
      icon={<IconTransfer />}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}

export default WarrantyConfirmationModal
