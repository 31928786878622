import { CellContext } from "@tanstack/react-table"
import classNames from "classnames"

import { UITypes } from "types"

import styles from "./index.module.scss"

interface Props {
  cell?: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
  physicalStatus?: string
}

export const StatusOfPhysical = ({ cell, physicalStatus }: Props) => {
  const type = cell?.getValue().value ? String(cell.getValue().value) : physicalStatus
  let label = ""

  switch (type) {
    case "stocked":
      label = "In Stock"
      break

    case "inventory":
      label = "In inventory"
      break

    case "installed":
      label = "Installed"
      break

    case "missing":
      label = "Missing"
      break

    case "production":
      label = "Production"
      break

    case "packed":
      label = "Packed"
      break

    case "warranty":
      label = "Warranty"
      break

    case "damaged":
      label = "Damaged"
      break

    case "preinstalled":
      label = "Pre-installed"
      break

    case "transferring":
      label = "Transferring"
      break

    default:
      label = type ? String(type) : ""
      break
  }

  return (
    <span
      className={classNames(styles.physicalStatus, {
        [styles[String(type)]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
