import { Middleware } from "react-tooltip"
import { Action, Dispatch, MiddlewareAPI } from "@reduxjs/toolkit"

interface CustomAction extends Action {
  error?: boolean
  payload?: {
    status?: number
  }
}

export const authMiddleware: Middleware =
  (_props: MiddlewareAPI) => (next: Dispatch<CustomAction>) => (action: CustomAction) => {
    if (action.error && action.payload?.status === 401) {
      window.location.href = "/"
    }

    return next(action)
  }
