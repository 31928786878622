import { createApi } from "@reduxjs/toolkit/query/react"

import { ApiTypes, UITypes } from "types"

import { baseQueryWithAuth } from "utils/api"
import { prepareQueryParams } from "utils/prepareQueryParams"

export const dictionaryApi = createApi({
  reducerPath: "dictionary",
  tagTypes: ["dictionary"],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    getCities: builder.query<{ data: ApiTypes.Res.Dictionary[] }, { name?: string }>({
      query: arg => {
        const { name } = arg
        return {
          url: `/dictionary/cities`,
          method: "GET",
          params: {
            name,
          },
        }
      },
    }),
    getSuburbs: builder.query<{ data: ApiTypes.Res.Dictionary[] }, { name?: string }>({
      query: arg => {
        const { name } = arg
        return {
          url: `/dictionary/suburbs`,
          method: "GET",
          params: {
            name,
          },
        }
      },
    }),
    getCountries: builder.query<{ data: ApiTypes.Res.Dictionary[] }, UITypes.Params>({
      query: arg => {
        return {
          url: `/dictionary/countries`,
          method: "GET",
          params: prepareQueryParams(arg),
        }
      },
    }),
    getZipCodes: builder.query<{ data: ApiTypes.Res.Dictionary[] }, UITypes.Params>({
      query: arg => {
        return {
          url: `/dictionary/zipCodes`,
          method: "GET",
          params: prepareQueryParams(arg),
        }
      },
    }),
    getStates: builder.query<{ data: ApiTypes.Res.Dictionary[] }, { name?: string }>({
      query: arg => {
        const { name } = arg
        return {
          url: `/dictionary/states`,
          method: "GET",
          params: {
            name,
          },
        }
      },
    }),
    getResidentTypes: builder.query<{ data: ApiTypes.Res.Dictionary[] }, { name?: string }>({
      query: arg => {
        const { name } = arg
        return {
          url: `/dictionary/residentTypes`,
          method: "GET",
          params: {
            name,
          },
        }
      },
    }),
    getDeviceLocations: builder.query<{ data: ApiTypes.Res.Dictionary[] }, { name?: string; rt?: string }>({
      query: arg => {
        return {
          url: `/dictionary/deviceLocations`,
          method: "GET",
          params: prepareQueryParams(arg),
        }
      },
    }),
  }),
})

export const {
  useLazyGetCountriesQuery,
  useLazyGetCitiesQuery,
  useLazyGetSuburbsQuery,
  useLazyGetZipCodesQuery,
  useLazyGetStatesQuery,
  useLazyGetResidentTypesQuery,
  useLazyGetDeviceLocationsQuery,
} = dictionaryApi
