import { Outlet } from "react-router-dom"

import logo from "assets/svg/logo-white.svg"

import styles from "./index.module.scss"

export const AuthLayout = () => (
  <section className={styles.authWrapper}>
    <Outlet />
    <figure className={styles.logoWrapper}>
      <img className={styles.logo} src={logo} alt="logo" />
    </figure>
  </section>
)
