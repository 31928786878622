import { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { useLazyGetWHOrdersQuery } from "store/api/warehouseManagerApi"
import { useAppDispatch } from "store/hooks"
import { showPackingOrderModal, showShippingOrderModal } from "store/modalSlise"
import { setSelectedWHOrder } from "store/tempDataSlise"
import { warehouseManagerOrderStatusOptions } from "constants/SelectFieldOptions"
import { UITypes } from "types"

import { convertWHManagerOrdersToTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import { StatusOfWHOrder } from "components/table/columnComponents/StatusOfWHOrder"
import TableWrapper from "components/table/TableWrapper"
import { WidgetOrders } from "components/widgets/WidgetOrders"
import { WidgetWarehouse } from "components/widgets/WidgetWarehouse"

import styles from "./index.module.scss"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Order number",
    type: "sort-search",
    size: "middle",
    name: "order_number",
  },
  {
    title: "Status",
    type: "select",
    size: "middle",
    name: "status",
    options: warehouseManagerOrderStatusOptions,
    cellComponent: StatusOfWHOrder,
  },
  {
    title: "Company name",
    type: "sort-search",
    name: "company_name",
  },
  {
    title: "Brand name",
    type: "sort-search",
    name: "brand_name",
  },
  {
    title: "Order quantity",
    type: "sort-search",
    size: "middle",
    name: "order_quantity",
  },
  {
    title: "Contractor ID",
    type: "sort-search",
    size: "middle",
    name: "contractor_id",
  },
]

export const Orders = () => {
  const [triggerGetWHOrders, { isFetching, data }] = useLazyGetWHOrdersQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const dispatch = useAppDispatch()
  const [isFirstRender, setFirstRender] = useState(true)

  const sendRequest = async (params: Record<string, string>) => {
    try {
      const res = await triggerGetWHOrders(params)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender])

  const onPackingClick = (id: number) => {
    const result = data?.data?.find(item => item.id === id)
    dispatch(setSelectedWHOrder(result))
    dispatch(showPackingOrderModal(true))
  }

  const onShippingClick = (id: number) => {
    const result = data?.data?.find(item => item.id === id)
    dispatch(setSelectedWHOrder(result))
    dispatch(showShippingOrderModal(true))
  }

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetOrders />
        <WidgetWarehouse />
      </div>
      <ContentWrapper title="Orders">
        <TableWrapper
          tableName="wh-orders"
          headerCells={tableHeaderCells}
          isLoading={isFetching}
          data={convertWHManagerOrdersToTableRows(data?.data)}
          dynamicNavHandler1={onPackingClick}
          dynamicNavHandler2={onShippingClick}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
