import { useMemo } from "react"
import { Tooltip } from "react-tooltip"
import classNames from "classnames"

import { UITypes } from "types"
import { ReactComponent as IconAdd } from "assets/svg/add-icon.svg"
import { ReactComponent as IconDelete } from "assets/svg/icon-delete.svg"
import { ReactComponent as IconEdit } from "assets/svg/icon-edit.svg"
import { ReactComponent as IconEye } from "assets/svg/icon-eye.svg"
import { ReactComponent as IconLog } from "assets/svg/icon-log.svg"
import { ReactComponent as IconPower } from "assets/svg/icon-power.svg"
import { ReactComponent as StatsReportIcon } from "assets/svg/icon-stats-report.svg"
import { ReactComponent as PerformanceTestIcon } from "assets/svg/menu-performance.svg"

import styles from "../index.module.scss"

interface Props {
  tableName?: string
  rowData?: UITypes.TableBodyRow
  customClassName?: string
  onDeleteClick?: (id: number) => void
  onEditClick?: (id: number) => void
  dynamicNavHandler1?: (id: number) => void
  dynamicNavHandler2?: (id: number) => void
  dynamicNavHandler3?: (id: number) => void
}

export const TableRowNavigation = ({
  tableName,
  rowData,
  customClassName,
  onDeleteClick,
  onEditClick,
  dynamicNavHandler1,
  dynamicNavHandler2,
  dynamicNavHandler3,
}: Props) => {
  const rowSettings = rowData?.rowSettings

  const onDelete = useMemo(() => {
    if (tableName === "vendor-orders" && rowSettings?.status !== "new") {
      return undefined
    }
    if (tableName === "contractor-orders" && rowSettings?.status !== "new") {
      return undefined
    } else if (onDeleteClick) {
      return () => onDeleteClick(rowSettings?.id as number)
    }
  }, [tableName, rowSettings, onDeleteClick])

  const onEdit = useMemo(() => {
    if (
      tableName === "vendor-orders" &&
      rowSettings?.status !== "new" &&
      rowSettings?.status !== "denied" &&
      rowSettings?.status !== "confirmed" &&
      rowSettings?.status !== "pending"
    ) {
      return undefined
    }
    if (tableName === "contractor-orders" && rowSettings?.status !== "new") {
      return undefined
    }
    if (tableName === "customers" && rowSettings?.isEnable !== true) {
      return undefined
    } else if (onEditClick) {
      return () => onEditClick(rowSettings?.id as number)
    }
  }, [tableName, rowSettings, onEditClick])

  const dynamicNavHandler = () => {
    if (tableName === "message-history" && dynamicNavHandler1) {
      return dynamicNavHandler1(rowSettings?.id as number)
    }

    if (tableName === "wh-orders") {
      const whOrderStatus = rowData?.status?.value

      if (whOrderStatus === "confirmed") {
        dynamicNavHandler1?.(rowSettings?.id as number)
      }

      if (whOrderStatus === "packed") {
        dynamicNavHandler2?.(rowSettings?.id as number)
      }
    }

    if (!dynamicNavHandler1 || !dynamicNavHandler2 || rowSettings?.isEnable === undefined) return false
  }

  const DynamicNavIcon = useMemo(() => {
    if (tableName === "message-history") {
      return IconEye
    }

    if (
      tableName === "message-library-templates" &&
      dynamicNavHandler1 &&
      dynamicNavHandler2 &&
      rowSettings?.isEnable !== false
    ) {
      return () => (
        <div className={styles.messageTemplateButtonContainer}>
          <span onClick={() => dynamicNavHandler2(rowSettings?.id as number)} data-tooltip-id="reportBtn">
            <IconEye />
          </span>
          <Tooltip
            id="reportBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            View template
          </Tooltip>
          <span onClick={() => dynamicNavHandler1(rowSettings?.id as number)} data-tooltip-id="perfTestBtn">
            <IconAdd />
          </span>
          <Tooltip
            id="perfTestBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            Add to my templates
          </Tooltip>
        </div>
      )
    }

    if (tableName === "wh-orders") {
      if (rowSettings?.status === "confirmed") {
        return () => <span className={styles.dynamicNavButton}>Packing</span>
      }

      if (rowSettings?.status === "packed") {
        return () => <span className={styles.dynamicNavButton}>Shipping</span>
      }
    }

    if (tableName === "monitoring" && dynamicNavHandler1 && dynamicNavHandler2 && dynamicNavHandler3) {
      const isOffline = rowSettings?.item.work_status === "offline"

      return () => (
        <div className={styles.monitoringIconsWrapper}>
          <span
            onClick={() => isOffline || dynamicNavHandler3(rowSettings?.id as number)}
            className={classNames({ [styles.disabledNavButton]: isOffline })}
            data-tooltip-id="rebootBtn"
          >
            <IconPower />
          </span>
          <Tooltip
            id="rebootBtn"
            place="top"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={classNames(styles.tooltip)}
          >
            Reboot device
            {isOffline && (
              <>
                <br />
                (device is offline)
              </>
            )}
          </Tooltip>
          <span
            onClick={() => isOffline || dynamicNavHandler1(rowSettings?.id as number)}
            className={classNames({ [styles.disabledNavButton]: isOffline })}
            data-tooltip-id="perfTestBtn"
          >
            <PerformanceTestIcon />
          </span>
          <Tooltip
            id="perfTestBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            Start performance test
            {isOffline && (
              <>
                <br />
                (device is offline)
              </>
            )}
          </Tooltip>
          <span onClick={() => dynamicNavHandler2(rowSettings?.id as number)} data-tooltip-id="reportBtn">
            <StatsReportIcon />
          </span>
          <Tooltip
            id="reportBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            Monitoring report
          </Tooltip>
        </div>
      )
    }

    if (tableName === "adminDevices" && dynamicNavHandler1 && dynamicNavHandler2 && dynamicNavHandler3) {
      const isOffline = rowSettings?.item.work_status === "offline"

      return () => (
        <div
          className={classNames(styles.monitoringIconsWrapper, {
            [styles.redFlag]: !!rowSettings?.isRedFlag,
          })}
        >
          <span
            onClick={() => isOffline || dynamicNavHandler3(rowSettings?.id as number)}
            className={classNames({ [styles.disabledNavButton]: isOffline })}
            data-tooltip-id="rebootBtn"
          >
            <IconPower />
          </span>
          <Tooltip
            id="rebootBtn"
            place="top"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={classNames(styles.tooltip)}
          >
            Reboot device
            {isOffline && (
              <>
                <br />
                (device is offline)
              </>
            )}
          </Tooltip>
          <span
            onClick={() => isOffline || dynamicNavHandler1(rowSettings?.id as number)}
            className={classNames({ [styles.disabledNavButton]: isOffline })}
            data-tooltip-id="perfTestBtn"
          >
            <IconLog />
          </span>
          <Tooltip
            id="perfTestBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            Send Logs to DB
            {isOffline && (
              <>
                <br />
                (device is offline)
              </>
            )}
          </Tooltip>
          <span onClick={() => dynamicNavHandler2(rowSettings?.id as number)} data-tooltip-id="reportBtn">
            <StatsReportIcon />
          </span>
          <Tooltip
            id="reportBtn"
            place="top-start"
            variant="dark"
            classNameArrow={styles.tooltipArrow}
            className={styles.tooltip}
          >
            Monitoring report
          </Tooltip>
        </div>
      )
    }
  }, [tableName, rowSettings, dynamicNavHandler1, dynamicNavHandler2, dynamicNavHandler3])

  return (
    <div
      className={classNames(styles.rowNavWrapper, customClassName, {
        [styles.redFlag]: rowSettings?.isRedFlag,
      })}
    >
      <div className={styles.rowNavButtonsContainer}>
        {DynamicNavIcon && (
          <span className={styles.rowNavButton} onClick={dynamicNavHandler}>
            <DynamicNavIcon />
          </span>
        )}
        {onEdit && (
          <span className={styles.rowNavButton} onClick={onEdit}>
            <IconEdit />
          </span>
        )}
        {onDelete && (
          <span className={styles.rowNavButton} onClick={onDelete}>
            <IconDelete />
          </span>
        )}
      </div>
    </div>
  )
}
