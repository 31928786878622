import { useState } from "react"
import { CellContext } from "@tanstack/react-table"
import styled from "styled-components/macro"

import { UITypes } from "types"

import { InstalledDevicesHistoryModal } from "modals/InstalledDevicesHistoryModal"

const Container = styled.div`
  color: #2db2c8;
  text-decoration: underline;
  cursor: pointer;

  span:hover {
    opacity: 0.8;
  }
`

interface ContractorInstalledDevicesCountProps {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}
const ContractorInstalledDevicesCount = ({ cell: { getValue, row } }: ContractorInstalledDevicesCountProps) => {
  const contractor = row.original.rowSettings.item
  const [shownInstalledDevicesContractorId, setShownInstalledDevicesContractorId] = useState<number | null>(null)

  const onShowInstalledDevicesHistory = async () => {
    setShownInstalledDevicesContractorId(contractor.id)
  }

  return (
    <>
      <Container onClick={onShowInstalledDevicesHistory}>{getValue().value}</Container>
      {shownInstalledDevicesContractorId !== null && (
        <InstalledDevicesHistoryModal
          contractorId={shownInstalledDevicesContractorId}
          onCancel={() => setShownInstalledDevicesContractorId(null)}
        />
      )}
    </>
  )
}

export default ContractorInstalledDevicesCount
