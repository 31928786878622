import { useEffect, useState } from "react"
import { debounce } from "lodash"

import { ReactComponent as IconTableCross } from "assets/svg/icon-table-cross.svg"
import { ReactComponent as IconTableSearch } from "assets/svg/icon-table-search.svg"

import useUrlQueryParams from "hooks/useUrlQueryParams"

import styles from "./index.module.scss"

interface Props {
  name?: string
}

export const TableSearch = ({ name }: Props) => {
  const [isActive, setActive] = useState(false)
  const [value, setValue] = useState("")
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()

  const onClear = () => {
    setValue("")
    if (name) {
      setUrlQueryParams({ [name]: null })
    }
    setActive(false)
  }

  const onInputChange = (value: string) => {
    if (name) {
      setUrlQueryParams({
        [name]: value,
      })
    }
  }

  useEffect(() => {
    if (name && urlQueryParams[name] && !value) {
      setValue(urlQueryParams[name])
      setActive(true)
    } else if (urlQueryParams && name && !Object.hasOwn(urlQueryParams, name)) {
      setValue("")
      setActive(false)
    }
  }, [urlQueryParams])

  const setDebaunceSearchValue = debounce(onInputChange, 500)

  return (
    <div className={styles.tableSearchWrapper} onClick={() => {}}>
      {isActive ? (
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputSearch}
            placeholder="Search.."
            autoFocus
            defaultValue={value}
            onChange={event => setDebaunceSearchValue(event.currentTarget?.value)}
            name={name === "email" ? "emailField" : name}
          />
          <span className={styles.iconClear} onClick={onClear}>
            <IconTableCross />
          </span>
        </div>
      ) : (
        <span className={styles.iconSearch} onClick={() => setActive(true)}>
          <IconTableSearch />
        </span>
      )}
    </div>
  )
}
