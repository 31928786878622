import { useEffect, useState } from "react"
import styled from "styled-components"

import { useLazyGetReordersWidgetQuery } from "store/api/adminApi"

import { calculateLastDays } from "../utils"
import ReordersListModal from "./ReordersListModal"
import { WidgetWrapper } from "./styled"
import WidgetContent from "./WidgetContent"
import WidgetHeader from "./WidgetHeader"

const WidgetContentContainer = styled.div`
  cursor: pointer;
`

const ReordersWidget = () => {
  const [selectedDateRange, setSelectedDateRange] = useState(calculateLastDays(7))
  const [triggerGetReordersWidget, { isFetching, data }] = useLazyGetReordersWidgetQuery()
  const [showOrdersListModal, setShowOrdersListModal] = useState(false)

  const contentDate = data
    ? [
        {
          title: "Contractors",
          value: data.contractors,
        },
        {
          title: "Number of reorders",
          value: data.reorders,
        },
        {
          title: "Number of devices",
          value: data.devices,
        },
      ]
    : []

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetReordersWidget(
          {
            start_date: selectedDateRange[0],
            end_date: selectedDateRange[1],
          },
          true,
        )
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [selectedDateRange])

  const handleWidgetClick = () => {
    setShowOrdersListModal(true)
  }

  return (
    <>
      <WidgetWrapper marginTop={24}>
        <WidgetHeader title="Reorders" onSelectDateRange={setSelectedDateRange} includeCustomRangeSelect />
        <WidgetContentContainer onClick={handleWidgetClick}>
          <WidgetContent isFetching={isFetching} data={contentDate} />
        </WidgetContentContainer>
      </WidgetWrapper>
      {showOrdersListModal && (
        <ReordersListModal selectedDateRange={selectedDateRange} onClose={() => setShowOrdersListModal(false)} />
      )}
    </>
  )
}

export default ReordersWidget
