// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"

import SubscriptionPlanTitlesWithPrefix from "constants/SubscriptionPlanTitlesWithPrefix"
import { ApiTypes, UITypes } from "types"

import SubscriptionPlan from "../types/enums/SubscriptionPlan"
import { capitalizeFirstLetter } from "./capitalizeFirstLetter"
import { parseDateStringToUTCString } from "./parseDateStringToUTCString"
import { parseSystemType } from "./parseSystemType"
import { transformString } from "./transformString"
import { transformText } from "./transformText"

export const convertStatusIDToOrderStatus = (id: number): UITypes.OrderStatus => {
  /*
    CASE New = 1;
    CASE Pending = 2;
    CASE Denied = 3;
    CASE Confirmed = 4;
    CASE Packed = 5;
    CASE Shipping = 6;
    CASE Archived (Fulfilled) = 7;
  */

  switch (id) {
    case 1:
      return "new"
    case 2:
      return "pending"
    case 3:
      return "denied"
    case 4:
      return "confirmed"
    case 5:
      return "packed"
    case 6:
      return "shipping"
    case 7:
      return "fulfilled"
    default:
      return ""
  }
}

export const convertStatusesToOptions = (data?: ApiTypes.Res.Status[]): UITypes.Option[] => {
  if (!data?.length) return []

  return data.map(item => {
    return {
      value: String(item.id),
      label: item.name,
    }
  })
}

export const convertDataToOptions = <T extends Record<Y | Z, string | number>, Y extends keyof T, Z extends keyof T>(
  data: { data: T[] | null } | undefined,
  valueName: Y,
  labelName: Z,
): UITypes.Option[] => {
  if (!data?.data?.length) return []

  return data.data.map((item: T) => ({
    value: item && item[valueName],
    label: item && item[labelName]?.toString(),
  }))
}

export const convertStringArrayToOptions = (data: string[]): UITypes.Option[] => [
  ...data.map(item => ({
    value: item,
    label: item,
  })),
]

// TABLES

export const convertTechniciansToTableRows = (data?: ApiTypes.Model.Technician[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, full_name, username, installed_devices }) =>
      ({
        id: { value: id },
        full_name: { value: full_name },
        username: { value: username },
        installed_devices: { value: installed_devices },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertVSMOrdersToTableRows = (data?: ApiTypes.Model.VSManagerOrder[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []
  return data.map(
    ({ id, create_date = "", quantity, status, email, phone_number, company_name, warehouse, status_updated }) =>
      ({
        checkbox: {},
        id: { value: id },
        status: { value: status },
        company_name: { value: company_name },
        create_date: { value: create_date ? dayjs(create_date).format("YYYY-MM-DD") : undefined },
        quantity: { value: quantity },
        warehouse: { value: warehouse },
        phone_number: { value: phone_number },
        email: { value: email },
        status_updated: { value: status_updated ? dayjs(status_updated).format("YYYY-MM-DD") : undefined },
        rowSettings: {
          id,
          status,
          isEnable: status === "new" ? true : false,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertOrdersToTableRows = (data?: ApiTypes.Model.Order[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(({ id, create_date, quantity, warehouse, status }) => ({
    checkbox: {},
    id: { value: id },
    create_date: { value: dayjs(create_date).format("YYYY-MM-DD") },
    quantity: { value: quantity },
    status: { value: status },
    warehouse: { value: warehouse },
    rowSettings: {
      id,
      status: status,
    },
  }))
}

export const convertClientsToTableRows = (data?: ApiTypes.Model.Client[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, full_name, devices, email, phone_number, membership }) =>
      ({
        email: { value: email },
        devices_count: { value: devices },
        phone: { value: phone_number },
        full_name: { value: full_name },
        id: { value: id },
        membership: { value: membership },
        rowSettings: {
          id,
          isEnable: devices ? true : false,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertContractorsToTableRows = (data?: ApiTypes.Model.Contractor[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    contractor =>
      ({
        name: { value: contractor.name },
        state: { value: contractor.state?.name },
        country: { value: contractor.country?.name },
        subscription_plan: {
          value: SubscriptionPlanTitlesWithPrefix[contractor.subscription_plan || SubscriptionPlan.BASIC],
        },
        brand: { value: contractor.brand },
        purchased: { value: contractor.purchased_devices },
        installed: { value: contractor.installed_devices },
        install_7_day: { value: contractor.install_7_day },
        active_mobile: { value: contractor.active_mobile },
        inventory: { value: contractor.inventory_devices },
        isEnabled: { value: contractor.is_enabled ? "1" : "0" },
        warranty: { value: contractor.warranty },
        rowSettings: {
          id: Number(contractor.id),
          isEnabled: contractor.is_enabled,
          item: contractor,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertAdminAlertsToTableRows = (data?: ApiTypes.Model.Alert[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, sn, country, address1, city, state, date, status, type, full_name, phone_number, email, location }) =>
      ({
        checkbox: {},
        type: { value: transformText(type) },
        date: { value: date },
        sn: { value: sn },
        status: { value: status },
        full_name: { value: full_name },
        phone_number: { value: phone_number },
        email: { value: email },
        location: { value: location },
        country: { value: country },
        address1: { value: address1 },
        city: { value: city },
        state: { value: state },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertAlertsToTableRows = (data?: ApiTypes.Model.Alert[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, sn, country, address1, city, state, date, status, type, full_name, phone_number, email, location }) =>
      ({
        checkbox: {},
        type: { value: transformText(type) },
        date: { value: date },
        sn: { value: sn },
        status: { value: status },
        full_name: { value: full_name },
        phone_number: { value: phone_number },
        email: { value: email },
        location: { value: location },
        country: { value: country },
        address1: { value: address1 },
        city: { value: city },
        state: { value: state },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertMessageTemplatesToTableRows = (data?: ApiTypes.Model.MessageTemplate[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, title, body, created_at }) =>
      ({
        title: { value: title },
        created_at: { value: dayjs(created_at).format("MM-DD-YYYY") },
        message: { value: body },
        id: { value: id },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertTemplatesLibraryToTableRows = (
  data?: ApiTypes.Model.TemplatesLibrary[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data?.map(group => ({
    id: { value: group?.name, groupHeader: true },
    title: { value: "", hidden: true },
    message: { value: "", hidden: true },
    rowSettings: {
      id: group?.id + 1000,
      isEnable: false,
    },
    subRows:
      group?.items?.map(items => ({
        id: { value: items?.id },
        title: { value: items?.title },
        message: { value: items?.description },
        rowSettings: {
          id: items?.id,
        },
      })) || [],
  }))
}

export const convertWarehousesToTableRows = (data?: ApiTypes.Model.Warehouse[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, name, address, phone_number, zip, state, devices_qty, managers }) =>
      ({
        name: { value: name },
        devices_qty: { value: devices_qty },
        address: { value: address },
        zip: { value: zip?.code },
        state: { value: state?.name },
        phone_number: { value: phone_number },
        manager: { value: managers },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertDevicesToTableRows = (data?: ApiTypes.Model.Device[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      sn,
      country,
      homeowner_name,
      city,
      zip,
      state,
      membership,
      accessories,
      residence_type,
      hvac,
      system_type,
      activation_date,
      email,
      option,
    }) =>
      ({
        checkbox: {},
        email: { value: email },
        sn: { value: sn },
        country: { value: country?.name },
        city: { value: city?.name },
        zip_code: { value: zip?.code },
        state: { value: state?.name },
        option: { value: option ? capitalizeFirstLetter(option).replace("_", "-") : "" },
        activation_date: { value: activation_date },
        homeowner_name: { value: homeowner_name },
        membership: { value: membership },
        residence_type: { value: residence_type?.name },
        system_type: { value: system_type },
        accessories: { value: accessories },
        installation_type: { value: hvac },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertDevicesToDashboardTableRows = (data?: ApiTypes.Model.Device[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      sn,
      homeowner_name,
      country,
      city,
      state,
      zip,
      address,
      membership,
      alerts,
      accessories,
      system_type,
      activation_date,
      phisical_status,
      hvac,
    }) =>
      ({
        checkbox: {},
        sn: { value: sn },
        phisical_status: {
          value: phisical_status,
        },
        homeowner_name: { value: homeowner_name },
        country: { value: country?.name },
        zip_code: { value: zip?.code },
        activation_date: { value: activation_date },
        address: {
          value: `${address ? address : ""} ${state?.name ? state?.name : ""} ${city?.name ? city?.name : ""}`,
        },
        installation_type: { value: hvac },
        alerts: { value: alerts },
        membership: { value: membership },
        system_type: { value: parseSystemType(system_type) },
        accessories: { value: accessories },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertDataToPerformanceTestTableRows = (data?: ApiTypes.Model.Device[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(device => {
    const {
      id,
      email,
      sn,
      perftest,
      homeowner_name,
      country,
      city,
      state,
      zip,
      work_status,
      address,
      hvac,
      alerts,
      membership,
      system_type,
      accessories,
    } = device

    return {
      checkbox: { disabled: perftest?.status?.toLowerCase() === "running" },
      email: { value: email },
      perftest_status: { value: perftest?.status?.toLowerCase() },
      work_status: { value: work_status },
      perftest_label: { value: perftest?.label },
      country: { value: country?.name },
      city: { value: city?.name },
      zip_code: { value: zip?.code },
      state: { value: state?.name },
      homeowner_name: { value: homeowner_name },
      sn: { value: sn },
      address: { value: address || "" },
      installation_type: { value: hvac },
      alerts: { value: alerts },
      membership: { value: membership },
      system_type: { value: parseSystemType(system_type) },
      accessories: { value: accessories },
      perftest_schedule: { value: perftest?.schedule },
      rowSettings: {
        id,
        item: device,
      },
    } as UITypes.TableBodyRow
  })
}

export const convertDataToPerformanceTestHistoryTableRows = (
  data?: ApiTypes.Model.PerformanceTestHistory[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ client, device, perftest }) =>
      ({
        email: { value: client?.email },
        status: { value: perftest?.status?.toLowerCase() },
        time: { value: dayjs(parseDateStringToUTCString(perftest.time)).format("YYYY-MM-DD") },
        label: { value: perftest?.label },
        country: { value: device?.country?.name },
        city: { value: device?.city?.name },
        zip_code: { value: device?.zip?.code },
        state: { value: device?.state?.name },
        homeowner_name: { value: client?.homeowner_name },
        sn: { value: device?.sn },
        activation_date: { value: device?.activation_date },
        address: { value: device?.address || "" },
        rowSettings: {
          isRowClickable: true,
          id: perftest?.perftest_result_id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertDataToMonitoringTableRows = (data?: ApiTypes.Model.Device[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(device => {
    const {
      id,
      email,
      perftest,
      sn,
      homeowner_name,
      country,
      city,
      state,
      zip,
      work_status,
      name,
      address,
      hvac,
      alerts,
      membership,
      system_type,
      accessories,
    } = device

    const perfTestStatus = perftest?.status?.toLowerCase()

    return {
      email: { value: email },
      perftest_status: { value: ["passed", "issue", "running"].includes(perfTestStatus) ? perfTestStatus : null },
      work_status: { value: work_status },
      country: { value: country?.name },
      city: { value: city?.name },
      zip_code: { value: zip?.code },
      state: { value: state?.name },
      homeowner_name: { value: homeowner_name },
      sn: { value: sn },
      name: { value: name },
      address: { value: address || "" },
      installation_type: { value: hvac },
      alerts: { value: alerts },
      membership: { value: membership },
      system_type: { value: parseSystemType(system_type) },
      accessories: { value: accessories },
      rowSettings: {
        id,
        item: device,
      },
    } as UITypes.TableBodyRow
  })
}

export const convertMessagesHistoryToTableRows = (data?: ApiTypes.Model.MessageHitory[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, title, body, pending, failed, delivered, date }) =>
      ({
        title: { value: title },
        body: { value: body },
        date: { value: date },
        delivered: { value: delivered, color: "blue" },
        pending: { value: pending, color: "orange" },
        failed: { value: failed, color: "red" },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertWHManagerOrdersToTableRows = (data?: ApiTypes.Model.WHManagerOrder[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, contractor_id, order_quantity, status, company_name, brand_name }) =>
      ({
        order_number: { value: id },
        status: { value: status },
        company_name: { value: company_name },
        brand_name: { value: brand_name },
        order_quantity: { value: order_quantity },
        contractor_id: { value: contractor_id },
        rowSettings: {
          id,
          status,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertWHManagerDevicessToTableRows = (
  data?: ApiTypes.Model.WHManagerDevice[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, sn, input_date, phisical_statue }) =>
      ({
        checkbox: {},
        sn: { value: sn },
        input_date: { value: input_date },
        phisical_status: { value: phisical_statue },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertMessageReportToTableRows = (data?: ApiTypes.Model.MessageReport[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      device_sn,
      client_name,
      country,
      city,
      zip_code,
      state,
      activation_date,
      membership,
      residence_type,
      installation_type,
      accessories,
      status,
      system_type,
    }) =>
      ({
        device_sn: { value: device_sn },
        client_name: { value: client_name },
        country: { value: country },
        city: { value: city },
        zip: { value: zip_code },
        state: { value: state },
        activation_date: { value: activation_date },
        membership: { value: membership },
        residence_type: { value: residence_type },
        system_type: { value: system_type },
        accessories: { value: accessories },
        installation_type: { value: installation_type },
        status: { value: status },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertUsersToTableRows = (data?: ApiTypes.Model.User[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, email, full_name, phone, role, created_by, creation_date }) =>
      ({
        name: { value: full_name },
        role: { value: transformString(role?.type) },
        email: { value: email },
        phone: { value: phone },
        creation_date: { value: creation_date },
        created_by: { value: created_by },
        rowSettings: {
          id,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertValidatedDevicesToState = (data: ApiTypes.Model.InvalideDevice[]): UITypes.InvalidDevice[] => {
  if (!data?.length) return []

  return data.map((item, index) => ({
    sn: item?.sn ? item.sn : "",
    row: item?.row ? item.row : (index += 1),
    uid: item?.uid ? item.uid : uuidv4(),
    errorMessage: item?.error_message ? item?.error_message : "",
  }))
}

export const convertVendorDevicesToTableRows = (
  data?: ApiTypes.Model.VendorDevice[],
  headerCells?: UITypes.TableHeaderCell[],
  callback?: (id: number) => void,
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    device =>
      ({
        checkbox: {},
        sn: { value: device.sn, callback: () => callback && callback(device.id) },
        work_status: { value: device.work_status },
        phisical_status: { value: device.phisical_status },
        contractor_name: { value: device.contractor_name },
        activation_date: { value: device.activation_date },
        red_flag: { value: `${device.red_flag ? "Yes" : "No"}` },
        red_flag_type: { value: device.red_flag_type },
        homeowner: { value: device.homeowner },
        country: { value: device.country_name },
        address: { value: device.address },
        email: { value: device.email },
        phone: { value: device.phone },
        installation_type: { value: device.installation_type },
        device_location: { value: device.device_location },
        rowSettings: {
          id: Number(device.id),
          isRedFlag: device.red_flag,
          item: device,
        },
      }) as UITypes.TableBodyRow,
  )
}

export const convertDeviceHistoryLog = (data: ApiTypes.Model.HistoryLog[]): UITypes.HistoryLog[] => {
  if (!data?.length) return []

  return data?.map(item => ({
    status: item?.status,
    author: item?.author,
    lastUpdate: item?.last_update,
  }))
}

export const convertEditDevicesToState = (data: ApiTypes.Model.Device[]): UITypes.Device[] => {
  if (!data?.length) return []

  const checkInstallationType = (value: string | undefined) => {
    switch (value) {
      case "new":
        return [
          {
            value: "new",
            label: "New",
          },
        ]
      case "existing":
        return [
          {
            value: "existing",
            label: "Replacement",
          },
        ]
      default:
        return []
    }
  }

  return data?.map(item => {
    return {
      _id: uuidv4(),
      id: item?.id ? item?.id : undefined,
      serialNumber: item?.sn ? item?.sn : "",
      address1: item?.address1 ? item?.address1 : "",
      address2: item?.address2 ? item?.address2 : "",
      country: convertDataToOptions({ data: [item?.country] }, "id", "name"),
      zip: convertDataToOptions({ data: [item?.zip] }, "id", "code"),
      state: convertDataToOptions({ data: [item?.state] }, "id", "name"),
      city: convertDataToOptions({ data: [item?.city] }, "id", "name"),
      suburb: convertDataToOptions({ data: [item?.suburb] }, "id", "name"),
      residentialType: item?.residence_type
        ? convertDataToOptions({ data: [item?.residence_type] }, "id", "name")
        : undefined,
      deviceLocation: convertDataToOptions({ data: [item?.location] }, "id", "name"),
      deviceName: item?.name || "",
      installationType: checkInstallationType(item.installation_type),
      workStatus: item?.work_status as UITypes.DeviceWorkStatus,
    }
  })
}
