import { UITypes } from "types"

import { FilterTags } from "components/common/FilterTags"

import Table, { TableProps } from "./Table"

interface TableWrapperProps extends TableProps {
  additionalTags?: UITypes.AdditionalTag[]
  clearAdditionalTags?: () => void
  skippedTags?: string[]
}

const TableWrapper = (props: TableWrapperProps) => {
  const { additionalTags, clearAdditionalTags, skippedTags, ...rest } = props

  return (
    <>
      <FilterTags
        additionalTags={additionalTags}
        clearAdditionalTags={clearAdditionalTags}
        skippedTags={skippedTags}
        headerCells={props.headerCells}
      />
      <Table {...rest} />
    </>
  )
}

export default TableWrapper
