import { createApi } from "@reduxjs/toolkit/query/react"

import { ApiTypes, UITypes } from "types"

import { baseQueryWithAuth } from "utils/api"
import { prepareQueryParams } from "utils/prepareQueryParams"

export const adminApi = createApi({
  reducerPath: "admin",
  tagTypes: ["admin"],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    getUsers: builder.query<ApiTypes.Res.Users, UITypes.Params>({
      query: arg => {
        return {
          url: `/admin/users`,
          params: prepareQueryParams(arg),
        }
      },
    }),
    getUser: builder.query<{ data: ApiTypes.Model.User }, number>({
      query: id => {
        return {
          url: `/admin/users/${id}`,
        }
      },
    }),
    editUser: builder.query<void, { body: ApiTypes.Req.EditUser; id: number }>({
      query: data => {
        return {
          url: `/admin/users/${data.id}`,
          method: "PUT",
          body: data.body,
        }
      },
    }),
    createUser: builder.query<void, ApiTypes.Req.CreateUser>({
      query: body => {
        return {
          url: `/admin/users`,
          method: "POST",
          body,
        }
      },
    }),
    getVendorStatusesWidget: builder.query<{ data: ApiTypes.Res.VendorStatusesWidget }, void>({
      query: () => {
        return {
          url: `/admin/alerts/statuses-widget`,
        }
      },
    }),
    getVendorAlertAndDeviceWidget: builder.query<{ data: ApiTypes.Res.AlertsAndDeviceWidget }, void>({
      query: () => ({
        url: `/admin/alerts/devices-widget`,
        method: "GET",
      }),
    }),
    getVendorAlertsTypeWidget: builder.query<{ data: ApiTypes.Res.AlertTypesWidget }, void>({
      query: () => ({
        url: `/admin/alerts/types-widget`,
        method: "GET",
      }),
    }),
    getPhysicalStatusesWidget: builder.query<{ data: ApiTypes.Res.PhysicalStatusesWidget }, void>({
      query: () => ({
        url: `/admin/devices/widgets/phisical-statuses`,
        method: "GET",
      }),
    }),
    getInstalledDevicesWidget: builder.query<{ data: ApiTypes.Res.InstalledDevicesWidget }, void>({
      query: () => ({
        url: `/admin/devices/widgets/installed-devices`,
        method: "GET",
      }),
    }),
    getMobileUsageWidget: builder.query<ApiTypes.Res.MobileUsageWidget, void>({
      query: () => ({
        url: `/vendor/widgets/mobile-usage`,
        method: "GET",
      }),
    }),
    getMobileTrendsWidget: builder.query<
      ApiTypes.Res.MobileTrendsWidget,
      {
        start_date: string
        end_date: string
      }
    >({
      query: ({ start_date, end_date }) => ({
        url: `/vendor/widgets/mobile-trends`,
        headers: { Accept: "application/json" },
        method: "GET",
        params: prepareQueryParams({ start_date, end_date }),
      }),
    }),
    getInstallTrendsWidget: builder.query<
      ApiTypes.Res.InstallTrendsWidget,
      {
        start_date: string
        end_date: string
      }
    >({
      query: ({ start_date, end_date }) => ({
        url: `/vendor/widgets/install-trends`,
        headers: { Accept: "application/json" },
        method: "GET",
        params: prepareQueryParams({ start_date, end_date }),
      }),
    }),
    getReordersWidget: builder.query<
      ApiTypes.Res.ReordersWidget,
      {
        start_date: string
        end_date: string
      }
    >({
      query: ({ start_date, end_date }) => ({
        url: `/vendor/widgets/reorders`,
        headers: { Accept: "application/json" },
        method: "GET",
        params: prepareQueryParams({ start_date, end_date }),
      }),
    }),
    getReordersList: builder.query<
      ApiTypes.Res.ReordersData[],
      {
        start_date: string
        end_date: string
      }
    >({
      query: ({ start_date, end_date }) => ({
        url: `/vendor/reorders-list`,
        headers: { Accept: "application/json" },
        method: "GET",
        params: prepareQueryParams({ start_date, end_date }),
      }),
    }),
    getAdminDevices: builder.query<ApiTypes.Res.VendorDevices, UITypes.Params>({
      query: arg => ({
        url: `/admin/devices`,
        method: "GET",
        params: prepareQueryParams(arg),
      }),
    }),
    getAdminAlerts: builder.query<ApiTypes.Res.Alerts, UITypes.Params>({
      query: arg => ({
        url: `/admin/alerts`,
        method: "GET",
        params: prepareQueryParams(arg),
      }),
    }),
    getDeviceHistoryLog: builder.query<{ data: ApiTypes.Res.DeviceHistoryLog }, number>({
      query: id => {
        return {
          url: `/admin/devices/history-log/${id}`,
        }
      },
    }),
    putDevicesAsMissing: builder.query<void, UITypes.Params>({
      query: arg => {
        return {
          url: `/admin/devices/actions/mark-as-missing`,
          method: "PUT",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    putDevicesAsDamaged: builder.query<void, UITypes.Params>({
      query: arg => {
        return {
          url: `/admin/devices/actions/mark-as-damaged`,
          method: "PUT",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    putDevicesAsUndamaged: builder.query<void, UITypes.Params>({
      query: arg => {
        return {
          url: `/admin/devices/actions/mark-as-undamaged`,
          method: "PUT",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    putDevicesReturnFromWarranty: builder.query<void, UITypes.Params>({
      query: arg => {
        return {
          url: `/admin/devices/actions/return-from-warranty`,
          method: "PUT",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    putTransferDevices: builder.query<void, UITypes.Params>({
      query: arg => {
        return {
          url: `/admin/devices/actions/transfer`,
          method: "PUT",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    putReturnToWarehouse: builder.query<void, UITypes.Params>({
      query: arg => {
        return {
          url: `/admin/devices/actions/return-to-warehouse`,
          method: "PUT",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    deleteVendorOrder: builder.query<ApiTypes.Model.Order, number>({
      query: id => ({
        url: `/admin/orders/${id}`,
        method: "DELETE",
      }),
    }),
  }),
})

export const {
  useLazyGetUsersQuery,
  useLazyGetUserQuery,
  useLazyEditUserQuery,
  useLazyCreateUserQuery,
  useLazyGetVendorStatusesWidgetQuery,
  useLazyGetVendorAlertAndDeviceWidgetQuery,
  useLazyGetVendorAlertsTypeWidgetQuery,
  useLazyGetPhysicalStatusesWidgetQuery,
  useLazyGetInstalledDevicesWidgetQuery,
  useLazyGetMobileTrendsWidgetQuery,
  useLazyGetInstallTrendsWidgetQuery,
  useLazyGetReordersWidgetQuery,
  useLazyGetReordersListQuery,
  useLazyGetMobileUsageWidgetQuery,
  useLazyGetAdminDevicesQuery,
  useLazyGetDeviceHistoryLogQuery,
  useLazyPutDevicesAsMissingQuery,
  useLazyPutDevicesAsDamagedQuery,
  useLazyPutDevicesAsUndamagedQuery,
  useLazyPutDevicesReturnFromWarrantyQuery,
  useLazyPutTransferDevicesQuery,
  useLazyPutReturnToWarehouseQuery,
  useLazyGetAdminAlertsQuery,
  useLazyDeleteVendorOrderQuery,
} = adminApi
