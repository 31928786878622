import { useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const useUrlQueryParams = () => {
  const navigate = useNavigate()
  const { search, pathname } = useLocation()

  const queryParams = useMemo(() => {
    const params = new URLSearchParams(search)
    const result: Record<string, string> = {}
    params.forEach((value, key) => {
      result[key] = value
    })
    return result
  }, [search])

  const setUrlQueryParams = useCallback(
    (updates: Record<string, string | number | true | null>, clearParams?: boolean) => {
      const params = new URLSearchParams(clearParams ? "" : search)

      Object.entries(updates).forEach(([key, value]) => {
        if (value === null) {
          params.delete(key)
        } else {
          params.set(key, String(value))
        }
      })
      navigate(`${pathname}?${params.toString()}`, { replace: true })
    },
    [navigate, search, pathname],
  )

  return {
    urlQueryParams: queryParams,
    setUrlQueryParams,
  }
}

export default useUrlQueryParams
