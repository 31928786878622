import { useEffect, useRef } from "react"
import styled from "@emotion/styled"

import { hexToRGBA } from "utils/hexToRGBA"

const LIB_NAME = "react-dropdown-select"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getByPath = (object: any, path: string) => {
  if (!path) {
    return
  }

  return path.split(".").reduce((acc, value) => acc[value], object)
}

type Item = {
  disabled: boolean
}

interface ItemRendererProps {
  props: {
    multi: boolean
    keepSelectedInList: boolean
    labelField: string
    valueField: string
    color: string
    disabledLabel: string
  }
  state: {
    cursor: number
  }
  methods: {
    isSelected: (item: Item) => boolean
    addItem: (item: Item) => void
  }
  item: Item
  itemIndex: number
}

const Item = ({ props: settings, state, methods, item, itemIndex }: ItemRendererProps) => {
  const itemRef = useRef(null as HTMLElement | null)

  useEffect(() => {
    if (itemRef.current && !settings.multi && settings.keepSelectedInList && methods.isSelected(item)) {
      itemRef.current.scrollIntoView({ block: "nearest", inline: "start" })
    }
  }, [])

  useEffect(() => {
    if (state.cursor === itemIndex && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
    }
  }, [state.cursor, itemIndex])

  if (!settings.keepSelectedInList && methods.isSelected(item)) {
    return null
  }

  return (
    <ItemComponent
      role="option"
      ref={itemRef}
      aria-selected={methods.isSelected(item)}
      aria-disabled={item.disabled}
      aria-label={getByPath(item, settings.labelField)}
      disabled={item.disabled}
      key={`${getByPath(item, settings.valueField)}${getByPath(item, settings.labelField)}`}
      tabIndex={-1}
      className={`${LIB_NAME}-item ${
        methods.isSelected(item) ? `${LIB_NAME}-item-selected` : ""
      } ${state.cursor === itemIndex ? `${LIB_NAME}-item-active` : ""} ${
        item.disabled ? `${LIB_NAME}-item-disabled` : ""
      }`}
      onClick={item.disabled ? undefined : () => methods.addItem(item)}
      onKeyPress={item.disabled ? undefined : () => methods.addItem(item)}
      color={settings.color}
    >
      {getByPath(item, settings.labelField)} {item.disabled && <ins>{settings.disabledLabel}</ins>}
    </ItemComponent>
  )
}

const ItemComponent = styled.span<{ disabled: boolean }>`
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #fff;

  &.${LIB_NAME}-item-active {
    border-bottom: 1px solid #fff;
    ${({ disabled, color }) => !disabled && color && `background: ${hexToRGBA(color, "0.1")};`}
  }

  :hover,
  :focus {
    background: ${({ color }) => color && hexToRGBA(color, "0.1")};
    outline: none;
  }

  &.${LIB_NAME}-item-selected {
    ${({ disabled, color }) =>
      disabled
        ? `
    background: #f2f2f2;
    color: #ccc;
    `
        : `
    background: ${color};
    color: #fff;
    border-bottom: 1px solid #fff;
    `}
  }

  ${({ disabled }) =>
    disabled
      ? `
    background: #f2f2f2;
    color: #ccc;

    ins {
      text-decoration: none;
      border:1px solid #ccc;
      border-radius: 2px;
      padding: 0px 3px;
      font-size: x-small;
      text-transform: uppercase;
    }
    `
      : ""}
`

export default Item
