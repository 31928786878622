import { SelectStatic } from "./SelectStatic"

interface DummyStaticSelectProps {
  onClick: () => void
  value: string
  placeholder: string
}

const DummyStaticSelect = ({ onClick, value, placeholder }: DummyStaticSelectProps) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    onClick()
  }

  return (
    <div onClickCapture={handleClick}>
      <SelectStatic placeholder={placeholder} values={value ? [{ value: 1, label: value }] : []} />
    </div>
  )
}

export default DummyStaticSelect
