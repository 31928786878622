import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useAppSelector } from "store/hooks"

import { checkRoleType } from "utils/checkRoleType"

export const RedirectPage = () => {
  const navigate = useNavigate()
  const userProfile = useAppSelector(state => state.user.userProfile)
  const userRole = checkRoleType(userProfile?.type)

  useEffect(() => {
    if (!userProfile) {
      navigate("/auth/sign-in")
    }

    if (userRole === "contractor-manager") {
      navigate("/dashboard")
    }

    if (userRole === "contractor-technician") {
      navigate("/add-device/customer")
    }

    if (userRole === "vendor-admin") {
      navigate("/contractors")
    }

    if (userRole === "warehouse-manager") {
      navigate("/wh-orders")
    }

    if (userRole === "sales-manager") {
      navigate("/contractors")
    }
  }, [])

  return null
}
