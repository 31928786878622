import { CellContext } from "@tanstack/react-table"
import dayjs from "dayjs"
import styled from "styled-components/macro"

import { ApiTypes, UITypes } from "types"
import PerformanceTestStatus from "types/enums/PerformanceTestStatus"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -6px 0;
  gap: 6px;
`

const ScheduleDate = styled.span`
  font-size: 14px;
`

const ScheduleTime = styled.span`
  font-size: 12px;
  color: #64748b;
`

interface PerformanceTestScheduleDateProps {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}
const PerformanceTestScheduleDate = ({ cell: { row } }: PerformanceTestScheduleDateProps) => {
  const device: ApiTypes.Model.Device = row.original.rowSettings.item

  const getScheduledDateAndTime = (date: string) => {
    const dateTime = dayjs(date)
    return [dateTime.format("DD MMM, YYYY"), dateTime.format("hh:mm A")]
  }

  const getNearestPerformanceTestDateAndTime = () => {
    const dateTime = dayjs(device.perftest.time)
    const date = dateTime < dateTime.hour(12) ? dateTime : dateTime.add(1, "day")

    return [date.format("DD MMM, YYYY"), "10:00 AM - 12:00 PM"]
  }

  const getDateAndTime = () => {
    if (device.perftest.schedule) {
      return getScheduledDateAndTime(device.perftest.schedule)
    }

    return getNearestPerformanceTestDateAndTime()
  }

  if (
    device.perftest.status !== PerformanceTestStatus.SCHEDULED &&
    device.perftest.status !== PerformanceTestStatus.RESCHEDULED
  ) {
    return null
  }

  const [date, time] = getDateAndTime()

  return (
    <Container>
      <ScheduleDate>{date}</ScheduleDate>
      <ScheduleTime>{time}</ScheduleTime>
    </Container>
  )
}

export default PerformanceTestScheduleDate
