import { CellContext } from "@tanstack/react-table"
import styled from "styled-components/macro"

import { UITypes } from "types"
import { ReactComponent as RightIcon } from "assets/svg/icon-right.svg"

const Container = styled.div<{ expanded: boolean }>`
  display: flex;
  align-items: center;
  background: #f8fafc;
  font-size: 12px;
  font-weight: 500;
  color: #64748b;
  text-transform: uppercase;

  svg {
    margin-right: 4px;
    fill: #64748b;
    transform: rotate(0deg);
    cursor: pointer;

    ${({ expanded }) =>
      expanded &&
      `
      transform: rotate(90deg);
    `}
  }
`

interface Props {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}

const GroupHeader = ({ cell: { row, getValue } }: Props) => {
  const title = getValue().value
  const expanded = row.getIsExpanded()

  return (
    <Container expanded={expanded}>
      <RightIcon onClick={row.getToggleExpandedHandler()} />
      {title}
    </Container>
  )
}

export default GroupHeader
