import styled from "styled-components/macro"

import newTabOpen from "assets/svg/new-tab-open.svg"

import { ButtonNew } from "components/form/ButtonNew"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px;

  span {
    &:after {
      content: "";
      background: url("${newTabOpen}") center no-repeat;

      width: 30px;
      height: 30px;
    }
  }
`

const Title = styled.h2`
  font-size: 16px;
  margin: 30px 0 40px;
  color: #64748b;
  text-align: center;
`

const AccountSettingsBilling = () => {
  const handleClick = () => {
    window.open("https://billing.stripe.com/p/login/6oE2bkd9Wf0P1UY5kk", "_blank")
  }

  return (
    <Container>
      <Title>
        Input your Stripe account related Email address to see your payment methods, billing & shipping information &
        invoice history.
      </Title>
      <ButtonNew color="blue" onClick={handleClick}>
        Click to see
      </ButtonNew>
    </Container>
  )
}

export default AccountSettingsBilling
