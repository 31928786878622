import { createApi } from "@reduxjs/toolkit/query/react"

import { ApiTypes, UITypes } from "types"

import { baseQueryWithAuth } from "utils/api"
import { prepareQueryParams } from "utils/prepareQueryParams"

export const alertsApi = createApi({
  reducerPath: "alerts",
  tagTypes: ["alerts"],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    getAlerts: builder.query<ApiTypes.Res.Alerts, UITypes.Params>({
      query: arg => ({
        url: `/alerts`,
        method: "GET",
        params: prepareQueryParams(arg),
      }),
    }),
    getAlertStatusesWidget: builder.query<{ data: ApiTypes.Res.AlertStatusesWidget }, void>({
      query: () => ({
        url: `/alerts/statuses-widget`,
        method: "GET",
      }),
    }),
    getAlertTypesWidget: builder.query<{ data: ApiTypes.Res.AlertTypesWidget }, void>({
      query: () => ({
        url: `/alerts/types-widget`,
        method: "GET",
      }),
    }),
    getAlertAndDeviceWidget: builder.query<{ data: ApiTypes.Res.AlertsAndDeviceWidget }, void>({
      query: () => ({
        url: `/alerts/alerts-and-devices-widget`,
        method: "GET",
      }),
    }),
    postAlertFreeze: builder.query<ApiTypes.Model.Order, number>({
      query: id => ({
        url: `/alerts/freeze`,
        method: "POST",
        body: {
          alerts: [id],
        },
      }),
    }),
    postAlertUnfreeze: builder.query<ApiTypes.Model.Order, number>({
      query: id => ({
        url: `/alerts/activate`,
        method: "POST",
        body: {
          alerts: [id],
        },
      }),
    }),
    postAlertAcknowledge: builder.query<ApiTypes.Model.Order, ApiTypes.Req.AlertAcknowledge>({
      query: data => ({
        url: `/alerts/acknowledge`,
        method: "POST",
        body: {
          alerts: [data.id],
          until: data.until,
        },
      }),
    }),
  }),
})

export const {
  useLazyGetAlertsQuery,
  useLazyGetAlertStatusesWidgetQuery,
  useLazyGetAlertTypesWidgetQuery,
  useLazyGetAlertAndDeviceWidgetQuery,
  useLazyPostAlertFreezeQuery,
  useLazyPostAlertAcknowledgeQuery,
  useLazyPostAlertUnfreezeQuery,
} = alertsApi
