import { FormEvent, PropsWithChildren } from "react"
import classNames from "classnames"

import styles from "./index.module.scss"

interface Props extends PropsWithChildren {
  isChecked?: boolean
  name?: string
  isReverse?: boolean
  className?: string
  id: string
  onChange?: (event: FormEvent<HTMLInputElement>) => void
}

export const ToggleButton = ({ isChecked, isReverse, children, className, name, id, onChange }: Props) => {
  return (
    <div
      className={classNames(styles.toggleWrapper, className, {
        [styles.reverse]: isReverse,
      })}
    >
      <label className={styles.toggleContainer} htmlFor={id}>
        <div
          className={classNames(styles.toggleSlider, {
            [styles.checked]: isChecked,
          })}
        >
          <div className={styles.toggleHandle}></div>
        </div>
        <input type="checkbox" name={name} id={id} checked={isChecked} onChange={onChange} />
      </label>
      {children && <div className={styles.toggleText}>{children}</div>}
    </div>
  )
}
