import { toast } from "react-toastify"

import { useLazyDeleteMessageTemplateQuery, useLazyGetMessageTemplatesQuery } from "store/api/contractorsApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setCurrentTemplateID } from "store/tempDataSlise"

import getErrorMessage from "utils/getErrorMessage"

import { DeleteModal } from "../DeleteModal"

interface Props {
  onCancel: () => void
}

export const DeleteMessageTemplateModal = ({ onCancel }: Props) => {
  const [triggerDeleteMessageTemplate, { isFetching }] = useLazyDeleteMessageTemplateQuery()
  const [triggerGetMessageTemplates] = useLazyGetMessageTemplatesQuery()
  const currentTemplateID = useAppSelector(state => state.tempData.currentTemplateID)
  const dispatch = useAppDispatch()

  const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()
    if (!currentTemplateID) return false

    try {
      const res = await triggerDeleteMessageTemplate(currentTemplateID as unknown as string)
      const error = getErrorMessage(res?.error)
      if (error) {
        toast.error(error)
      } else {
        toast.success("Message template has been successfully deleted!")
        triggerGetMessageTemplates()
        dispatch(setCurrentTemplateID(undefined))
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DeleteModal
      title="Delete template"
      confirmButtonText="Delete"
      message="Are you sure you want to delete the selected template?"
      isFetching={isFetching}
      onCancel={onCancel}
      onConfirm={onSubmit}
    />
  )
}
