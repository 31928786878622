import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import classNames from "classnames"
import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"

import { useLazyGetDeviceHistoryLogQuery } from "store/api/adminApi"
import { useAppSelector } from "store/hooks"
import { ApiTypes, UITypes } from "types"
import { ReactComponent as IconDeviceSN } from "assets/svg/modal-icon-device-sn.svg"

import { convertDeviceHistoryLog } from "utils/convertData"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"
import { Spinner } from "components/common/Spinner"
import { StatusOfPhysical } from "components/table/columnComponents/StatusOfPhysical"

import styles from "./index.module.scss"

interface Props {
  onCancel: () => void
}

export const DeviceHistoryLogModal = ({ onCancel }: Props) => {
  const deviceHistoryLogID = useAppSelector(state => state.tempData.deviceHistoryLogID)
  const [triggerGetDeviceHistoryLog, { isFetching }] = useLazyGetDeviceHistoryLogQuery()
  const [historyLog, setHistoryLog] = useState({
    id: "",
    sn: "",
    log: [],
  })

  useEffect(() => {
    if (!deviceHistoryLogID) return

    const fetchData = async () => {
      try {
        const res = await triggerGetDeviceHistoryLog(deviceHistoryLogID)
        if (res?.data?.data) {
          const sortedLogs = res?.data?.data?.history_log
            ?.map((item: ApiTypes.Model.HistoryLog) => {
              return {
                ...item,
                last_update: dayjs(item?.last_update),
              }
            })
            .sort((a, b) => {
              return b.last_update > a.last_update ? 1 : -1
            })
            .map(item => ({
              ...item,
              last_update: item.last_update.format("YYYY-MM-DD HH:mm:ss"),
            }))

          setHistoryLog({
            id: res?.data?.data?.id || "",
            sn: res?.data?.data?.sn || "",
            log: convertDeviceHistoryLog(sortedLogs),
          } as never)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [deviceHistoryLogID])

  return (
    <>
      {createPortal(
        <ModalLayout width="490px" isWithoutFooter={true} onCancel={onCancel}>
          <ModalHeader title={historyLog.sn}>
            <IconDeviceSN />
          </ModalHeader>
          <div className={classNames(styles.modalContent)}>
            <header className={styles.snTableHeader}>
              <span className={styles.snHederCell}>Last Update </span>
              <span className={styles.snHederCell}>status</span>
              <span className={styles.snHederCell}>Author</span>
            </header>
            {isFetching ? (
              <Spinner />
            ) : (
              <div>
                {historyLog.log?.map((item: UITypes.HistoryLog) => (
                  <div className={styles.snBodyRow} key={uuidv4()}>
                    <span className={styles.snBodyCell}>{item?.lastUpdate}</span>
                    <span className={styles.snBodyCell}>
                      <StatusOfPhysical physicalStatus={item?.status} />
                    </span>
                    <span className={styles.snBodyCell}>{item?.author}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
