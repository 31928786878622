import { useEffect, useState } from "react"
import classNames from "classnames"

import { useLazyGetStatusQuery } from "store/api/widgetsApi"

import { WidgetLayout } from "layouts/WidgetLayout"
import { RainbowChart } from "components/common/RainbowChart"
import { Spinner } from "components/common/Spinner"

import styles from "./index.module.scss"

const initialState = {
  offlineBroken: 0,
  enrolled: 0,
  missing: 0,
  inventory: 0,
  total: 0,
  transferring: 0,
  warranty: 0,
  preinstalled: 0,
}

export const WidgetStatus = () => {
  const [triggerGetStatus, { isFetching }] = useLazyGetStatusQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetStatus()
        if (res?.data) {
          setWidgetData({
            offlineBroken: res?.data?.data?.installed_devices?.offline_broken || 0,
            enrolled: res?.data?.data?.installed_devices?.enrolled || 0,
            missing: res?.data?.data?.missing || 0,
            inventory: res?.data?.data?.inventory || 0,
            total: res?.data?.data?.installed_devices?.total || 0,
            transferring: res?.data?.data?.transferring || 0,
            warranty: res?.data?.data?.warranty || 0,
            preinstalled: res?.data?.data?.preinstalled || 0,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <WidgetLayout title="Status" className={styles.widgetStatus}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <div className={styles.chart}>
            <RainbowChart
              values={[
                widgetData.enrolled,
                widgetData.offlineBroken,
                widgetData.inventory,
                widgetData.missing,
                widgetData.transferring,
                widgetData.warranty,
                widgetData.preinstalled,
              ]}
              colors={["#86efac", "#e0e7ff", "#fcceee", "#f59e0b", "#2563EB", "#2db2c8", "#14B8A6"]}
            />
            <div className={styles.widgetMainValue}>
              <span className={styles.widgetNote}>DEVICES</span>
              <div className={styles.widgetValueWrapper}>
                {widgetData.total +
                  widgetData.missing +
                  widgetData.inventory +
                  widgetData.transferring +
                  widgetData.warranty +
                  widgetData.preinstalled}
              </div>
            </div>
          </div>
          <div className={styles.chartNotesWrapper}>
            <div className={styles.chartNotesWorkStatusSection}>
              {/* <div className={classNames(styles.chartNote, styles.online)}> */}
              {/* <span className={styles.chartNoteCategory}>Online</span> */}
              {/* <span className={styles.chartNoteValue}>{widgetData.enrolled}</span> */}
              {/* </div> */}
              {/* <div className={classNames(styles.chartNote, styles.offline)}> */}
              {/* <span className={styles.chartNoteCategory}>Offline</span> */}
              {/* <span className={styles.chartNoteValue}>{widgetData.offlineBroken}</span> */}
              {/* </div> */}
            </div>
            <div className={styles.chartNotesStatusSection}>
              <div className={classNames(styles.chartNote, styles.inventory)}>
                <span className={styles.chartNoteCategory}>In Inventory</span>
                <span className={styles.chartNoteValue}>{widgetData.inventory}</span>
              </div>
              <div className={classNames(styles.chartNote, styles.missing)}>
                <span className={styles.chartNoteCategory}>Missing</span>
                <span className={styles.chartNoteValue}>{widgetData.missing}</span>
              </div>
              <div className={classNames(styles.chartNote, styles.transferring)}>
                <span className={styles.chartNoteCategory}>Transferring</span>
                <span className={styles.chartNoteValue}>{widgetData.transferring}</span>
              </div>
              <div className={classNames(styles.chartNote, styles.installed)}>
                <span className={styles.chartNoteCategory}>Installed</span>
                <span className={styles.chartNoteValue}>{widgetData.total}</span>
              </div>
              <div className={classNames(styles.chartNote, styles.preinstalled)}>
                <span className={styles.chartNoteCategory}>Pre-Installed</span>
                <span className={styles.chartNoteValue}>{widgetData.preinstalled}</span>
              </div>
              <div className={classNames(styles.chartNote, styles.warranty)}>
                <span className={styles.chartNoteCategory}>Warranty</span>
                <span className={styles.chartNoteValue}>{widgetData.warranty}</span>
              </div>
            </div>
          </div>
        </div>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
