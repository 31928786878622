import { useEffect, useRef, useState } from "react"
import styled from "styled-components/macro"

import { UITypes } from "types"
import { ReactComponent as Icon } from "assets/svg/icon-select.svg"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  min-width: 0;
`

const ArrowIcon = styled(Icon)<{ type: "left" | "right" }>`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  transform: rotate(${({ type }) => (type === "left" ? 90 : -90)}deg);
  cursor: pointer;

  path {
    fill: #94a3b8;
  }
`

const TabsContainer = styled.div`
  padding: 4px;
  background: #f1f5f9;
  border-radius: 32px;
  height: 40px;
  min-width: 0;
`

const Tabs = styled.div`
  width: 100%;
  display: inline-flex;
  overflow: hidden;
  border-radius: 32px;
`

const Tab = styled.span<{ active: boolean; online: boolean }>`
  height: 32px;
  border-radius: 32px;
  padding: 0 16px;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  background: #f1f5f9;
  color: #94a3b8;
  cursor: pointer;

  ${({ active }) =>
    active && {
      background: "#fff",
      color: "#334155",
      boxShadow: "0 1px 3px 0 rgb(51 65 85 / 4%), 0 1px 2px 0 rgb(51 65 85 / 4%)",
    }}

  ${({ online }) =>
    online && {
      color: "#22c55e",
    }}
`

interface TabsWrapperProps {
  devices: UITypes.Device[] | undefined
  currentTab: number
  onTabChange: (index: number) => void
}

const TabsWrapper = ({ devices, currentTab, onTabChange }: TabsWrapperProps) => {
  const [showScroll, setShowScroll] = useState(false)
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const wrapper = container.current

    if (wrapper === null) {
      return
    }

    if (wrapper.scrollWidth > wrapper.clientWidth) {
      setShowScroll(true)
    }

    const handleResize = () => {
      if (wrapper.scrollWidth > wrapper.clientWidth && !showScroll) {
        setShowScroll(true)
      } else if (wrapper.scrollWidth <= wrapper.clientWidth && showScroll) {
        setShowScroll(false)
      }
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [devices, showScroll])

  const handleTabChange = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, index: number) => {
    onTabChange(index)
    event.currentTarget.scrollIntoView({ block: "nearest", behavior: "smooth" })
  }

  const handleScroll = (direction: "left" | "right") => {
    const wrapper = container.current
    const tabs = (container.current ? [...container.current.children] : []) as HTMLSpanElement[]
    const sortedTabs = direction === "left" ? tabs.reverse() : tabs

    if (tabs.length === 0 || wrapper === null) {
      return
    }

    for (let i = 0; i < sortedTabs.length; i++) {
      const elementRect = sortedTabs[i].getBoundingClientRect()
      const containerRect = wrapper.getBoundingClientRect()

      if (direction === "right") {
        if (
          (elementRect.right > containerRect.right + 1 && elementRect.left > containerRect.right) ||
          i === sortedTabs.length - 1
        ) {
          sortedTabs[i].scrollIntoView({ block: "nearest", behavior: "smooth" })
          break
        }
      } else {
        if (
          (elementRect.left < containerRect.left - 1 && elementRect.right < containerRect.left) ||
          i === sortedTabs.length - 1
        ) {
          sortedTabs[i].scrollIntoView({ block: "nearest", behavior: "smooth" })
          break
        }
      }
    }
  }

  return (
    <Wrapper>
      {showScroll && <ArrowIcon type="left" onClick={() => handleScroll("left")} />}
      <TabsContainer>
        <Tabs ref={container}>
          {devices?.map((item, index) => (
            <Tab
              key={item.id}
              onClick={event => handleTabChange(event, index)}
              active={devices?.length > 1 && currentTab === index}
              online={item.workStatus === "online"}
            >
              {item.deviceName || `Device ${index + 1}`}
            </Tab>
          ))}
        </Tabs>
      </TabsContainer>
      {showScroll && <ArrowIcon type="right" onClick={() => handleScroll("right")} />}
    </Wrapper>
  )
}

export default TabsWrapper
