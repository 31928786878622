import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"

import logo from "assets/svg/logo-white.svg"
import { ReactComponent as IconDevice } from "assets/svg/menu-device.svg"
import { ReactComponent as IconWarranty } from "assets/svg/menu-warranty.svg"

import { Hamburger } from "components/common/Hamburger"
import { NavLink } from "components/common/NavLink"
import { UserMenu } from "components/common/UserMenu"

import styles from "./index.module.scss"

export const TechnicianTopbar = () => {
  const [isSticky, setSticky] = useState(false)
  const [isOpen, setOpen] = useState(false)

  const closeMenu = () => {
    setOpen(false)
  }

  const handleScroll = () => {
    const distance = window.scrollY

    if (distance > 0 && !isSticky) {
      setSticky(true)
    } else if (distance === 0 && isSticky) {
      setSticky(false)
    }
  }

  useEffect(() => {
    const setStickyOnScroll = () => handleScroll()

    window.addEventListener("scroll", setStickyOnScroll)

    return () => {
      window.removeEventListener("scroll", setStickyOnScroll)
    }
  }, [isSticky])

  return (
    <>
      <header className={classNames(styles.topbar, { [styles.sticky]: isSticky })}>
        <Link className={styles.logoWrapper} to="/">
          <img className={styles.logo} src={logo} alt="logo" />
        </Link>
        <nav className={classNames(styles.menuWrapper, { [styles.opened]: isOpen })}>
          <NavLink to="/add-device" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
            <IconDevice className={classNames(styles.menuIconStroke)} />
            Add Device
          </NavLink>
          <NavLink to="/warranty" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
            <IconWarranty className={styles.menuIcon} />
            Warranty
          </NavLink>
        </nav>
        <Hamburger onClick={() => setOpen(!isOpen)} isOpen={isOpen} />
        <UserMenu />
      </header>
    </>
  )
}
