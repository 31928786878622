import { useEffect, useMemo } from "react"
import classNames from "classnames"

import { useLazyGetTableColumnsQuery } from "store/api/dashboardApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  showAlertsAcknowledgeModal,
  showAlertsFreezeModal,
  showAlertsUnfreezeModal,
  showEditColumnsModal,
} from "store/modalSlise"
import { setCurrentAlert, setCurrentTableColumns } from "store/tempDataSlise"
import { alertStatusOptions, alertTypeOptions } from "constants/SelectFieldOptions"
import { ApiTypes, UITypes } from "types"

import { convertAlertsToTableRows } from "utils/convertData"
import { reorderTableHeaderCells } from "utils/reorderTabbleColumns"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import { ButtonNew } from "components/form/ButtonNew"
import { StatusOfAlert } from "components/table/columnComponents/StatusOfAlert"
import TableWrapper from "components/table/TableWrapper"

import styles from "./index.module.scss"

// TABLE HEADER
const initialTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
    size: "mini",
    name: "checkbox",
    cellComponent: "checkbox",
  },
  {
    title: "Alerts",
    type: "select",
    name: "type",
    options: alertTypeOptions,
    id: 1,
  },
  {
    title: "Date",
    type: "sort-date",
    name: "date",
    id: 2,
  },
  {
    title: "Device S/N",
    type: "sort-search",
    name: "sn",
    id: 3,
  },
  {
    title: "Status",
    type: "select",
    name: "status",
    options: alertStatusOptions,
    cellComponent: StatusOfAlert,
    id: 4,
  },
  {
    title: "Full Name",
    type: "sort-search",
    name: "full_name",
    id: 5,
  },
  {
    title: "Phone Number",
    type: "sort-search",
    name: "phone_number",
    id: 6,
  },
  {
    title: "Email",
    type: "sort-search",
    name: "email",
    id: 7,
  },
  {
    title: "Device Location",
    type: "sort-search",
    name: "location",
    id: 8,
  },
  {
    title: "Country",
    type: "sort-search",
    name: "country",
    id: 9,
  },
  {
    title: "Address",
    type: "sort-search",
    name: "address1",
    id: 10,
  },
  {
    title: "City",
    type: "sort-search",
    name: "city",
    id: 11,
  },
  {
    title: "State",
    type: "sort-search",
    name: "state",
    id: 12,
  },
]

interface AlertsPageContentProps {
  alertsList: ApiTypes.Model.Alert[]
  isFetching: boolean
  meta: ApiTypes.Model.Pagination | undefined
}

export const AlertsPageContent = ({ alertsList, isFetching, meta }: AlertsPageContentProps) => {
  const dispatch = useAppDispatch()
  const currentAlert = useAppSelector(state => state.tempData.currentAlert)
  const [triggerGetTableColumns, { data: columnsData }] = useLazyGetTableColumnsQuery()

  useEffect(() => {
    triggerGetTableColumns({ list_name: "contractor_alerts_list" })
  }, [])

  const tableHeaderCells = useMemo(
    (): UITypes.TableHeaderCell[] =>
      columnsData?.data?.config
        ? reorderTableHeaderCells(initialTableHeaderCells, columnsData?.data?.config)
        : initialTableHeaderCells,
    [columnsData],
  )

  const onEditColumnsClick = () => {
    dispatch(showEditColumnsModal(true))
    dispatch(
      setCurrentTableColumns({
        table: "contractor_alerts_list",
        columns: tableHeaderCells,
      }),
    )
  }

  const onSetCurrentAlert = (id?: number) => {
    if (!id || !alertsList?.length) {
      dispatch(setCurrentAlert(undefined))
    } else {
      const currentAlert = alertsList.filter(item => item.id === id)[0]
      dispatch(setCurrentAlert(currentAlert))
    }
  }

  const onCheckboxChange = (id: number) => {
    onSetCurrentAlert(id === currentAlert?.id ? undefined : id)
  }

  const onAcknowledgeClick = () => {
    dispatch(showAlertsAcknowledgeModal(true))
  }

  const onFreezeClick = () => {
    dispatch(showAlertsFreezeModal(true))
  }

  const onUnfreezeClick = () => {
    dispatch(showAlertsUnfreezeModal(true))
  }

  return (
    <ContentWrapper title="Alerts History">
      <nav className={styles.tableNavWrapper}>
        <div></div>
        <div className={styles.tableNavButtonsWrapper}>
          <span className={styles.editColumnsButton} onClick={onEditColumnsClick}>
            Edit columns
          </span>
          {currentAlert ? (
            <ButtonNew color="blue" onClick={onAcknowledgeClick} className={classNames(styles.tableNavItem)}>
              Acknowledge
            </ButtonNew>
          ) : (
            <ButtonNew color="grey" disabled className={classNames(styles.tableNavItem)}>
              Acknowledge
            </ButtonNew>
          )}
          {currentAlert ? (
            currentAlert?.status === "frozen" ? (
              <ButtonNew onClick={onUnfreezeClick} className={styles.tableNavItem}>
                Unfreeze
              </ButtonNew>
            ) : (
              <ButtonNew onClick={onFreezeClick} className={styles.tableNavItem}>
                Freeze
              </ButtonNew>
            )
          ) : (
            <ButtonNew color="grey" disabled className={styles.tableNavItem}>
              Freeze
            </ButtonNew>
          )}
        </div>
      </nav>
      <TableWrapper
        headerCells={tableHeaderCells}
        columnsLoaded={!!columnsData}
        data={convertAlertsToTableRows(alertsList)}
        isLoading={isFetching}
        onCheckboxChange={onCheckboxChange}
        currentItemID={currentAlert?.id}
      />
      <Pagination meta={meta} />
    </ContentWrapper>
  )
}
