import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import classNames from "classnames"

import { useLazyGetWHOrdersQuery, useLazyShippDevicesQuery } from "store/api/warehouseManagerApi"
import { useAppSelector } from "store/hooks"
import styles from "assets/scss/index.module.scss"
import { ReactComponent as IconShipping } from "assets/svg/modal-icon-shipping.svg"

import getErrorMessage from "utils/getErrorMessage"
import { ModalLayout } from "layouts/ModalLayout"

interface Props {
  onCancel: () => void
}

export const ShippingModal = ({ onCancel }: Props) => {
  const [triggerShippDevice, { isFetching }] = useLazyShippDevicesQuery()
  const [triggerGetWHOrders] = useLazyGetWHOrdersQuery()
  const selectedWHOrder = useAppSelector(state => state.tempData.selectedWHOrder)

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    if (!selectedWHOrder?.id) return

    try {
      const res = await triggerShippDevice(selectedWHOrder?.id)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success("Order shipped successfully!")
        triggerGetWHOrders({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          isFetching={isFetching}
          onConfirm={onSubmit}
          onCancel={onCancel}
          confirmButtonText="Yes"
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconShipping />
            <h3 className={styles.modalTitle}>Shipping</h3>
            <div className={styles.modalMessage}>
              Are you sure you want to ship the Order number {selectedWHOrder?.id}?
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
