import { PropsWithChildren } from "react"

import styles from "./index.module.scss"

interface Props extends PropsWithChildren {
  title: string
  subtitle?: string
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

export const AuthForm = ({ title, subtitle, onSubmit, children }: Props) => {
  return (
    <section className={styles.authSection}>
      <div className={styles.formWrapper}>
        <h2 className={styles.formTitle}>{title}</h2>
        <div className={styles.formSubtitle}>{subtitle}</div>
        <form onSubmit={onSubmit}>{children}</form>
      </div>
    </section>
  )
}
