import { toast } from "react-toastify"

import { useLazyDeleteNewOrderQuery, useLazyGetOrdersQuery } from "store/api/ordersApi"
import { useLazyGetVendorOrdersQuery } from "store/api/vendorApi"
import { useAppSelector } from "store/hooks"

import getErrorMessage from "utils/getErrorMessage"

import { DeleteModal } from "../DeleteModal"

interface Props {
  onCancel: () => void
}

export const DeleteOrderModal = ({ onCancel }: Props) => {
  const [triggerDeleteNewOrder, { isFetching }] = useLazyDeleteNewOrderQuery()
  const [triggerGetOrders] = useLazyGetOrdersQuery()
  const [triggerGetVendorOrder] = useLazyGetVendorOrdersQuery()
  const currentOrder = useAppSelector(state => state.tempData.currentOrder)

  const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()

    try {
      const res = await triggerDeleteNewOrder(currentOrder?.id as unknown as number)
      const error = getErrorMessage(res?.error)
      if (error) {
        toast.error(error)
      } else {
        toast.success("Order has been successfully deleted!")
        triggerGetOrders({})
        triggerGetVendorOrder({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DeleteModal
      title="Delete order"
      message="Are you sure you want to delete the selected order(s)?"
      isFetching={isFetching}
      onCancel={onCancel}
      onConfirm={onSubmit}
    />
  )
}
