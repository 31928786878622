import dayjs, { Dayjs } from "dayjs"
import dayjs_plugin_utc from "dayjs/plugin/utc"

dayjs.extend(dayjs_plugin_utc)

export const dropDownOptions = [
  {
    value: "yesterday",
    label: "Yesterday",
  },
  {
    value: "last3Days",
    label: "Last 3 Days",
  },
  {
    value: "last7Days",
    label: "Last 7 Days",
  },
  {
    value: "last30Days",
    label: "Last 30 Days",
  },
  {
    value: "custom",
    label: "Custom",
  },
]

const setDateParam = (startDate: Dayjs, endDate: Dayjs) => {
  const startDateString = startDate.format("YYYY-MM-DD")
  const endDateString = endDate.format("YYYY-MM-DD")

  return {
    startDateTime: startDate,
    startDateString,
    endDateString,
  }
}

export const getInstalledDevicesDateRangeParams = (
  value: string | number | boolean,
  dateRange: [Dayjs, Dayjs] | null,
) => {
  if (value === "yesterday") {
    const yesterdayStart = dayjs().utc().subtract(1, "day")

    return { ...setDateParam(yesterdayStart, yesterdayStart), daysCount: 1 }
  }

  if (value === "last3Days") {
    const last3DaysStart = dayjs().utc().subtract(2, "day").startOf("day")
    const last3DaysEnd = dayjs().utc().startOf("day")

    return { ...setDateParam(last3DaysStart, last3DaysEnd), daysCount: 3 }
  }

  if (value === "last7Days") {
    const last7DaysStart = dayjs().utc().subtract(6, "day").startOf("day")
    const last7DaysEnd = dayjs().utc().startOf("day")

    return { ...setDateParam(last7DaysStart, last7DaysEnd), daysCount: 7 }
  }

  if (value === "last30Days") {
    const last30DaysStart = dayjs().utc().subtract(29, "day").startOf("day")
    const last30DaysEnd = dayjs().utc().startOf("day")

    return { ...setDateParam(last30DaysStart, last30DaysEnd), daysCount: 30 }
  }

  const dateRangeStart = dateRange?.[0] || dayjs().utc().startOf("day")
  const dateRangeEnd = dateRange?.[1] || dayjs().utc().startOf("day")

  const daysCount = dateRangeEnd.diff(dateRange?.[0], "days") + 1

  return {
    startDateTime: dateRangeStart.startOf("day"),
    startDateString: dateRangeStart.startOf("day")?.format("YYYY-MM-DD"),
    endDateString: dateRangeEnd.startOf("day")?.format("YYYY-MM-DD"),
    daysCount,
  }
}
