import { useEffect, useState } from "react"
import classNames from "classnames"

import { useLazyGetOrderStatusWidgetQuery } from "store/api/vendorApi"

import { WidgetLayout } from "layouts/WidgetLayout"
import { DoughnutChart } from "components/common/DoughnutChart"
import { Spinner } from "components/common/Spinner"

import styles from "./index.module.scss"

const initialState = {
  new: 0,
  pending: 0,
  confirmed: 0,
  packed: 0,
  shipping: 0,
  denied: 0,
}

export const WidgetVendorOrderStatus = () => {
  const [triggerGetStatus, { isFetching, data }] = useLazyGetOrderStatusWidgetQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetStatus()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!data) return
    setWidgetData({
      new: data?.new,
      pending: data?.pending,
      confirmed: data?.confirmed,
      packed: data?.packed,
      shipping: data?.shipping,
      denied: data?.denied,
    })
  }, [data])

  return (
    <WidgetLayout title="Status" className={styles.widgetWrapper}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <div className={styles.chart}>
            <DoughnutChart
              values={Object.values(widgetData)}
              colors={["#4ade80", "#fde047", "#43e0f8", "#7e22ce", "#2563eb", "#ef4444"]}
            />
            <div className={styles.widgetMainValue}>
              <span className={styles.widgetNote}>ORDERS</span>
              <div className={styles.widgetValueWrapper}>{data?.total || 0}</div>
            </div>
          </div>

          <div className={styles.chartNotesWrapper}>
            <div className={classNames(styles.chartNotesWrapperColumn)}>
              <div className={classNames(styles.chartNote, styles.new)}>
                <span>New</span>
                <span className={styles.chartNoteValue}>{widgetData?.new}</span>
              </div>
              <div className={classNames(styles.chartNote, styles.pending)}>
                <span>Pending</span>
                <span className={styles.chartNoteValue}>{widgetData?.pending}</span>
              </div>
              <div className={classNames(styles.chartNote, styles.confirmed)}>
                <span>Confirmed</span>
                <span className={styles.chartNoteValue}>{widgetData?.confirmed}</span>
              </div>
            </div>

            <div className={classNames(styles.chartNotesWrapperColumn)}>
              <div className={classNames(styles.chartNote, styles.packed)}>
                <span>Packed</span>
                <span className={styles.chartNoteValue}>{widgetData?.packed}</span>
              </div>
              <div className={classNames(styles.chartNote, styles.shipping)}>
                <span>Shipping</span>
                <span className={styles.chartNoteValue}>{widgetData?.shipping}</span>
              </div>
              <div className={classNames(styles.chartNote, styles.denied)}>
                <span>Denied</span>
                <span className={styles.chartNoteValue}>{widgetData?.denied}</span>
              </div>
            </div>
          </div>
        </div>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
