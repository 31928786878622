import { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { useLazyGetTechniciansQuery } from "store/api/techniciansApi"
import { useAppDispatch } from "store/hooks"
import { showAddTechnicianModal, showEditTechnicianModal } from "store/modalSlise"
import { setEditableTechnician } from "store/tempDataSlise"
import { UITypes } from "types"
import styles from "assets/scss/index.module.scss"

import { convertTechniciansToTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import { ButtonNew } from "components/form/ButtonNew"
import { CheckboxNew } from "components/form/CheckboxNew"
import TableWrapper from "components/table/TableWrapper"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Technician (ID number)",
    type: "sort-search",
    name: "id",
  },
  {
    title: "Full Name",
    type: "sort-search",
    name: "full_name",
    size: "large",
  },
  {
    title: "Username",
    type: "sort-search",
    name: "username",
    size: "big",
  },
  {
    title: "Installed Devices",
    type: "sort-search",
    name: "installed_devices",
  },
]

export const Technicians = () => {
  const dispatch = useAppDispatch()
  const [triggerGetTechnicians, { isFetching, data }] = useLazyGetTechniciansQuery()
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()
  const [isFirstRender, setFirstRender] = useState(true)

  const onSetEditableTechnician = (id?: number) => {
    if (!id || !data?.data?.length) {
      dispatch(setEditableTechnician(undefined))
    } else {
      const selectedTechnician = data?.data?.filter(item => item.id === id)[0]
      dispatch(setEditableTechnician(selectedTechnician))
    }
  }

  const onEditClick = (id: number) => {
    onSetEditableTechnician(id)
    dispatch(showEditTechnicianModal(true))
  }

  const onAddClick = () => {
    dispatch(showAddTechnicianModal(true))
  }

  const onShowToggleDisabled = () => {
    setUrlQueryParams({
      isEnabled: urlQueryParams["isEnabled"] === "0" ? null : 0,
    })
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res = await triggerGetTechnicians(params)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender])

  return (
    <>
      <ContentWrapper title="Technicians">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={onAddClick} color="blue">
              Add technician
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <CheckboxNew
              className={styles.tableNavItem}
              isReverse={true}
              id="fulfilled"
              onChange={onShowToggleDisabled}
            >
              Show Disabled
            </CheckboxNew>
            {/* <ButtonNew className={styles.tableNavItem}>Export</ButtonNew> */}
          </div>
        </nav>
        <TableWrapper
          headerCells={tableHeaderCells}
          data={convertTechniciansToTableRows(data?.data)}
          isLoading={isFetching}
          onEditClick={onEditClick}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
