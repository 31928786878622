import { useEffect, useState } from "react"

import { useLazyGetInstallTrendsWidgetQuery } from "store/api/adminApi"

import { calculateLastDays } from "../utils"
import { WidgetWrapper } from "./styled"
import WidgetContent from "./WidgetContent"
import WidgetHeader from "./WidgetHeader"

const InstallationTrendsWidget = () => {
  const [selectedDateRange, setSelectedDateRange] = useState(calculateLastDays(7))
  const [triggerGetInstallTrendsWidget, { isFetching, data }] = useLazyGetInstallTrendsWidgetQuery()

  const contentDate = data
    ? [
        {
          title: "Installed Devices",
          value: data.installed_devices,
        },
        {
          title: "Purchase Devices",
          value: data.purchased_devices,
        },
      ]
    : []

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetInstallTrendsWidget(
          {
            start_date: selectedDateRange[0],
            end_date: selectedDateRange[1],
          },
          true,
        )
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [selectedDateRange])

  return (
    <WidgetWrapper>
      <WidgetHeader title="Installation trends" onSelectDateRange={setSelectedDateRange} />
      <WidgetContent isFetching={isFetching} data={contentDate} />
    </WidgetWrapper>
  )
}

export default InstallationTrendsWidget
