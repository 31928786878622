import { TypedUseLazyQuery } from "@reduxjs/toolkit/dist/query/react"

import { parentGroupOptions } from "constants/SelectFieldOptions"
import { ApiTypes, UITypes } from "types"

import ContractorCompanyName from "components/table/columnComponents/ContractorCompanyName"

import downloadFile from "../utils/downloadFile"

const PER_PAGE = 100

interface Props {
  params: UITypes.Params
  headerCells: UITypes.TableHeaderCell[]
  meta: ApiTypes.Model.Pagination | undefined
  fileName: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convertData: (data: any) => UITypes.TableBodyRow[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useLazyQuery: TypedUseLazyQuery<any, any, any>
}

const useExportCSV = ({ params, headerCells, meta, fileName, convertData, useLazyQuery }: Props) => {
  const [triggerQuery] = useLazyQuery()

  const getRowValues = (cell: UITypes.TableBodyRow, headerCells: UITypes.TableHeaderCell[]) => {
    return headerCells.map(headerCell => {
      const value = cell[headerCell.name]?.value || ""
      if (headerCell.type === "select") {
        return headerCell.options?.find(option => option.value === value)?.label || value
      }

      if (headerCell.cellComponent === ContractorCompanyName) {
        const item = cell.rowSettings.item
        return `${value}${item.parent_group !== "none" && item.parent_group !== null ? ` (${parentGroupOptions.find(option => option.value === item.parent_group)?.label})` : ""}`
      }

      return value
    })
  }

  const handleExport = async () => {
    const total = meta?.total || 0
    const pages = Math.ceil(total / PER_PAGE)

    const response = await Promise.all(
      Array.from({ length: pages }).map((_, i) => triggerQuery({ ...params, page: i + 1, per_page: PER_PAGE })),
    )

    const data = response.map(res => res.data.data).flat()
    const parsedData = convertData(data)
    const visibleHeaderCells = headerCells.filter(
      cell => cell.visibility !== false && cell.type !== "checkbox" && cell.type !== undefined,
    )
    const headerRow = visibleHeaderCells.map(cell => cell.label || cell.title)
    const CSVdata = [headerRow, ...parsedData.map(row => getRowValues(row, visibleHeaderCells))]

    downloadFile(
      [
        CSVdata.map(row =>
          row
            .map(item => (typeof item === "string" ? item.replaceAll('"', '""') : item))
            .map(item => (item?.toString().includes(",") ? `"${item}"` : item)),
        ).join("\n"),
      ],
      `${fileName}.csv`,
    )
  }

  return { handleExport }
}

export default useExportCSV
