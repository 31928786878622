import { useEffect, useMemo } from "react"
import { toast } from "react-toastify"

import "assets/libs/react-datepicker.css"

import { useLazyGetPerformanceTestsHistoryQuery } from "store/api/performanceTestApi"
import { useAppSelector } from "store/hooks"
import { UITypes } from "types"
import SubscriptionPrivilege from "types/enums/SubscriptionPrivilege"

import { convertDataToPerformanceTestHistoryTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import hasSubscriptionAccess from "utils/hasSubscriptionAccess"
import useExportCSV from "hooks/useExportCSV"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { SubscriptionUpgradeModal } from "modals/SubscriptionUpgradeModal/SubscriptionUpgradeModal"

import { dummyMeta, dummyPerfTestDevices } from "../PerformanceTestPage/dummyPerfTestData"
import PerformanceTestHistoryPageContent from "./PerformanceTestHistoryPageContent"
import performanceTestHistoryTableHeaderCells from "./performanceTestHistoryTableHeaderCells"

const PerformanceTestHistoryPage = () => {
  const userProfile = useAppSelector(state => state.user.userProfile)
  const [triggerGetPerformanceTestsHistory, { isFetching, data }] = useLazyGetPerformanceTestsHistoryQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const requestParams = { orderColumn: "time", orderType: "DESC" as UITypes.Sort["orderType"], ...urlQueryParams }
  const hasPerformanceTestAccess = userProfile
    ? hasSubscriptionAccess(userProfile, SubscriptionPrivilege.PerformanceTest)
    : false
  const meta = hasPerformanceTestAccess ? data?.meta : dummyMeta
  const isFetchingData = isFetching && !data
  const devices = useMemo(() => {
    if (!hasPerformanceTestAccess) {
      return dummyPerfTestDevices
    }

    return data?.data || []
  }, [data, hasPerformanceTestAccess])

  const { handleExport } = useExportCSV({
    params: requestParams,
    headerCells: performanceTestHistoryTableHeaderCells,
    meta: meta,
    fileName: "performance_test_history",
    convertData: convertDataToPerformanceTestHistoryTableRows,
    useLazyQuery: useLazyGetPerformanceTestsHistoryQuery,
  })

  useEffect(() => {
    sendRequest(requestParams)
  }, [urlQueryParams])

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res = await triggerGetPerformanceTestsHistory(params)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <PerformanceTestHistoryPageContent
        devices={devices}
        meta={meta}
        isFetching={isFetchingData}
        onExport={handleExport}
      />
      {!hasPerformanceTestAccess && <SubscriptionUpgradeModal page={SubscriptionPrivilege.PerformanceTest} />}
    </>
  )
}

export default PerformanceTestHistoryPage
