import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"

import { useLazyGetTableColumnsQuery } from "store/api/dashboardApi"
import { useLazyGetDevicesQuery } from "store/api/devicesApi"
import { useAppDispatch } from "store/hooks"
import { showEditColumnsModal } from "store/modalSlise"
import { setCurrentTableColumns, setSelectedDeviceIDs } from "store/tempDataSlise"
import { UITypes } from "types"

import { convertDevicesToDashboardTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import { reorderTableHeaderCells } from "utils/reorderTabbleColumns"
import useExportCSV from "hooks/useExportCSV"
import useUrlQueryParams from "hooks/useUrlQueryParams"

import contractorDashboardDefaultTableHeaderCells from "./contractorDashboardDefaultTableHeaderCells"
import ContractorDashboardPageContent from "./ContractorDashboardPageContent"

const Dashboard = () => {
  const [triggerGetDevices, { isFetching, data }] = useLazyGetDevicesQuery()
  const [triggerGetTableColumns, { data: columnsData }] = useLazyGetTableColumnsQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const dispatch = useAppDispatch()
  const [isFirstRender, setFirstRender] = useState(true)
  const devices = data?.data || []
  const tableHeaderCells = useMemo(
    (): UITypes.TableHeaderCell[] =>
      columnsData?.data?.config
        ? reorderTableHeaderCells(contractorDashboardDefaultTableHeaderCells, columnsData?.data?.config)
        : contractorDashboardDefaultTableHeaderCells,
    [columnsData],
  )
  const { handleExport } = useExportCSV({
    params: urlQueryParams,
    headerCells: tableHeaderCells,
    meta: data?.meta,
    fileName: "devices",
    convertData: convertDevicesToDashboardTableRows,
    useLazyQuery: useLazyGetDevicesQuery,
  })

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res = await triggerGetDevices(params)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onEditColumnsClick = () => {
    dispatch(showEditColumnsModal(true))
    dispatch(
      setCurrentTableColumns({
        table: "contractor_devices_list",
        columns: tableHeaderCells,
      }),
    )
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
    triggerGetTableColumns({ list_name: "contractor_devices_list" })
  }, [urlQueryParams, isFirstRender])

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setSelectedDeviceIDs([]))
    },
    [],
  )

  return (
    <ContractorDashboardPageContent
      devices={devices}
      tableHeaderCells={tableHeaderCells}
      isFetching={isFetching}
      columnsLoaded={!!columnsData}
      meta={data?.meta}
      onEditColumnsClick={onEditColumnsClick}
      onExport={handleExport}
    />
  )
}

export default Dashboard
