import { SerializedError } from "@reduxjs/toolkit"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"

const getErrorMessage = (
  error: SerializedError | FetchBaseQueryError | undefined,
  errorProp: "message" | "description" = "message",
  defaultMessage = "Something went wrong",
) => {
  if (!error) {
    return ""
  }

  if ("status" in error && "data" in error) {
    const data = error.data as { message: string; description: string }
    return data[errorProp] || defaultMessage
  }

  if ("status" in error && "error" in error && !("message" in error)) {
    return error.error
  }

  return error.message as string
}

export default getErrorMessage
