import classNames from "classnames"

import styles from "./index.module.scss"

interface Props {
  isOpen?: boolean
  onClick?: () => void
}

export const Hamburger = ({ onClick, isOpen }: Props) => {
  return (
    <div className={classNames(styles.hamburgerLines, { [styles.opened]: isOpen })} onClick={onClick}>
      <span className={classNames(styles.line, styles.line1)}></span>
      <span className={classNames(styles.line, styles.line2)}></span>
      <span className={classNames(styles.line, styles.line3)}></span>
    </div>
  )
}
