import { CellContext } from "@tanstack/react-table"
import styled from "styled-components/macro"

import { parentGroupOptions } from "constants/SelectFieldOptions"
import { UITypes } from "types"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  span:nth-child(2) {
    font-size: 12px;
    color: #64748b;
  }
`

interface ContractorCompanyNameProps {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}
const ContractorCompanyName = ({ cell: { row } }: ContractorCompanyNameProps) => {
  const contractor = row.original.rowSettings.item
  const parentGroup = contractor.parent_group || "none"

  return (
    <Container>
      <span>{contractor.name}</span>
      {parentGroup !== "none" && (
        <span>{parentGroupOptions.find(option => option.value === parentGroup)?.label || ""}</span>
      )}
    </Container>
  )
}

export default ContractorCompanyName
