import styled from "styled-components/macro"

export const WidgetWrapper = styled.div<{ marginTop?: number }>`
  border-radius: 20px;
  border: 1px solid #e2e8f0;
  background: #ffffff;
  padding: 0 32px 20px;
  flex: 1;
  margin: 0 12px;
  width: 100%;
  min-height: 173px;
  position: relative;

  @media only screen and (max-width: 900px) {
    flex: 1 0 60%;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
`
