import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { useLazyCreateMessageTemplateQuery, useLazyGetMessageTemplatesQuery } from "store/api/contractorsApi"
import { ApiTypes, UITypes } from "types"
import { ReactComponent as BackIcon } from "assets/svg/icon-arrow-left.svg"

import getErrorMessage from "utils/getErrorMessage"
import { ContentWrapper } from "components/common/ContentWrapper"
import MessageTemplatesList from "components/common/MessageTemplatesList"
import { ButtonNew } from "components/form/ButtonNew"
import { MessageForm } from "components/form/MessageForm"

import styles from "./index.module.scss"

export const MessageTemplateCreate = () => {
  const [triggerCreateMessageTemplate] = useLazyCreateMessageTemplateQuery()
  const [triggerGetMessageTemplates] = useLazyGetMessageTemplatesQuery()
  const navigate = useNavigate()

  const onCreateTemplate = async (data: UITypes.MessageTemplate) => {
    try {
      const res = await triggerCreateMessageTemplate({
        title: data.title,
        body: data.message,
        repeat_every: data.frequency as ApiTypes.Model.MessageTemplateFrequency,
      })
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success("Message template has been successfully created!")
        triggerGetMessageTemplates()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <ContentWrapper title="Create message template">
        <nav className={styles.tableNavWrapper}>
          <div></div>
          <ButtonNew onClick={() => navigate(-1)} color="greyBordered" className={styles.backButton}>
            <BackIcon />
            Back
          </ButtonNew>
        </nav>
        <div className={styles.contentWrapper}>
          <div className={styles.sectionWrapper}>
            <MessageForm onSave={onCreateTemplate} />
          </div>
          <div className={styles.sectionWrapper}>
            <MessageTemplatesList />
          </div>
        </div>
      </ContentWrapper>
    </>
  )
}
