import { Dispatch, FormEvent, SetStateAction, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import {
  getContractorProfile,
  useLazyChangeContractorProfileQuery,
  useLazyGetContractorProfileQuery,
} from "store/api/contractorsApi"
import { useAppSelector } from "store/hooks"
import { UITypes } from "types"

import getErrorMessage from "utils/getErrorMessage"
import { changeContractorProfile, onFormValidate, validationInitialState } from "utils/onValidate"
import { Spinner } from "components/common/Spinner"
import { ButtonNew } from "components/form/ButtonNew"
import { CheckboxNew } from "components/form/CheckboxNew"
import { InputNew } from "components/form/InputNew"
import { InputPhone } from "components/form/InputPhone"

import { ContractorPhoneSupport } from "../components/contractorPhoneSupport"

import styles from "./index.module.scss"

const inputValuesInitialState = {
  fullName: "",
  email: "",
  alertEmail: "",
  phone1: "",
  image: "",
  phoneForSupport: true,
  phoneForSupportCall: "",
  phoneForSupportText: "",
  emailAlerts: false,
}

export type UpdateAccount = typeof inputValuesInitialState

interface Props {
  onCancel: () => void
}

export const AccountSettingsProfile = ({ onCancel }: Props) => {
  const [triggerGetContractorProfile, { isFetching, data }] = useLazyGetContractorProfileQuery()
  const [triggerChangeContractorProfile, { isFetching: changeContratorIsFetching }] =
    useLazyChangeContractorProfileQuery()
  const [inputValues, setInputValues] = useState({ ...inputValuesInitialState })
  const [error, setError] = useState(validationInitialState)
  const selectContractorProfile = useMemo(() => getContractorProfile.select(), [])
  const contractorProfile = useAppSelector(selectContractorProfile)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues(prevState => {
      return {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
        ...(name === "phoneForSupport" && checked ? { phoneForSupportCall: "", phoneForSupportText: "" } : {}),
      }
    })
  }

  const sendRequest = async () => {
    const formData = new FormData()
    formData.append("profile[full_name]", inputValues.fullName)
    formData.append("profile[email]", inputValues.email)
    formData.append("profile[alert_email]", inputValues.alertEmail)
    formData.append("profile[phone1]", inputValues.phone1)
    formData.append("profile[phone_for_support]", inputValues.phoneForSupport ? "1" : "0")
    formData.append("profile[phone_for_support_call]", inputValues.phoneForSupportCall)
    formData.append("profile[phone_for_support_text]", inputValues.phoneForSupportText)
    formData.append("profile[email_alerts]", inputValues.emailAlerts ? "1" : "0")

    try {
      const res = await triggerChangeContractorProfile(formData)
      const errorMessage = getErrorMessage(res.error, "message", "Server error")

      if (errorMessage) {
        toast.error(errorMessage)
      } else {
        toast.success("Account has been successfully updated!")
        setInputValues(inputValuesInitialState)
        onCancel()
        triggerGetContractorProfile()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (onFormValidate(inputValues, changeContractorProfile, setError)) {
      sendRequest()
    }
  }

  useEffect(() => {
    const res = contractorProfile?.data ? contractorProfile?.data : data
    if (res) {
      setInputValues({
        fullName: res?.data?.profile?.full_name || "",
        email: res?.data?.profile?.email || "",
        alertEmail: res?.data?.profile?.alert_email || "",
        phone1: res?.data?.profile?.phone1 || "",
        phoneForSupport: res?.data?.profile?.phone_for_support || false,
        phoneForSupportCall: res?.data?.profile?.phone_for_support_call || "",
        phoneForSupportText: res?.data?.profile?.phone_for_support_text || "",
        emailAlerts: res?.data?.profile?.email_alerts || false,
        image: res?.data?.profile?.image || "",
      })
    } else {
      const fetchData = async () => {
        try {
          await triggerGetContractorProfile()
        } catch (error) {
          console.error(error)
        }
      }
      fetchData()
    }
  }, [data, contractorProfile?.data])

  return (
    <>
      <div className={classNames(styles.modalContent, styles.settings)}>
        {isFetching && (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        <InputNew
          label="Full name*"
          placeholder="Full name"
          name="fullName"
          onChange={onInputChange}
          value={inputValues.fullName}
          errorMessage={error.field === "fullName" ? error.message : ""}
        />
        <div className={classNames(styles.row, styles.directionColumn)}>
          <InputNew
            label="Email*"
            placeholder="Email"
            name="email"
            onChange={onInputChange}
            disabled={true}
            className={styles.inputText}
            value={inputValues.email}
            errorMessage={error.field === "email" ? error.message : ""}
          />
          <CheckboxNew
            id={uuidv4()}
            className={styles.emailAlertsCheckbox}
            name="emailAlerts"
            onChange={onInputChange}
            isChecked={inputValues.emailAlerts}
          >
            Use this email address to receive alert notifications
          </CheckboxNew>
        </div>
        <InputNew
          label="Email for alert notifications (optional)"
          placeholder="Email"
          name="alertEmail"
          onChange={onInputChange}
          value={inputValues.alertEmail}
          errorMessage={error.field === "alertEmail" ? error.message : ""}
        />
        <InputPhone
          label="Phone number*"
          placeholder="Phone number"
          name="phone1"
          className={styles.inputText}
          onChange={value => setInputValues({ ...inputValues, ...{ phone1: value } })}
          value={inputValues.phone1}
          errorMessage={error.field === "phone1" ? error.message : ""}
        />
        <ContractorPhoneSupport
          phoneForSupport={inputValues.phoneForSupport}
          phoneForSupportCall={inputValues.phoneForSupportCall}
          phoneForSupportText={inputValues.phoneForSupportText}
          inputValues={inputValues as unknown as UITypes.Contractor}
          isProfileSettings
          error={error}
          setError={setError}
          setInputValues={setInputValues as Dispatch<SetStateAction<UITypes.Contractor>>}
        />
      </div>
      <footer className={styles.modalFooter}>
        <ButtonNew onClick={onCancel} size="halfWidth" color="greyBordered">
          Cancel
        </ButtonNew>
        <ButtonNew onClick={onSubmit} size="halfWidth" type="submit" color="blue" disabled={changeContratorIsFetching}>
          {changeContratorIsFetching ? <Spinner /> : "Save"}
        </ButtonNew>
      </footer>
    </>
  )
}
