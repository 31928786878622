import { useEffect, useMemo, useRef, useState } from "react"
import { Dayjs } from "dayjs"
import styled from "styled-components/macro"

import { UITypes } from "types"

import DateRangePicker from "modals/StatsReportModal/DateRangePicker"
import { SelectStatic } from "components/form/SelectStatic"

import { dateSelectOptions } from "../constants"
import { calculateLastDays } from "../utils"

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #f1f5f9;

  .react-dropdown-select {
    min-width: 150px;
    display: flex;
    min-height: 32px !important;
  }
`

const SelectWrapper = styled.div`
  position: relative;
`

const DateRangePickerContainer = styled.div<{ position: "top" | "bottom" }>`
  > div > div {
    right: 0px;

    ${({ position }) => (position === "bottom" ? `top: 37px;` : `bottom: 5px;`)}
  }
`

const Title = styled.h3`
  font-family: $secondaryFont;
  font-size: 1rem;
  font-weight: 500;
`

interface WidgetHeaderProps {
  title: string
  includeCustomRangeSelect?: boolean
  onSelectDateRange: (date: [string, string]) => void
}

const WidgetHeader = ({ title, includeCustomRangeSelect, onSelectDateRange }: WidgetHeaderProps) => {
  const selectKey = useRef(1)
  const selectWrapperRef = useRef<HTMLDivElement | null>(null)
  const [selectedDateRange, setSelectedDateRange] = useState<[Dayjs, Dayjs] | null>(null)
  const [selectedDate, setSelectedDate] = useState(dateSelectOptions[0])
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false)
  const [position, setPosition] = useState<"top" | "bottom">("top")
  const isCustom = selectedDate.value === "custom"
  const options = useMemo(
    () => [...dateSelectOptions, ...(includeCustomRangeSelect ? [{ label: "Custom", value: "custom" }] : [])],
    [includeCustomRangeSelect],
  )

  useEffect(() => {
    if (!includeCustomRangeSelect) {
      return
    }

    const calculatePosition = () => {
      if (!selectWrapperRef.current) return

      const boundary = selectWrapperRef.current.getBoundingClientRect()

      if (boundary.top < 455) {
        setPosition("bottom")
      } else {
        setPosition("top")
      }
    }

    document.addEventListener("scroll", calculatePosition)

    return () => {
      document.removeEventListener("scroll", calculatePosition)
    }
  }, [includeCustomRangeSelect])

  const handleSelectDate = (date: UITypes.Option[]) => {
    if (date[0].value === "custom") {
      setShowCustomDatePicker(true)
      return
    }

    setSelectedDate(date[0])
    onSelectDateRange(calculateLastDays(Number(date[0].value)))
  }

  const handleDateCancel = () => {
    selectKey.current += 1
    setShowCustomDatePicker(false)
  }

  const handleDateRangeSubmit = (dateRange: [Dayjs, Dayjs]) => {
    onSelectDateRange([dateRange[0].format("YYYY-MM-DD"), dateRange[1].format("YYYY-MM-DD")])
    setSelectedDateRange(dateRange)
    setShowCustomDatePicker(false)
    setSelectedDate(options[options.length - 1])
  }

  const contentRenderer = () => {
    if (isCustom && selectedDateRange) {
      return () => (
        <>
          {selectedDateRange[0].format("MMM D, YYYY")}-{selectedDateRange[1].format("MMM D, YYYY")}
        </>
      )
    }

    return undefined
  }

  return (
    <Container ref={selectWrapperRef}>
      <Title>{title}</Title>
      <SelectWrapper>
        {showCustomDatePicker && (
          <DateRangePickerContainer position={position}>
            <DateRangePicker
              onCancel={handleDateCancel}
              onSubmit={handleDateRangeSubmit}
              selectedRange={selectedDateRange || undefined}
            />
          </DateRangePickerContainer>
        )}
        <SelectStatic
          key={selectKey.current}
          values={[selectedDate]}
          onChange={handleSelectDate}
          options={options}
          contentRenderer={contentRenderer()}
        />
      </SelectWrapper>
    </Container>
  )
}

export default WidgetHeader
