import React, { useEffect } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"

import {
  useLazyAddTemplateLibraryQuery,
  useLazyGetMessageTemplatesQuery,
  useLazyGetTemplateLibraryGroupsQuery,
} from "store/api/contractorsApi"
import { useAppDispatch } from "store/hooks"
import { showDeleteMessageTemplateModal } from "store/modalSlise"
import { setCurrentTemplateID } from "store/tempDataSlise"
import { UITypes } from "types"

import { convertMessageTemplatesToTableRows, convertTemplatesLibraryToTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import Table from "components/table/Table"

import { NavLink } from "../NavLink"

import styles from "./index.module.scss"

// TABLE HEADER
const libraryTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "ID",
    size: "tiny",
    name: "id",
  },
  {
    title: "Title",
    size: "big",
    name: "title",
  },
  {
    title: "Message",
    size: "extra-big",
    name: "message",
    cellComponent: "markdown",
  },
]

const templatesTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Title",
    name: "title",
  },
  {
    title: "Creation date",
    size: "small",
    name: "created_at",
  },
  {
    title: "Message",
    size: "extra-big",
    name: "message",
    cellComponent: "markdown",
  },
  {
    title: "ID",
    size: "tiny",
    name: "id",
  },
]

const MessageTemplatesList = React.memo(() => {
  const [triggerGetMessageTemplates, { isFetching, data }] = useLazyGetMessageTemplatesQuery()
  const [triggerGetTemplateLibraryGroups, { isFetching: isTemplatesFetching, data: templatesData }] =
    useLazyGetTemplateLibraryGroupsQuery()
  const [triggerAddTemplateLibrary, { isFetching: isAddTemplateFetching }] = useLazyAddTemplateLibraryQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const id = searchParams.get("id")

  const onDeleteClick = (id: number) => {
    dispatch(setCurrentTemplateID(id))
    dispatch(showDeleteMessageTemplateModal(true))
    navigate(`/messages/create-template`)
  }

  const onAddTemplateLibrary = async (value: number) => {
    try {
      const res = await triggerAddTemplateLibrary(value)

      const errorMessage = getErrorMessage(res.error)
      if (errorMessage) {
        toast.error(errorMessage)
      } else {
        toast.success("Message template has been successfully added!")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onEditClick = (id: number) => {
    navigate(`/messages/edit-template?id=${id}`, { replace: true })
  }

  const onViewClick = (id: number) => {
    navigate(`/messages/add-template?id=${id}`, { replace: true })
  }

  const renderRightLink = (path: string) => {
    switch (path) {
      case "/messages/add-template":
        return "/messages/edit-template"
      case "/messages/create-template":
        return "/messages/create-template"
      default:
        return "/messages/edit-template"
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetMessageTemplates()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetTemplateLibraryGroups()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <div className={styles.templateList}>
      <div className={styles.tabsWrapper}>
        <NavLink className={styles.tab} to={renderRightLink(location.pathname)} activeClassName={styles.active}>
          My templates
        </NavLink>
        <NavLink className={styles.tab} to="/messages/add-template" activeClassName={styles.active}>
          Templates library
        </NavLink>
      </div>
      {location.pathname === "/messages/add-template" ? (
        <Table
          tableName="message-library-templates"
          headerCells={libraryTableHeaderCells}
          className={styles.table}
          isLoading={isFetching || isTemplatesFetching || isAddTemplateFetching}
          currentItemID={id}
          dynamicNavHandler1={onAddTemplateLibrary}
          dynamicNavHandler2={onViewClick}
          data={convertTemplatesLibraryToTableRows(templatesData?.data)}
        />
      ) : (
        <Table
          headerCells={templatesTableHeaderCells}
          className={styles.table}
          isLoading={isFetching}
          currentItemID={id}
          data={convertMessageTemplatesToTableRows(data?.data)}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
        />
      )}
    </div>
  )
})

export default MessageTemplatesList
