import { useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import classNames from "classnames"

import {
  useLazyGetAdminAlertsQuery,
  useLazyGetVendorAlertAndDeviceWidgetQuery,
  useLazyGetVendorStatusesWidgetQuery,
} from "store/api/adminApi"
import {
  useLazyGetAlertAndDeviceWidgetQuery,
  useLazyGetAlertsQuery,
  useLazyGetAlertStatusesWidgetQuery,
  useLazyPostAlertAcknowledgeQuery,
} from "store/api/alertsApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setCurrentAlert } from "store/tempDataSlise"
import { ReactComponent as IconAcknowledge } from "assets/svg/icon-modal-acknowledge.svg"

import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ModalLayout } from "layouts/ModalLayout"

import styles from "./index.module.scss"

interface Props {
  isFetching?: boolean
  onCancel?: () => void
  onConfirm?: () => void
}

export const AlertsAcknowledgeModal = ({ onCancel }: Props) => {
  const isVendor = useAppSelector(state => state.user.userProfile?.contractor == null)
  const [triggerAlertAcknowledge, { isFetching }] = useLazyPostAlertAcknowledgeQuery()
  const [triggerGetVendorStatusesWidget] = useLazyGetVendorStatusesWidgetQuery()
  const [triggerGetVendorAlertAndDeviceWidget] = useLazyGetVendorAlertAndDeviceWidgetQuery()
  const [triggerGetAdminAlerts] = useLazyGetAdminAlertsQuery()
  const [triggerGetAlerts] = useLazyGetAlertsQuery()
  const [triggerGetAlertStatusesWidget] = useLazyGetAlertStatusesWidgetQuery()
  const [triggerGetAlertsAndDeviceWidget] = useLazyGetAlertAndDeviceWidgetQuery()

  const dispatch = useAppDispatch()
  const currentAlert = useAppSelector(state => state.tempData.currentAlert)
  const { urlQueryParams } = useUrlQueryParams()
  const [period, setPeriod] = useState("6h")

  const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()

    try {
      await triggerAlertAcknowledge({
        id: currentAlert?.id || 0,
        until: period,
      })

      toast.success("Alert has been successfully acknowledged!")
      dispatch(setCurrentAlert(undefined))
      if (isVendor) {
        triggerGetAdminAlerts(urlQueryParams)
        triggerGetVendorStatusesWidget()
        triggerGetVendorAlertAndDeviceWidget()
      } else {
        triggerGetAlerts(urlQueryParams)
        triggerGetAlertStatusesWidget()
        triggerGetAlertsAndDeviceWidget()
      }
      onCancel?.()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconAcknowledge />
            <h3 className={styles.modalTitle}>Acknowledge</h3>
            <div className={styles.modalMessage}>
              Please choose the duration for which the alert will stay in the acknowledged status. During this time,
              alerts on the device will be temporarily paused.
            </div>
            <div className={styles.separator} />
            <div className={styles.periodButtonsWrapper}>
              <span
                className={classNames(styles.periodButton, { [styles.active]: period === "6h" })}
                onClick={() => setPeriod("6h")}
              >
                6 hr
              </span>
              <span
                className={classNames(styles.periodButton, { [styles.active]: period === "12h" })}
                onClick={() => setPeriod("12h")}
              >
                12 hr
              </span>
              <span
                className={classNames(styles.periodButton, { [styles.active]: period === "1d" })}
                onClick={() => setPeriod("1d")}
              >
                1 day
              </span>
              <span
                className={classNames(styles.periodButton, { [styles.active]: period === "3d" })}
                onClick={() => setPeriod("3d")}
              >
                3 days
              </span>
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
