import { MutableRefObject, useRef, useState } from "react"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import { ReactComponent as IconClose } from "assets/svg/icon-close-small.svg"
import { ReactComponent as IconFile } from "assets/svg/icon-file.svg"

import styles from "./index.module.scss"

interface Props {
  label?: string
  accept?: string
  errorMessage?: string
  uploadedFiles: FileList | null
  onFileReset?: () => void
  setUploadedFiles: (data: FileList | null) => void
}

export const InputFile = ({ label, errorMessage, accept, uploadedFiles, setUploadedFiles, onFileReset }: Props) => {
  const [isDragging, setIsDragging] = useState(false)
  const [inputKey, setInputKey] = useState(uuidv4())
  const uploadAvatarRef: MutableRefObject<HTMLInputElement | null> = useRef(null)

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
    setIsDragging(false)

    const files = e.dataTransfer.files
    setUploadedFiles(files)
  }

  const onClear = () => {
    setUploadedFiles(null)
    setInputKey(uuidv4())
    onFileReset?.()
  }

  return (
    <div className={styles.fieldWrapper}>
      {label && <div className={styles.fieldLabel}>{label}</div>}
      <div className={styles.field}>
        <input
          type="file"
          id="file"
          accept={accept}
          className={styles.inputFile}
          key={inputKey}
          onChange={event => {
            setUploadedFiles(event?.target?.files)
          }}
          ref={uploadAvatarRef}
        />
        <label
          htmlFor="file"
          className={classNames(styles.fileWrapper, {
            [styles.dragging]: isDragging,
          })}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className={styles.labelText}>
            <span>Click to upload</span> or drag and drop <br />
            .xls, .xlsx, .csv
          </div>
        </label>
      </div>
      {uploadedFiles?.[0] && (
        <div className={styles.fileNameWrapper}>
          <IconFile />
          <span className={styles.fileName}>{uploadedFiles?.[0]?.name}</span>
          <IconClose className={styles.clearFile} onClick={onClear} />
        </div>
      )}

      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
