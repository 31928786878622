import { FormEvent, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"

import { useLazyGetCitiesQuery, useLazyGetStatesQuery, useLazyGetZipCodesQuery } from "store/api/dictionaryApi"
import { useLazyEditWarehouseQuery, useLazyGetWarehouseListQuery } from "store/api/warehousesApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setCurrentZip } from "store/tempDataSlise"
import { ApiTypes, UITypes } from "types"
import styles from "assets/scss/index.module.scss"
import { ReactComponent as IconWarehouse } from "assets/svg/modal-icon-warehouse.svg"

import { convertDataToOptions } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import { editWarehouseSchema, onFormValidate, validationInitialState } from "utils/onValidate"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"
import { InputNew } from "components/form/InputNew"
import { InputPhone } from "components/form/InputPhone"
import { SelectDynamic } from "components/form/SelectDynamic"
import { ToggleButton } from "components/form/ToggleButton"

const inputValuesInitialState: UITypes.Warehouse = {
  name: "",
  address: "",
  zip: [],
  state: [],
  city: [],
  phone: "",
  isDisable: false,
}

interface Props {
  onCancel: () => void
}

export const EditWarehouseModal = ({ onCancel }: Props) => {
  const [triggerEditWarehouse, { isFetching }] = useLazyEditWarehouseQuery()
  const [triggerGetWarehouseList] = useLazyGetWarehouseListQuery()
  const editableWarehouse = useAppSelector(state => state.tempData.editableWarehouse)
  const dispatch = useAppDispatch()
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)
  const currentZip = useAppSelector(state => state.tempData.currentZip)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues(prevState => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (onFormValidate(inputValues, editWarehouseSchema, setError)) {
      sendRequest(inputValues)
    }
  }

  const onCancelClick = () => {
    dispatch(setCurrentZip(undefined))
    onCancel()
  }

  const sendRequest = async (data: UITypes.Warehouse) => {
    try {
      const res = await await triggerEditWarehouse({
        body: {
          name: data.name,
          address: data.address,
          phone_number: data.phone,
          zip_id: data.zip[0]?.value,
          state_id: data.state[0]?.value,
          city_id: data.city[0]?.value,
          is_enabled: data?.isDisable ? 0 : 1,
        } as ApiTypes.Req.EditWarehouse,
        id: editableWarehouse!.id,
      })
      const error = getErrorMessage(res?.error, "message", "Server error")
      if (error) {
        toast.error(error)
      } else {
        toast.success("Warehouse has been successfully updated!")
        setInputValues(inputValuesInitialState)
        dispatch(setCurrentZip(undefined))
        triggerGetWarehouseList({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  // FILL IN CITY AND STATE SELECT BASED ON ZIP
  useEffect(() => {
    if (currentZip?.city) {
      const city = convertDataToOptions({ data: [currentZip.city] }, "id", "name")
      const state = convertDataToOptions({ data: [currentZip.state] }, "id", "name")

      if (city && state) {
        setInputValues({
          ...inputValues,
          ...{
            city,
            state,
          },
        })
      }
    }
  }, [currentZip])

  useEffect(() => {
    if (!editableWarehouse) return

    setInputValues({
      name: editableWarehouse?.name,
      address: editableWarehouse?.address,
      zip: editableWarehouse?.zip && convertDataToOptions({ data: [editableWarehouse?.zip] }, "id", "code"),
      state: editableWarehouse?.state && convertDataToOptions({ data: [editableWarehouse?.state] }, "id", "name"),
      city: editableWarehouse?.city && convertDataToOptions({ data: [editableWarehouse?.city] }, "id", "name"),
      phone: editableWarehouse?.phone_number,
      isDisable: !editableWarehouse?.is_enabled,
    })
  }, [editableWarehouse])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          isFetching={isFetching}
          confirmButtonType="submit"
        >
          <ModalHeader title="Edit Warehouse">
            <IconWarehouse />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Warehouse name"
              placeholder="Enter warehouse name"
              name="name"
              value={inputValues.name}
              onChange={onInputChange}
              errorMessage={error.field === "name" ? error.message : ""}
            />
            <div className={styles.formCellsWrapper}>
              <div className={styles.formCell}>
                <SelectDynamic
                  label="Zip Code"
                  valueName="id"
                  labelName="code"
                  searchFieldName="code"
                  name="zip"
                  values={inputValues.zip}
                  useLazyQuery={useLazyGetZipCodesQuery}
                  placeholder="Enter Zip Code"
                  onChange={value => setInputValues({ ...inputValues, ...{ zip: value } })}
                  errorMessage={error.field === "zip" ? error.message : ""}
                />
              </div>
              <div className={styles.formCell}>
                <SelectDynamic
                  label="State"
                  valueName="id"
                  labelName="name"
                  placeholder="Enter state"
                  name="state"
                  useLazyQuery={useLazyGetStatesQuery}
                  values={inputValues.state}
                  onChange={value => {
                    setInputValues({ ...inputValues, ...{ state: value } })
                  }}
                  errorMessage={error.field === "state" ? error.message : ""}
                />
              </div>
            </div>
            <SelectDynamic
              label="City"
              valueName="id"
              labelName="name"
              name="city"
              useLazyQuery={useLazyGetCitiesQuery}
              placeholder="Enter city"
              values={inputValues.city}
              onChange={value => setInputValues({ ...inputValues, ...{ city: value } })}
              errorMessage={error.field === "city" ? error.message : ""}
            />
            <InputNew
              label="Address"
              placeholder="Enter address "
              name="address"
              value={inputValues.address}
              onChange={onInputChange}
              errorMessage={error.field === "address" ? error.message : ""}
            />
            <InputPhone
              label="Phone number"
              placeholder="Enter phone"
              name="phone"
              value={inputValues.phone}
              onChange={value => setInputValues({ ...inputValues, ...{ phone: value } })}
              errorMessage={error.field === "phone" ? error.message : ""}
            />
            <div className={styles.modalLine}></div>
            <div className={styles.toggleFieldWrapper}>
              <ToggleButton id="isDisable" name="isDisable" isChecked={inputValues.isDisable} onChange={onInputChange}>
                Disable warehouse
              </ToggleButton>
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
