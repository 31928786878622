import { PropsWithChildren } from "react"
import classNames from "classnames"

import { UITypes } from "types"

import styles from "./index.module.scss"

interface Props extends PropsWithChildren, UITypes.Button {}

export const Button = ({
  color = "white",
  size = "auto",
  children,
  type,
  disabled,
  className,
  text,
  onClick,
}: Props) => {
  const attributes = {
    type,
    disabled,
    onClick,
    className: classNames(className, styles.button, {
      [styles[color]]: color,
      [styles[size]]: size,
    }),
  }

  return type === "submit" ? (
    <button {...attributes}>
      {text} {children}
    </button>
  ) : (
    <span {...attributes}>
      {text} {children}
    </span>
  )
}
