import {
  contractorDevicePhysicalStatusOptions,
  deviceAccessoriesOptions,
  devicesAlertsOptions,
  devicesMembershipOptions,
  deviceSystemTypeOptions,
  hvacOptions,
} from "constants/SelectFieldOptions"
import { UITypes } from "types"

import { StatusOfPhysical } from "components/table/columnComponents/StatusOfPhysical"

const contractorDashboardDefaultTableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
    type: "checkbox",
    name: "checkbox",
    cellComponent: "checkbox",
    size: "mini",
    id: 1,
  },
  {
    title: "Device S/N",
    type: "sort-search",
    name: "sn",
    size: "small",
    id: 2,
  },
  {
    title: "Status",
    type: "select",
    name: "phisical_status",
    size: "small",
    options: contractorDevicePhysicalStatusOptions,
    cellComponent: StatusOfPhysical,
    id: 3,
  },
  {
    title: "Customer",
    type: "sort-search",
    name: "homeowner_name",
    size: "middle",
    id: 4,
  },
  {
    title: "Country",
    type: "sort-search",
    name: "country",
    id: 5,
  },
  {
    title: "ZIP code",
    type: "sort-search",
    name: "zip_code",
    size: "small",
    id: 6,
  },
  {
    title: "Activation date",
    type: "sort-date",
    name: "activation_date",
    size: "middle",
    id: 7,
  },
  {
    title: "Address",
    type: "sort-search",
    name: "address",
    id: 8,
  },
  {
    title: "HVAC",
    type: "select",
    size: "small",
    name: "installation_type",
    options: hvacOptions,
    id: 9,
  },
  {
    title: "Alerts",
    type: "select",
    size: "small",
    name: "alerts",
    options: devicesAlertsOptions,
    id: 10,
  },
  {
    title: "Membership",
    type: "select",
    size: "small",
    name: "membership",
    options: devicesMembershipOptions,
    id: 11,
  },
  {
    title: "System type",
    type: "select",
    size: "small",
    name: "system_type",
    options: deviceSystemTypeOptions,
    id: 12,
  },
  {
    title: "Accessories",
    type: "select",
    size: "small",
    name: "accessories",
    options: deviceAccessoriesOptions,
    id: 13,
  },
]

export default contractorDashboardDefaultTableHeaderCells
