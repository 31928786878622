import { useEffect, useMemo, useState } from "react"
import classNames from "classnames"

import { getContractorProfile, useLazyGetContractorProfileQuery } from "store/api/contractorsApi"
import { useAppSelector } from "store/hooks"

import { convertDataToOptions } from "utils/convertData"
import { Spinner } from "components/common/Spinner"
import { InputNew } from "components/form/InputNew"
import { SelectStatic } from "components/form/SelectStatic"

import styles from "./index.module.scss"

const inputValuesInitialState = {
  clientId: "",
  clientSecret: "",
  apiKey: "",
  tenant: "",
  memberships: [] as { id: number; name: string }[],
}

export const AccountSettingsIntegration = () => {
  const [triggerGetContractorProfile, { isFetching, data }] = useLazyGetContractorProfileQuery()
  const selectContractorProfile = useMemo(() => getContractorProfile.select(), [])
  const contractorProfile = useAppSelector(selectContractorProfile)
  const [inputValues, setInputValues] = useState({ ...inputValuesInitialState })

  useEffect(() => {
    const res = contractorProfile?.data ? contractorProfile?.data : data
    if (res) {
      setInputValues({
        clientId: res?.data?.integration?.client_id || "",
        clientSecret: res?.data?.integration?.client_secret || "",
        apiKey: res?.data?.integration?.api_key || "",
        tenant: res?.data?.integration?.tenant || "",
        memberships: res?.data?.integration?.memberships || [],
      })
    } else {
      const fetchData = async () => {
        try {
          await triggerGetContractorProfile()
        } catch (error) {
          console.error(error)
        }
      }
      fetchData()
    }
  }, [data, contractorProfile?.data])

  return (
    <>
      <div className={classNames(styles.modalContent, styles.settingsIntegration)}>
        {isFetching && (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        <InputNew disabled label="Client ID*" placeholder="Client ID" value={inputValues?.clientId} />
        <InputNew disabled label="Client Secret*" placeholder="Client Secret" value={inputValues?.clientSecret} />
        <InputNew disabled label="API Key" placeholder="API Key" value={inputValues?.apiKey} />
        <InputNew disabled label="Tenant" placeholder="Tenant member" value={inputValues?.tenant} />
        <h4 className={styles.fullWidthTitle}>ST Memberships</h4>
        <SelectStatic
          label="Memberships"
          placeholder="Select Membership"
          multi={true}
          values={convertDataToOptions({ data: inputValues.memberships }, "id", "name")}
          disabled
        />
        <div className={styles.modalNoteText}>
          Please contact Nuve Admin in case you have any integration related questions.
        </div>
      </div>
    </>
  )
}
