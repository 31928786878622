import { RefObject, useRef, useState } from "react"
import { ConfigProvider, DatePicker, DatePickerProps } from "antd"
import { RangePickerProps } from "antd/es/date-picker"
import dayjs, { Dayjs } from "dayjs"
import styled from "styled-components/macro"

import { useOutsideClick } from "utils/useOutsideClick"

import { ButtonNew } from "../../components/form/ButtonNew"

const { RangePicker } = DatePicker

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
`

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding-bottom: 14px;
  position: absolute;
  border-radius: 8px;
  width: 580px;
  height: 400px;
  background-color: #fff;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

  > div {
    position: relative;
    padding-bottom: 0;
  }

  div {
    box-shadow: none !important;
  }

  td {
    padding: 2px 0 !important;
  }

  .ant-picker {
    position: relative;
    height: 32px;
    padding-bottom: 3px;
    z-index: 10000;
  }

  .ant-picker-dropdown {
    inset: initial !important;
  }

  .ant-picker-body {
    padding: 6px !important;
  }
`

const RangePickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0 0 12px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 280px;

  button {
    font-weight: 400;
  }
`

interface DateRangePickerProps {
  maxRange?: number
  selectedRange?: [Dayjs, Dayjs]
  onSubmit: (dateRange: [Dayjs, Dayjs]) => void
  onCancel: () => void
}

const DateRangePicker = ({ maxRange, selectedRange, onSubmit, onCancel }: DateRangePickerProps) => {
  const [applyDisabled, setApplyDisabled] = useState(!selectedRange)
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null)
  const container: RefObject<HTMLDivElement | null> = useRef(null)
  useOutsideClick(container, onCancel)

  const disabledRange: DatePickerProps["disabledDate"] = (current, { from }) => {
    if (current > dayjs().endOf("day")) {
      return true
    }

    if (from) {
      return Math.abs(current.diff(from, "days")) >= (maxRange || 0)
    }

    return false
  }

  const handleChange: RangePickerProps["onChange"] = dateRange => {
    if (!dateRange?.[0] || !dateRange?.[1]) return

    if (dateRange?.length && (!maxRange || dateRange[1].diff(dateRange[0], "days") < maxRange)) {
      setApplyDisabled(false)
      setDateRange([dateRange[0], dateRange[1]])
    } else {
      setApplyDisabled(true)
      setDateRange(null)
    }
  }

  const handleSubmit = () => {
    if (dateRange) {
      onSubmit(dateRange)
    }
  }

  return (
    <Wrapper>
      <Container ref={container}>
        <RangePickerContainer>
          <ConfigProvider
            theme={{
              components: {
                DatePicker: {
                  cellHeight: 32,
                },
              },
              token: {
                motion: false,
                colorPrimary: "#43e0f8",
                colorPrimaryBg: "#ebfcff",
              },
            }}
          >
            <RangePicker
              open
              placement="topLeft"
              defaultValue={selectedRange}
              getPopupContainer={() => container.current!}
              disabledDate={maxRange ? disabledRange : undefined}
              onChange={handleChange}
              size="middle"
            />
          </ConfigProvider>
          <ButtonsContainer>
            <ButtonNew onClick={onCancel} size={"auto"} color="greyBordered">
              Cancel
            </ButtonNew>
            <ButtonNew onClick={handleSubmit} size={"auto"} disabled={applyDisabled} color={"blue"}>
              Apply
            </ButtonNew>
          </ButtonsContainer>
        </RangePickerContainer>
      </Container>
    </Wrapper>
  )
}

export default DateRangePicker
