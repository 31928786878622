import { Dayjs } from "dayjs"
import styled from "styled-components/macro"

import { UITypes } from "types"

import { SelectStatic } from "components/form/SelectStatic"

import { timeSelectOptions } from "./timeSelectOptions"

const Container = styled.div`
  padding: 5px 18px 0;
  font-size: 12px;
`

const Label = styled.div`
  font-size: 14px;
`

const Description = styled.div`
  color: #64748b;
`

const TimeSelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 13px;
  padding-right: 20px;

  div:first-child {
    margin-right: 10px;
    width: 125px;
  }

  div {
    margin-bottom: 0 !important;
  }
`

const TimeIndicator = styled.div<{ selected: boolean }>`
  flex-shrink: 0;
  font-size: 14px;
  margin-left: 5px;
  padding: 4px 11px;
  border-radius: 32px;
  cursor: pointer;
  border: 1px solid #e2e8f0;
  line-height: 27px;

  ${({ selected }) =>
    selected &&
    `
  color: #2db2c0;
  border-color: #43e0f8;
  `};

  &:hover {
    color: #2db2c8;
    border-color: #43e0f8;
  }
`

export type TimeIndicator = "AM" | "PM"

interface SelectTimeContainerProps {
  selectedTime: UITypes.Option[]
  selectedDateTime: Dayjs | null
  timeIndicator: string
  onChangeOption: (option: UITypes.Option[]) => void
  onTimeIndicatorClick: (indicator: TimeIndicator) => void
}

const SelectTimeContainer = ({
  selectedTime,
  selectedDateTime,
  timeIndicator,
  onChangeOption,
  onTimeIndicatorClick,
}: SelectTimeContainerProps) => (
  <Container>
    <Label>Select a time</Label>
    <TimeSelectContainer>
      <SelectStatic options={timeSelectOptions} values={selectedTime} onChange={onChangeOption} />
      <TimeIndicator selected={timeIndicator === "AM"} onClick={() => onTimeIndicatorClick("AM")}>
        AM
      </TimeIndicator>
      <TimeIndicator selected={timeIndicator === "PM"} onClick={() => onTimeIndicatorClick("PM")}>
        PM
      </TimeIndicator>
    </TimeSelectContainer>
    <Description>
      The Performance test will be activated on selected device(s) based on their local time{" "}
      {selectedDateTime ? `at ${selectedDateTime.format("MMM DD, YYYY hh:mma")}` : ""}
    </Description>
  </Container>
)

export default SelectTimeContainer
