import { useDispatch } from "react-redux"

import { useAppSelector } from "store/hooks"
import { setModalContent } from "store/tempDataSlise"
import { ReactComponent as IconAlerts } from "assets/svg/menu-alerts.svg"

import { ModalLayout } from "layouts/ModalLayout"

import { PerformanceTestSelectedDevices } from "../components/performanceTestSelectedDevices"

import styles from "./index.module.scss"

interface PerformanceTestFailModalProps {
  onCancel: () => void
}

export const PerformanceTestFailModal = ({ onCancel }: PerformanceTestFailModalProps) => {
  const dispatch = useDispatch()
  const modalContent = useAppSelector(state => state.tempData.modalContent)

  const handleCancel = () => {
    onCancel()
    dispatch(setModalContent())
  }

  return (
    <>
      <ModalLayout width="524px" onCancel={handleCancel} cancelButtonText="Close" buttonSize="fullWidth">
        <div className={styles.modalBody}>
          <div className={styles.iconWrapper}>
            <IconAlerts />
          </div>
          <h3>{modalContent?.title}</h3>
          <p>{modalContent?.content}</p>
        </div>
        {modalContent?.devices?.length && <PerformanceTestSelectedDevices devices={modalContent.devices} />}
      </ModalLayout>
    </>
  )
}
