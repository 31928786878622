import { Link } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import { UITypes } from "types"
import { ReactComponent as IconArrow } from "assets/svg/icon-breadcrumb-arrow.svg"

import styles from "./index.module.scss"

interface Props {
  list?: UITypes.Breadcrumb[]
}

export const Breadcrumbs = ({ list }: Props) => {
  if (!list?.length) return null

  return (
    <div className={styles.breadcrumbsWrapper}>
      {list.map(item =>
        item.link ? (
          <Link key={uuidv4()} to={item.link} className={styles.breadcrumb}>
            {item.label} <IconArrow className={styles.breadcrumbIcon} />
          </Link>
        ) : (
          <span key={uuidv4()} className={styles.breadcrumb}>
            {item.label} <IconArrow className={styles.breadcrumbIcon} />
          </span>
        ),
      )}
    </div>
  )
}
