import { FormEvent, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import classNames from "classnames"

import { useLazyGetClientsQuery, useLazyUpdateClientQuery } from "store/api/clientApi"
import {
  useLazyGetCitiesQuery,
  useLazyGetCountriesQuery,
  useLazyGetDeviceLocationsQuery,
  useLazyGetResidentTypesQuery,
  useLazyGetStatesQuery,
  useLazyGetSuburbsQuery,
  useLazyGetZipCodesQuery,
} from "store/api/dictionaryApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setCurrentZip } from "store/tempDataSlise"
import { UITypes } from "types"
import { ReactComponent as IconHomeowner } from "assets/svg/modal-icon-edit-homeowner.svg"

import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter"
import { convertDataToOptions, convertEditDevicesToState } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import {
  editCustomerCommonSchema,
  editCustomerDeviceSchema,
  onFormValidate,
  validationInitialState,
} from "utils/onValidate"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"
import { InputNew } from "components/form/InputNew"
import { InputPhone } from "components/form/InputPhone"
import { SearchFnType, SelectDynamic } from "components/form/SelectDynamic"
import { SelectStatic } from "components/form/SelectStatic"

import TabsWrapper from "./TabsWrapper"

import styles from "./index.module.scss"

const commonValuesState: {
  fullName: string
  homeownerID: number | undefined
  email: string
  phone: string
  membership: UITypes.Option[]
} = {
  fullName: "",
  homeownerID: undefined,
  email: "",
  phone: "",
  membership: [{ value: "no", label: "No" }] as UITypes.Option[],
}

const deviceValuesState: UITypes.Device = {
  deviceName: "",
  address1: "",
  address2: "",
  serialNumber: "",
  installationType: [],
  zip: [],
  country: [],
  state: [],
  city: [],
  suburb: [],
  residentialType: [],
  deviceLocation: [],
}

interface Props {
  onCancel: () => void
}

export const EditCustomerModal = ({ onCancel }: Props) => {
  const [triggerUpdateClientQuery, { isFetching }] = useLazyUpdateClientQuery()
  const [triggerGetClients] = useLazyGetClientsQuery()
  const dispatch = useAppDispatch()
  const [devices, setDevices] = useState<UITypes.Device[]>([])
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [commonValues, setCommonValues] = useState(commonValuesState)
  const [deviceValues, setDeviceValues] = useState(deviceValuesState)
  const currentZip = useAppSelector(state => state.tempData.currentZip)
  const editableClient = useAppSelector(state => state.tempData.editableClient)
  const [error, setError] = useState(validationInitialState)
  const [errorDeviceID, setErrorDeviceID] = useState<number | undefined>(undefined)

  const onCancelClick = () => {
    dispatch(setCurrentZip(undefined))
    onCancel()
  }

  const onTabChange = (tab: number) => {
    if (tab === currentTab) return
    saveChanges()
    setCurrentTab(tab)
  }

  // UPDATE DEVICES STATE
  const saveChanges = () => {
    const _devices = devices?.map(obj => {
      if (obj?.id === deviceValues?.id) {
        return deviceValues
      }
      return obj
    })

    setDevices(_devices)
    return _devices
  }

  // EDIT VALUES
  const onCommonValuesChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setCommonValues(prevState => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onDeviceValuesChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setDeviceValues(prevState => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const getInstallationType = (device: UITypes.Device): string => {
    const type = device.installationType
    if (type) {
      if (typeof type === "string") {
        return type
      }

      return type[0]?.value as string
    }

    return ""
  }

  const prepareData = (devicesData: UITypes.Device[]) => {
    return {
      client: {
        full_name: commonValues.fullName,
        tel: commonValues.phone,
        email: commonValues.email,
        membership: commonValues.membership?.[0]?.value as string,
      },
      devices: devicesData?.map(item => {
        const installationType = getInstallationType(item)

        return {
          sn: item.serialNumber,
          name: item.deviceName || "",
          address1: item.address1,
          address2: item.address2,
          state: item.state?.[0] && Number(item.state?.[0]?.value),
          city: item.city?.[0] && Number(item.city?.[0]?.value),
          ...(item.suburb?.[0]?.value ? { suburb: item.suburb?.[0]?.value as string } : {}),
          country_id: item?.country?.[0] && Number(item?.country?.[0]?.value),
          zip_code: (item.zip?.[0] && item.zip?.[0]?.label) as string,
          where_installed_id: (item.deviceLocation?.[0] && Number(item.deviceLocation?.[0]?.value)) as number,
          resident_type_id: (item.residentialType?.[0] && Number(item.residentialType?.[0]?.value)) as number,
          installation_type: installationType,
        }
      }),
    }
  }

  // SEND REQUEST
  const sendRequest = async () => {
    try {
      const res = await triggerUpdateClientQuery({
        body: prepareData(saveChanges()),
        id: editableClient?.id as number,
      })
      const error = getErrorMessage(res?.error)

      if (res?.isSuccess) {
        toast.success("Customer has been successfully edited!")
        setCommonValues(commonValuesState)
        setDeviceValues(deviceValuesState)
        onCancel()
        dispatch(setCurrentZip(undefined))
        triggerGetClients({})
      }
      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault()
    const _devices = saveChanges()

    // VALIDATION
    if (!onFormValidate(commonValues, editCustomerCommonSchema, setError)) return

    const isAllow = _devices?.every(item => {
      const result = onFormValidate(item, editCustomerDeviceSchema, setError)

      if (!result) {
        setErrorDeviceID(item?.id)
      } else {
        setErrorDeviceID(undefined)
      }

      return result
    })

    if (!isAllow) return

    sendRequest()
  }

  // SET CURRENT TAB
  useEffect(() => {
    if (!devices?.length) return
    setDeviceValues(devices[currentTab])
  }, [currentTab, devices])

  // SWITCH TAB WHEN VALIDATION ERROR IS HAPPEN
  useEffect(() => {
    if (errorDeviceID !== deviceValues?.id && error?.field) {
      const index = devices.findIndex(item => item?.id === errorDeviceID)
      if (index !== -1) {
        setCurrentTab(index)
      }
    }
  }, [error, errorDeviceID])

  // FILL IN CITY AND STATE SELECT BASED ON ZIP
  useEffect(() => {
    if (currentZip?.city) {
      const city = convertDataToOptions({ data: [currentZip.city] }, "id", "name")
      const state = convertDataToOptions({ data: [currentZip.state] }, "id", "name")
      const country = convertDataToOptions({ data: [currentZip.country] }, "id", "name")
      const suburb = convertDataToOptions(
        { data: currentZip.suburbs?.length > 0 ? [currentZip.suburbs[0]] : null },
        "id",
        "name",
      )

      if (city && state) {
        setDeviceValues({
          ...deviceValues,
          ...{
            city,
            state,
            country,
            suburb,
          },
        })
      }
    }
  }, [currentZip])

  // INITIAL SET STATE
  useEffect(() => {
    setCommonValues({
      fullName: editableClient?.full_name ? editableClient?.full_name : "",
      homeownerID: editableClient?.id ? editableClient?.id : undefined,
      email: editableClient?.email ? editableClient?.email : "",
      phone: editableClient?.phone_number ? editableClient?.phone_number : "",
      membership: [
        {
          value: editableClient?.devices?.[0].membership || "",
          label: capitalizeFirstLetter(editableClient?.devices?.[0].membership),
        },
      ],
    })

    if (editableClient?.devices?.length) {
      const _devices = convertEditDevicesToState(editableClient?.devices)
      setDevices(_devices)
      setDeviceValues(_devices[0])
    }
  }, [])

  const searchFn: SearchFnType = ({ state, methods }) => {
    let search = state.search
    if (deviceValues.country[0].value === 2) {
      search = search.slice(0, 3)
    }

    return methods.sortBy().filter(item => item.label.toLowerCase().includes(search.toLowerCase()))
  }

  const handleCountryChange = (country: UITypes.Option[]) => {
    if (deviceValues.country?.[0]?.value === country[0]?.value) {
      return
    }

    setDeviceValues({ ...deviceValues, ...{ country, zip: [], state: [], city: [], suburb: [] } })
  }

  const renderCitySuburb = () => {
    const showSuburb = deviceValues.country?.[0]?.value === 3
    const entity = showSuburb ? "suburb" : "city"
    const query = showSuburb ? useLazyGetSuburbsQuery : useLazyGetCitiesQuery

    return (
      <>
        <span className={styles.modalFieldLabel}>{capitalizeFirstLetter(entity)}</span>
        <SelectDynamic
          key={entity}
          valueName="id"
          labelName="name"
          name={entity}
          useLazyQuery={query}
          placeholder={`Enter ${entity}`}
          values={showSuburb ? deviceValues.suburb : deviceValues.city}
          onChange={value => setDeviceValues({ ...deviceValues, ...{ [entity]: value } })}
          errorMessage={error.field === entity ? error.message : ""}
        />
      </>
    )
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="1252px"
          confirmButtonText="Save"
          confirmButtonType="submit"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          buttonSize="auto"
          isFetching={isFetching}
        >
          <ModalHeader title={`Edit Customer ${commonValues.homeownerID}`}>
            <IconHomeowner />
          </ModalHeader>
          <div className={styles.modalContent}>
            <div className={styles.modalContentWrapper}>
              <aside className={styles.modalSidebar}>
                <h4 className={styles.sidebarSubtitle}>Customer information</h4>
                <div className={styles.modalFieldWrapper}>
                  <span className={styles.modalFieldLabel}>Customer ID</span>
                  <span className={styles.modalFieldValue}>{commonValues.homeownerID}</span>
                </div>
                <div className={styles.modalFieldWrapper}>
                  <span className={styles.modalFieldLabel}>Full Name</span>
                  <InputNew
                    placeholder="Enter Full Name"
                    name="fullName"
                    value={commonValues.fullName}
                    onChange={onCommonValuesChange}
                    errorMessage={error.field === "fullName" ? error.message : ""}
                  />
                </div>
                <div className={styles.modalFieldWrapper}>
                  <span className={styles.modalFieldLabel}>Devices</span>
                  <span className={styles.modalFieldValue}>{editableClient?.devices?.length}</span>
                </div>
                <div className={classNames(styles.modalFieldWrapper, styles.inputLabelWrapper)}>
                  <span className={styles.modalFieldLabel}>Email</span>
                  <InputNew
                    label=""
                    className={styles.emailInput}
                    placeholder="Enter email"
                    name="email"
                    onChange={onCommonValuesChange}
                    value={commonValues?.email}
                    errorMessage={error.field === "email" ? error.message : ""}
                  />
                </div>
                <div className={classNames(styles.modalFieldWrapper, styles.inputLabelWrapper)}>
                  <span className={styles.modalFieldLabel}>Phone number</span>
                  <InputPhone
                    label=""
                    placeholder="Enter phone number"
                    name="phone"
                    onChange={value => setCommonValues({ ...commonValues, ...{ phone: value } })}
                    value={commonValues.phone}
                    errorMessage={error.field === "phone" ? error.message : ""}
                  />
                </div>
              </aside>
              <main className={styles.modalMain}>
                <TabsWrapper devices={devices} currentTab={currentTab} onTabChange={onTabChange} />
                <div className={styles.modalMainContent}>
                  <div className={styles.modalMainContentColumn}>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>Address 1</span>
                      <InputNew
                        placeholder="Enter Address"
                        name="address1"
                        value={deviceValues.address1}
                        onChange={onDeviceValuesChange}
                        errorMessage={error.field === "address1" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>Address 2</span>
                      <InputNew
                        placeholder="Enter Address"
                        name="address2"
                        value={deviceValues.address2}
                        onChange={onDeviceValuesChange}
                        errorMessage={error.field === "address2" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>Country</span>
                      <SelectDynamic
                        valueName="id"
                        labelName="name"
                        name="country"
                        useLazyQuery={useLazyGetCountriesQuery}
                        placeholder="Enter country"
                        values={deviceValues.country}
                        onChange={value => handleCountryChange(value)}
                        errorMessage={error.field === "country" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>{renderCitySuburb()}</div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>Zip Code</span>
                      <SelectDynamic
                        valueName="id"
                        labelName="code"
                        name="zip"
                        searchFieldName="code"
                        useLazyQuery={useLazyGetZipCodesQuery}
                        placeholder="Enter Zip Code"
                        values={deviceValues.zip}
                        searchFn={searchFn}
                        onChange={value => setDeviceValues({ ...deviceValues, ...{ zip: value } })}
                        queryParams={{ country_id: (deviceValues.country?.[0]?.value || 1) as unknown as number }}
                        errorMessage={error.field === "zip" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>State</span>
                      <SelectDynamic
                        valueName="id"
                        labelName="name"
                        placeholder="Enter state"
                        name="state"
                        useLazyQuery={useLazyGetStatesQuery}
                        values={deviceValues.state}
                        onChange={value => setDeviceValues({ ...deviceValues, ...{ state: value } })}
                        errorMessage={error.field === "state" ? error.message : ""}
                      />
                    </div>
                  </div>
                  <div className={styles.modalMainContentColumn}>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>Device name</span>
                      <InputNew
                        placeholder="Enter Device name"
                        name="deviceName"
                        value={deviceValues.deviceName}
                        onChange={onDeviceValuesChange}
                        errorMessage={error.field === "deviceName" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>S/N</span>
                      <span className={styles.modalFieldValue}>{deviceValues.serialNumber}</span>
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>Work status</span>
                      <span
                        className={classNames(styles.modalFieldValue, styles.capitalize, {
                          [styles.online]: deviceValues.workStatus === "online",
                        })}
                      >
                        {deviceValues.workStatus || "offline"}
                      </span>
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>Residence type</span>
                      <SelectDynamic
                        placeholder="Select residence type"
                        useLazyQuery={useLazyGetResidentTypesQuery}
                        valueName="id"
                        labelName="name"
                        values={deviceValues.residentialType}
                        onChange={value => setDeviceValues({ ...deviceValues, ...{ residentialType: value } })}
                        errorMessage={error.field === "residentialType" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>Installation Type</span>
                      <SelectStatic
                        placeholder="Select Type"
                        values={deviceValues.installationType as UITypes.Option[]}
                        options={[
                          {
                            value: "new",
                            label: "New",
                          },
                          {
                            value: "existing",
                            label: "Replacement",
                          },
                        ]}
                        onChange={value => setDeviceValues({ ...deviceValues, ...{ installationType: value } })}
                        errorMessage={error.field === "installationType" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>Device location</span>
                      <SelectDynamic
                        placeholder="Select device location"
                        useLazyQuery={useLazyGetDeviceLocationsQuery}
                        valueName="id"
                        labelName="name"
                        values={deviceValues.deviceLocation}
                        onChange={value => setDeviceValues({ ...deviceValues, ...{ deviceLocation: value } })}
                        errorMessage={error.field === "deviceLocation" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>Membership</span>
                      <SelectStatic
                        placeholder="Select Membership"
                        values={commonValues?.membership as UITypes.Option[]}
                        options={[
                          {
                            value: "yes",
                            label: "Yes",
                          },
                          {
                            value: "no",
                            label: "No",
                          },
                        ]}
                        onChange={value => setCommonValues({ ...commonValues, ...{ membership: value } })}
                        errorMessage={error.field === "membership" ? error.message : ""}
                      />
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
