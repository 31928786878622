import styles from "./index.module.scss"

const RADIUS = 20
const DEFAULT_WAI_ARIA_ATTRIBUTE = {
  "aria-busy": true,
  role: "progressbar",
}

const getViewBoxSize = (strokeWidth: number, secondaryStrokeWidth: number, radius: number): string => {
  const maxStrokeWidth = Math.max(strokeWidth, secondaryStrokeWidth)
  const startingPoint = -radius - maxStrokeWidth / 2 + 1
  const endpoint = radius * 2 + maxStrokeWidth
  return [startingPoint, startingPoint, endpoint, endpoint].join(" ")
}

const getPath = (radius: number): string => {
  return ["M" + radius + " 0c0-9.94-8.06", radius, radius, radius].join("-")
}

interface Props {
  width?: number
  height?: number
}

export const Spinner = ({ width = 28, height = 28 }: Props) => {
  const strokeWidth = 2
  const strokeWidthSecondary = 2

  return (
    <div style={{}} className={styles.spinnerWrapper} aria-label="oval-loading" {...DEFAULT_WAI_ARIA_ATTRIBUTE}>
      <svg
        width={width}
        height={height}
        viewBox={getViewBoxSize(Number(strokeWidth), Number(strokeWidthSecondary || strokeWidth), RADIUS)}
        xmlns="http://www.w3.org/2000/svg"
        stroke="#fff"
        data-testid="oval-svg"
      >
        <g fill="none" fillRule="evenodd">
          <g
            transform="translate(1 1)"
            strokeWidth={Number(strokeWidthSecondary || strokeWidth)}
            data-testid="oval-secondary-group"
          >
            <circle strokeOpacity=".5" cx="0" cy="0" r={RADIUS} stroke="#000" strokeWidth={strokeWidth} />
            <path d={getPath(RADIUS)}>
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 0 0"
                to="360 0 0"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
  )
}
