import { useEffect, useState } from "react"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import { useLazyGetMonitoringQuery } from "store/api/widgetsApi"

import { WidgetLayout } from "layouts/WidgetLayout"
import { RainbowChart } from "components/common/RainbowChart"
import { Spinner } from "components/common/Spinner"
import { Tooltip } from "components/common/Tooltip"

import styles from "./index.module.scss"

const initialState = {
  installedDevices: 0,
  installedDevicesInPercent: 0,
  purchasedDevices: 0,
}

export const WidgetMonitoring = () => {
  const [triggerGetMonitoring, { isFetching }] = useLazyGetMonitoringQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchMonitoringData = async () => {
      try {
        const res = await triggerGetMonitoring()
        if (res?.data) {
          const data = res.data.data

          setWidgetData({
            installedDevices: data?.installed_devices,
            purchasedDevices: data?.purchased_devices,
            installedDevicesInPercent:
              data?.purchased_devices === 0 ? 0 : (data?.installed_devices / data?.purchased_devices) * 100,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchMonitoringData()
  }, [])

  return (
    <WidgetLayout title="Monitoring" className={styles.widgetMembership}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <RainbowChart
            colors={["#43E0F8", "#F1F5F9"]}
            values={[widgetData.installedDevices, widgetData.purchasedDevices - widgetData.installedDevices]}
            className={styles.chart}
          />
        </div>
        <div className={styles.widgetMainValue}>
          <Tooltip id={uuidv4()} text="INSTALLED">
            % of purchased and installed devices <br />
            (Missing and Warranty devices are excluded)
          </Tooltip>
          <div className={styles.widgetValueWrapper}>{widgetData.installedDevicesInPercent.toFixed()}%</div>
        </div>
        <footer className={styles.widgetFooter}>
          <div className={classNames(styles.widgetFooterItem)}>
            <Tooltip id={uuidv4()} text="Purchased">
              Device(s) under Missing and Warranty status are excluded from the count.
            </Tooltip>
            <div className={styles.widgetValueWrapper}>{widgetData.purchasedDevices}</div>
          </div>
          <div className={classNames(styles.widgetFooterItem)}>
            <span className={styles.widgetNote}>Installed</span>
            <div className={styles.widgetValueWrapper}>{widgetData.installedDevices}</div>
          </div>
        </footer>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
