import { flexRender, Table } from "@tanstack/react-table"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import { UITypes } from "types"

import { TableRowNavigation } from "./TableRowNavigation"

import styles from "./index.module.scss"

interface TableBodyProps {
  tableName?: string
  table: Table<UITypes.TableBodyRow>
  currentItemID?: number | string | null
  currentItemIDs?: number[] | string[] | undefined
  onDeleteClick?: (id: number) => void
  onEditClick?: (id: number) => void
  onRowClick?: (id: number) => void
  dynamicNavHandler1?: (id: number) => void
  dynamicNavHandler2?: (id: number) => void
  dynamicNavHandler3?: (id: number) => void
}

const TableBody = ({
  tableName,
  table,
  currentItemID,
  currentItemIDs,
  onEditClick,
  onDeleteClick,
  onRowClick,
  dynamicNavHandler1,
  dynamicNavHandler2,
  dynamicNavHandler3,
}: TableBodyProps) => {
  return (
    <div className={styles.tableBody}>
      {table.getRowModel().rows.map(row => {
        return (
          <div
            key={uuidv4()}
            className={classNames(styles.tableRow, {
              [styles.active]: currentItemIDs?.includes(row.id as never) || currentItemID === row.id,
              [styles.redFlag]: !!row?.original.rowSettings.isRedFlag,
              [styles.clickableRow]: row?.original.rowSettings.isRowClickable,
            })}
            onClick={onRowClick ? () => onRowClick(row.original.rowSettings.id) : undefined}
          >
            {row.getVisibleCells().map(cell => {
              const cellProps = cell.getValue() as UITypes.TableBodyCell
              if (cellProps.hidden) {
                return null
              }

              return (
                <div
                  className={classNames(styles.tableCell, styles.withoutSizeSettings, {
                    [styles.tableCellCheckbox]: cell.column.id === "checkbox",
                    [styles.groupHeader]: cellProps.groupHeader,
                  })}
                  key={cell.id}
                  style={{ width: `${cell.column.getSize()}px` }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              )
            })}
            <TableRowNavigation
              tableName={tableName}
              rowData={row.original}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              customClassName={styles.rowNavVisibility}
              dynamicNavHandler1={dynamicNavHandler1}
              dynamicNavHandler2={dynamicNavHandler2}
              dynamicNavHandler3={dynamicNavHandler3}
            />
          </div>
        )
      })}
    </div>
  )
}

export default TableBody
