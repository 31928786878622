import { PropsWithChildren, useEffect } from "react"
import classNames from "classnames"

import { UITypes } from "types"

import { Spinner } from "components/common/Spinner"
import { ButtonNew } from "components/form/ButtonNew"

import styles from "./index.module.scss"

interface Props extends PropsWithChildren {
  width: string
  confirmButtonColor?: UITypes.ButtonColor
  confirmButtonText?: string
  cancelButtonText?: string
  buttonSize?: UITypes.ButtonSize
  isFetching?: boolean
  isWithoutFooter?: boolean
  isDisabled?: boolean
  isDrawer?: boolean
  confirmButtonType?: "submit"
  closeIconClassName?: string
  footerClassName?: string
  className?: string
  onExport?: () => void
  onCancel?: () => void
  onClose?: () => void
  onConfirm?: (event: React.FormEvent<HTMLElement>) => void
}

export const ModalLayout = ({
  children,
  width,
  isFetching,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  confirmButtonColor = "blue",
  confirmButtonType,
  isWithoutFooter,
  buttonSize,
  isDisabled,
  isDrawer,
  closeIconClassName,
  footerClassName,
  className,
  onConfirm,
  onCancel,
  onClose,
  onExport,
}: Props) => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden"

    return () => {
      if (document.getElementsByClassName("modalViewPort").length === 0) {
        document.getElementsByTagName("body")[0].style.overflow = ""
      }
    }
  }, [])

  return (
    <>
      <form
        onSubmit={onConfirm}
        className={classNames(styles.layout, className, {
          [styles.modal]: !isDrawer,
          [styles.drawer]: isDrawer,
        })}
        style={{ maxWidth: `${width}` }}
      >
        <span className={classNames(styles.closeIcon, closeIconClassName)} onClick={onClose ? onClose : onCancel} />
        {children}
        {!isWithoutFooter && (
          <footer
            className={classNames(styles.modalFooter, footerClassName, {
              [styles.buttonsAutoWidth]: buttonSize === "auto",
            })}
          >
            {onExport && (
              <ButtonNew onClick={onExport} size="auto" color="blueBordered" className={styles.additionalButton}>
                Export
              </ButtonNew>
            )}
            {onCancel && (
              <ButtonNew onClick={onCancel} size={buttonSize ? buttonSize : "halfWidth"} color="greyBordered">
                {cancelButtonText}
              </ButtonNew>
            )}
            {onConfirm && (
              <ButtonNew
                onClick={onConfirm}
                size={buttonSize ? buttonSize : "halfWidth"}
                type={confirmButtonType}
                disabled={isDisabled || isFetching}
                color={confirmButtonColor}
              >
                {isFetching ? <Spinner /> : confirmButtonText}
              </ButtonNew>
            )}
          </footer>
        )}
      </form>
      <div className={classNames(styles.viewport, "modalViewPort")} onClick={onClose ? onClose : onCancel}></div>
    </>
  )
}
