import { FormEvent, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import dayjs from "dayjs"

import { useLazyLoginQuery } from "store/api/authApi"
import { useLazyGetUserProfileQuery } from "store/api/profileApi"
import { useAppDispatch } from "store/hooks"
import { setUserAuthorized, setUserProfile, setUserTemperatureScale } from "store/userSlise"

import getErrorMessage from "utils/getErrorMessage"
import { onFormValidate, signInSchema, validationInitialState } from "utils/onValidate"
import { storageKeys } from "utils/storageKeys"
import { Spinner } from "components/common/Spinner"
import { AuthForm } from "components/form/AuthForm"
import { Button } from "components/form/Button"
import { Input } from "components/form/Input"

import styles from "./index.module.scss"

const inputValuesInitialState = {
  email: "",
  password: "",
}

export const SignIn = () => {
  const navigate = useNavigate()
  const [triggerLogin, { isFetching, data }] = useLazyLoginQuery()
  const [triggerGetProfile] = useLazyGetUserProfileQuery()
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const dispatch = useAppDispatch()

  const [error, setError] = useState(validationInitialState)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues(prevState => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const getProfile = async () => {
    try {
      const res = await triggerGetProfile()
      if (!res.data?.data) {
        return
      }

      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        const lastSync = dayjs()
        const data = { ...res?.data?.data, lastSync }
        const userTemperatureScale = localStorage.getItem(storageKeys.userTemperatureScale) as
          | "celsius"
          | "fahrenheit"
          | null
        const temperatureScale = data?.contractor?.country?.id === 1 ? "fahrenheit" : "celsius"
        localStorage.setItem(storageKeys.userProfile, JSON.stringify(data))

        if (!userTemperatureScale) {
          localStorage.setItem(storageKeys.userTemperatureScale, temperatureScale)
        }
        dispatch(setUserProfile(data))
        dispatch(setUserAuthorized(true))
        dispatch(setUserTemperatureScale(userTemperatureScale || temperatureScale))
        navigate("/")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (onFormValidate(inputValues, signInSchema, setError)) {
      try {
        const res = await triggerLogin({
          username: inputValues.email,
          password: inputValues.password,
        })
        const error = getErrorMessage(res?.error)

        if (error) {
          toast.error(error)
        }
      } catch (e) {
        const error = e as Error
        toast.error(error?.message)
      }
    }
  }

  useEffect(() => {
    if (data?.token) {
      localStorage.setItem(storageKeys.token, data?.token)
      getProfile()
      setInputValues(inputValuesInitialState)
    }
  }, [data?.token])

  return (
    <>
      <Helmet>
        <title>Nuve - SignIn</title>
      </Helmet>
      <div>
        <AuthForm title="Log In" subtitle="Welcome back! Please enter your details." onSubmit={onSubmit}>
          <Input
            label="Email / Username"
            placeholder="Enter email or username"
            name="email"
            value={inputValues.email}
            onChange={onInputChange}
            errorMessage={error.field === "email" ? error.message : ""}
          />
          <Input
            label="Password"
            placeholder="Enter password"
            inputType="password"
            name="password"
            value={inputValues.password}
            onChange={onInputChange}
            errorMessage={error.field === "password" ? error.message : ""}
          />
          <Link className={styles.forgotPasswordLink} to="/auth/forgot-password">
            Forgot password
          </Link>
          <div className={styles.authButtonsWrapper}>
            <Button onClick={onSubmit} type="submit" disabled={isFetching} color="colored" size="fullWidth">
              {isFetching ? <Spinner width={38} height={38} /> : "Log in"}
            </Button>
          </div>
        </AuthForm>
      </div>
    </>
  )
}
