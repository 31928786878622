import dayjs from "dayjs"
import styled from "styled-components/macro"

import { useAppSelector } from "store/hooks"

import { InputNumber } from "components/form/InputNumber"

import styles from "./index.module.scss"

const QuantityContainer = styled.div`
  position: relative;
  top: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;

  > span {
    font-size: 12px;
    color: #94a3b8;
  }
`

interface OrdersModalContentProps {
  isNewOrder?: boolean
  quantity: number
  setQuantity: (value: number) => void
}

const OrdersModalContent = ({ isNewOrder, quantity, setQuantity }: OrdersModalContentProps) => {
  const userProfile = useAppSelector(state => state.user.userProfile)

  return (
    <div className={styles.modalContent}>
      <div className={styles.orderDetails}>
        <div className={styles.orderDetailsItem}>
          <span className={styles.orderDetailsLabel}>Contractor ID</span>
          <span className={styles.orderDetailsValue}>{userProfile?.contractor?.id}</span>
        </div>
        <div className={styles.orderDetailsItem}>
          <span className={styles.orderDetailsLabel}>Creation date</span>
          <span className={styles.orderDetailsValue}>{dayjs().format("MM-DD-YYYY")}</span>
        </div>
        <div className={styles.orderDetailsItem}>
          <span className={styles.orderDetailsLabel}>Company name</span>
          <span className={styles.orderDetailsValue}>{userProfile?.contractor?.name}</span>
        </div>
      </div>
      <div className={styles.separator} />
      <div className={styles.deviceWrapper}>
        <div className={styles.deviceQtyWrapper}>
          <span className={styles.deviceName}>Nuve Thermostats</span>
          <span className={styles.deviceQty}>Qty: {quantity}</span>
        </div>
        <QuantityContainer>
          <InputNumber minValue={15} onInputChange={setQuantity} value={quantity} />
          <span>
            Minimal quantity: 15 thermostats.
            <br />
            For less contact Nuve team.
          </span>
        </QuantityContainer>
      </div>
      {isNewOrder && (
        <div className={styles.orderNote}>
          The recently placed order will be visible in the Orders list under the “NEW” status. You can monitor any
          changes to your order’s status through Orders list.
        </div>
      )}
    </div>
  )
}

export default OrdersModalContent
