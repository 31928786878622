import { ScriptableContext, ScriptableLineSegmentContext } from "chart.js"
import dayjs, { Dayjs } from "dayjs"

import { ApiTypes } from "types"

export const getChartGradient = (context: ScriptableContext<"line">, startColor: string, endColor: string) => {
  const ctx = context.chart.ctx
  const gradient = ctx.createLinearGradient(0, 0, 0, 200)

  gradient.addColorStop(0, startColor)
  gradient.addColorStop(1, endColor)
  return gradient
}

export const defaultChartTooltipOptions = {
  caretSize: 0,
  caretPadding: 6,
  xAlign: "center" as const,
  yAlign: "bottom" as const,
  displayColors: false,
}

export const borderDashWithSkipped = (ctx: ScriptableLineSegmentContext) => (ctx.p0.skip || ctx.p1.skip ? [4, 2] : [])

export const borderColorWithSkipped = (ctx: ScriptableLineSegmentContext) =>
  ctx.p0.skip || ctx.p1.skip ? "#cccccc" : undefined

const createEmptyHistoricalData = (itemsCount: number) =>
  [...Array(itemsCount)].map(() => ({
    key_as_string: "",
    "avg#average_current_temperature_embedded": { value: null },
    "avg#average_current_humidity_embedded": { value: null },
    "avg#average_current_air_quality": { value: null },
    "avg#average_set_temperature": { value: null },
    "avg#average_set_humidity": { value: null },
    "filters#current_cooling_stage_count": { buckets: { count1: { doc_count: 0 }, count2: { doc_count: 0 } } },
    "filters#current_heating_stage_count": {
      buckets: { count1: { doc_count: 0 }, count2: { doc_count: 0 }, count3: { doc_count: 0 } },
    },
    "filters#running_mode_count": {
      buckets: {
        auto: { doc_count: 0 },
        cooling: { doc_count: 0 },
        emergency_heat: { doc_count: 0 },
        heating: { doc_count: 0 },
        off: { doc_count: 0 },
        vacation: { doc_count: 0 },
      },
    },
  }))

export const fillMonitoringMissingHistoricalData = (
  allHistoricalData: ApiTypes.Res.MonitoringHistoricalDataBucket[],
  startDateTimestamp: Dayjs,
  intervalInMinutes: number,
  itemsCount: number,
  isCustom: boolean,
) => {
  const historicalData = allHistoricalData.slice(0, itemsCount)
  if (historicalData.length === 0) {
    return [] as ApiTypes.Res.MonitoringHistoricalDataBucket[]
  }

  const firstItemDateTime = dayjs(historicalData[0].key_as_string)
  const diff = firstItemDateTime.diff(startDateTimestamp, "minutes")
  const missingItemsFromStart = Math.max(Math.floor(diff / intervalInMinutes) - (isCustom ? 1 : 0), 0)
  const missingItemsFromEnd = Math.max(itemsCount - historicalData.length - missingItemsFromStart, 0)

  return [
    ...createEmptyHistoricalData(missingItemsFromStart),
    ...historicalData,
    ...createEmptyHistoricalData(missingItemsFromEnd),
  ] as ApiTypes.Res.MonitoringHistoricalDataBucket[]
}

export const fillMissingData = (data: { [key: string]: number }, startDateTimestamp: Dayjs, daysCount: number) => {
  const dataKeys = Object.keys(data)
  const firstItemDateTime = dataKeys[0]
  const firstItemDate = dayjs.utc(firstItemDateTime)
  const diff = firstItemDate.diff(startDateTimestamp, "days")
  const missingItemsFromStart = Math.max(diff, 0)

  const endDate = dayjs.utc(dataKeys[dataKeys.length - 1])
  const historicalDataRange = endDate.diff(firstItemDate, "days") + 1

  const parsedData = [...Array(historicalDataRange)].map((_, index) => {
    const date = firstItemDate.add(index, "days").format("YYYY-MM-DD")
    return data[date] ? { date, count: data[date] } : { date, count: 0 }
  })

  const missingItemsFromEnd = Math.max(daysCount - parsedData.length - missingItemsFromStart, 0)

  return [
    ...[...Array(missingItemsFromStart)].map((_, index) => ({
      date: startDateTimestamp.add(index, "days").format("YYYY-MM-DD"),
      count: 0,
    })),
    ...parsedData,
    ...[...Array(missingItemsFromEnd)].map((_, index) => ({
      date: endDate.add(index + 1, "days").format("YYYY-MM-DD"),
      count: 0,
    })),
  ]
}
