import { CellContext } from "@tanstack/react-table"
import classNames from "classnames"

import { UITypes } from "types"

import styles from "./index.module.scss"

interface Props {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}

export const StatusOfWHOrder = ({ cell: { getValue } }: Props) => {
  const type = getValue().value ? String(getValue().value) : ""
  let label = ""

  switch (type) {
    case "new":
      label = "NEW"
      break

    case "pending":
      label = "PENDING"
      break

    case "denied":
      label = "DENIED"
      break

    case "confirmed":
      label = "CONFIRMED"
      break

    case "packed":
      label = "PACKED"
      break

    case "shipping":
      label = "SHIPPING"
      break

    case "archived":
      label = "ARCHIVED"
      break

    case "canceled":
      label = "CANCELED"
      break

    default:
      label = type
      break
  }

  return (
    <span
      className={classNames(styles.alertStatus, {
        [styles[type]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
