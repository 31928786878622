import { CellContext } from "@tanstack/react-table"
import classNames from "classnames"

import { UITypes } from "types"

import styles from "./index.module.scss"

interface Props {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}

export const StatusOfAlert = ({ cell: { getValue } }: Props) => {
  const type = getValue().value ? String(getValue().value) : ""
  let label = ""

  switch (type) {
    case "active":
      label = "ACTIVE"
      break

    case "frozen":
      label = "FROZEN"
      break

    case "acknowledged":
      label = "ACKNOWLEDGED"
      break

    default:
      label = type
      break
  }

  return (
    <span
      className={classNames(styles.alertStatus, {
        [styles[type]]: type ? true : false,
      })}
    >
      {label}
    </span>
  )
}
