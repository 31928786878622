const parseFormattedValueToTime = (formattedValue: string, dataType: string): string => {
  const value = Number(formattedValue)

  if (dataType === "minutes") {
    const minutes = Math.floor(value)
    return `${minutes === 0 ? "" : `${minutes}m`}`
  }

  const hours = Math.floor(value)
  const minutes = Math.floor((value - hours) * 60)

  return `${hours === 0 ? "" : `${hours}h`} ${minutes}m`
}

export default parseFormattedValueToTime
