import SubscriptionPlan from "../types/enums/SubscriptionPlan"

const SubscriptionPlanTitles: Record<SubscriptionPlan, string> = {
  [SubscriptionPlan.CONNECT]: "Connect",
  [SubscriptionPlan.NETWORK]: "Network",
  [SubscriptionPlan.PLUS]: "Plus",
  [SubscriptionPlan.BASIC]: "Basic",
  [SubscriptionPlan.GO]: "Go",
}

export default SubscriptionPlanTitles
