import { useEffect, useState } from "react"
import classNames from "classnames"

import { useLazyGetDeviceStatusesQuery } from "store/api/warehouseManagerApi"

import { WidgetLayout } from "layouts/WidgetLayout"
import { BarChart } from "components/common/BarChart"
import { Spinner } from "components/common/Spinner"

import styles from "./index.module.scss"

const initialState = {
  inStock: 0,
  damaged: 0,
  missing: 0,
  warranty: 0,
  packed: 0,
}

export const WidgetDevicesStatus = () => {
  const [triggerGetDeviceStatuses, { isFetching, data }] = useLazyGetDeviceStatusesQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  const sumObjectValues = (obj: Record<string, number>) => {
    let sum = 0
    for (const key in obj) {
      if (obj[key]) {
        sum += obj[key]
      }
    }
    return sum
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetDeviceStatuses()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (data?.data) {
      setWidgetData({
        inStock: data?.data?.in_stock,
        damaged: data?.data?.damaged,
        missing: data?.data?.missing,
        warranty: data?.data?.warranty,
        packed: data?.data?.packed,
      })
    }
  }, [data])

  return (
    <WidgetLayout title="Status" className={styles.devicesWrapper} tag={sumObjectValues(widgetData)}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <BarChart
          items={[
            {
              value: widgetData.inStock,
              color: "#4ADE80",
            },
            {
              value: widgetData.missing,
              color: "#FDE047",
            },
            {
              value: widgetData.damaged,
              color: "#EF4444",
            },
            {
              value: widgetData.warranty,
              color: "#43E0F8",
            },
            {
              value: widgetData.packed,
              color: "#A855F7",
            },
          ]}
        />
        <footer className={classNames(styles.widgetFooter, styles.devicesFooter)}>
          <div className={styles.widgetFooterItem}>
            <span className={classNames(styles.valueNote, styles.green)}>In Stock</span>
            <div className={styles.widgetValueWrapper}>{widgetData.inStock}</div>
          </div>
          <div className={styles.widgetFooterItem}>
            <span className={classNames(styles.valueNote, styles.yellow)}>Missing</span>
            <div className={styles.widgetValueWrapper}>{widgetData.missing}</div>
          </div>
          <div className={styles.widgetFooterItem}>
            <span className={classNames(styles.valueNote, styles.red)}>Damaged</span>
            <div className={styles.widgetValueWrapper}>{widgetData.damaged}</div>
          </div>
          <div className={styles.widgetFooterItem}>
            <span className={classNames(styles.valueNote, styles.turquoise)}>Warranty</span>
            <div className={styles.widgetValueWrapper}>{widgetData.warranty}</div>
          </div>
          <div className={styles.widgetFooterItem}>
            <span className={classNames(styles.valueNote, styles.purple)}>Packed</span>
            <div className={styles.widgetValueWrapper}>{widgetData.packed}</div>
          </div>
        </footer>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
