import styled from "styled-components/macro"

import { Spinner } from "components/common/Spinner"

const WidgetLoader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 20px 0 6px;
  border-right: 1px solid #f1f5f9;

  &:last-child {
    border-right: none;
  }

  h4 {
    font-size: 12px;
    color: #64748b;
  }

  span {
    font-size: 24px;
    font-weight: 500;
    color: #0f172a;
  }
`

interface WidgetContentProps {
  isFetching: boolean
  data: { title: string; value: number }[]
}

const WidgetContent = ({ isFetching, data }: WidgetContentProps) => {
  return (
    <>
      {isFetching && (
        <WidgetLoader>
          <Spinner />
        </WidgetLoader>
      )}
      <Container>
        {data?.map(({ title, value }) => (
          <Item key={title}>
            <h4>{title}</h4>
            <span>{value}</span>
          </Item>
        ))}
      </Container>
    </>
  )
}

export default WidgetContent
