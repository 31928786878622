import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import { ApiTypes } from "types"

import { Spinner } from "components/common/Spinner"
import { Tooltip } from "components/common/Tooltip"

import styles from "./index.module.scss"

interface AlertsWidgetData {
  widgetData: ApiTypes.Model.AlertsWidgetData
  isFetching: boolean
}

export const WidgetAlerts = ({ widgetData, isFetching }: AlertsWidgetData) => (
  <div className={classNames(styles.alertsWidgetWrapper)}>
    <header className={styles.widgetHeader}>
      <h3 className={styles.widgetTitle}>Alerts</h3>
      <div className="">
        <span className={styles.summaryIndicators}>
          <i>{widgetData.enrolledDevicesCount}</i> Total Enrolled Devices
        </span>
        <span className={styles.summaryIndicators}>
          <i>{widgetData.devicesWithActiveAlerts}</i> Devices With Alerts
        </span>
        <span className={styles.summaryIndicators}>
          <i>{widgetData.alertsCount}</i> Alerts
        </span>
      </div>
    </header>
    <div className={styles.dataWrapper}>
      <div className={styles.indicatorsWrapper}>
        <span className={styles.indicators}>
          <Tooltip id={uuidv4()} text="System Efficiency" miniText={"(coming soon)"}>
            System failed to reach the set temperature
          </Tooltip>
          <i>{widgetData.temperature}</i>
        </span>
        <span className={styles.indicators}>
          Air Quality Sensor Malfunction <i>{widgetData.airQuality}</i>
        </span>
        <span className={styles.indicators}>
          Ambient Sensor Malfunction <i>{widgetData.ambientSensorMalfunction}</i>
        </span>
        <span className={styles.indicators}>
          Fan Malfunction <i>{widgetData.fanMalfunction}</i>
        </span>
        <span className={styles.indicators}>
          Humidity Sensor Malfunction <i>{widgetData.humiditySensorMalfunction}</i>
        </span>
        <span className={styles.indicators}>
          Temperature Sensor Malfunction <i>{widgetData.temperatureSensorMalfunction}</i>
        </span>
      </div>
      {isFetching && (
        <div className={styles.widgetLoader}>
          <Spinner />
        </div>
      )}
    </div>
  </div>
)
