import { useState } from "react"
import { createPortal } from "react-dom"
import classNames from "classnames"

import { useAppDispatch, useAppSelector } from "store/hooks"
import { setUserTemperatureScale } from "store/userSlise"

import { storageKeys } from "utils/storageKeys"
import { ModalLayout } from "layouts/ModalLayout"
import ModalSectionTitle from "components/common/ModalSectionTitle/ModalSectionTitle"
import { ToggleButton } from "components/form/ToggleButton"

import LiveDateView from "./LiveDataView"
import { StatsReportChartsWrapper } from "./StatsReportChartsWrapper"

import styles from "./index.module.scss"

interface Props {
  onCancel: () => void
}

export const StatsReportModal = ({ onCancel }: Props) => {
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = useState("liveData")
  const device = useAppSelector(state => state.tempData.selectedDeviceForMonitoringReport)
  const userTemperatureScale = useAppSelector(state => state.user.userTemperatureScale)
  const isCelsius = userTemperatureScale === "celsius"

  if (!device) return null

  const handleChangeTempToggle = () => {
    const scale = isCelsius ? "fahrenheit" : "celsius"
    localStorage.setItem(storageKeys.userTemperatureScale, scale)
    dispatch(setUserTemperatureScale(scale))
  }

  return (
    <>
      {createPortal(
        <ModalLayout buttonSize="auto" onClose={onCancel} width="770px" isDrawer isWithoutFooter>
          <div className={styles.modalContent}>
            <h3 className={styles.modalTitle}>Stats Report</h3>
          </div>
          <div className={styles.tempToggleWrapper}>
            <span>°C</span>
            <ToggleButton
              className={styles.tempToggle}
              id="monitoringTemperature"
              name="monitoringTemperature"
              isChecked={!isCelsius}
              onChange={handleChangeTempToggle}
            />
            <span>°F</span>
          </div>
          <ModalSectionTitle title="Details" />
          <div className={styles.statsScrollableArea}>
            <div className={classNames(styles.monitoringReportContent)}>
              <div className={classNames(styles.devicesDataContainer)}>
                <div className={styles.devicesDataItem}>
                  <h4 className={styles.modalSubtitle}>Customer</h4>
                  <div className={styles.devicesDataText}>{device?.homeowner_name || "-"}</div>
                </div>
                <div className={styles.devicesDataItem}>
                  <h4 className={styles.modalSubtitle}>Zip Code</h4>
                  <div className={styles.devicesDataText}>{device?.zip?.code || "-"}</div>
                </div>
                <div className={styles.devicesDataItem}>
                  <h4 className={styles.modalSubtitle}>Email</h4>
                  <div className={styles.devicesDataText}>{device?.email || "-"}</div>
                </div>
                <div className={styles.devicesDataItem}>
                  <h4 className={styles.modalSubtitle}>Device S/N</h4>
                  <div className={styles.devicesDataText}>{device?.sn}</div>
                </div>
                {/* <div className={styles.devicesDataItem}>
                  <h4 className={styles.modalSubtitle}>Mobile App</h4>
                  <div className={styles.devicesDataText}>Activated</div>
                </div> */}
              </div>
            </div>
            <div className={styles.drawerTabsWrapper}>
              <div className={styles.drawerTabs}>
                <div
                  className={classNames(styles.drawerTab, { [styles.active]: activeTab === "liveData" })}
                  onClick={() => setActiveTab("liveData")}
                >
                  Live Data
                </div>
                <div
                  className={classNames(styles.drawerTab, { [styles.active]: activeTab === "reporting" })}
                  onClick={() => setActiveTab("reporting")}
                >
                  Reporting
                </div>
              </div>
            </div>
            {activeTab === "liveData" ? (
              <LiveDateView device={device} isCelsius={isCelsius} />
            ) : (
              <StatsReportChartsWrapper device={device} isCelsius={isCelsius} />
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
