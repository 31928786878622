import { useRef } from "react"
import { Tooltip } from "react-tooltip"
import { CellContext } from "@tanstack/react-table"
import classNames from "classnames"

import { UITypes } from "types"

import styles from "./index.module.scss"

interface Props {
  cell: CellContext<UITypes.TableBodyRow, UITypes.TableBodyCell>
}

export const OptedInOut = ({ cell: { getValue } }: Props) => {
  const option = getValue().value ? String(getValue().value) : ""
  const element = useRef(null)

  return (
    <>
      <Tooltip id="optInOut" ref={element} classNameArrow={styles.tooltipArrow} className={styles.tooltip}>
        {option === "Opted-in" ? (
          <p>The customer has agreed to receive marketing messages sent from the Messaging Center.</p>
        ) : (
          <p>The customer has chosen not to receive any marketing messages from the Messaging Center.</p>
        )}
      </Tooltip>
      <span data-tooltip-id={"optInOut"} className={classNames(styles.optInOutStatus, styles[option])}>
        {option}
      </span>
    </>
  )
}
