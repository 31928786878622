import { useEffect, useState } from "react"

import { ApiTypes } from "types"

import useUrlQueryParams from "hooks/useUrlQueryParams"

import { PaginationDropdown } from "../PaginationDropdown"
import PaginationItems from "./PaginationItems"

import styles from "./index.module.scss"

interface Props {
  meta?: ApiTypes.Model.Pagination
}

export const Pagination = ({ meta }: Props) => {
  const { setUrlQueryParams } = useUrlQueryParams()
  const [data, setData] = useState({
    currentPage: 0,
    from: 0,
    lastPage: 0,
    perPage: 15,
  })

  const onPageChange = (value: number) => {
    setUrlQueryParams({ page: value })
    setData(prevStats => ({
      ...prevStats,
      page: value,
    }))
  }

  const onPerPageChange = (value: number) => {
    setData(prevStats => ({
      ...prevStats,
      perPage: value,
    }))

    if (data.perPage < value) {
      setData(prevStats => ({
        ...prevStats,
        page: 1,
      }))

      setUrlQueryParams({
        perPage: value,
        page: 1,
      })
    } else {
      setUrlQueryParams({
        perPage: value,
      })
    }
  }

  useEffect(() => {
    if (!meta) return

    const { current_page, from, last_page, per_page } = meta

    setData({
      currentPage: current_page,
      lastPage: last_page,
      perPage: per_page,
      from,
    })

    if (current_page > last_page) {
      onPageChange(last_page)
    }
  }, [meta])

  return (
    <div className={styles.paginationWrapper}>
      <div className={styles.paginationText}>
        Page {data.currentPage} of {data.lastPage}
      </div>
      <PaginationItems currentPage={data.currentPage} totalPages={data.lastPage} onPageChange={onPageChange} />
      <PaginationDropdown onSelect={onPerPageChange} currentNumber={data.perPage} numbersOfPage={[50, 30, 15, 5]} />
    </div>
  )
}
