import { FormEvent, useState } from "react"
import classNames from "classnames"

import styles from "./index.module.scss"

interface Props {
  inputType?: "password" | "text"
  placeholder?: string
  label?: string
  errorMessage?: string
  value?: string
  name?: string
  id?: string
  onChange?: (event: FormEvent<HTMLInputElement>) => void
}

export const Input = ({ inputType = "text", placeholder, label, errorMessage, value, name, id, onChange }: Props) => {
  const [isPasswordShown, togglePassword] = useState(true)

  return (
    <div className={styles.fieldWrapper}>
      <div className={styles.fieldLabel}>{label}</div>
      <div className={styles.field}>
        <input
          className={classNames(styles.input, { [styles.error]: errorMessage })}
          type={inputType === "password" ? (isPasswordShown ? "password" : "text") : "text"}
          placeholder={placeholder}
          value={value}
          name={name}
          id={id}
          onChange={onChange}
        />
        {inputType === "password" && value && (
          <span
            className={classNames(styles.iconButton, {
              [styles.showIcon]: isPasswordShown,
              [styles.hideIcon]: !isPasswordShown,
            })}
            onClick={() => togglePassword(!isPasswordShown)}
          />
        )}
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
