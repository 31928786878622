import { FormEvent, useState } from "react"

import { UITypes } from "types"
import { ReactComponent as IconClear } from "assets/svg/icon-input-sn-clear.svg"

import { isValidDeviceSn } from "utils/isValidDeviceSn"

import { InputNew } from "../InputNew"

import styles from "./index.module.scss"

interface Props extends UITypes.InvalidDevice {
  onChange: (data: UITypes.InvalidDevice) => void
  onDelete: (id: string) => void
  onBlur: (data: UITypes.InvalidDevice) => void
  autoFocus?: boolean
}

export const InputSN = ({ uid, sn, row, errorMessage, autoFocus, onBlur, onDelete, onChange }: Props) => {
  const [value, setValue] = useState(sn)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value
    const parsedValue = value?.replace(",", "")
    if (isValidDeviceSn(parsedValue)) {
      onBlur?.({
        uid,
        row,
        errorMessage,
        sn: parsedValue || "",
      })
      setValue("")
    } else {
      setValue(event?.currentTarget?.value)
      onChange({
        uid,
        row,
        errorMessage: "",
        sn: event?.currentTarget?.value,
      })
    }
  }

  return (
    <div className={styles.inputWrapper}>
      <div className={styles.inputNumber}>{row}</div>
      <InputNew
        className={styles.inputSN}
        placeholder="Enter S/N"
        name="sn"
        autoFocus={autoFocus}
        value={value}
        onChange={onInputChange}
        errorMessage={errorMessage}
      />
      {onDelete && (
        <span className={styles.clearButton} onClick={() => onDelete(uid)}>
          <IconClear />
        </span>
      )}
    </div>
  )
}
