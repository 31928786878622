import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import classNames from "classnames"

import {
  useLazyGetAdminDevicesQuery,
  useLazyGetInstalledDevicesWidgetQuery,
  useLazyGetPhysicalStatusesWidgetQuery,
  useLazyPutDevicesAsMissingQuery,
} from "store/api/adminApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setSelectedDeviceIDs } from "store/tempDataSlise"
import styles from "assets/scss/index.module.scss"
import { ReactComponent as IconMissing } from "assets/svg/modal-icon-missing.svg"

import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ModalLayout } from "layouts/ModalLayout"

interface Props {
  onCancel: () => void
}

export const MissingModalVendor = ({ onCancel }: Props) => {
  const [triggerPutDevicesAsMissing, { isFetching }] = useLazyPutDevicesAsMissingQuery()
  const [triggerGetPhysicalStatusesWidget] = useLazyGetPhysicalStatusesWidgetQuery()
  const [triggerGetInstalledDEvicesWidget] = useLazyGetInstalledDevicesWidgetQuery()
  const [triggerGetAdminDevices] = useLazyGetAdminDevicesQuery()
  const dispatch = useAppDispatch()
  const selectedDeviceIDs = useAppSelector(state => state.tempData.selectedDeviceIDs)
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const res = await triggerPutDevicesAsMissing({ device_ids: selectedDeviceIDs })
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success("The status of the selected device(s) was changed successfully!")
        dispatch(setSelectedDeviceIDs([]))
        triggerGetAdminDevices(urlQueryParams)
        triggerGetPhysicalStatusesWidget()
        triggerGetInstalledDEvicesWidget()
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconMissing />
            <h3 className={styles.modalTitle}>Mark as missing</h3>
            <div className={styles.modalMessage}>
              By changing the status of the selected device(s) to “Missing”, you confirm that these device(s) are no
              longer in your Stock. Are you sure you want to change the status of the selected device(s)?
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
