import { parentGroupOptions } from "constants/SelectFieldOptions"
import { ApiTypes, UITypes } from "types"

import { convertDataToOptions } from "utils/convertData"

export const parseApiDataToContractor = (contractor: ApiTypes.Model.Contractor): UITypes.Contractor => ({
  id: contractor.id,
  name: contractor.name,
  brand: contractor.brand,
  parentGroup: [parentGroupOptions.find(option => contractor.parent_group === option.value) || parentGroupOptions[0]],
  address1: contractor.address1,
  address2: contractor.address2 || "",
  zip: convertDataToOptions({ data: [contractor.zip] }, "id", "code"),
  state: convertDataToOptions({ data: [contractor.state] }, "id", "name"),
  city: convertDataToOptions({ data: [contractor.city] }, "id", "name"),
  country: convertDataToOptions({ data: [contractor.country] }, "id", "name"),
  suburb: convertDataToOptions({ data: [contractor.suburb] }, "id", "name"),
  isDisable: !contractor.is_enabled,
  isSTChecked: contractor.is_st_checked,
  logo: contractor.logo,
  logo2String: contractor.logo_w2h1,
  url: contractor.url,
  schedule: contractor.schedule,
  fullName: contractor.full_name,
  phone: contractor.tel1,
  email: contractor.email,
  alertEmail: contractor.alert_email,
  emailAlerts: contractor.email_alerts,
  isCustom: !!contractor.is_custom,
  appStoreLink: contractor.app_store_link || "",
  googlePlayLink: contractor.google_play_link || "",
  phoneForSupport: !!contractor.phone_for_support,
  phoneForSupportCall: contractor.phone_for_support_call || "",
  phoneForSupportText: contractor.phone_for_support_text || "",
  stClientID: contractor.st_client_id,
  stClientSecret: contractor.st_client_secret,
  stAppKey: contractor.st_app_key,
  stTenant: contractor.st_tenant,
  memberships: contractor.memberships,
  subscriptionPlan: contractor.subscription_plan,
})
