import SubscriptionPlan from "../types/enums/SubscriptionPlan"
import SubscriptionPlanTitles from "./SubscriptionPlanTitles"

const SubscriptionPlanTitlesWithPrefix = Object.entries(SubscriptionPlanTitles).reduce(
  (acc, [key, value]) => {
    // @ts-expect-error: The code below works fine, but TypeScript throws an error
    acc[key] = `Nuve ${value}`
    return acc
  },
  {} as Record<SubscriptionPlan, string>,
)

export default SubscriptionPlanTitlesWithPrefix
