import { useEffect, useState } from "react"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import styles from "./index.module.scss"

interface Props {
  className?: string
  items: {
    color: string
    value: number
  }[]
}

export const BarChart = ({ items, className }: Props) => {
  const [isAnimationEnabled, setAnimationEnabled] = useState(false)

  function sumValues(items: Props["items"]): number {
    let total = 0
    for (let i = 0; i < items.length; i++) {
      total += items[i].value
    }
    return total
  }

  const summ = sumValues(items)

  useEffect(() => {
    setAnimationEnabled(true)
  }, [])

  const calculateWidth = (value: number): number => {
    return Number(((value / summ) * 100).toFixed(1))
  }

  if (summ === 0) {
    return <div className={styles.noDataMessage}>No data</div>
  } else {
    return (
      <div className={classNames(styles.barWidgetWrapper, className)}>
        {Boolean(items?.length) &&
          items.map(item => (
            <div
              className={styles.barWidgetItem}
              key={uuidv4()}
              style={{
                width: isAnimationEnabled ? calculateWidth(item.value) + "%" : "",
                backgroundColor: item.color,
              }}
            />
          ))}
      </div>
    )
  }
}
