import { FormEvent, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"

import { useLazyEditUserQuery, useLazyGetUsersQuery } from "store/api/adminApi"
import { useAppSelector } from "store/hooks"
import { UITypes } from "types"
import styles from "assets/scss/index.module.scss"
import { ReactComponent as IconEditUser } from "assets/svg/modal-icon-edit-user.svg"

import { checkRoleType } from "utils/checkRoleType"
import getErrorMessage from "utils/getErrorMessage"
import { editUserSchema, onFormValidate, validationInitialState } from "utils/onValidate"
import { transformString } from "utils/transformString"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"
import { InputNew } from "components/form/InputNew"
import { InputPhone } from "components/form/InputPhone"
import { SelectStatic } from "components/form/SelectStatic"
import { ToggleButton } from "components/form/ToggleButton"

const inputValuesInitialState: UITypes.User = {
  fullName: "",
  email: "",
  phone: "",
  useRole: [],
  isDisable: undefined,
}

interface Props {
  onCancel: () => void
}

export const EditUserModal = ({ onCancel }: Props) => {
  const [triggerEditUser, { isFetching }] = useLazyEditUserQuery()
  const [triggerGetUsers] = useLazyGetUsersQuery()
  const editableUser = useAppSelector(state => state.tempData.editableUser)
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues(prevState => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const prepareData = () => {
    const data = {
      full_name: inputValues.fullName,
      phone: inputValues.phone,
      email: inputValues.email,
      is_enabled: !inputValues.isDisable,
    }

    return data
  }

  const sendRequest = async () => {
    try {
      const res = await triggerEditUser({ body: prepareData(), id: editableUser?.id as number })
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success("User has been successfully edited!")
        setInputValues(inputValuesInitialState)
        triggerGetUsers({})
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (onFormValidate(inputValues, editUserSchema, setError)) {
      sendRequest()
    }
  }

  const onCancelClick = () => {
    onCancel()
  }

  useEffect(() => {
    if (!editableUser) {
      return
    }

    setInputValues({
      fullName: editableUser.full_name,
      email: editableUser.email,
      phone: editableUser.phone,
      useRole: [
        {
          value: editableUser.role?.id as number,
          label: transformString(editableUser.role?.type as string),
        },
      ],
      isDisable: editableUser.is_enabled === "1" ? false : true,
    })
  }, [editableUser])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          confirmButtonType="submit"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          isFetching={isFetching}
        >
          <ModalHeader title="Edit user">
            <IconEditUser />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Full name"
              placeholder="Enter full name"
              name="fullName"
              value={inputValues.fullName}
              onChange={onInputChange}
              errorMessage={error.field === "fullName" ? error.message : ""}
            />
            <InputNew
              label="Email"
              placeholder="Enter Email"
              name="email"
              disabled
              value={inputValues.email}
              onChange={onInputChange}
              errorMessage={error.field === "email" ? error.message : ""}
            />
            <InputPhone
              label="Phone number"
              placeholder="Enter phone"
              name="phone"
              value={inputValues.phone}
              onChange={value => setInputValues({ ...inputValues, ...{ phone: value } })}
              errorMessage={error.field === "phone" ? error.message : ""}
            />
            <SelectStatic label="Role" options={[]} name="userRole" values={inputValues.useRole} disabled />
            {checkRoleType(editableUser?.role?.id) !== "vendor-admin" && (
              <div className={styles.toggleFieldWrapper}>
                <ToggleButton
                  id="isDisable"
                  isReverse
                  name="isDisable"
                  isChecked={inputValues.isDisable}
                  onChange={onInputChange}
                >
                  Disable user
                </ToggleButton>
              </div>
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
