import { createPortal } from "react-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import classNames from "classnames"

import { useLazyAcceptTermsOfUseQuery } from "store/api/authApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setUserProfile } from "store/userSlise"
import { ReactComponent as IconTerms } from "assets/svg/modal-icon-terms.svg"

import getErrorMessage from "utils/getErrorMessage"
import updateStorageUser from "utils/updateStorageUser"
import { ModalLayout } from "layouts/ModalLayout"

import styles from "./index.module.scss"

interface Props {
  onCancel: () => void
}

export const TermOfUseModal = ({ onCancel }: Props) => {
  const [triggerAcceptTermsOfUs, { isFetching }] = useLazyAcceptTermsOfUseQuery()
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector(state => state.user.userProfile)!

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const res = await triggerAcceptTermsOfUs()
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        updateStorageUser({ terms: true })
        dispatch(setUserProfile({ ...userProfile, terms: true }))
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onLogOut = () => {
    onCancel()
    sessionStorage.clear()
    const userTemperatureScale = localStorage.getItem("userTemperatureScale")
    localStorage.clear()
    localStorage.setItem("userTemperatureScale", userTemperatureScale!)
    window.location.reload()
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          cancelButtonText="Log out"
          confirmButtonText="Agree"
          isFetching={isFetching}
          onConfirm={onSubmit}
          onCancel={onLogOut}
        >
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconTerms />
            <h3 className={styles.modalTitle}>Term of Use & Privacy Policy</h3>
            <div className={styles.modalMessage}>
              I agree to the Privacy Policy and Terms of Use, which contain arbitration provisions waiving my right to a
              jury trial and my right to enforce this contract via class action.
              <div className={styles.modalLinksSection}>
                <Link className={styles.modalLink} to="https://www.nuvehome.com/terms-of-use/" target="_blank">
                  Terms of Use
                </Link>{" "}
                and{" "}
                <Link className={styles.modalLink} to="https://www.nuvehome.com/privacy-policy/" target="_blank">
                  Privacy Policy
                </Link>
                .
              </div>
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
