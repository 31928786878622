import dayjs from "dayjs"

import { UITypes } from "types"

export const timeSelectOptions: UITypes.Option[] = Array.from({ length: 48 }, (_, i) => {
  const noon = dayjs().hour(12).minute(0).second(0)
  const time = noon.add(i * 15, "minute")

  return {
    value: time.format("hh:mm"),
    label: time.format("hh:mm"),
  }
})
