import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { pick } from "lodash"

import getErrorMessage from "utils/getErrorMessage"

import { ReactComponent as IconEditUser } from "../../assets/svg/modal-icon-edit-columns.svg"
import { DADItem } from "../../components/common/DADItem"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ModalLayout } from "../../layouts/ModalLayout"
import { useLazyGetTableColumnsQuery, useLazyUpdateTableColumnsQuery } from "../../store/api/dashboardApi"
import { useAppSelector } from "../../store/hooks"
import { ApiTypes, UITypes } from "../../types"

import styles from "./index.module.scss"

interface Props {
  onCancel: () => void
}

export const EditColumnsModal = ({ onCancel }: Props) => {
  const [items, setItems] = useState<UITypes.TableHeaderCell[]>([])
  const currentTableColumns = useAppSelector(state => state.tempData.currentTableColumns)
  const [triggerUpdateTableColumnsQuery, { isFetching }] = useLazyUpdateTableColumnsQuery()
  const [triggerGetTableColumnsQuery] = useLazyGetTableColumnsQuery()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const onCancelClick = () => {
    onCancel()
  }

  function handleDragEnd(event: DragEndEvent) {
    if (!items?.length) return

    const { active, over } = event

    if (over && active.id !== over.id) {
      setItems(items => {
        const oldIndex = items?.findIndex(item => item.id === active.id)
        const newIndex = items?.findIndex(item => item.id === over.id)

        const newItems = arrayMove(items!, oldIndex!, newIndex!)
        return [...newItems] // создаем новый массив
      })
    }
  }

  const onVisibilityChange = (value: number) => {
    const result = items?.map(item => {
      if (item?.id === value) {
        return { ...item, ...{ visibility: !item.visibility } }
      }
      return item
    })

    setItems(result)
  }

  const sendRequest = async () => {
    try {
      const res = await triggerUpdateTableColumnsQuery({
        body: {
          list_name: currentTableColumns?.table as ApiTypes.Model.PageTableName,
          config: items.map(item => pick(item, ["label", "name", "visibility"])) as ApiTypes.Model.TableColumn[],
        },
      })
      const error = getErrorMessage(res?.error)

      if (res?.isSuccess) {
        toast.success("Table columns have been successfully updated!")
        triggerGetTableColumnsQuery({ list_name: currentTableColumns?.table as ApiTypes.Model.PageTableName })
        onCancel()
      }
      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = async () => {
    sendRequest()
  }

  useEffect(() => {
    if (currentTableColumns) {
      setItems(currentTableColumns.columns.map(item => ({ ...item, label: item.label || item.title })))
    }
  }, [currentTableColumns])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="760px"
          confirmButtonText="Save"
          buttonSize="auto"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          isFetching={isFetching}
        >
          <ModalHeader
            title="Edit columns"
            subtitle="Customize your view by hiding unnecessary columns and reordering them to suit your needs."
          >
            <IconEditUser />
          </ModalHeader>
          <div className={styles.draggableWrapper}>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              {Boolean(items?.length) && (
                <SortableContext items={items.map(item => item?.id as number)} strategy={verticalListSortingStrategy}>
                  {items?.map(item => <DADItem key={item?.id} {...item} onVisibilityChange={onVisibilityChange} />)}
                </SortableContext>
              )}
            </DndContext>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
