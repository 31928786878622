import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"

import { useLazyGetNewOrdersQuantityQuery } from "store/api/ordersApi"
import styles from "assets/scss/index.module.scss"
import logo from "assets/svg/logo-white.svg"
import { ReactComponent as IconAlerts } from "assets/svg/menu-alerts.svg"
import { ReactComponent as IconCustomers } from "assets/svg/menu-customers.svg"
import { ReactComponent as IconDashboard } from "assets/svg/menu-dashboard.svg"
import { ReactComponent as IconMessages } from "assets/svg/menu-messages.svg"
import { ReactComponent as IconMonitoring } from "assets/svg/menu-monitoring.svg"
import { ReactComponent as IconOrders } from "assets/svg/menu-orders.svg"
import { ReactComponent as IconPerformance } from "assets/svg/menu-performance.svg"

import { Hamburger } from "components/common/Hamburger"
import { NavLink } from "components/common/NavLink"
import { UserMenu } from "components/common/UserMenu"

export const ContractorTopbar = () => {
  const [triggerGetNewOrdersQuantity, { data }] = useLazyGetNewOrdersQuantityQuery()
  const [isSticky, setSticky] = useState(false)
  const [isOpen, setOpen] = useState(false)

  const closeMenu = () => {
    setOpen(false)
  }

  const handleScroll = () => {
    const distance = window.scrollY

    if (distance > 0 && !isSticky) {
      setSticky(true)
    } else if (distance === 0 && isSticky) {
      setSticky(false)
    }
  }

  useEffect(() => {
    const setStickyOnScroll = () => handleScroll()

    window.addEventListener("scroll", setStickyOnScroll)

    return () => {
      window.removeEventListener("scroll", setStickyOnScroll)
    }
  }, [isSticky])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetNewOrdersQuantity()
      } catch (error) {
        console.error(error)
      }
    }

    const intervalId = setInterval(fetchData, 60000)
    fetchData()
    return () => clearInterval(intervalId)
  }, [])

  return (
    <header className={classNames(styles.topbar, { [styles.sticky]: isSticky })}>
      <Link className={styles.logoWrapper} to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>
      <nav className={classNames(styles.menuWrapper, { [styles.opened]: isOpen })}>
        <NavLink to="/dashboard" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconDashboard className={styles.menuIcon} />
          Dashboard
        </NavLink>
        <NavLink to="/customers" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconCustomers className={styles.menuIcon} />
          Customers
        </NavLink>
        <NavLink to="/orders" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconOrders className={styles.menuIcon} />
          Orders
          {Boolean(data?.data?.quantity) && <span className={styles.redDot}>{data?.data?.quantity}</span>}
        </NavLink>
        {/* <NavLink to="/technicians" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconTechnicians className={styles.menuIcon} />
          Technicians
        </NavLink> */}
        <NavLink to="/messages" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconMessages className={styles.menuIcon} />
          Messages
        </NavLink>
        <NavLink to="/alerts" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconAlerts className={styles.menuIcon} />
          Alerts
        </NavLink>
        <NavLink to="/perf-test" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconPerformance className={classNames(styles.menuIcon, styles.menuIconPerformance)} />
          Performance Test
        </NavLink>
        <NavLink to="/monitoring" className={styles.menuItem} activeClassName={styles.active}>
          <IconMonitoring className={styles.menuIcon} />
          Monitoring
        </NavLink>
      </nav>
      <Hamburger onClick={() => setOpen(!isOpen)} isOpen={isOpen} />
      <UserMenu />
    </header>
  )
}
