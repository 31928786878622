import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { useLazyGetMessageHistoryQuery } from "store/api/contractorsApi"
import { UITypes } from "types"
import styles from "assets/scss/index.module.scss"

import { convertMessagesHistoryToTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import TableWrapper from "components/table/TableWrapper"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Title",
    type: "sort-search",
    name: "title",
  },
  {
    title: "Message",
    type: "sort-search",
    cellComponent: "markdown",
    name: "body",
  },
  {
    title: "Date",
    type: "sort-date",
    name: "date",
  },
  {
    title: "Delivered",
    type: "sort",
    size: "small",
    name: "delivered",
  },
  {
    title: "Pending",
    type: "sort",
    size: "small",
    name: "pending",
  },
  {
    title: "Failed",
    type: "sort",
    size: "small",
    name: "failed",
  },
]

export const MessageHistory = () => {
  const [triggerGetMessageHistory, { isFetching, data }] = useLazyGetMessageHistoryQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const navigate = useNavigate()
  const [isFirstRender, setFirstRender] = useState(true)

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res = await triggerGetMessageHistory(params)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender])

  const goToMessageIDDetails = (id: number) => {
    navigate(`/messages/report?id=${id}`)
  }

  return (
    <>
      <ContentWrapper
        title="Message history"
        breadcrumbs={[
          {
            label: "Message center",
            link: "/messages/center",
          },
          {
            label: "Message history",
            link: "/messages/history",
          },
        ]}
      >
        <nav className={styles.tableNavWrapper}>
          <div></div>
          <div className={styles.tableNavButtonsWrapper}>
            {/* <ButtonNew className={styles.tableNavItem} disabled>Export</ButtonNew> */}
          </div>
        </nav>
        <TableWrapper
          tableName="message-history"
          dynamicNavHandler1={goToMessageIDDetails}
          isLoading={isFetching}
          headerCells={tableHeaderCells}
          data={convertMessagesHistoryToTableRows(data?.data)}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
