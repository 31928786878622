import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import classNames from "classnames"

import {
  useLazyGetAdminAlertsQuery,
  useLazyGetVendorAlertAndDeviceWidgetQuery,
  useLazyGetVendorStatusesWidgetQuery,
} from "store/api/adminApi"
import {
  useLazyGetAlertAndDeviceWidgetQuery,
  useLazyGetAlertsQuery,
  useLazyGetAlertStatusesWidgetQuery,
  useLazyPostAlertUnfreezeQuery,
} from "store/api/alertsApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setCurrentAlert } from "store/tempDataSlise"
import styles from "assets/scss/index.module.scss"
import { ReactComponent as IconUnfreeze } from "assets/svg/modal-icon-unfreeze.svg"

import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ModalLayout } from "layouts/ModalLayout"

interface Props {
  onCancel?: () => void
}

export const AlertsUnfreezeModal = ({ onCancel }: Props) => {
  const isVendor = useAppSelector(state => state.user.userProfile?.contractor == null)
  const [triggerAlertUnfreeze, { isFetching }] = useLazyPostAlertUnfreezeQuery()
  const [triggerGetAdminAlerts] = useLazyGetAdminAlertsQuery()
  const [triggerGetVendorStatusesWidget] = useLazyGetVendorStatusesWidgetQuery()
  const [triggerGetVendorAlertAndDeviceWidget] = useLazyGetVendorAlertAndDeviceWidgetQuery()
  const [triggerGetAlerts] = useLazyGetAlertsQuery()
  const [triggerGetAlertStatusesWidget] = useLazyGetAlertStatusesWidgetQuery()
  const [triggerGetAlertsAndDeviceWidget] = useLazyGetAlertAndDeviceWidgetQuery()

  const dispatch = useAppDispatch()
  const currentAlert = useAppSelector(state => state.tempData.currentAlert)
  const { urlQueryParams } = useUrlQueryParams()

  const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()

    try {
      const res = await triggerAlertUnfreeze(currentAlert?.id as number)
      const error = getErrorMessage(res?.error)

      if (res?.error) {
        toast.error(error)
      } else {
        toast.success("Alert has been successfully unfrozen!")
        dispatch(setCurrentAlert(undefined))

        if (isVendor) {
          triggerGetAdminAlerts(urlQueryParams)
          triggerGetVendorStatusesWidget()
          triggerGetVendorAlertAndDeviceWidget()
        } else {
          triggerGetAlerts(urlQueryParams)
          triggerGetAlertStatusesWidget()
          triggerGetAlertsAndDeviceWidget()
        }
        onCancel?.()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {createPortal(
        <ModalLayout width="450px" isFetching={isFetching} onConfirm={onSubmit} onCancel={onCancel}>
          <div className={classNames(styles.modalContent, styles.center)}>
            <IconUnfreeze />
            <h3 className={styles.modalTitle}>Unfreeze</h3>
            <div className={styles.modalMessage}>
              By unfreezing you will start receiving alert(s) on the selected device(s). Are you sure you want to
              unfreeze the alert(s)?
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
