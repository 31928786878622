import { useEffect } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components/macro"

import { useLazyGetReordersListQuery } from "store/api/adminApi"

import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"

const ModalContent = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  max-height: 440px;
`

const Header = styled.div`
  font-size: 14px;
  color: #64748b;

  div {
    padding: 5px 0 20px;
  }
`

const Row = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  padding: 20px 0;

  border-bottom: 1px solid #e0e0e0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  span:first-child {
    flex: 7;
  }

  span:nth-child(2) {
    flex: 2;
  }

  span:last-child {
    flex: 3;
  }
`

const ListContent = styled.div`
  overflow-y: auto;
  flex: 1;
  max-height: calc(90vh - 140px);
`

interface ReordersListModalProps {
  selectedDateRange: [string, string]
  onClose: () => void
}

const ReordersListModal = ({ selectedDateRange, onClose }: ReordersListModalProps) => {
  const [triggerGetReordersList, { isFetching, data }] = useLazyGetReordersListQuery()

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetReordersList(
          {
            start_date: selectedDateRange[0],
            end_date: selectedDateRange[1],
          },
          true,
        )
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [selectedDateRange])

  return (
    <>
      {createPortal(
        <ModalLayout width="500px" isFetching={isFetching} onClose={onClose} isWithoutFooter>
          <ModalHeader title="Reorders" />
          <ModalContent>
            <Header>
              <Row>
                <span>Contractor name</span>
                <span>Devices</span>
                <span>Date</span>
              </Row>
            </Header>
            <ListContent>
              {data?.map(item => (
                <Row key={`${item.contractor_name}_${item.date}`}>
                  <span>{item.contractor_name}</span>
                  <span>{item.devices}</span>
                  <span>{item.date}</span>
                </Row>
              ))}
            </ListContent>
          </ModalContent>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}

export default ReordersListModal
