import { FormEvent, SetStateAction } from "react"
import classNames from "classnames"

import { contractorSubscriptionPlanOptions } from "constants/SelectFieldOptions"
import { UITypes } from "types"
import SubscriptionPlan from "types/enums/SubscriptionPlan"

import ModalSectionTitle from "components/common/ModalSectionTitle/ModalSectionTitle"
import { SelectStatic } from "components/form/SelectStatic"
import { ToggleButton } from "components/form/ToggleButton"

import styles from "./index.module.scss"

interface Props {
  inputValues: UITypes.Contractor
  isCreate?: boolean
  onInputChange: (event: FormEvent<HTMLInputElement>) => void
  setInputValues: (value: SetStateAction<UITypes.Contractor>) => void
}

const SubscriptionSection = ({ inputValues, isCreate, onInputChange, setInputValues }: Props) => {
  const currentPlan =
    contractorSubscriptionPlanOptions.find(option => option.value === inputValues.subscriptionPlan) ||
    contractorSubscriptionPlanOptions[2]

  const handleChange = (values: UITypes.Option[]) => {
    if (!values.length) return

    setInputValues(prevState => {
      return { ...prevState, subscriptionPlan: values[0]?.value as SubscriptionPlan }
    })
  }

  return (
    <div>
      <ModalSectionTitle title="Subscription plan" />
      <div className={classNames(styles.row, styles.subscriptionRow)}>
        <div className={styles.subscriptionPlanName}>
          <div>{currentPlan?.label}</div>
          <div>Monthly</div>
        </div>
        <div className={styles.subscriptionDropdownContainer}>
          <SelectStatic
            placeholder="Select subscription plan"
            values={[currentPlan]}
            options={contractorSubscriptionPlanOptions}
            onChange={handleChange}
            contentRenderer={() => <>Change plan</>}
            backspaceDelete={false}
          />
        </div>
      </div>
      {!isCreate && (
        <>
          <div className={classNames(styles.row, styles.subscriptionRow)}>
            <div className={classNames(styles.modalLine, styles.subscriptionSectionModalLine)}></div>
          </div>
          <div className={styles.row}>
            <div className={styles.toggleFieldWrapper}>
              <ToggleButton id="isDisable" name="isDisable" isChecked={inputValues.isDisable} onChange={onInputChange}>
                Disable contractor
              </ToggleButton>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SubscriptionSection
