export const getBarChartSettings = (labelPrefix: string) => {
  return {
    lastHour: {
      ticksCount: 1,
      labelY: `${labelPrefix} (minutes)`,
      labelX: "Hours",
      barWidth: 0.4,
      intervalItemsCount: 60,
      hourlyDivider: 1,
      suggestedMax: 60,
      dataType: "minutes",
    },
    last6Hours: {
      ticksCount: 6,
      labelY: `${labelPrefix} (minutes)`,
      labelX: "Hours",
      barWidth: 0.7,
      intervalItemsCount: 20,
      hourlyDivider: 1,
      suggestedMax: 60,
      dataType: "minutes",
    },
    last24Hours: {
      ticksCount: 24,
      labelY: `${labelPrefix} (minutes)`,
      labelX: "Hours",
      barWidth: 0.7,
      intervalItemsCount: 6,
      hourlyDivider: 1,
      suggestedMax: 60,
      dataType: "minutes",
    },
    yesterday: {
      ticksCount: 24,
      labelY: `${labelPrefix} (minutes)`,
      labelX: "Hours",
      barWidth: 0.7,
      intervalItemsCount: 6,
      hourlyDivider: 1,
      suggestedMax: 60,
      dataType: "minutes",
    },
    last3Days: {
      ticksCount: 3,
      labelY: `${labelPrefix} (hourly)`,
      labelX: "Days",
      barWidth: 0.5,
      intervalItemsCount: 48,
      hourlyDivider: 60,
      suggestedMax: 24,
      dataType: "hourly",
    },
    last7Days: {
      ticksCount: 7,
      labelY: `${labelPrefix} (hourly)`,
      labelX: "Days",
      barWidth: 0.7,
      intervalItemsCount: 24,
      hourlyDivider: 60,
      suggestedMax: 24,
      dataType: "hourly",
    },
    last30Days: {
      ticksCount: 30,
      labelY: `${labelPrefix} (hourly)`,
      labelX: "Days",
      barWidth: 0.7,
      intervalItemsCount: 6,
      hourlyDivider: 60,
      suggestedMax: 24,
      dataType: "hourly",
    },
    custom: {
      ticksCount: 0,
      labelY: `${labelPrefix} (hourly)`,
      labelX: "Days",
      barWidth: 0.7,
      intervalItemsCount: 6,
      hourlyDivider: 60,
      suggestedMax: 24,
      dataType: "hourly",
    },
  }
}
