import { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import {
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  tablePlugin,
  thematicBreakPlugin,
} from "@mdxeditor/editor"
import dayjs from "dayjs"

import { useLazyGetMessageReportQuery, useLazyGetMessageTemplateQuery } from "store/api/contractorsApi"
import { deviceAccessoriesOptions, hvacOptions } from "constants/SelectFieldOptions"
import { UITypes } from "types"
import { ReactComponent as DateIcon } from "assets/svg/message-report-date.svg"
import { ReactComponent as MessageIcon } from "assets/svg/message-report-message.svg"
import { ReactComponent as TitleIcon } from "assets/svg/message-report-title.svg"

import { convertMessageReportToTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import { StatusOfMessageReport } from "components/table/columnComponents/StatusOfMessageReport"
import TableWrapper from "components/table/TableWrapper"

import styles from "./index.module.scss"

// TABLE HEADER

const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Device S/N",
    type: "sort-search",
    name: "device_sn",
    size: "small",
  },
  {
    title: "Customer name",
    type: "sort-search",
    size: "middle",
    name: "client_name",
  },
  {
    title: "Country",
    type: "sort-search",
    size: "small",
    name: "country",
  },
  {
    title: "City",
    type: "sort-search",
    size: "small",
    name: "city",
  },
  {
    title: "Zip code",
    type: "sort-search",
    size: "small",
    name: "zip",
  },
  {
    title: "State",
    type: "sort-search",
    size: "small",
    name: "state",
  },
  {
    title: "Activation date",
    type: "sort-date",
    size: "middle",
    name: "activation_date",
  },
  {
    title: "Membership",
    type: "sort-search",
    size: "small",
    name: "membership",
  },
  {
    title: "Residence type",
    type: "sort-search",
    size: "middle",
    name: "residence_type",
  },
  {
    title: "System type",
    type: "sort-search",
    size: "small",
    name: "system_type",
  },
  {
    title: "Accessories",
    type: "select",
    size: "small",
    name: "accessories",
    options: deviceAccessoriesOptions,
  },
  {
    title: "HVAC",
    type: "select",
    size: "small",
    name: "installation_type",
    options: hvacOptions,
  },
  {
    title: "Status",
    type: "sort-search",
    size: "small",
    name: "status",
    cellComponent: StatusOfMessageReport,
  },
]

export const MessageReport = () => {
  const [triggerGetMessageReport, { isFetching, data }] = useLazyGetMessageReportQuery()
  const [triggerGetMessageTemplate, { data: messageTemplate }] = useLazyGetMessageTemplateQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const [isFirstRender, setFirstRender] = useState(true)
  const editorRef = useRef<MDXEditorMethods>(null)

  const sendRequest = async (params: UITypes.Params) => {
    if (!id) return

    try {
      const res = await triggerGetMessageReport({ id, params })
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSetMarkdown = (markdown: string) => {
    if (editorRef.current) {
      editorRef.current.setMarkdown(markdown)
    }
  }

  useEffect(() => {
    if (messageTemplate?.data?.body) {
      handleSetMarkdown(messageTemplate?.data?.body)
    }
  }, [messageTemplate?.data?.body])

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender])

  useEffect(() => {
    if (data?.data[0]?.message_template_id) {
      triggerGetMessageTemplate(data?.data[0]?.message_template_id)
    }
  }, [data?.data[0]?.message_template_id])

  return (
    <>
      <ContentWrapper
        title={messageTemplate?.data?.title}
        breadcrumbs={[
          {
            label: "Message center",
            link: "/messages/center",
          },
          {
            label: "Message history",
            link: "/messages/history",
          },
          {
            label: messageTemplate?.data?.title ? messageTemplate?.data?.title : "",
          },
        ]}
      >
        <nav className={styles.tableNavWrapper}>
          <div className={styles.detailsWrapper}>
            <div className={styles.detailsItem}>
              <h3 className={styles.detailsTitle}>
                <DateIcon className={styles.detailsIcon} />
                Date
              </h3>
              <div className={styles.detailsText}>{dayjs(messageTemplate?.data?.created_at).format("MM-DD-YYYY")}</div>
            </div>
            <div className={styles.detailsItem}>
              <h3 className={styles.detailsTitle}>
                <TitleIcon className={styles.detailsIcon} />
                Title
              </h3>
              <div className={styles.detailsText}>{messageTemplate?.data?.title}</div>
            </div>
            <div className={styles.detailsItem}>
              <h3 className={styles.detailsTitle}>
                <MessageIcon className={styles.detailsIcon} />
                Message
              </h3>
              <div className={styles.detailsText}>
                <MDXEditor
                  className={styles.markdownWrapper}
                  markdown={messageTemplate?.data?.body || ("" as string)}
                  readOnly={true}
                  ref={editorRef}
                  plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    tablePlugin(),
                    thematicBreakPlugin(),
                    markdownShortcutPlugin(),
                  ]}
                />
              </div>
            </div>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            {/* <ButtonNew className={styles.tableNavItem} disabled>Export</ButtonNew> */}
          </div>
        </nav>
        <TableWrapper
          headerCells={tableHeaderCells}
          isLoading={isFetching}
          data={convertMessageReportToTableRows(data?.data)}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
