import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { ApiTypes } from "types"

import { quotesClear } from "utils/quotesClear"
import { storageKeys } from "utils/storageKeys"

export const authApi = createApi({
  reducerPath: "auth",
  tagTypes: ["auth"],
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL_API }),
  endpoints: builder => ({
    login: builder.query<ApiTypes.Res.Login, ApiTypes.Req.login>({
      query: body => ({
        url: `/user/login`,
        method: "POST",
        body,
      }),
    }),
    forgotPassword: builder.query<null, ApiTypes.Req.ForgotPassword>({
      query: body => ({
        url: `/user/forgot-password`,
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.query<ApiTypes.Res.ResetPassword, ApiTypes.Req.ResetPassword>({
      query: body => ({
        url: `/user/reset-password`,
        method: "POST",
        body,
      }),
    }),
    createPassword: builder.query<void, ApiTypes.Req.CreatePassword>({
      query: body => ({
        url: `/user/create-password`,
        method: "POST",
        headers: { Accept: "application/json" },
        body,
      }),
    }),
    acceptTermsOfUse: builder.query<void, void>({
      query: () => {
        const token = localStorage.getItem(storageKeys.token)

        return {
          url: `/user/accept-terms`,
          method: "POST",
          body: { terms: true },
          headers: {
            Authorization: token ? `Bearer ${quotesClear(token)}` : "",
          },
        }
      },
    }),
  }),
})

export const {
  useLazyLoginQuery,
  useLazyForgotPasswordQuery,
  useLazyResetPasswordQuery,
  useLazyCreatePasswordQuery,
  useLazyAcceptTermsOfUseQuery,
} = authApi
