import dayjs, { Dayjs } from "dayjs"
import dayjs_plugin_utc from "dayjs/plugin/utc"

dayjs.extend(dayjs_plugin_utc)

export const dateOptions = [
  {
    value: "last14Days",
    label: "Last 14 Days",
  },
  {
    value: "last30Days",
    label: "Last 30 Days",
  },
  {
    value: "last90Days",
    label: "Last 90 Days",
  },
]

const setDateParam = (startDate: Dayjs, endDate: Dayjs) => {
  const startDateString = startDate.format("YYYY-MM-DD")
  const endDateString = endDate.format("YYYY-MM-DD")

  return {
    startDateTime: startDate,
    startDateString,
    endDateString,
  }
}

const getDaysCount = (value: string | number | boolean) => {
  if (value === "last14Days") {
    return 14
  }

  if (value === "last30Days") {
    return 30
  }

  return 90
}

export const getWarrantyDevicesDateRangeParams = (value: string | number | boolean) => {
  const daysCount = getDaysCount(value)

  const startDate = dayjs()
    .utc()
    .subtract(daysCount - 1, "day")
    .startOf("day")
  const endDate = dayjs().utc().startOf("day")

  return { ...setDateParam(startDate, endDate), daysCount }
}
