import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"

import {
  useLazyAddDevicesBulkQuery,
  useLazyGetWHDevicesQuery,
  useLazyGetWHManagerInfoQuery,
  useLazyValidateDevicesBulkQuery,
} from "store/api/warehouseManagerApi"
import { UITypes } from "types"
import { ReactComponent as IconAddDevice } from "assets/svg/modal-icon-add-device.svg"

import { convertValidatedDevicesToState } from "utils/convertData"
import { isValidDeviceSn } from "utils/isValidDeviceSn"
import { parseStringToArray, shouldParseString } from "utils/parseMultipleSNs"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"
import { Spinner } from "components/common/Spinner"
import { InputFile } from "components/form/InputFile"
import { InputSN } from "components/form/InputSN"

import styles from "./index.module.scss"

interface Props {
  onCancel: () => void
}

export const AddDeviceModal = ({ onCancel }: Props) => {
  const [triggerValidateDevices, { isFetching: isValidationFetching }] = useLazyValidateDevicesBulkQuery()
  const [triggerAddDevices, { isFetching }] = useLazyAddDevicesBulkQuery()
  const [triggerGetWHManagerInfo, { data }] = useLazyGetWHManagerInfoQuery()
  const [triggerGetWHMDevices] = useLazyGetWHDevicesQuery()
  const [isNoDataError, setNoDataError] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<FileList | null>(null)
  const { urlQueryParams } = useUrlQueryParams()
  const [validatedDevices, setValidatedDevices] = useState<UITypes.InvalidDevice[]>([
    {
      uid: uuidv4(),
      sn: "",
      errorMessage: "",
    },
  ])

  const onItemDelete = (id: string) => {
    const result = validatedDevices?.filter(item => item.uid !== id)
    setValidatedDevices(result)
  }

  const checkDuplicates = (devices: UITypes.InvalidDevice[], currentItem: UITypes.InvalidDevice) => {
    const snCount: Record<string, number> = {}

    devices.forEach(item => {
      if (item.sn && item.uid !== currentItem.uid) {
        snCount[item.sn] = (snCount[item.sn] || 0) + 1
      }
    })

    const isDuplicate = currentItem.sn && snCount[currentItem.sn] > 0

    return devices.map(item =>
      item.uid === currentItem.uid ? { ...item, errorMessage: isDuplicate ? `Duplicate sn: ${item.sn}` : "" } : item,
    )
  }

  const onItemBlur = (data: UITypes.InvalidDevice) => {
    // FOR MULTIPLE SN`S
    if (shouldParseString(data?.sn)) {
      const array = parseStringToArray(data.sn)

      const temp = array.map(item => ({
        uid: uuidv4(),
        sn: item,
        errorMessage: "",
      }))

      const updatedDevices = validatedDevices?.filter(item => item.uid !== data.uid)
      const result = checkDuplicates([...updatedDevices, ...temp], data)
      setValidatedDevices(result)
    }

    // FOR SINGLE SN
    else {
      const updatedDevices = validatedDevices?.map(item => {
        if (item.uid === data.uid) return data
        else return item
      })
      const result = checkDuplicates(updatedDevices || [], data)

      const parseResult = result.map(item => (item.uid === data.uid ? { ...item, uid: uuidv4() } : item))

      setValidatedDevices([
        ...parseResult.filter((item, index) => item.sn !== "" || index !== parseResult.length - 1),
        {
          uid: data.uid,
          sn: "",
          errorMessage: "",
        },
      ])
    }
  }

  const sendRequest = async (devices: string[]) => {
    try {
      const res = await triggerAddDevices({ serial_numbers: devices })

      if (res?.data?.data?.failed?.length) {
        setValidatedDevices(convertValidatedDevicesToState(res?.data?.data?.failed))
      } else {
        toast.success("Successfully added")
        triggerGetWHMDevices(urlQueryParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()

    if (checkSubmitAvailability()) {
      return
    }

    const serialNumbers: string[] = []

    validatedDevices.forEach(item => {
      if (item.sn) {
        serialNumbers.push(item.sn as never)
      }
    })

    if (!serialNumbers?.length) {
      setNoDataError(true)
      return
    }

    setNoDataError(false)
    sendRequest(serialNumbers)
  }

  const handleChange = (data: UITypes.InvalidDevice) => {
    setValidatedDevices(prev => prev.map(item => (item.uid === data.uid ? { ...item, sn: data.sn } : item)))
  }

  const checkSubmitAvailability = (): boolean => {
    if (
      validatedDevices?.some((item, index) => {
        if (item?.sn === "" && validatedDevices?.length === 1) return true
        if (item?.sn === "" && index !== validatedDevices?.length - 1) return true
        if (item?.sn !== "" && !isValidDeviceSn(item.sn)) return true
        if (item?.errorMessage !== "") return true
      })
    ) {
      return true
    }
    return false
  }

  // UPLOAD FILE
  useEffect(() => {
    if (!uploadedFiles) return

    const formData = new FormData()
    formData.append("file", uploadedFiles?.[0])

    const fetchData = async () => {
      try {
        const res = await triggerValidateDevices(formData)
        if (res?.data?.data?.failed || res?.data?.data?.success) {
          const failed = Array.isArray(res?.data?.data?.failed) ? res?.data?.data?.failed : []
          const success = Array.isArray(res?.data?.data?.success) ? res?.data?.data?.success : []
          setValidatedDevices(convertValidatedDevicesToState([...failed, ...success]))
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [uploadedFiles])

  // GET WH MANAGER INFO
  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetWHManagerInfo()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [uploadedFiles])

  // ADD LAST INPUT
  useEffect(() => {
    if (validatedDevices[validatedDevices?.length - 1]?.sn != "") {
      setValidatedDevices([
        ...validatedDevices,
        ...[
          {
            uid: uuidv4(),
            sn: "",
            errorMessage: "",
          },
        ],
      ])
    }
  }, [validatedDevices.length])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          buttonSize="auto"
          isDisabled={checkSubmitAvailability()}
          onCancel={onCancel}
          onConfirm={onSubmit}
          isFetching={isFetching}
        >
          <ModalHeader title="Add new device">
            <IconAddDevice />
          </ModalHeader>
          <div className={styles.modalContent}>
            <div className={styles.dataWrapper}>
              <div className={styles.dataItem}>
                <h3 className={styles.dataItemTitle}>WH Manager</h3>
                <span className={styles.dataItemText}>{data?.data?.warehouse_manager}</span>
              </div>
              <div className={styles.dataItem}>
                <h3 className={styles.dataItemTitle}>Warehouse</h3>
                <span className={styles.dataItemText}>{data?.data?.warehouse}</span>
              </div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.inputFileWrapper}>
              <InputFile
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                onFileReset={() => setValidatedDevices([])}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              {isValidationFetching && (
                <span className={styles.spinnerWrapper}>
                  <Spinner />
                </span>
              )}
            </div>
            <div className={styles.line}></div>
            {Boolean(validatedDevices?.length) &&
              validatedDevices.map((item, index) => (
                <InputSN
                  {...item}
                  row={index + 1}
                  key={item.uid}
                  onChange={handleChange}
                  onBlur={onItemBlur}
                  onDelete={onItemDelete}
                  autoFocus={validatedDevices?.length == index + 1 ? true : false}
                />
              ))}
            {isNoDataError && (
              <div className={classNames(styles.errorMessage, styles.errorMessageFullWith)}>
                Please upload the file or add the S/N manually
              </div>
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
