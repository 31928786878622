import { createApi } from "@reduxjs/toolkit/query/react"

import { ApiTypes, UITypes } from "types"

import { baseQueryWithAuth } from "utils/api"
import { prepareQueryParams } from "utils/prepareQueryParams"

export const ordersApi = createApi({
  reducerPath: "orders",
  tagTypes: ["orders"],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    createOrder: builder.query<ApiTypes.Model.Order, ApiTypes.Req.CreateOrder>({
      query: body => ({
        url: `/orders`,
        method: "POST",
        body,
      }),
    }),
    editNewOrder: builder.query<ApiTypes.Model.Order, { data: ApiTypes.Req.CreateOrder; id: number }>({
      query: body => ({
        url: `/orders/${body.id}/new`,
        method: "POST",
        body: body.data,
      }),
    }),
    fulfillOrder: builder.query<ApiTypes.Model.Order, number>({
      query: id => ({
        url: `/orders/${id}/fulfill`,
        method: "POST",
        headers: { accept: "application/json" },
      }),
    }),
    deleteNewOrder: builder.query<ApiTypes.Model.Order, number>({
      query: id => ({
        url: `/orders/${id}/new`,
        method: "DELETE",
      }),
    }),
    getOrders: builder.query<ApiTypes.Res.Orders, UITypes.Params>({
      query: arg => {
        return {
          url: `/orders`,
          method: "GET",
          params: prepareQueryParams(arg),
        }
      },
    }),
    getFulfilledOrders: builder.query<ApiTypes.Res.Orders, UITypes.Params>({
      query: arg => {
        return {
          url: `/orders/fulfilled`,
          method: "GET",
          params: prepareQueryParams(arg),
        }
      },
    }),
    getNewOrdersQuantity: builder.query<{ data: { quantity: number } }, void>({
      query: () => {
        return {
          url: `/orders/new-quantity`,
          method: "GET",
          headers: { Accept: "application/json" },
        }
      },
    }),
  }),
})

export const {
  useLazyCreateOrderQuery,
  useLazyGetOrdersQuery,
  useLazyEditNewOrderQuery,
  useLazyDeleteNewOrderQuery,
  useLazyFulfillOrderQuery,
  useLazyGetFulfilledOrdersQuery,
  useLazyGetNewOrdersQuantityQuery,
} = ordersApi

export const getOrders = ordersApi.endpoints.getOrders
