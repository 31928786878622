import { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { useLazyGetWarehouseListQuery } from "store/api/warehousesApi"
import { useAppDispatch } from "store/hooks"
import { showAddWarehouseModal, showEditWarehouseModal } from "store/modalSlise"
import { setEditableWarehouse } from "store/tempDataSlise"
import { UITypes } from "types"
import styles from "assets/scss/index.module.scss"

import { convertWarehousesToTableRows } from "utils/convertData"
import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ContentWrapper } from "components/common/ContentWrapper"
import { Pagination } from "components/common/Pagination"
import { ButtonNew } from "components/form/ButtonNew"
import { CheckboxNew } from "components/form/CheckboxNew"
import TableWrapper from "components/table/TableWrapper"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Warehouse",
    type: "sort-search",
    name: "name",
  },
  {
    title: "Devices Qty",
    type: "sort-search",
    size: "small",
    name: "devices_qty",
  },
  {
    title: "Address",
    type: "sort-search",
    name: "address",
  },
  {
    title: "Zip code",
    type: "sort-search",
    size: "small",
    name: "zip",
  },
  {
    title: "State",
    type: "sort-search",
    size: "small",
    name: "state",
  },
  {
    title: "Phone",
    type: "sort-search",
    name: "phone_number",
  },
  {
    title: "Manager",
    type: "sort-search",
    name: "manager",
  },
]

export const WarehouseList = () => {
  const dispatch = useAppDispatch()
  const [triggerGetWarehouseList, { isFetching, data }] = useLazyGetWarehouseListQuery()
  const { urlQueryParams, setUrlQueryParams } = useUrlQueryParams()
  const [isFirstRender, setFirstRender] = useState(true)

  const onAddClick = () => {
    dispatch(showAddWarehouseModal(true))
  }

  const onShowDisabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrlQueryParams({
      isEnabled: event?.target?.checked === true ? 0 : null,
    })
  }

  const onEditClick = (id: number) => {
    const result = data?.data?.filter(item => item?.id === id)
    if (result?.length) {
      dispatch(setEditableWarehouse(result[0]))
    }
    dispatch(showEditWarehouseModal(true))
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res = await triggerGetWarehouseList(params)
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(urlQueryParams)
  }, [urlQueryParams, isFirstRender])

  return (
    <>
      <ContentWrapper title="Warehouse List">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={onAddClick} color="blue">
              Add warehouse
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <CheckboxNew className={styles.tableNavItem} isReverse={true} id="fulfilled" onChange={onShowDisabled}>
              Show Disabled
            </CheckboxNew>
            {/* <ButtonNew className={styles.tableNavItem}>Export</ButtonNew> */}
          </div>
        </nav>
        <TableWrapper
          headerCells={tableHeaderCells}
          data={convertWarehousesToTableRows(data?.data)}
          isLoading={isFetching}
          onEditClick={onEditClick}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
