import { useState } from "react"
import { createPortal } from "react-dom"
import { toast } from "react-toastify"
import classNames from "classnames"

import { useLazyEditContractorQuery, useLazyGetContractorsQuery } from "store/api/contractorsApi"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setContractor, setCurrentZip } from "store/tempDataSlise"
import SubscriptionPlanTitlesWithPrefix from "constants/SubscriptionPlanTitlesWithPrefix"
import { UITypes } from "types"
import SubscriptionPlan from "types/enums/SubscriptionPlan"
import { ReactComponent as IconChangePlan } from "assets/svg/icon-change-plan.svg"
import { ReactComponent as IconContractor } from "assets/svg/modal-icon-contractor.svg"

import getErrorMessage from "utils/getErrorMessage"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"

import { ContractorModalContent } from "../components/contractorModalContent"

import styles from "./index.module.scss"

interface Props {
  onCancel: () => void
}

export const EditContractorModal = ({ onCancel }: Props) => {
  const [triggerEditContractor, { isFetching }] = useLazyEditContractorQuery()
  const [triggerGetContractors] = useLazyGetContractorsQuery()
  const { urlQueryParams } = useUrlQueryParams()
  const contractor = useAppSelector(state => state.tempData.contractor)
  const [editFormData, setEditFormData] = useState<FormData | null>(null)
  const [contractorRawData, setContractorRawData] = useState<UITypes.Contractor | null>(null)
  const [showSubscriptionPlanChangeModal, setShowSubscriptionPlanChangeModal] = useState(false)
  const dispatch = useAppDispatch()

  const onCancelClick = () => {
    onCancel()
    dispatch(setContractor(null))
    dispatch(setCurrentZip(undefined))
  }

  const onSubmit = async (data: FormData, contractorRawData: UITypes.Contractor) => {
    if (contractorRawData.subscriptionPlan !== contractor?.subscriptionPlan) {
      setEditFormData(data)
      setContractorRawData(contractorRawData)
      return setShowSubscriptionPlanChangeModal(true)
    }

    submit(data)
  }

  const submit = async (data: FormData) => {
    try {
      const res = await triggerEditContractor({
        data,
        id: contractor?.id || 0,
      })

      const errorMessage = getErrorMessage(res.error, "description")

      if (errorMessage) {
        toast.error(errorMessage)
      } else {
        toast.success("Contractor has been successfully edited!")
        dispatch(setCurrentZip(undefined))
        dispatch(setContractor(null))
        triggerGetContractors(urlQueryParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (!contractor) return null

  return (
    <>
      {showSubscriptionPlanChangeModal && (
        <div className={styles.changeSubscriptionSubModal}>
          <ModalLayout
            width="454px"
            buttonSize="halfWidth"
            onCancel={() => setShowSubscriptionPlanChangeModal(false)}
            onConfirm={() => submit(editFormData!)}
          >
            <div className={styles.modalBody}>
              <div className={styles.iconWrapper}>
                <IconChangePlan />
              </div>
              <h3>Change Subscription Plan</h3>
              <p>
                Are you sure you want to change the subscription plan from{" "}
                <span>“{SubscriptionPlanTitlesWithPrefix[contractor.subscriptionPlan || SubscriptionPlan.BASIC]}”</span>{" "}
                to{" "}
                <span>
                  “{SubscriptionPlanTitlesWithPrefix[contractorRawData?.subscriptionPlan || SubscriptionPlan.BASIC]}”
                </span>
                ?
              </p>
            </div>
          </ModalLayout>
        </div>
      )}
      {createPortal(
        <ModalLayout
          width="770px"
          onClose={onCancelClick}
          className={classNames({ [styles.hiddenModal]: showSubscriptionPlanChangeModal })}
          closeIconClassName={classNames(styles.closeIconClassName)}
          isDrawer
          isFetching={isFetching}
          isWithoutFooter
        >
          <ModalHeader title="Edit Contractor" borderless withShadow>
            <IconContractor />
          </ModalHeader>
          <ContractorModalContent contractor={contractor} onSubmit={onSubmit} />
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
