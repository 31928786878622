import { useEffect, useState } from "react"

import { useLazyGetMobileTrendsWidgetQuery } from "store/api/adminApi"

import { calculateLastDays } from "../utils"
import { WidgetWrapper } from "./styled"
import WidgetContent from "./WidgetContent"
import WidgetHeader from "./WidgetHeader"

const CustomerTrendsWidget = () => {
  const [selectedDateRange, setSelectedDateRange] = useState(calculateLastDays(7))
  const [triggerGetMobileTrendsWidget, { isFetching, data }] = useLazyGetMobileTrendsWidgetQuery()

  const contentDate = data
    ? [
        {
          title: "Customers",
          value: data.customers,
        },
        {
          title: "With mobile app",
          value: data.mobile_app_activated,
        },
      ]
    : []

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetMobileTrendsWidget(
          {
            start_date: selectedDateRange[0],
            end_date: selectedDateRange[1],
          },
          true,
        )
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [selectedDateRange])

  return (
    <WidgetWrapper>
      <WidgetHeader title="App/Customer trends" onSelectDateRange={setSelectedDateRange} />
      <WidgetContent isFetching={isFetching} data={contentDate} />
    </WidgetWrapper>
  )
}

export default CustomerTrendsWidget
