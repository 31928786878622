import { createApi } from "@reduxjs/toolkit/query/react"

import { ApiTypes, UITypes } from "types"

import { baseQueryWithAuth } from "utils/api"
import { prepareQueryParams } from "utils/prepareQueryParams"

export const clientApi = createApi({
  reducerPath: "clients",
  tagTypes: ["clients"],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    getClients: builder.query<ApiTypes.Res.Clients, UITypes.Params>({
      query: arg => ({
        url: `/clients`,
        params: prepareQueryParams(arg),
        headers: { Accept: "application/json" },
      }),
    }),
    getClient: builder.query<{ data: ApiTypes.Model.ClientDevices }, number>({
      query: id => ({
        url: `/clients/${id}`,
        headers: { Accept: "application/json" },
      }),
    }),
    updateClient: builder.query<void, { body: ApiTypes.Req.ClientDevices; id: number }>({
      query: data => ({
        url: `/clients/${data?.id}`,
        body: data?.body,
        method: "PUT",
        headers: { Accept: "application/json" },
      }),
    }),
  }),
})

export const { useLazyGetClientsQuery, useLazyGetClientQuery, useLazyUpdateClientQuery } = clientApi

export const getClients = clientApi.endpoints.getClients
