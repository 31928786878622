import { UITypes } from "types"

/*
1 - VENDOR_ADMIN // vendor
2 - VENDOR_SALES_MANAGER
3 - VENDOR_STORE_MANAGER // warehouse
4 - CONTRACTOR_MANAGER // contractor
5 - CONTRACTOR_ENGINEER  // technician
6 - CLIENT_ADMIN 
7 - CLIENT_USER 
*/

export const checkRoleType = (value?: number): UITypes.UserRole | undefined => {
  switch (value) {
    case 1:
      return "vendor-admin"
    case 2:
      return "sales-manager"
    case 3:
      return "vendor-warehouse"
    case 4:
      return "contractor-manager"
    case 5:
      return "contractor-technician"
    case 8:
      return "warehouse-manager"
    // case 6: return "client-admin";
    // case 7: return "client-user";

    default:
      return undefined
  }
}
