import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import classNames from "classnames"

import { useLazyGetContractorWarrantyDevicesByDateQuery } from "store/api/vendorApi"
import { ApiTypes, UITypes } from "types"

import { fillMissingData } from "utils/chartUtils"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"

import { dateOptions, getWarrantyDevicesDateRangeParams } from "./getWarrantyDevicesDateRangeParams"
import { WarrantyDevicesHistoryModalContent } from "./WarrantyDevicesModalContent"

import styles from "./index.module.scss"

interface WarrantyDevicesHistoryModalProps {
  contractorId: number
  onCancel: () => void
}

export const WarrantyDevicesHistoryModal = ({ onCancel, contractorId }: WarrantyDevicesHistoryModalProps) => {
  const [selectedTime, setSelectedTime] = useState<UITypes.Option[]>([
    dateOptions.find(option => option.value === "last90Days")!,
  ])
  const [triggerGetContractorWarrantyDevicesByDate, { isFetching }] = useLazyGetContractorWarrantyDevicesByDateQuery()
  const [data, setData] = useState<ApiTypes.Model.WarrantyDevicesCount[] | null>(null)
  const {
    startDateString,
    endDateString,
    startDateTime,
    daysCount = 0,
  } = getWarrantyDevicesDateRangeParams(selectedTime[0].value)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetContractorWarrantyDevicesByDate(
          {
            contractor_id: contractorId,
            start_date: startDateString,
            end_date: endDateString,
          },
          true,
        )

        if (res?.data) {
          const data = fillMissingData(res?.data.warranty_devices, startDateTime, daysCount)
          setData(data)
        }
      } catch (error) {
        console.error(error)
        setData([])
      }
    }

    fetchData()
  }, [contractorId, selectedTime[0].value])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="770px"
          onClose={onCancel}
          closeIconClassName={classNames(styles.closeIconClassName)}
          className={styles.WarrantyDevicesHistoryModalWrapper}
          isDrawer
          isFetching={isFetching}
          isWithoutFooter
        >
          <ModalHeader title="Warranty Devices" borderless />
          <WarrantyDevicesHistoryModalContent
            historicalData={data}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            daysCount={daysCount}
          />
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
