import { ApiTypes } from "types"
import SubscriptionPlan from "types/enums/SubscriptionPlan"
import SubscriptionPrivilege from "types/enums/SubscriptionPrivilege"

const SubscriptionPrivileges: Record<SubscriptionPlan, SubscriptionPrivilege[]> = {
  [SubscriptionPlan.GO]: [],
  [SubscriptionPlan.BASIC]: [],
  [SubscriptionPlan.PLUS]: [
    SubscriptionPrivilege.Monitoring,
    SubscriptionPrivilege.PerformanceTest,
    SubscriptionPrivilege.Alerts,
  ],
  [SubscriptionPlan.NETWORK]: [
    SubscriptionPrivilege.Monitoring,
    SubscriptionPrivilege.PerformanceTest,
    SubscriptionPrivilege.Alerts,
  ],
  [SubscriptionPlan.CONNECT]: [],
}

const hasSubscriptionAccess = (user: ApiTypes.Model.UserProfile, privilege: SubscriptionPrivilege) => {
  const privileges = SubscriptionPrivileges[user?.contractor?.subscription_plan || SubscriptionPlan.BASIC]

  return privileges.includes(privilege)
}

export default hasSubscriptionAccess
