import { useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"

import { getTemplateLibrary, useLazyCreateMessageTemplateQuery } from "store/api/contractorsApi"
import { useAppSelector } from "store/hooks"
import { ApiTypes, UITypes } from "types"
import { ReactComponent as BackIcon } from "assets/svg/icon-arrow-left.svg"

import getErrorMessage from "utils/getErrorMessage"
import { ContentWrapper } from "components/common/ContentWrapper"
import MessageTemplatesList from "components/common/MessageTemplatesList"
import { ButtonNew } from "components/form/ButtonNew"
import { MessageForm } from "components/form/MessageForm"

import styles from "./index.module.scss"

export const MessageTemplateAdd = () => {
  const [triggerCreateMessageTemplate, { isFetching }] = useLazyCreateMessageTemplateQuery()
  const [currentTemplateLibrary, setCurrentTemplateLibrary] = useState<ApiTypes.Model.TemplatesLibraryItem | null>(null)
  const [searchParams] = useSearchParams()
  const templateId = searchParams.get("id")
  const selecttTemplateLibrary = useMemo(() => getTemplateLibrary.select(), [])
  const templateLibrary = useAppSelector(selecttTemplateLibrary)

  const navigate = useNavigate()

  const trasformEditData = (): UITypes.MessageTemplate | undefined => {
    if (!currentTemplateLibrary) return undefined

    return {
      title: currentTemplateLibrary?.title,
      message: currentTemplateLibrary?.description,
    }
  }

  const onSetCurrentTemplate = () => {
    const templatesList = templateLibrary?.data?.data
    if (!templateId || !templatesList?.length) {
      setCurrentTemplateLibrary(null)
    } else {
      const currentTemplate = templatesList
        ?.flatMap(library => library?.items)
        ?.filter(item => item.id === Number(templateId))[0]
      setCurrentTemplateLibrary(currentTemplate)
    }
  }

  const onCreateTemplate = async (data: UITypes.MessageTemplate) => {
    try {
      const res = await triggerCreateMessageTemplate({
        title: data.title,
        body: data.message,
        repeat_every: "never",
      })
      const error = getErrorMessage(res?.error)

      if (error) {
        toast.error(error)
      } else {
        toast.success("Message template has been successfully added!")
        navigate("/messages/add-template")
        setCurrentTemplateLibrary(null)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    onSetCurrentTemplate()
  }, [templateLibrary, templateId])

  return (
    <>
      <ContentWrapper title="Add message template">
        <nav className={styles.tableNavWrapper}>
          <div></div>
          <ButtonNew onClick={() => navigate(-1)} color="greyBordered" className={styles.backButton}>
            <BackIcon />
            Back
          </ButtonNew>
        </nav>
        <div className={styles.contentWrapper}>
          <div className={styles.sectionWrapper}>
            <MessageForm isLoading={isFetching} onSave={onCreateTemplate} editableTemplate={trasformEditData()} />
          </div>
          <div className={styles.sectionWrapper}>
            <MessageTemplatesList />
          </div>
        </div>
      </ContentWrapper>
    </>
  )
}
