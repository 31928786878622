import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import classNames from "classnames"
import { Dayjs } from "dayjs"

import { useLazyGetContractorInstalledDevicesByDateQuery } from "store/api/vendorApi"
import { ApiTypes, UITypes } from "types"

import { fillMissingData } from "utils/chartUtils"
import { ModalLayout } from "layouts/ModalLayout"
import { ModalHeader } from "components/common/ModalHeader"

import { getInstalledDevicesDateRangeParams } from "../StatsReportModal/getInstalledDevicesDateRangeParams"
import { dropDownOptions } from "../StatsReportModal/getMonitoringDateRangeParams"
import { InstalledDevicesHistoryModalContent } from "./InstalledDevicesModalContent"

import styles from "./index.module.scss"

interface InstalledDevicesHistoryModalProps {
  contractorId: number
  onCancel: () => void
}

export const InstalledDevicesHistoryModal = ({ onCancel, contractorId }: InstalledDevicesHistoryModalProps) => {
  const [selectedTime, setSelectedTime] = useState<UITypes.Option[]>([
    dropDownOptions.find(option => option.value === "last30Days")!,
  ])
  const [selectedDateRange, setSelectedDateRange] = useState<[Dayjs, Dayjs] | null>(null)
  const [triggerGetContractorInstalledDevicesByDate, { isFetching }] = useLazyGetContractorInstalledDevicesByDateQuery()
  const [data, setData] = useState<ApiTypes.Model.InstalledDevicesCount[] | null>(null)
  const {
    startDateString,
    endDateString,
    startDateTime,
    daysCount = 0,
  } = getInstalledDevicesDateRangeParams(selectedTime[0].value, selectedDateRange)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetContractorInstalledDevicesByDate(
          {
            contractor_id: contractorId,
            start_date: startDateString,
            end_date: endDateString,
          },
          true,
        )

        if (res?.data) {
          const data = fillMissingData(res?.data.installed_devices, startDateTime, daysCount)
          setData(data)
        }
      } catch (error) {
        console.error(error)
        setData([])
      }
    }

    fetchData()
  }, [contractorId, selectedTime[0].value, selectedDateRange])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="770px"
          onClose={onCancel}
          closeIconClassName={classNames(styles.closeIconClassName)}
          className={styles.installedDevicesHistoryModalWrapper}
          isDrawer
          isFetching={isFetching}
          isWithoutFooter
        >
          <ModalHeader title="Installed Devices" borderless />
          <InstalledDevicesHistoryModalContent
            historicalData={data}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            daysCount={daysCount}
          />
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
