import { createApi } from "@reduxjs/toolkit/query/react"

import { ApiTypes, UITypes } from "types"

import { baseQueryWithAuth } from "utils/api"
import { prepareQueryParams } from "utils/prepareQueryParams"

export const warehouseManagerApi = createApi({
  reducerPath: "warehouseManager",
  tagTypes: ["warehouseManager"],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    getOrderStatuses: builder.query<{ data: ApiTypes.Res.OrderStatusesWidget }, void>({
      query: () => {
        return {
          url: `/warehouse-manager/widgets/order-statuses`,
          method: "GET",
        }
      },
    }),
    getDeviceStatuses: builder.query<{ data: ApiTypes.Res.DeviceStatusesWidget }, void>({
      query: () => {
        return {
          url: `/warehouse-manager/widgets/device-statuses`,
          method: "GET",
        }
      },
    }),
    getWHDevices: builder.query<ApiTypes.Res.WHManagerDevices, UITypes.Params>({
      query: arg => {
        return {
          url: `/warehouse-manager/devices`,
          method: "GET",
          params: prepareQueryParams(arg),
        }
      },
    }),
    getWHOrders: builder.query<ApiTypes.Res.WHManagerOrders, UITypes.Params>({
      query: arg => {
        return {
          url: `/warehouse-manager/orders`,
          method: "GET",
          params: prepareQueryParams(arg),
        }
      },
    }),
    getWHODevicesWidget: builder.query<{ data: ApiTypes.Res.WHManagerDevicesWidget }, void>({
      query: () => {
        return {
          url: `/warehouse-manager/widgets/device-in-warehouse`,
        }
      },
    }),
    updateWHDevicesStatus: builder.query<void, UITypes.Params>({
      query: arg => {
        return {
          url: `/warehouse-manager/devices/status`,
          method: "PUT",
          params: prepareQueryParams(arg),
        }
      },
    }),
    validateDevicesBulk: builder.query<{ data: ApiTypes.Res.DeviceValidattion }, FormData>({
      query: body => {
        return {
          url: `/warehouse-manager/validate-devices-bulk`,
          method: "POST",
          body,
        }
      },
    }),
    addDevicesBulk: builder.query<{ data: ApiTypes.Res.DeviceValidattion }, ApiTypes.Req.AddDevices>({
      query: body => {
        return {
          url: `/warehouse-manager/add-devices-bulk`,
          method: "POST",
          body,
          headers: { Accept: "application/json" },
        }
      },
    }),
    packDevices: builder.query<
      { data: ApiTypes.Res.DeviceValidattion },
      { body: ApiTypes.Req.PackDevices; id: number }
    >({
      query: data => {
        return {
          url: `/warehouse-manager/orders/${data.id}/packing`,
          method: "POST",
          body: data.body,
        }
      },
    }),
    shippDevices: builder.query<void, number>({
      query: id => {
        return {
          url: `/warehouse-manager/orders/${id}/ship`,
          method: "POST",
        }
      },
    }),
    getWHManagerInfo: builder.query<{ data: ApiTypes.Res.WHManagerInfo }, void>({
      query: body => {
        return {
          url: `/warehouse-manager/info`,
          body,
        }
      },
    }),
  }),
})

export const {
  useLazyGetOrderStatusesQuery,
  useLazyGetDeviceStatusesQuery,
  useLazyGetWHOrdersQuery,
  useLazyGetWHODevicesWidgetQuery,
  useLazyGetWHDevicesQuery,
  useLazyUpdateWHDevicesStatusQuery,
  useLazyValidateDevicesBulkQuery,
  useLazyGetWHManagerInfoQuery,
  useLazyAddDevicesBulkQuery,
  useLazyPackDevicesQuery,
  useLazyShippDevicesQuery,
} = warehouseManagerApi
